import item from "@/components/grid-me/item";
import Image from "@/components/image";
import { fp } from "@/utils/dimension";
import React from "react";
import { StyleSheet } from "react-native";
import { Button, Checkbox, Colors } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  id: string;
  price: string;
  priceDesc: string;
  title: string;
  limitDate: string;
  status?: number;
  onUsePressed?: (id: string) => void;
  onDrawPressed?: (id: string) => void;
}

/// the coupon selector component
const CouponCard: React.FC<Props> = (props) => {
  let item = null;
  if (props.status === 480) {
    item = (
      <Button
        mode="contained"
        onPress={() => props.onDrawPressed && props.onDrawPressed(props.id)}
      >
        去领取
      </Button>
    );
  } else if (props.status === 481) {
    item = (
      <Button
        mode="contained"
        onPress={() => props.onUsePressed && props.onUsePressed(props.id)}
      >
        去使用
      </Button>
    );
  } else if (props.status === 482) {
    item = (
      <Image
        style={styles.image}
        source={require("@/assets/images/me/me_coupon_used.png")}
      />
    );
  } else if (props.status === 483) {
    item = (
      <Image
        style={styles.image}
        source={require("@/assets/images/me/me_coupon_outdate.png")}
      />
    );
  }
  return (
    <CouponItemView>
      <ItemLeadingView>
        <PriceText>{props.price}</PriceText>
        <DescText>{props.priceDesc}</DescText>
      </ItemLeadingView>
      <ItemMiddleView>
        <TitleText>{props.title}</TitleText>
        <LimitText>有效期：{props.limitDate}</LimitText>
      </ItemMiddleView>
      <ItemEndView>{item}</ItemEndView>
    </CouponItemView>
  );
};

CouponCard.defaultProps = {
  status: 2000,
};

export default CouponCard;

const CouponItemView = styled.Pressable`
  flex-direction: row;
  align-items: center;
  margin: 8px 16px 8px 16px;
  padding: 20px 16px 20px 16px;
  border-width: 1px;
  border-color: ${Colors.grey200};
  border-radius: 8px;
  background-color: ${(props: IStyledProps) => props.theme.background};
`;
const ItemLeadingView = styled.View`
  justify-content: center;
  align-items: center;
`;
const PriceText = styled.Text`
  font-size: ${fp(5.7)}px;
  font-weight: bold;
  color: ${Colors.red400};
`;
const DescText = styled.Text`
  font-size: ${fp(3.2)}px;
  color: ${Colors.grey400};
  margin-top: 7px;
`;
const ItemMiddleView = styled.View`
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
`;
const TitleText = styled.Text`
  font-size: ${fp(3.8)}px;
  font-weight: bold;
  color: ${Colors.grey900};
`;
const LimitText = styled.Text`
  font-size: ${fp(3.2)}px;
  color: ${Colors.grey400};
  margin-top: 12px;
`;
const ItemEndView = styled.View``;

const styles = StyleSheet.create({
  image: {
    width: 64,
    height: 64,
  },
});
