import { wp } from "@/utils/dimension";
import React, { memo } from "react";
import { Platform, StyleSheet } from "react-native";
import Canvas from "react-native-canvas";
import styled from "styled-components/native";

import F2 from "@antv/f2";

// const F2 = require("@antv/f2/lib/core");
// require("@antv/f2/lib/geom/");
// require("@antv/f2/lib/geom/adjust/");
// require("@antv/f2/lib/coord/polar");
// require("@antv/f2/lib/component/axis/circle");
// require("@antv/f2/lib/scale/time-cat");
// require("@antv/f2/lib/component/guide");
// const Guide = require("@antv/f2/lib/plugin/guide");
// const Legend = require("@antv/f2/lib/plugin/legend");
// const Tooltip = require("@antv/f2/lib/plugin/tooltip");
// F2.Chart.plugins.register([Legend, Guide, Tooltip]);

interface Props {
  data: Array<any>;
  horizontal?: boolean;
}

/// bar chart
const AntBarChart: React.FC<Props> = (props) => {
  const handleCanvas = async (canvas: any) => {
    if (canvas) {
      const context = await canvas.getContext("2d");
      canvas.width = wp(100) - 36;
      canvas.height = 220;
      const chart = new F2.Chart({
        context: context,
        pixelRatio: window.devicePixelRatio,
        width: wp(100) - 36,
        height: props.horizontal ? 440 : 240,
      });
      chart.source(props.data, {
        value: {
          min: 0,
        },
        label: {
          tickCount: 6,
        },
      });
      chart.coord({
        transposed: props.horizontal,
      });
      chart.tooltip({
        showItemMarker: true,
        onShow: (ev: any) => {
          const items = ev.items;
          items[0].name = null;
          items[0].name = items[0].title;
          items[0].value = items[0].value;
        },
      });
      chart.interval().position("label*value");
      chart.render();
      // 绘制柱状图文本
      // const offset = -5;
      // const chartCanvas = chart.get("canvas");
      // const group = chartCanvas.addGroup();
      // const shapes: any = [];
      // props.data.forEach((obj: any) => {
      //   const point = chart.getPosition(obj);
      //   const text = group.addShape("text", {
      //     attrs: {
      //       x: point.x,
      //       y: point.y + offset,
      //       text: obj.value,
      //       textAlign: "center",
      //       textBaseline: "bottom",
      //       fill: "#808080",
      //     },
      //   });
      //   shapes[obj.label] = text;
      // });
    }
  };
  return (
    <ChartView>
      {Platform.OS === "web" ? (
        <canvas ref={handleCanvas} />
      ) : (
        <Canvas ref={handleCanvas} />
      )}
    </ChartView>
  );
};

AntBarChart.defaultProps = {
  data: [],
  horizontal: false,
};

export default memo(AntBarChart);

const ChartView = styled.View``;

const styles = StyleSheet.create({});
