import Page from "@/components/page";
import PdfViewer from "@/components/pdf";
import ShareAlert from "@/components/share/share-alert";
import { useMount } from "@/react-native-ahook";
import { GET_TRADE_REPORT_LINK } from "@/redux/models/trade";
import { createAction } from "@/utils";
import { maxWidth, sw, wp } from "@/utils/dimension";
import request from "@/utils/request";
import { aesDecode, isWechat } from "@/utils/tools";
import { useNavigation, useRoute } from "@react-navigation/native";
import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";
import * as Clipboard from "expo-clipboard";
import { LinkTo } from "@/utils/link";
import { ProcessUrl } from "@/utils/image";
import { saveAs } from "@/utils/download";

interface Props {}

/// Screen: Trade PDF View
const TradePdfScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const route: any = useRoute();
  const navigation = useNavigation();
  const { item } = route.params;
  const { tradePk, title, pageNum, type } = JSON.parse(aesDecode(item));
  const [downloadVisible, setDownloadVisible] = React.useState(false);
  const { tradeReportLink } = useSelector(({ trade, loading }: any) => ({
    tradeReportLink: trade.tradeReportLink,
  }));
  const { fileUrl } = tradeReportLink;
  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: title,
    });
  }, [navigation, title]);

  useMount(() => {
    dispatch(
      createAction(GET_TRADE_REPORT_LINK)({
        type: type,
        pk: tradePk,
      })
    );
  });

  const onDownloadPress = () => {
    if (isWechat()) {
      setDownloadVisible(true);
    } else {
      saveAs(ProcessUrl(fileUrl), title);
    }
  };

  return (
    <Page>
      <PageView>
        <PdfViewer file={fileUrl} pageNum={pageNum || 0} />
      </PageView>
      <Pressable onPress={() => onDownloadPress()}>
        <DownloadImage
          source={require("@/assets/images/trade/trade_download.png")}
        />
      </Pressable>
      {downloadVisible && (
        <ShareAlert
          headTitle="研报下载链接复制成功"
          title="你可打开手机浏览器，粘贴链接下载"
          visible={downloadVisible}
          onClose={() => {
            Clipboard.setString(ProcessUrl(fileUrl));
            setDownloadVisible(false);
          }}
        />
      )}
    </Page>
  );
};

export default TradePdfScreen;

const PageView = styled.ScrollView``;

const DownloadImage = styled.Image`
  position: fixed;
  width: ${wp(10)}px;
  height: ${wp(10)}px;
  /* left: ${wp(10)}px; */
  right: 12px;
  bottom: 12px;
`;

const styles = StyleSheet.create({});
