import ButtonSelector from "@/components/button-selector";
import TextInput from "@/components/textinput";
import TradeItem from "@/components/trade/trade-item";
import { TOAST_LOADING } from "@/constants/app";
import { useMount } from "@/react-native-ahook";
import { NoticeBar, Toast } from "@/react-native-papal";
import { GET_TRADE_DIMENSION } from "@/redux/models/trade";
import { createAction, createActions } from "@/utils";
import { fp, wp } from "@/utils/dimension";
import { aesEncode } from "@/utils/tools";
import { uuid } from "@/utils/uuid";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { FlatList, StyleSheet } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

interface Props {}

const SELECTOR_DATA = [
  {
    id: 1,
    title: "搜图表",
  },
  {
    id: 2,
    title: "搜研报",
  },
];

// the trade screen
const TradeScreen = (props: Props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [keyIndex, setKeyIndex] = React.useState(1);
  const [placeholder, setPlaceholder] =
    React.useState("输入行业关键词 快速搞懂一个行业");
  const { tradeDimension } = useSelector(({ trade, loading }: any) => ({
    tradeDimension: trade.tradeDimension,
  }));

  useMount(() => {
    const loadingKey = Toast.loading("...", TOAST_LOADING);
    dispatch(
      createActions(GET_TRADE_DIMENSION)({})(() => {
        Toast.remove(loadingKey);
      })
    );
  });

  // render header
  const renderHeader = () => {
    return (
      <ImageBackground
        resizeMode="cover"
        source={require("@/assets/images/home/home_banner_1.png")}
      >
        <LogoImage source={require("@/assets/images/home/logo_show.png")} />
        <HeaderView>
          <TitleText>对行业认知深度{"\n"}就是行业竞争力</TitleText>
          <ButtonSelector
            style={styles.buttonSelector}
            data={SELECTOR_DATA}
            onChange={(key: number) => {
              setKeyIndex(key);
            }}
          />
          <TextInput
            editable={false}
            selectTextOnFocus={false}
            placeholderTextColor={Colors.grey400}
            styles={styles.textInput}
            placeholder={placeholder}
            onPress={() => {
              if (keyIndex === 1) {
                navigation.navigate("TradeChart", {
                  items: aesEncode(JSON.stringify({ pk: "" })),
                });
              } else if (keyIndex === 2) {
                navigation.navigate("TradeReport");
              }
            }}
          />
        </HeaderView>
        {/* <DescView>
          <DescText>400+{"\n"}细分行业</DescText>
          <DescText>100W+{"\n"}行业图表</DescText>
          <DescText>700W+{"\n"}行业数据</DescText>
        </DescView> */}
        <NoticeView>
          <NoticeText>开放测试期：2022.01.10-2022.02.09</NoticeText>
        </NoticeView>
      </ImageBackground>
    );
  };
  // render dimension
  const renderDimensionHeader = () => {
    return (
      <BrandHeaderView>
        <BrandTitle>十二大行业维度</BrandTitle>
      </BrandHeaderView>
    );
  };

  const renderItem = React.useCallback(
    ({ item }) => (
      <TradeItem
        key={uuid()}
        title={item.name}
        desc={item.descs}
        subDesc={item.subDesc}
        source={item.imgSrc}
        onPress={() =>
          navigation.navigate("TradeChart", {
            items: aesEncode(JSON.stringify(item)),
          })
        }
      />
    ),
    []
  );
  const keyExtractor = React.useCallback((item) => uuid(), []);

  const renderDescView = () => {
    return (
      <SceneView>
        <FlatList
          data={tradeDimension || []}
          numColumns={2}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ListHeaderComponent={renderDimensionHeader}
        />
      </SceneView>
    );
  };
  return (
    <PageView>
      <NoticeBar
        marqueeProps={{
          loop: true,
          leading: 5000,
          style: { fontSize: 13, color: "red" },
        }}
      >
        当前为测试版本，供部分用户体验使用，测试期间数据部分完整(逐渐更新)，功能可能不稳定(逐渐迭代)，欢迎反馈。{" "}
      </NoticeBar>
      {renderHeader()}
      {renderDescView()}
    </PageView>
  );
};

export default TradeScreen;

const PageView = styled.ScrollView`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;

const ImageBackground = styled.ImageBackground`
  width: 100%;
  height: 254px;
`;
const HeaderView = styled.View`
  align-items: center;
  padding: 16px;
`;
const TitleText = styled.Text`
  font-size: ${fp(5)}px;
  color: ${Colors.white};
  line-height: 28px;
  text-align: center;
`;
const DescView = styled.View`
  flex-direction: row;
  justify-content: space-around;
  padding: 0px 16px 0px 16px;
`;
const DescText = styled.Text`
  text-align: center;
  color: ${Colors.white};
  line-height: 20px;
  /* margin-top: -8px; */
  font-size: ${fp(3.5)}px;
`;

const BrandHeaderView = styled.View`
  justify-content: center;
  align-items: center;
  padding: 12px 12px 12px 12px;
`;
const BrandTitle = styled(Paragraph)`
  font-size: ${fp(4.8)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  font-weight: bold;
`;

const SceneView = styled.View`
  margin: 12px 12px 12px 12px;
  /* background-color: ${Colors.white}; */
  /* padding: 16px; */
  border-radius: 5px;
`;
const LogoImage = styled.Image`
  width: 88px;
  height: 17px;
  margin-top: 10px;
  margin-left: 10px;
`;

const NoticeView = styled.View`
  padding: 0px 16px 0px 16px;
  margin-top: -4px;
`;
const NoticeText = styled.Text`
  text-align: center;
  color: ${Colors.white};
  line-height: 20px;
  font-size: ${fp(3)}px;
`;

const styles = StyleSheet.create({
  textInput: {},
  buttonSelector: {
    marginTop: 0,
  },
});
