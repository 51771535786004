import { fp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  title: string;
  desc: string;
}
/// 邀请项
const InviteHeaderItem: React.FC<Props> = (props) => {
  return (
    <ContentView>
      <Title>{props.title}</Title>
      <DescText>{props.desc}</DescText>
    </ContentView>
  );
};

export default React.memo(InviteHeaderItem);

const ContentView = styled.View`
  padding: 16px 12px 16px 12px;
  align-items: center;
  justify-content: center;
`;
const Title = styled(Paragraph)`
  color: ${(props: IStyledProps) => props.theme.redText};
  font-size: ${fp(6.5)}px;
  font-weight: bold;
`;

const DescText = styled(Paragraph)`
  color: ${(props: IStyledProps) => props.theme.paragraph};
  font-size: ${fp(3.5)}px;
  margin-top: 6px;
`;

const styles = StyleSheet.create({});
