import Page from "@/components/page";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";
import InviteHeaderItem from "@/components/invite/header-item";
import InviteSettleItem from "@/components/invite/settle-item";
import { fp, wp } from "@/utils/dimension";
import { usePagination } from "@/react-native-ahook";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import { aesDecode, fenToYuan } from "@/utils/tools";
import { Toast } from "@/react-native-papal";
import { TOAST_LOADING } from "@/constants/app";
import { createActions } from "@/utils";
import { PAGE_INVITE_EARN } from "@/redux/models/me";
import { uuid } from "@/utils/uuid";

interface Props {}

/// 累计收益
const EarningsScreen: React.FC<Props> = (props) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const route: any = useRoute();
  const { item, type } = route.params;
  const { data, addData, onEndReached, pageIndex, ListFooterComponent } =
    usePagination(20);
  const { earnFeeCount, earnFee, settleFee } = JSON.parse(aesDecode(item));

  React.useEffect(() => {
    let loadingKey: number;
    if (pageIndex == 0) {
      loadingKey = Toast.loading("...", TOAST_LOADING);
    }
    dispatch(
      createActions(PAGE_INVITE_EARN)({
        current: pageIndex + 1,
        size: 20,
        type: type,
      })((data: any) => {
        Toast.remove(loadingKey);
        addData(data.records || []);
      })
    );
  }, [pageIndex]);

  const renderHeader = () => {
    return (
      <PageHeaderView>
        <HeaderView>
          <InviteHeaderItem title={earnFeeCount} desc="佣金数(笔)" />
          <Divider />
          <InviteHeaderItem title={fenToYuan(earnFee)} desc="累计佣金(元)" />
        </HeaderView>
        <SettleView>
          <SettleText>待结算(元)：</SettleText>
          <SettleRedText>{fenToYuan(settleFee)}</SettleRedText>
          <ButtonView>
            <ButtonText onPress={() => navigation.navigate("SettleDetail")}>
              结算明细
            </ButtonText>
          </ButtonView>
        </SettleView>
      </PageHeaderView>
    );
  };

  const renderFlatListHeader = () => {
    return (
      <ListHeaderView>
        <ListTitle>好友付费记录</ListTitle>
        <ListSectionView>
          <ListSectionFirstText>好友</ListSectionFirstText>
          <ListSectionAmountText>实付</ListSectionAmountText>
          <ListSectionAmountText>佣金</ListSectionAmountText>
          <ListSectionText>交易日期</ListSectionText>
        </ListSectionView>
      </ListHeaderView>
    );
  };

  const renderItem = React.useCallback(
    ({ item }) => <InviteSettleItem item={item} />,
    []
  );
  const keyExtractor = React.useCallback((item) => uuid(), []);

  return (
    <Page>
      {renderHeader()}
      <ListView
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        contentContainerStyle={{ flexGrow: 1 }}
        data={data || []}
        renderItem={renderItem}
        ListHeaderComponent={renderFlatListHeader()}
        ListFooterComponent={ListFooterComponent}
        keyExtractor={keyExtractor}
      />
    </Page>
  );
};

export default EarningsScreen;

const PageHeaderView = styled.View``;

const HeaderView = styled.View`
  background-color: ${Colors.white};
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
const Divider = styled.View`
  width: ${StyleSheet.hairlineWidth}px;
  height: 16px;
  background-color: ${(props: IStyledProps) => props.theme.divider};
`;

const SettleView = styled.View`
  background-color: ${Colors.white};
  flex-direction: row;
  align-items: center;
  padding: 12px;
  margin-top: 6px;
`;
const SettleText = styled(Paragraph)`
  font-size: ${fp(4)}px;
  font-weight: bold;
`;

const SettleRedText = styled(Paragraph)`
  font-size: ${fp(4.5)}px;
  color: ${(props: IStyledProps) => props.theme.redText};
`;

const ButtonView = styled.View`
  flex: 1;
  align-items: flex-end;
`;
const ButtonText = styled(Paragraph)`
  background-color: ${Colors.orange700};
  padding: 4px 12px 4px 12px;
  border-radius: 32px;
  color: ${Colors.white};
`;

const ListView = styled.FlatList``;
const ListHeaderView = styled.View``;
const ListTitle = styled(Paragraph)`
  padding: 6px 12px 6px 12px;
  font-size: ${fp(4.1)}px;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const ListSectionView = styled.View`
  padding: 6px 12px 6px 12px;
  background-color: ${Colors.white};
  flex-direction: row;
  align-items: center;
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${(props: IStyledProps) => props.theme.subPrimary};
  /* justify-content: space-between; */
`;
const ListSectionText = styled(Paragraph)`
  font-size: ${fp(3.8)}px;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const ListSectionFirstText = styled(Paragraph)`
  width: ${wp(35)}px;
  font-size: ${fp(3.8)}px;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const ListSectionAmountText = styled(Paragraph)`
  width: ${wp(14)}px;
  font-size: ${fp(3.8)}px;
  color: ${(props: IStyledProps) => props.theme.text};
`;

const styles = StyleSheet.create({});
