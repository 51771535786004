import { fp, wp } from "@/utils/dimension";
import { HandleVipTerm, ParseVipCode, parseVipCodeToName } from "@/utils/vip";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  item: any;
}

const MemberRecordItem = (props: Props) => {
  return (
    <ItemView>
      <DateText>
        {dayjs(props.item.crtTime).format("YYYY-MM-DD HH:mm")}
      </DateText>
      <AmountText>{parseVipCodeToName(props.item.vipTypeName)}</AmountText>
      <AmountText>{HandleVipTerm(props.item.vipTerm)}</AmountText>
    </ItemView>
  );
};

export default React.memo(MemberRecordItem);

const ItemView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.white};
  padding: 12px;
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${(props: IStyledProps) => props.theme.subPrimary};
`;

const AmountText = styled(Paragraph)`
  font-size: ${fp(3.7)}px;
  width: ${wp(30)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  text-align: right;
`;

const DateText = styled(Paragraph)`
  width: ${wp(40)}px;
  font-size: ${fp(3.7)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;

const styles = StyleSheet.create({});
