/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */

import { Ionicons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { Platform } from "react-native";
import { useTheme, Colors } from "react-native-paper";
import { navigationRef } from "@/utils/navigate";
import NotFoundScreen from "../screens/NotFoundScreen";

import {
  RootStackParamList,
  RootTabParamList,
  RootTabScreenProps,
} from "../types";
import LinkingConfiguration from "./LinkingConfiguration";
import HomeScreen from "@/screens/home";
import EconomyScreen from "@/screens/economy";
import TradeScreen from "@/screens/trade";
import EnterpriseScreen from "@/screens/enterprise";
import MeScreen from "@/screens/me";
import PersonalInfoScreen from "@/screens/me/info";
import MemberScreen from "@/screens/me/member";
import CouponScreen from "@/screens/me/coupon";
import RedeemScreen from "@/screens/me/redeem";
import PresentScreen from "@/screens/me/present";
import AccountScreen from "@/screens/me/account";
import EconomySearchScreen from "@/screens/economy/search";
import EconomyDetailScreen from "@/screens/economy/details";
import LoginScreen from "@/screens/account/login";
import InviteScreen from "@/screens/me/invite";
import PartnerScreen from "@/screens/me/partner";
import PartnerCenterScreen from "@/screens/me/partner/center";
import WithdrawScreen from "@/screens/me/invite/withdraw";
import EarningsScreen from "@/screens/me/invite/earnings";
import InviteRecordScreen from "@/screens/me/invite/invite-record";
import EnterpriseListScreen from "@/screens/enterprise/list";
import EnterpriseDetailScreen from "@/screens/enterprise/details";
import MobileBindScreen from "@/screens/me/info/mobile-bind";
import PresentRecordScreen from "@/screens/me/present/record";
import EnterpriseSearchScreen from "@/screens/enterprise/search";
import NationInfoScreen from "@/screens/enterprise/details/nation-info";
import CityInfoScreen from "@/screens/enterprise/details/city-info";
import PerCustomerScreen from "@/screens/enterprise/details/per-customer";
import FeedbackRateScreen from "@/screens/enterprise/details/feedback-rate";
import TradeChartScreen from "@/screens/trade/chart";
import TradeReportScreen from "@/screens/trade/report";
import TradePdfScreen from "@/screens/trade/pdf-view";
import SettleDetailScreen from "@/screens/me/invite/settle-detail";
import AccountRecordScreen from "@/screens/me/partner/account-record";
import AuthRecordScreen from "@/screens/me/partner/auth-record";
import AuthVipScreen from "@/screens/me/partner/auth-vip";
import MemberOrderRecordScreen from "@/screens/me/member/order-record";

export default function Navigation({ theme }: { theme: any }) {
  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      theme={theme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerTintColor: Colors.black,
        headerShown: Platform.OS === "web" ? false : true,
        headerShadowVisible: false,
      }}
    >
      <Stack.Screen
        name="Root"
        component={BottomTabNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />
      {/* <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{ title: "登录" }}
        />
      </Stack.Group> */}
      <Stack.Screen
        name="PersonalInfo"
        component={PersonalInfoScreen}
        options={{ title: "个人资料" }}
      />
      <Stack.Screen
        name="Member"
        component={MemberScreen}
        options={{ title: "会员付费" }}
      />
      <Stack.Screen
        name="Invite"
        component={InviteScreen}
        options={{ title: "邀请得VIP" }}
      />
      <Stack.Screen
        name="Withdraw"
        component={WithdrawScreen}
        options={{ title: "结算明细" }}
      />
      <Stack.Screen
        name="InviteRecord"
        component={InviteRecordScreen}
        options={{ title: "邀请记录" }}
      />
      <Stack.Screen
        name="Earnings"
        component={EarningsScreen}
        options={{ title: "累计收益" }}
      />
      <Stack.Screen
        name="Partner"
        component={PartnerScreen}
        options={{ title: "合伙人" }}
      />
      <Stack.Screen
        name="PartnerCenter"
        component={PartnerCenterScreen}
        options={{ title: "合伙人中心" }}
      />
      <Stack.Screen
        name="Coupon"
        component={CouponScreen}
        options={{ title: "优惠券" }}
      />
      <Stack.Screen
        name="Redeem"
        component={RedeemScreen}
        options={{ title: "兑换码" }}
      />
      <Stack.Screen
        name="Present"
        component={PresentScreen}
        options={{ title: "赠送好友VIP" }}
      />
      <Stack.Screen
        name="Account"
        component={AccountScreen}
        options={{ title: "子账号管理" }}
      />
      <Stack.Screen
        name="EconomySearch"
        component={EconomySearchScreen}
        options={{ title: "搜索指标" }}
      />
      <Stack.Screen
        name="EconomyDetail"
        component={EconomyDetailScreen}
        options={{ title: "经济数据" }}
      />
      <Stack.Screen
        name="EnterpriseList"
        component={EnterpriseListScreen}
        options={{ title: "企业数据" }}
      />
      <Stack.Screen
        name="EnterpriseDetail"
        component={EnterpriseDetailScreen}
        options={{ title: "司马诸葛" }}
      />
      <Stack.Screen
        name="MobileBind"
        component={MobileBindScreen}
        options={{ title: "手机验证" }}
      />
      <Stack.Screen
        name="PresentRecord"
        component={PresentRecordScreen}
        options={{ title: "赠送记录" }}
      />
      <Stack.Screen
        name="EnterpriseSearch"
        component={EnterpriseSearchScreen}
        options={{ title: "搜索品牌" }}
      />
      <Stack.Screen
        name="NationInfo"
        component={NationInfoScreen}
        options={{ title: "全国门店" }}
      />
      <Stack.Screen
        name="CityInfo"
        component={CityInfoScreen}
        options={{ title: "门店分布" }}
      />
      <Stack.Screen
        name="PerCustomer"
        component={PerCustomerScreen}
        options={{ title: "客单价分布" }}
      />
      <Stack.Screen
        name="FeedbackRate"
        component={FeedbackRateScreen}
        options={{ title: "好评率分布" }}
      />
      <Stack.Screen
        name="TradeChart"
        component={TradeChartScreen}
        options={{ title: "行业数据" }}
      />
      <Stack.Screen
        name="TradeReport"
        component={TradeReportScreen}
        options={{ title: "行业数据" }}
      />
      <Stack.Screen
        name="TradePdf"
        component={TradePdfScreen}
        options={{ title: "报告查看" }}
      />
      <Stack.Screen
        name="SettleDetail"
        component={SettleDetailScreen}
        options={{ title: "结算明细" }}
      />
      <Stack.Screen
        name="AccountRecord"
        component={AccountRecordScreen}
        options={{ title: "采购记录" }}
      />
      <Stack.Screen
        name="AuthRecord"
        component={AuthRecordScreen}
        options={{ title: "开通VIP记录" }}
      />
      <Stack.Screen
        name="AuthVip"
        component={AuthVipScreen}
        options={{ title: "开通VIP" }}
      />
      <Stack.Screen
        name="MemberOrderRecord"
        component={MemberOrderRecordScreen}
        options={{ title: "订单记录" }}
      />
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const theme = useTheme();

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={{
        headerShadowVisible: false,
        tabBarStyle: {
          elevation: 0,
          borderTopWidth: 0,
          shadowColor: "transparent",
        },
        headerStyle: {
          borderBottomWidth: 0,
          elevation: 0,
          shadowColor: "transparent",
        },
        tabBarActiveTintColor: theme.colors.tabIconSelected,
        tabBarInactiveTintColor: theme.colors.tabIconDefault,
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={({ navigation }: RootTabScreenProps<"Home">) => ({
          title: "首页",
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="md-home" color={color} />
          ),
          headerShown: Platform.OS === "web" ? false : true,
        })}
      />
      <BottomTab.Screen
        name="Economy"
        component={EconomyScreen}
        options={{
          title: "经济",
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="md-globe" color={color} />
          ),
          headerShown: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="Trade"
        component={TradeScreen}
        options={{
          title: "行业",
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="md-cube" color={color} />
          ),
          headerShown: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="Enterprise"
        component={EnterpriseScreen}
        options={{
          title: "企业",
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="md-briefcase" color={color} />
          ),
          headerShown: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="Me"
        component={MeScreen}
        options={{
          title: "我的",
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="md-person" color={color} />
          ),
          headerShown: false,
        }}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof Ionicons>["name"];
  color: string;
}) {
  return <Ionicons size={22} style={{ marginBottom: -2 }} {...props} />;
}
