import Alert from "@/components/alert";
import { useMount } from "@/react-native-ahook";
import { GET_USER_INFO } from "@/redux/models/app";
import { createAction } from "@/utils";
import { hp, sw, wp } from "@/utils/dimension";
import { LinkTo } from "@/utils/link";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Image, PixelRatio, StyleSheet, Text, View } from "react-native";
import { Paragraph } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

interface Props {}

Image.getSize = (
  uri: string,
  success: (width: number, height: number) => void
) => {};

const PartnerScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [alertVisible, setAlertVisible] = React.useState(false);
  const { userInfo } = useSelector(({ app, loading }: any) => ({
    userInfo: app.userInfo,
  }));

  useMount(() => {
    dispatch(createAction(GET_USER_INFO)({}));
  });

  return (
    <PageView>
      <ContentView>
        <BackgroundImage
          resizeMode="cover"
          source={require("@/assets/images/me/me_partner_image.png")}
        />
      </ContentView>

      <ButtonView
        onPress={() =>
          userInfo?.vipLevel > 440
            ? LinkTo("https://jinshuju.net/f/TSVK23")
            : setAlertVisible(true)
        }
      >
        <ButtonImage
          resizeMode="cover"
          source={require("@/assets/images/me/me_partner_apply.png")}
        />
      </ButtonView>
      <Alert
        visible={alertVisible}
        title="遗憾"
        content={
          <AlertContentText>
            申请分销合伙人，是VIP会员的专属特权，您目前还不是会员，暂无法申请
          </AlertContentText>
        }
        cancelText="我再想想"
        sureText="先了解会员"
        onClose={() => setAlertVisible(false)}
        onSure={() => {
          setAlertVisible(false);
          navigation.navigate("Member", { tabIndex: 0 });
        }}
      />
    </PageView>
  );
};

export default PartnerScreen;

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;
const ContentView = styled.ScrollView`
  /* background-color: ${(props: IStyledProps) => props.theme.page}; */
`;
const BackgroundImage = styled.Image`
  height: ${(wp(100) * 2100) / 780}px;
`;

const ButtonView = styled.Pressable`
  width: ${wp(100)}px;
  background-color: #f3924b;
  position: absolute;
  align-self: center;
  padding-top: 16px;
  padding-bottom: 16px;
  bottom: 0;
`;
const ButtonImage = styled.Image`
  width: ${wp(90)}px;
  height: ${wp(10)}px;
  align-self: center;
  border-radius: 5px;
`;

const AlertContentText = styled(Paragraph)``;

const styles = StyleSheet.create({});
