import { AppApi } from "@/redux/services";
import { check, createAction } from "@/utils";
import { DvaModel, ReduxAction } from "../redux";
import { ResponseGenerator } from "../connect";
import { SAVE, TOAST_LOADING } from "@/constants/app";
import { Toast } from "@/react-native-papal";

// actions
export const GET_USER_INFO = "app/getUserInfo";
export const DO_GENERATE_POSTER = "app/doGeneratePoster";
export const DO_GENERATE_POSTERS = "app/doGeneratePosters";
export const DO_EDIT_USER = "app/doEditUser";
export const GET_BANNER_INFO = "app/fetchBannerInfo";
export const GET_ICON_CONFIG = "app/getIconConfig";

export interface IAppState {
  loading?: boolean;
  userInfo: IUserInfoItem;
  posterImage?: string;
  posters?: Array<string>;
  banners?: Array<IBannerItem>;
  iconConfigs?: IIconConfigItem[];
}
export interface IUserInfoItem {
  avatar: string;
  nickName: string;
  slogan: string;
  inviteCode: string;
  vipLevel: string;
  level: string;
  gender: number;
  mobile: string;
  bindMobile: boolean;
  vipEndDate: string;
  qrcodeLink: string;
  company: string;
  withdrawMoney: number;
  unDrawCoupons: UnDrawCouponItem[];
  banners: IBannerItem[];
}

export interface IIconConfigItem {
  pk: string;
  title: string;
  subTitle: string;
  descText: string;
  iconUrl: string;
  params: string;
  linkTo: string;
}

export interface IBannerItem {
  imgUrl: string;
  linkTo: string;
  title: string;
}

interface UnDrawCouponItem {
  pk: string;
  type: string;
  imageUrl: string;
}

/**
 * app
 */
const NAMESPACE = "app";

const model: DvaModel<IAppState> = {
  namespace: NAMESPACE,
  state: {
    loading: false,
    // @ts-ignore
    userInfo: {
      unDrawCoupons: [],
      banners: [],
      bindMobile: true,
    },
    posters: [],
    banners: [],
    iconConfigs: [],
  },
  effects: {
    // 用户信息
    *getUserInfo({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        AppApi.fetchUserInfo,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ userInfo: response.data }));
        callback && callback();
      }
    },
    // 生成海报
    *doGeneratePoster({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        AppApi.doGeneratePoster,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ posterImage: response.data }));
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 生成多张海报
    *doGeneratePosters({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        AppApi.doGeneratePosters,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ posters: response.data }));
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 修改用户
    *doEditUser({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        AppApi.doEditUser,
        payload
      );
      if (check(response)) {
        yield put(createAction("getUserInfo")({}));
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // Banner信息
    *fetchBannerInfo({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        AppApi.fetchBannerInfo,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ banners: response.data }));
        callback && callback();
      }
    },
    // icon配置
    *getIconConfig({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        AppApi.fetchIconConfig,
        payload
      );
      if (check(response)) {
        // yield put(createAction(SAVE)({ iconConfigs: response.data }));
        callback && callback(response.data);
      }
      return response ? response.data : [];
    },
  },
  reducers: {
    save(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        ...payload,
      };
    },
    error(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        error: payload,
      };
    },
  },
  subscriptions: {
    init(dispatch: any) {},
  },
};
export default model;
