import { IBrandCategory } from "@/redux/models/enterprise";
import { fp, wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface ItemProps {
  item: IBrandCategory;
  onPress: () => void;
}

export const BrandCategoryItem: React.FC<ItemProps> = (props) => {
  return (
    <ItemView onPress={props.onPress}>
      <ItemText>{props.item.title}</ItemText>
    </ItemView>
  );
};

interface Props {
  item: IBrandCategory;
  onPress?: (item: IBrandCategory) => void;
}
/// 品牌分类
const BrandCategory: React.FC<Props> = (props) => {
  const itemData =
    props.item.children &&
    props.item.children.map((item) => {
      return (
        <BrandCategoryItem
          key={item.pk}
          item={item}
          onPress={() => props.onPress && props.onPress(item)}
        />
      );
    });
  return (
    <SceneView>
      <SceneTitle>{props.item.title}</SceneTitle>
      <BrandView>{itemData}</BrandView>
    </SceneView>
  );
};

export default BrandCategory;
const SceneView = styled.View`
  margin: 12px 16px 12px 16px;
  background-color: ${Colors.white};
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px;
`;
const SceneTitle = styled(Paragraph)`
  font-size: ${fp(4.2)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
  margin-left: 12px;
`;
const BrandView = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;
const ItemView = styled.Pressable`
  width: ${(fp(100) - 80) / 3}px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #f4f9ff;
  margin-top: 12px;
  border-radius: 5px;
  margin-left: 12px;
`;
const ItemText = styled(Paragraph)`
  font-size: ${fp(3.8)}px;
  color: #414141;
`;

const styles = StyleSheet.create({});
