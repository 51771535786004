import Modal from './Modal'
import alert from './alert'
import prompt from './prompt'
import operation from './operation'

Modal.alert = alert
Modal.prompt = prompt
Modal.operation = operation

export default Modal
