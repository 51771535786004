import React, { useEffect } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { Badge, Colors } from "react-native-paper";
import { useDebounceEffect, useTimeout } from "@/react-native-ahook";
import { fp } from "@/utils/dimension";

interface Props {
  style?: ViewStyle;
  data: Array<ItemProps>;
  onCallback?: (item: ItemProps) => void;
}

interface ItemProps {
  key: number;
  itemKey?: number;
  title: string;
  price: string;
  realPrice: string;
  desc: string;
  badge?: string;
  pk?: string;
  selected?: boolean;
  onPress?: (key: number) => void;
}

/// the member selector component
const MemberItem: React.FC<ItemProps> = (props) => {
  const itemStyles = props.selected ? styles.selected : styles.memberSelector;
  return (
    <MemberItemView
      style={itemStyles}
      onPress={() => props.onPress!(props.itemKey!)}
    >
      <Title>{props.title}</Title>
      <PriceText>{props.price}</PriceText>
      <OriginPriceText>{props.realPrice}</OriginPriceText>
      <DescText>{props.desc}</DescText>
      {props.badge ? <Badge style={styles.badge}>{props.badge}</Badge> : null}
    </MemberItemView>
  );
};

/// the member selector component
const MemberSelector: React.FC<Props> = (props) => {
  const { data } = props;
  const [selectKey, setSelectKey] = React.useState(3);

  useTimeout(() => {
    props.onCallback && props.onCallback(data[selectKey - 1]);
  }, 1000);
  const dataMap = data.map((item: ItemProps) => {
    return (
      <MemberItem
        key={item.key}
        title={item.title}
        price={item.price}
        itemKey={item.key}
        realPrice={item.realPrice}
        selected={selectKey == item.key}
        desc={item.desc}
        badge={item.badge}
        onPress={(key) => {
          setSelectKey(key);
          props.onCallback && props.onCallback(item);
        }}
      />
    );
  });
  return <MemberView style={props.style}>{dataMap}</MemberView>;
};
MemberSelector.defaultProps = {
  data: [],
};

export default React.memo(MemberSelector);

const MemberView = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;
const MemberItemView = styled.Pressable`
  background-color: #f7f7f7;
  padding: 16px 2px 16px 2px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 32%;
`;
const Title = styled.Text`
  color: ${(props: IStyledProps) => props.theme.text};
  font-weight: bold;
  font-size: ${fp(3.7)}px;
`;
const PriceText = styled.Text`
  font-weight: bold;
  font-size: ${fp(5)}px;
  color: ${(props: IStyledProps) => props.theme.redText};
  margin-top: 8px;
`;
const OriginPriceText = styled.Text`
  color: ${(props: IStyledProps) => props.theme.paragraph};
  font-size: ${fp(3.4)}px;
  margin-top: 8px;
  text-decoration: line-through;
  text-decoration-color: ${(props: IStyledProps) => props.theme.paragraph};
`;
const DescText = styled.Text`
  color: ${(props: IStyledProps) => props.theme.paragraph};
  font-size: ${fp(3.3)}px;
  margin-top: 1px;
`;

const styles = StyleSheet.create({
  selected: {
    borderWidth: 2,
    borderColor: "#f4cf89",
    backgroundColor: "#fcf4e9",
  },
  memberSelector: {},
  badge: {
    position: "absolute",
    top: 0,
    left: 0,
  },
});
