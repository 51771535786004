import TextInput from "@/components/textinput";
import React from "react";
import { StyleSheet } from "react-native";
import { Colors, Headline } from "react-native-paper";
import styled from "styled-components/native";
import { Ionicons } from "@expo/vector-icons";
import { useMount } from "@/react-native-ahook";
import { createAction } from "@/utils";
import { useDispatch, useSelector } from "react-redux";
import { GET_BRAND_CATEGORY, IBrandCategory } from "@/redux/models/enterprise";
import BrandCategory from "@/components/brand";
import { useNavigation } from "@react-navigation/native";
import { aesEncode } from "@/utils/tools";
import { fp, wp } from "@/utils/dimension";
import { NoticeBar } from "@/react-native-papal";

interface Props {}

// the company screen
const EnterpriseScreen = (props: Props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { brandCategory } = useSelector(({ enterprise, loading }: any) => ({
    brandCategory: enterprise.brandCategory,
  }));

  useMount(() => {
    // 获取品牌分类
    dispatch(createAction(GET_BRAND_CATEGORY)({}));
  });

  const renderHeader = () => {
    return (
      <ImageBackground
        resizeMode="cover"
        source={require("@/assets/images/home/home_banner_1.png")}
      >
        {/* <RankView>
          <Ionicons name="trophy" size={16} color={Colors.grey700} />
          <RankText>区域排行</RankText>
        </RankView> */}
        <LogoImage source={require("@/assets/images/home/logo_show.png")} />
        <HeaderView>
          <TitleText>查标杆、查竞品{"\n"}市场调研分析必备</TitleText>
          <TextInput
            placeholderTextColor={Colors.grey400}
            styles={styles.textInput}
            placeholder="搜品牌 最快了解同行业务分布 口碑等经营情况"
            editable={false}
            selectTextOnFocus={false}
            onPress={() => navigation.navigate("EnterpriseSearch")}
          />

          <HeaderDescText>
            采用官方公开数据建模，省市区县品牌经营数据
          </HeaderDescText>
        </HeaderView>
        {/* <DescView>
          <DescText>30+{"\n"}行业类别</DescText>
          <DescText>3000+{"\n"}区县数据</DescText>
          <DescText>2000W+{"\n"}品牌数据</DescText>
        </DescView> */}
        <NoticeView>
          <NoticeText>开放测试期：2022.01.10-2022.02.09</NoticeText>
        </NoticeView>
      </ImageBackground>
    );
  };
  const renderBrandHeader = () => {
    return (
      <BrandHeaderView>
        <BrandTitle>品牌分类</BrandTitle>
      </BrandHeaderView>
    );
  };

  const brandCategoryData = brandCategory.map(
    (category: IBrandCategory, index: number) => {
      return (
        <BrandCategory
          key={index}
          item={category}
          onPress={(item) =>
            navigation.navigate("EnterpriseList", {
              item: aesEncode(JSON.stringify(item)),
            })
          }
        />
      );
    }
  );

  return (
    <PageView>
      <NoticeBar
        marqueeProps={{
          loop: true,
          leading: 5000,
          style: { fontSize: 13, color: "red" },
        }}
      >
        测试期间，数据部分完整(不用担心，系统在逐渐更新)，功能可能不稳定(程序员小哥们在不断修复bug)，欢迎提出使用反馈建议。{" "}
      </NoticeBar>
      {renderHeader()}
      {brandCategory.length > 0 && renderBrandHeader()}
      {brandCategoryData}
    </PageView>
  );
};

export default EnterpriseScreen;

const PageView = styled.ScrollView`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;

const ImageBackground = styled.ImageBackground`
  width: 100%;
  height: 254px;
`;
const HeaderView = styled.View`
  align-items: center;
  padding: 16px;
  margin-top: 8px;
`;
const TitleText = styled.Text`
  font-size: ${fp(5)}px;
  color: ${Colors.white};
  line-height: 28px;
  text-align: center;
`;
const HeaderDescText = styled.Text`
  color: ${Colors.white};
  margin-top: 8px;
  font-size: ${fp(3.1)}px;
`;
const RankView = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background-color: ${Colors.white};
  position: absolute;
  right: 0px;
  top: 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;
const RankText = styled.Text`
  margin-left: 4px;
  color: ${Colors.grey700};
`;
const BrandHeaderView = styled.View`
  justify-content: center;
  align-items: center;
  padding: 12px 12px 0 12px;
`;
const BrandTitle = styled(Headline)`
  font-size: ${fp(4.8)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  font-weight: bold;
`;
const LogoImage = styled.Image`
  width: 88px;
  height: 17px;
  margin-top: 10px;
  margin-left: 10px;
`;
const DescView = styled.View`
  flex-direction: row;
  justify-content: space-around;
  padding: 0px 16px 0px 16px;
`;
const DescText = styled.Text`
  text-align: center;
  color: ${Colors.white};
  line-height: 20px;
  font-size: ${fp(3.5)}px;
`;

const NoticeView = styled.View`
  padding: 0px 16px 0px 16px;
  margin-top: -4px;
`;
const NoticeText = styled.Text`
  text-align: center;
  color: ${Colors.white};
  line-height: 20px;
  font-size: ${fp(3)}px;
`;

const styles = StyleSheet.create({
  textInput: {
    marginTop: 20,
    backgroundColor: "#F4F9FF",
  },
});
