import { hp, wp } from "@/utils/dimension";
import { ProcessUrl } from "@/utils/image";
import React from "react";
import { StyleSheet } from "react-native";
import { Modal, Portal } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  image: string;
  visible: boolean;
  onClose?: () => void;
}

/// 图片预览
const ImagePreview: React.FC<Props> = (props) => {
  return (
    <Portal>
      <Modal visible={props.visible} dismissable onDismiss={props.onClose}>
        <PressableView onPress={props.onClose}>
          <ImageView
            resizeMode="contain"
            source={{
              uri: ProcessUrl(props.image),
            }}
          />
        </PressableView>
      </Modal>
    </Portal>
  );
};

ImagePreview.defaultProps = {
  visible: false,
};

export default ImagePreview;

const ImageView = styled.Image`
  width: ${wp(100)}px;
  height: ${hp(100)}px;
`;
const PressableView = styled.Pressable``;

const styles = StyleSheet.create({});
