import { AppApi, EconomyApi } from "@/redux/services";
import { check, createAction } from "@/utils";
import { DvaModel, IDictItem, IDictTreeItem, ReduxAction } from "../redux";
import { ResponseGenerator } from "../connect";
import { SAVE, TOAST_LOADING } from "@/constants/app";
import { Toast } from "@/react-native-papal";
import _ from "lodash";
import { IPickerItem } from "@/components/picker-modal";
import { arrayDotToTree, ClipboardSetString } from "@/utils/tools";
import { IChartData } from "@/react-native-papal/ant-chart";
import { ProcessUrl } from "@/utils/image";

// actions
export const GET_ECONOMY_AREA_TREE = "economy/getEconomyAreaTree";
export const GET_ECONOMY_AREA_SEARCH = "economy/getEconomyAreaSearch";
export const GET_ECONOMY_SEARCH = "economy/getEconomySearch";
export const GET_ECONOMY_DETAIL = "economy/getEconomyDetails";
export const SWITCH_RANGE_DATE = "economy/switchRangeDate";
export const GET_ECONOMY_EXCEL = "economy/getEconomyExcel";
export const GET_HOT_AREA_LIST = "economy/getHotAreaList";

// interface
export interface IEconomyState {
  loading?: boolean;
  areaSearch?: Array<IEconomyAreaSearch>;
  economySearch?: Array<IEconomySearch>;
  economyDetail?: Array<IEconomyDetail>;
  chartData?: Array<IChartData>;
  tableData?: Array<IEconomyDetail>;
  searchStartDate: string;
  searchEndDate: string;
  startDateData: Array<IPickerItem>;
  endDateData: Array<IPickerItem>;
  hotAreaData: Array<Array<IDictTreeItem>>;
  excelUrl: string;
}

export interface IEconomyAreaSearch {
  nation: string;
  province: string;
  city: string;
  district: string;
}

export interface IEconomySearch {
  name: string;
  unit: string;
}

export interface IEconomyDetail {
  pk: string;
  source: string;
  year: string;
  month: string;
  day: string;
  dataType: string;
  dataTypeSub: string;
  nation: string;
  province: string;
  city: string;
  district: string;
  name: string;
  unit: string;
  value: string;
}

/**
 * app
 */
const NAMESPACE = "economy";

const model: DvaModel<IEconomyState> = {
  namespace: NAMESPACE,
  state: {
    loading: false,
    areaSearch: [],
    economySearch: [],
    economyDetail: [],
    tableData: [],
    startDateData: [],
    endDateData: [],
    chartData: [],
    searchStartDate: "2016",
    searchEndDate: "2021",
    // @ts-ignore
    hotAreaData: [],
    excelUrl: "",
  },
  effects: {
    // 经济区域树形
    *getEconomyAreaTree({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        EconomyApi.fetchEconomyAreaTree,
        payload
      );
      if (check(response)) {
        callback && callback();
      }
    },
    // 经济区域搜索
    *getEconomyAreaSearch({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        EconomyApi.fetchEconomyAreaSearch,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ areaSearch: response.data }));
        callback && callback();
      }
    },
    // 热门区域数据
    *getHotAreaList({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        AppApi.dictTreeByKey,
        payload
      );
      if (check(response)) {
        if (response.data.length > 0 && response.data[0].children.length > 0) {
          const hotAreaData = response.data[0].children;
          yield put(createAction(SAVE)({ hotAreaData }));
        }
        callback && callback();
      }
    },
    // 经济搜索
    *getEconomySearch({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        EconomyApi.fetchEconomySearch,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ economySearch: response.data }));
        callback && callback();
      }
      Toast.remove(loadingKey);
    },

    // 获取经济数据EXCEL
    *getEconomyExcel({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        EconomyApi.fetchEconomyExcel,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ excelUrl: response.data }));
        callback && callback(response.data);
      }
      Toast.remove(loadingKey);
    },
    // TODO:经济详情 后期考虑后台处理数据
    *getEconomyDetails({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        EconomyApi.fetchEconomyDetails,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ economyDetail: response.data }));
        const detailData = response.data || [];
        const tableData: IEconomyDetail[] = [];
        const chartData = Array<IChartData>();
        const dateArray: any[] = [];
        detailData.reverse();
        // 日数据
        const dayData =
          detailData
            .filter((item: IEconomyDetail) => item.day != null)
            .map((item: IEconomyDetail, index: number) => {
              if (tableData.length < 6) {
                chartData.push({
                  label: item.year + "-" + item.month + "-" + item.day + "",
                  value: parseFloat(item.value) || 0,
                });
                tableData.push(item);
              }
              return item;
            }) || [];
        // 月数据
        const monthData =
          (dayData.length == 0 &&
            detailData
              .filter(
                (item: IEconomyDetail) => item.day == null && item.month != null
              )
              .map((item: IEconomyDetail, index: number) => {
                if (tableData.length < 6) {
                  chartData.push({
                    label: item.year + "-" + item.month + "",
                    value: parseFloat(item.value) || 0,
                  });
                  tableData.push(item);
                }
                return item;
              })) ||
          [];
        // 年数据
        const yearData =
          (dayData.length == 0 &&
            monthData.length == 0 &&
            detailData
              .filter(
                (item: IEconomyDetail) =>
                  item.day == null && item.month == null && item.year != null
              )
              .map((item: IEconomyDetail, index: number) => {
                if (tableData.length < 6) {
                  chartData.push({
                    label: item.year + "",
                    value: parseFloat(item.value) || 0,
                  });
                  tableData.push(item);
                }
                return item;
              })) ||
          [];
        detailData.forEach((item: IEconomyDetail, index: number) => {
          // 处理日期
          dateArray.push(item.year + "." + item.month + "." + item.day);
        });
        chartData.reverse();
        tableData.reverse();
        // put result
        yield put(createAction(SAVE)({ chartData: chartData }));
        yield put(createAction(SAVE)({ tableData: tableData }));
        yield put(
          createAction(SAVE)({
            startDateData: arrayDotToTree(dateArray.reverse()),
            endDateData: arrayDotToTree(dateArray.reverse()),
          })
        );
        if (tableData.length > 0) {
          yield put(
            createAction(SAVE)({ searchStartDate: _.first(tableData)!.year })
          );
          yield put(
            createAction(SAVE)({ searchEndDate: _.last(tableData)!.year })
          );
        }
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 切换时间段
    *switchRangeDate({ payload, callback }, { call, put, select }) {
      const { minDate, maxDate } = payload;
      const minDateArr = minDate.split("-");
      const maxDateArr = maxDate.split("-");
      const arrLength = minDateArr.length;
      // @ts-ignore
      const economyDetail = yield select(
        (state: any) => state.economy.economyDetail
      );
      const tableData: IEconomyDetail[] = [];
      const chartData = Array<IChartData>();
      let startDate = minDateArr[0];
      let endDate = maxDateArr[0];
      economyDetail.forEach((item: IEconomyDetail) => {
        if (arrLength == 1) {
          if (
            item.month == null &&
            item.day == null &&
            minDateArr[0] <= item.year &&
            item.year <= maxDateArr[0]
          ) {
            chartData.push({
              label: item.year + "",
              value: parseFloat(item.value) || 0,
            });
            tableData.push(item);
            startDate = minDateArr[0];
            endDate = maxDateArr[0];
          }
        }
        if (arrLength == 2) {
          if (
            item.day == null &&
            minDateArr[1] <= item.month &&
            item.month <= maxDateArr[1] &&
            minDateArr[0] <= item.year &&
            item.year <= maxDateArr[0]
          ) {
            chartData.push({
              label: item.year + "-" + item.month + "",
              value: parseFloat(item.value) || 0,
            });
            tableData.push(item);
            startDate = minDateArr[1];
            endDate = maxDateArr[1];
          }
        }
        if (arrLength == 3) {
          if (
            minDateArr[2] <= item.day &&
            item.day <= maxDateArr[2] &&
            minDateArr[1] <= item.month &&
            item.month <= maxDateArr[1] &&
            minDateArr[0] <= item.year &&
            item.year <= maxDateArr[0]
          ) {
            chartData.push({
              label: item.year + "-" + item.month + "-" + item.day + "",
              value: parseFloat(item.value) || 0,
            });
            tableData.push(item);
            startDate = minDateArr[2];
            endDate = maxDateArr[2];
          }
        }
      });
      chartData.reverse();
      tableData.reverse();
      // put result
      yield put(createAction(SAVE)({ chartData: chartData }));
      yield put(createAction(SAVE)({ tableData: tableData }));
      yield put(createAction(SAVE)({ searchStartDate: startDate }));
      yield put(createAction(SAVE)({ searchEndDate: endDate }));
    },
  },
  reducers: {
    save(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        ...payload,
      };
    },
    error(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        error: payload,
      };
    },
  },
  subscriptions: {
    init(dispatch: any) {},
  },
};
export default model;
