import TextInput from "@/components/textinput";
import { useMount } from "@/react-native-ahook";
import { GET_ICON_CONFIG, IIconConfigItem } from "@/redux/models/app";
import { createAction } from "@/utils";
import { fp } from "@/utils/dimension";
import { aesEncode } from "@/utils/tools";
import { uuid } from "@/utils/uuid";
import { useNavigation } from "@react-navigation/core";
import React from "react";
import { FlatList, StyleSheet } from "react-native";
import { Colors, Headline } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";
import TradeItem from "@/components/trade/trade-item";
import { NoticeBar } from "@/react-native-papal";

interface Props {}

// the economy screen
const EconomyScreen = (props: Props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [iconConfigs, setIconConfigs] = React.useState<any>([]);
  // const { iconConfigs } = useSelector(({ app, loading }: any) => ({
  //   iconConfigs: app.iconConfigs,
  // }));

  useMount(async () => {
    // 630经济icon配置
    const data = await dispatch(createAction(GET_ICON_CONFIG)({ type: 630 }));
    setIconConfigs(data);
  });

  const renderHeader = () => {
    return (
      <ImageBackground
        resizeMode="cover"
        source={require("@/assets/images/home/home_banner_1.png")}
      >
        <LogoImage source={require("@/assets/images/home/logo_show.png")} />
        <HeaderView>
          <TitleText>洞察国家、城市经济{"\n"}跟上时代发展趋势</TitleText>
          <TextInput
            placeholderTextColor={Colors.grey400}
            styles={styles.textInput}
            placeholder="输入查询的经济指标 把握趋势"
            editable={false}
            selectTextOnFocus={false}
            onPress={() => navigation.navigate("EconomySearch")}
          />
          <HeaderDescText>
            数据来源于国家及各地方城市统计局，请放心使用
          </HeaderDescText>
        </HeaderView>
        {/* <DescView>
          <DescText>300+{"\n"}地级市数据</DescText>
          <DescText>3000+{"\n"}区县数据</DescText>
          <DescText>500W+{"\n"}经济数据</DescText>
        </DescView> */}
        <NoticeView>
          <NoticeText>开放测试期：2022.01.10-2022.02.09</NoticeText>
        </NoticeView>
      </ImageBackground>
    );
  };

  // render dimension
  const renderDimensionHeader = () => {
    return (
      <BrandHeaderView>
        <BrandTitle>经济指标</BrandTitle>
      </BrandHeaderView>
    );
  };

  const renderItem = React.useCallback(
    ({ item }) => (
      <TradeItem
        key={uuid()}
        title={item.title}
        desc={item.subTitle}
        subDesc={item.descText}
        source={item.iconUrl}
      />
    ),
    []
  );
  const keyExtractor = React.useCallback((item) => uuid(), []);

  const renderDescView = () => {
    return (
      <SceneView>
        <FlatList
          data={iconConfigs || []}
          numColumns={2}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ListHeaderComponent={renderDimensionHeader}
        />
      </SceneView>
    );
  };

  return (
    <PageView>
      <NoticeBar
        marqueeProps={{
          loop: true,
          leading: 5000,
          style: { fontSize: 13, color: "red" },
        }}
      >
        测试期间，数据部分完整(不用担心，系统在逐渐更新)，功能可能不稳定(程序员小哥们在不断修复bug)，欢迎提出使用反馈建议。{" "}
      </NoticeBar>
      {renderHeader()}
      {renderDescView()}
    </PageView>
  );
};

export default EconomyScreen;

const PageView = styled.ScrollView`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;

const ImageBackground = styled.ImageBackground`
  width: 100%;
  height: 254px;
`;
const HeaderView = styled.View`
  align-items: center;
  padding: 16px;
  margin-top: 8px;
`;
const TitleText = styled.Text`
  font-size: ${fp(5)}px;
  color: ${Colors.white};
  line-height: 28px;
  text-align: center;
`;
const HeaderDescText = styled.Text`
  font-size: ${fp(3.1)}px;
  color: ${Colors.white};
  margin-top: 8px;
`;
const DescView = styled.View`
  flex-direction: row;
  justify-content: space-around;
  padding: 0px 16px 0px 16px;
`;
const DescText = styled.Text`
  text-align: center;
  color: ${Colors.white};
  line-height: 20px;
  font-size: ${fp(3.5)}px;
`;

const BrandHeaderView = styled.View`
  justify-content: center;
  align-items: center;
  padding: 12px 12px 12px 12px;
`;
const BrandTitle = styled(Headline)`
  font-size: ${fp(4.8)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  font-weight: bold;
`;

const SceneView = styled.View`
  margin: 0px 12px 12px 12px;
  /* background-color: ${Colors.white}; */
  /* padding: 16px; */
  border-radius: 5px;
`;
const LogoImage = styled.Image`
  width: 88px;
  height: 17px;
  margin-top: 10px;
  margin-left: 10px;
`;

const NoticeView = styled.View`
  padding: 0px 16px 0px 16px;
  margin-top: -4px;
`;
const NoticeText = styled.Text`
  text-align: center;
  color: ${Colors.white};
  line-height: 20px;
  font-size: ${fp(3)}px;
`;

const styles = StyleSheet.create({
  textInput: {
    marginTop: 20,
    backgroundColor: "#F4F9FF",
  },
});
