import { useTimeout } from "@/react-native-ahook";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Badge, Colors } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  data: Array<ItemProps>;
  onCallback?: (item: ItemProps) => void;
}

interface ItemProps {
  pk?: string;
  key: number;
  itemKey?: number;
  title: string;
  price: string;
  badge?: string;
  selected?: boolean;
  onPress?: (key: number) => void;
}

const PresentSelectorItem: React.FC<ItemProps> = (props) => {
  const itemStyles = props.selected ? styles.selected : styles.unselected;
  return (
    <ItemView
      style={itemStyles}
      onPress={() => props.onPress && props.onPress(props.itemKey!)}
    >
      <ItemTitle>{props.title}</ItemTitle>
      <DescText>{props.price}</DescText>
      {props.badge ? <Badge style={styles.badge}>{props.badge}</Badge> : null}
    </ItemView>
  );
};
PresentSelectorItem.defaultProps = {
  selected: false,
};

const PresentSelector: React.FC<Props> = (props) => {
  const { data } = props;
  const [selectKey, setSelectKey] = React.useState<number>(1);
  useTimeout(() => {
    props.onCallback && props.onCallback(data[selectKey - 1]);
  }, 1000);
  const cardData = data.map((item: ItemProps) => {
    return (
      <PresentSelectorItem
        key={item.key}
        price={item.price}
        title={item.title}
        badge={item.badge}
        itemKey={item.key}
        selected={item.key == selectKey}
        onPress={(key) => {
          setSelectKey(key);
          props.onCallback && props.onCallback(item);
        }}
      />
    );
  });
  return <PresentView>{cardData}</PresentView>;
};
PresentSelector.defaultProps = {
  data: [],
};

export default PresentSelector;

const PresentView = styled.View``;

const ItemView = styled.Pressable`
  border-width: 1px;
  border-color: ${Colors.grey200};
  padding: 16px 12px 16px 12px;
  margin-top: 12px;
  flex-direction: row;
  border-radius: 5px;
`;

const ItemTitle = styled.Text`
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const DescText = styled.Text`
  font-size: 15px;
  color: ${Colors.grey600};
`;

const styles = StyleSheet.create({
  selected: {
    borderColor: "#f4cf89",
    backgroundColor: "#fcf4e9",
  },
  unselected: {
    borderColor: Colors.grey200,
    backgroundColor: Colors.white,
  },
  badge: {
    position: "absolute",
    top: 0,
    left: 0,
  },
});
