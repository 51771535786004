import React from "react";
import { Platform, StyleSheet, TextInputProps, TextStyle } from "react-native";
import { Colors } from "react-native-paper";
import styled from "styled-components/native";
import { Ionicons } from "@expo/vector-icons";
import { fp, wp } from "@/utils/dimension";

interface Props extends TextInputProps {
  styles?: TextStyle;
  onPress?: () => void;
  leftType?: "default" | "menu";
  onLeftPress?: () => void;
  menuText?: string;
}

const TextInput: React.FC<Props> = (props) => {
  let leftNode = null;
  let inputViewStyle = {};
  if (props.leftType === "menu") {
    leftNode = (
      <LeftView onPress={props.onLeftPress}>
        <MenuText>{props.menuText}</MenuText>
        <Ionicons name="caret-down" size={14} color={Colors.grey500} />
      </LeftView>
    );
    inputViewStyle = { backgroundColor: "#F4F9FF", height: 40 };
  } else if (props.leftType === "default") {
    leftNode = (
      <Ionicons name="search-outline" size={22} color={Colors.grey400} />
    );
  }
  return (
    <InputView
      style={[props.styles, inputViewStyle]}
      onPress={() => props.onPress && props.onPress()}
    >
      {leftNode}
      <Input
        {...props}
        onPressIn={() => props.onPress && props.onPress()}
        // @ts-ignore
        style={Platform.select({
          web: {
            outlineStyle: "none",
          },
        })}
      />
    </InputView>
  );
};

TextInput.defaultProps = {
  leftType: "default",
  menuText: "指标范围",
};

export default TextInput;

const InputView = styled.Pressable`
  background: ${Colors.white};
  width: 100%;
  /* height: ${wp(12)}px; */
  height: 40px;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
`;

const Input = styled.TextInput`
  /* height: 48px; */
  height: 40px;
  padding: 16px;
  width: 100%;
  font-size: ${fp(3.7)}px;
`;

const LeftView = styled.Pressable`
  flex-direction: row;
  align-items: center;
  /* height: 48px; */
  /* height: ${wp(12)}px; */
  height: 40px;
`;
const MenuText = styled.Text`
  /* font-size: ${fp(3.5)}px; */
  font-size: 12px;

  color: ${Colors.grey700};
`;

const styles = StyleSheet.create({});
