import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  defaultValue?: number;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
}

const Stepper = (props: Props) => {
  const [value, setValue] = React.useState(props.defaultValue!);
  // calculate value
  const calculateValue = (symbol: string) => {
    if (symbol === "+") {
      if (value < props.max!) {
        setValue(value + 1);
        props.onChange && props.onChange(value + 1);
      }
    } else {
      if (value > props.min!) {
        setValue(value - 1);
        props.onChange && props.onChange(value - 1);
      }
    }
  };
  return (
    <StepperView>
      <SymbolView onPress={() => calculateValue("-")}>
        <SymbolText>-</SymbolText>
      </SymbolView>
      <ValueText>{value}</ValueText>
      <SymbolView onPress={() => calculateValue("+")}>
        <SymbolText>+</SymbolText>
      </SymbolView>
    </StepperView>
  );
};

Stepper.defaultProps = {
  defaultValue: 3,
  min: 3,
  max: 100,
};

export default Stepper;

const StepperView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const SymbolView = styled.TouchableOpacity`
  background-color: ${Colors.white};
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;
const SymbolText = styled.Text`
  /* padding: 4px 12px 4px 12px; */
  font-size: 18px;
  color: ${Colors.grey800};
`;
const ValueText = styled.Text`
  font-size: 16px;
  text-align: center;
  width: 50px;
  color: ${Colors.grey800};
`;

const styles = StyleSheet.create({});
