import Page from "@/components/page";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";
import InviteHeaderItem from "@/components/invite/header-item";
import InviteRecordItem from "@/components/invite/record-item";
import { usePagination } from "@/react-native-ahook";
import { fp } from "@/utils/dimension";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import { aesDecode } from "@/utils/tools";
import { Toast } from "@/react-native-papal";
import { TOAST_LOADING } from "@/constants/app";
import { createActions } from "@/utils";
import { PAGE_INVITE_FRIEND_RECORD } from "@/redux/models/me";
import { uuid } from "@/utils/uuid";

interface Props {}

/// 邀请纪录
const InviteRecordScreen: React.FC<Props> = (props) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const route: any = useRoute();
  const { item, type } = route.params;
  const { inviteNum, earnVipNum } = JSON.parse(aesDecode(item));
  const { data, addData, onEndReached, pageIndex, ListFooterComponent } =
    usePagination(20);

  React.useEffect(() => {
    let loadingKey: number;
    if (pageIndex == 0) {
      loadingKey = Toast.loading("...", TOAST_LOADING);
    }
    dispatch(
      createActions(PAGE_INVITE_FRIEND_RECORD)({
        current: pageIndex + 1,
        size: 20,
        type: type,
      })((data: any) => {
        Toast.remove(loadingKey);
        addData(data.records || []);
      })
    );
  }, [pageIndex]);

  // 头部
  const renderHeader = () => {
    return (
      <HeaderView>
        <InviteHeaderItem title={inviteNum} desc="已邀请好友(人)" />
        {earnVipNum && <Divider />}
        {earnVipNum && (
          <InviteHeaderItem title={earnVipNum} desc="累计赚VIP时长(天)" />
        )}
      </HeaderView>
    );
  };

  const renderFlatListHeader = () => {
    return (
      <ListHeaderView>
        {data.length > 0 && <ListTitle>邀请时间记录</ListTitle>}
      </ListHeaderView>
    );
  };

  const renderItem = React.useCallback(
    ({ item }) => <InviteRecordItem item={item} />,
    []
  );
  const keyExtractor = React.useCallback((item) => uuid(), []);

  return (
    <Page>
      {renderHeader()}
      <ListView
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        contentContainerStyle={{ flexGrow: 1 }}
        data={data || []}
        renderItem={renderItem}
        ListHeaderComponent={renderFlatListHeader()}
        ListFooterComponent={ListFooterComponent}
        keyExtractor={keyExtractor}
      />
    </Page>
  );
};

export default InviteRecordScreen;

const HeaderView = styled.View`
  background-color: ${Colors.white};
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
const Divider = styled.View`
  width: ${StyleSheet.hairlineWidth}px;
  height: 16px;
  background-color: ${(props: IStyledProps) => props.theme.divider};
`;

const ListView = styled.FlatList``;
const ListHeaderView = styled.View`
  padding: 6px 12px 6px 12px;
`;
const ListTitle = styled(Paragraph)`
  font-size: ${fp(4.1)}px;
  color: ${(props: IStyledProps) => props.theme.text};
`;

const styles = StyleSheet.create({});
