import React, { Component } from "react";
import PropTypes from "prop-types";
import { View, Text, StyleSheet } from "react-native";
import { Cell } from "./cell";
import { sum } from "../utils";
interface Props {
  style?: any;
  textStyle?: any;
  borderStyle?: any;
  data?: Array<any>;
  height?: number;
  flexArr?: any;
  widthArr?: any;
}
export const Row: React.FC<Props> = (props) => {
  const { data, style, widthArr, height, flexArr, textStyle, borderStyle } =
    props;
  let width = widthArr ? sum(widthArr) : 0;

  return data ? (
    <View style={[height && { height }, width && { width }, styles.row, style]}>
      {data.map((item, i) => {
        const flex = flexArr && flexArr[i];
        const wth = widthArr && widthArr[i];
        return (
          <Cell
            key={i}
            data={item}
            width={wth}
            height={height}
            flex={flex}
            textStyle={textStyle}
            borderStyle={borderStyle}
          />
        );
      })}
    </View>
  ) : null;
};

interface RowsProps {
  style?: any;
  textStyle?: any;
  borderStyle?: any;
  data?: Array<any>;
  heightArr?: any;
  height?: number;
  flexArr?: any;
  widthArr?: any;
}
export const Rows: React.FC<RowsProps> = (props) => {
  const { data, style, widthArr, heightArr, flexArr, textStyle, borderStyle } =
    props;
  const flex = flexArr ? sum(flexArr) : 0;
  const width = widthArr ? sum(widthArr) : 0;

  return data ? (
    <View style={[flex && { flex }, width && { width }]}>
      {data.map((item, i) => {
        const height = heightArr && heightArr[i];
        return (
          <Row
            key={i}
            data={item}
            widthArr={widthArr}
            height={height}
            flexArr={flexArr}
            style={style}
            textStyle={textStyle}
            borderStyle={borderStyle}
          />
        );
      })}
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    overflow: "hidden",
  },
});
