import Share from "@/components/share";
import { fp, hp, sw, wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Divider, Paragraph, Title } from "react-native-paper";
import styled from "styled-components/native";
import SharePoster from "@/components/share-poster";
import { NoticeItem } from "@/react-native-papal";
import { INVITE_PURCHASE_DATA } from "@/constants/purchase-data";
import { useMount } from "@/react-native-ahook";
import { useDispatch, useSelector } from "react-redux";
import { createAction, createActions } from "@/utils";
import { DO_GENERATE_POSTERS } from "@/redux/models/app";
import RuleModal from "@/components/rule-modal";
import { useNavigation } from "@react-navigation/native";
import { GET_INVITE_REWARD } from "@/redux/models/me";
import { aesEncode, fenToYuan } from "@/utils/tools";
interface RewardItemProps {
  title: string;
  desc: string;
  onPress?: () => void;
}
const RewardItem: React.FC<RewardItemProps> = (props) => {
  return (
    <RewardItemView onPress={props.onPress}>
      <RewardItemText>{props.title}</RewardItemText>
      <RewardItemDescText>{props.desc}</RewardItemDescText>
    </RewardItemView>
  );
};

interface Props {}

const InviteScreen: React.FC<Props> = (props) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [shareVisible, setShareVisible] = React.useState(false);
  const [ruleVisible, setRuleVisible] = React.useState(false);
  const { posters, userInfo, inviteReward } = useSelector(
    ({ app, me, loading }: any) => ({
      posters: app.posters,
      userInfo: app.userInfo,
      inviteReward: me.inviteReward,
    })
  );

  useMount(() => {
    dispatch(createAction(GET_INVITE_REWARD)({}));
  });

  const renderHeader = () => {
    return (
      <HeaderImage
        resizeMode="cover"
        source={require("@/assets/images/me/me_invite_header.png")}
      >
        <HeaderNoticeView>
          <NoticeBarItem data={INVITE_PURCHASE_DATA} />
          <RuleView onPress={() => setRuleVisible(true)}>
            <RuleText>活动规则</RuleText>
          </RuleView>
        </HeaderNoticeView>
      </HeaderImage>
    );
  };
  const renderReward = () => {
    return (
      <TotalImage
        resizeMode="cover"
        source={require("@/assets/images/me/me_invite_total.png")}
      >
        <RewardView>
          <RewardHView>
            <RewardItem
              title={inviteReward.inviteNum}
              desc="已邀好友(人)"
              onPress={() =>
                navigation.navigate("InviteRecord", {
                  item: aesEncode(JSON.stringify(inviteReward)),
                  type: "normal",
                })
              }
            />
            <RewardItem
              title={inviteReward.earnVipNum}
              desc="已赚VIP(天)"
              onPress={() =>
                navigation.navigate("InviteRecord", {
                  item: aesEncode(JSON.stringify(inviteReward)),
                  type: "normal",
                })
              }
            />
            <RewardItem
              title={fenToYuan(inviteReward.earnFee)}
              desc="已赚佣金(元)"
              onPress={() =>
                navigation.navigate("Earnings", {
                  item: aesEncode(JSON.stringify(inviteReward)),
                  type: "normal",
                })
              }
            />
          </RewardHView>
          <Divider />
          <RewardHWView>
            <RewardHLeftView>
              <RewardTextW>待结算(元)：</RewardTextW>
              <RewardMoneyW>{fenToYuan(inviteReward.settleFee)}</RewardMoneyW>
            </RewardHLeftView>
            <RewardButton onPress={() => navigation.navigate("SettleDetail")}>
              <RewardButtonText>结算明细</RewardButtonText>
            </RewardButton>
          </RewardHWView>
        </RewardView>
      </TotalImage>
    );
  };
  const renderShare = () => {
    return (
      <ShareImage
        resizeMode="cover"
        source={require("@/assets/images/me/me_invite_share.png")}
      ></ShareImage>
    );
  };
  const renderPartner = () => {
    return (
      <PartnerView onPress={() => navigation.navigate("Partner")}>
        <PartnerImage
          resizeMode="cover"
          source={require("@/assets/images/me/me_invite_partner.png")}
        ></PartnerImage>
      </PartnerView>
    );
  };
  const renderButton = () => {
    return (
      <ButtonView
        onPress={() => {
          dispatch(
            createActions(DO_GENERATE_POSTERS)({})(() => {
              setShareVisible(true);
            })
          );
        }}
      >
        <ButtonImage
          resizeMode="cover"
          source={require("@/assets/images/me/me_invite_friend.png")}
        />
      </ButtonView>
    );
  };

  return (
    <PageView>
      <ContentView>
        {renderHeader()}
        {renderReward()}
        {renderShare()}
        {renderPartner()}
      </ContentView>
      {renderButton()}
      <Share
        visible={shareVisible}
        shareDesc={
          "温馨提示：\n你分享的所有图片/海报，都带有你的专属码。若用户通过你的海报扫码登录平台，就与你绑定了好友关系，你的好友在1年内任何时候购买会员等服务，你将获得佣金。"
        }
        content={<SharePoster data={posters || []} />}
        onClose={() => setShareVisible(false)}
      />
      <RuleModal
        visible={ruleVisible}
        content={
          <ContentText>
            1、你的好友通过你的邀请登录平台后，将与你绑定好友关系(绑定关系具备唯一性，若被你绑定，其他人则无法绑定)，绑定期限1年。
            {"\n"}
            2、每成功邀请3个好友（新用户）登录，奖励3天VIP时长，以此类推。且受邀好友，可获得抵扣优惠券。
            {"\n"}
            3、好友在绑定期购买平台VIP后，你可获得实付金额的15%作为佣金奖励，多邀多得。
            {"\n"}
            {"\n"}
            其他说明：{"\n"}
            1、只有你所邀请好友通过扫码你的专属海报登录，才计入奖励统计。{"\n"}
            2、新用户指的是之前未登陆过本平台的用户。{"\n"}
            3、若遇问题，请联系官方服务人员。{"\n"}
            4、活动解释权归司马诸葛所有。{"\n"}
          </ContentText>
        }
        onClose={() => setRuleVisible(false)}
      />
    </PageView>
  );
};

export default InviteScreen;

const PageView = styled.View`
  flex: 1;
  background-color: #e02020;
`;
const ContentView = styled.ScrollView`
  /* flex: 1; */
  margin-bottom: ${wp(14)}px;
`;
const HeaderImage = styled.ImageBackground`
  height: ${(sw * 1138) / 780}px;
`;
const HeaderNoticeView = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-left: 12px;
`;
const NoticeBarItem = styled(NoticeItem)`
  flex: 1;
  width: ${wp(95)}px;
`;
const RuleView = styled.Pressable`
  background-color: #fee3cb;
  padding: 5px;
  padding-left: 12px;
  margin-left: 12px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
`;
const RuleText = styled(Paragraph)``;

const TotalImage = styled.ImageBackground`
  height: ${(sw * 564) / 750}px;
  margin-top: 2px;
`;
const RewardView = styled.View`
  background-color: #fdeeee;
  padding: 20px 12px 20px 12px;
  margin-left: ${wp(9.4)}px;
  margin-right: ${wp(9.4)}px;
  margin-top: ${wp(20)}px;
  border-radius: 10px;
`;
const RewardHView = styled.View`
  flex-direction: row;
  justify-content: space-around;
`;
const RewardItemView = styled.Pressable`
  justify-content: center;
  align-items: center;
  width: 90px;
  margin-bottom: 20px;
`;
const RewardItemText = styled(Title)`
  font-size: ${fp(5.3)}px;
  color: #e02020;
`;
const RewardItemDescText = styled(Paragraph)`
  font-size: ${fp(3.3)}px;
  color: #030303;
`;
const RewardButton = styled.Pressable`
  background-color: #eb7070;
  padding: 5px;
  padding: 5px 10px 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;
const RewardButtonText = styled.Text`
  font-size: ${fp(3.3)}px;
  color: ${Colors.white};
`;

const RewardHWView = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;
const RewardHLeftView = styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;
const RewardTextW = styled(Paragraph)`
  font-size: ${fp(3.4)}px;
  color: #030303;
`;

const RewardMoneyW = styled(Title)`
  font-size: ${fp(5.5)}px;
  color: #e02020;
`;
const ContentText = styled(Paragraph)`
  font-size: ${fp(3.7)}px;
  color: ${Colors.grey500};
`;

const ShareImage = styled.ImageBackground`
  height: ${(sw * 496) / 682 - wp(4.7) * 2}px;
  margin-top: 16px;
  margin-left: ${wp(4.7)}px;
  margin-right: ${wp(4.7)}px;
  margin-bottom: 16px;
  border-radius: 5px;
`;
const PartnerView = styled.Pressable``;
const PartnerImage = styled.ImageBackground`
  height: ${(sw * 290) / 682 - wp(4.7) * 2}px;
  margin-left: ${wp(4.7)}px;
  margin-right: ${wp(4.7)}px;
  margin-bottom: 16px;
  border-radius: 5px;
`;

const ButtonView = styled.Pressable`
  position: absolute;
  bottom: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #e02020;
`;
const ButtonImage = styled.Image`
  margin-left: ${wp(4.7)}px;
  margin-right: ${wp(4.7)}px;
  padding-top: 16px;
  padding-bottom: 16px;
  width: ${wp(90)}px;
  height: ${wp(10)}px;
  border-radius: 5px;
`;

const styles = StyleSheet.create({});
