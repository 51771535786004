import { ProcessUrl } from "@/utils/image";
import React from "react";
import { Pressable, StyleSheet } from "react-native";
import { Avatar } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  style?: any;
  size?: number;
  source?: any;
  onPress?: () => void;
}

// the avatar component
const AvatarImage: React.FC<Props> = (props) => {
  return (
    <Pressable onPress={() => props.onPress && props.onPress()}>
      <Avatar.Image
        size={props.size}
        source={
          ProcessUrl(props.source) || require("@/assets/images/placeholder.png")
        }
      />
    </Pressable>
  );
};

AvatarImage.defaultProps = {
  size: 32,
};

export default AvatarImage;

const AvatarView = styled.View`
  /* width: ${(props: Props) => props.size}px;
  height: ${(props: Props) => props.size}px; */
  /* border-radius: 32px; */
`;

const styles = StyleSheet.create({});
