import { wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import styled from "styled-components/native";

interface Props {}

/// 暂无记录
const NoRecord: React.FC<Props> = (props) => {
  return (
    <PageView>
      <ImageView source={require("@/assets/images/common/no_record.png")} />
    </PageView>
  );
};

export default NoRecord;

const PageView = styled.View`
  flex: 1;
`;
const ImageView = styled.Image`
  width: ${wp(40)}px;
  height: ${wp(40)}px;
  margin-top: ${wp(24)}px;
  align-self: center;
`;

const styles = StyleSheet.create({});
