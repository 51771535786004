import CategoryItem from "@/components/category-item";
import MemberLockFooter from "@/components/pagination/member-lock";
import { TOAST_LOADING } from "@/constants/app";
import { useMount, usePagination } from "@/react-native-ahook";
import { Toast } from "@/react-native-papal";
import { GET_USER_INFO } from "@/redux/models/app";
import { PAGE_CATEGORY } from "@/redux/models/enterprise";
import { createAction, createActions } from "@/utils";
import { fp, sw, wp } from "@/utils/dimension";
import { ProcessUrl } from "@/utils/image";
import { aesEncode, aesDecode } from "@/utils/tools";
import { IsVip } from "@/utils/vip";
import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useCallback } from "react";
import { FlatList, StyleSheet, View, Text } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

interface Props {}

/// 企业数据列表
const EnterpriseListScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const route: any = useRoute();
  const navigation = useNavigation();
  const { item } = route.params;
  const { title, imgUrl, pk } = JSON.parse(aesDecode(item));
  const { userInfo } = useSelector(({ app, loading }: any) => ({
    userInfo: app.userInfo,
  }));
  const { data, addData, onEndReached, pageIndex, loadingMore, hasMoreData } =
    usePagination(20);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: title,
    });
  }, [navigation, title]);

  useMount(() => {
    dispatch(createAction(GET_USER_INFO)({}));
  });

  React.useEffect(() => {
    let loadingKey: number;
    if (pageIndex == 0) {
      loadingKey = Toast.loading("...", TOAST_LOADING);
    }
    dispatch(
      createActions(PAGE_CATEGORY)({
        categoryPk: pk,
        current: pageIndex + 1,
        size: 20,
      })((data: any) => {
        Toast.remove(loadingKey);
        addData(data.records || []);
      })
    );
  }, [pageIndex]);

  const renderHeader = () => {
    return (
      <HeaderImage resizeMode="cover" source={{ uri: ProcessUrl(imgUrl) }}>
        <HeaderTitle>{title}</HeaderTitle>
      </HeaderImage>
    );
  };

  const renderItem = useCallback(
    ({ item }) => (
      <CategoryItem
        key={item.pk}
        item={item}
        onPress={() =>
          navigation.navigate("EnterpriseDetail", {
            items: aesEncode(JSON.stringify(item)),
          })
        }
      />
    ),
    []
  );
  const keyExtractor = useCallback((item) => item.pk, []);
  const getItemLayout = useCallback(
    (data, index) => ({
      length: wp(40),
      offset: wp(40) * index,
      index,
    }),
    []
  );

  return (
    <PageView>
      <ListView
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        contentContainerStyle={{ flexGrow: 1 }}
        data={data}
        renderItem={renderItem}
        ListHeaderComponent={renderHeader()}
        ListFooterComponent={
          <MemberLockFooter
            loadingMore={loadingMore}
            hasMoreData={hasMoreData}
            isVip={IsVip(userInfo)}
            onMemberPress={() => navigation.navigate("Member", { tabIndex: 0 })}
          />
        }
        keyExtractor={keyExtractor}
      />
    </PageView>
  );
};

export default EnterpriseListScreen;

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;

const HeaderImage = styled.ImageBackground`
  height: ${(sw * 264) / 812}px;
  background-color: ${Colors.grey400};
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`;
const HeaderTitle = styled(Paragraph)`
  font-size: ${fp(6.4)}px;
  font-weight: bold;
  color: ${Colors.white};
`;
const ListView = styled.FlatList`
  /* padding-top: 12px; */
`;

const styles = StyleSheet.create({});
