import { fp, wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  style?: any;
  data: Array<ItemProps>;
  onChange?: (key: number) => void;
}

interface ItemProps {
  id: number;
  title: string;
  selected?: boolean;
  onPress?: (key: number) => void;
}

const ButtonSelectorItem: React.FC<ItemProps> = (props) => {
  const titleStyles = props.selected ? styles.selected : styles.unselected;
  const indicatorStyles = props.selected
    ? styles.indicatorSelected
    : styles.indicatorUnselected;
  return (
    <ItemView onPress={() => props.onPress && props.onPress(props.id)}>
      <Title style={titleStyles}>{props.title}</Title>
      <Indicator style={indicatorStyles} />
    </ItemView>
  );
};

const ButtonSelector: React.FC<Props> = (props) => {
  const [selectKey, setSelectKey] = React.useState(1);
  const dataMap = props.data.map((item: ItemProps) => {
    return (
      <ButtonSelectorItem
        key={item.id}
        {...item}
        selected={selectKey == item.id}
        onPress={(key) => {
          setSelectKey(key);
          props.onChange && props.onChange(key);
        }}
      />
    );
  });
  return <SelectorView style={props.style}>{dataMap}</SelectorView>;
};

ButtonSelector.defaultProps = {
  data: [],
};

export default ButtonSelector;
const SelectorView = styled.View`
  width: 100%;
  flex-direction: row;
  padding: 10px;
  justify-content: space-around;
`;
const ItemView = styled.Pressable`
  align-items: center;
  justify-content: center;
`;
const Title = styled.Text`
  font-size: ${fp(4.5)}px;
  color: ${Colors.white};
`;
const Indicator = styled.View`
  width: 44px;
  height: 2px;
  background-color: ${Colors.white};
  margin-top: 6px;
`;

const styles = StyleSheet.create({
  selected: {
    color: Colors.white,
  },
  unselected: {
    color: Colors.grey300,
  },
  indicatorSelected: {
    backgroundColor: Colors.white,
  },
  indicatorUnselected: {
    backgroundColor: "transparent",
  },
});
