import { wp } from "@/utils/dimension";
import React, { memo } from "react";
import { Platform, StyleSheet } from "react-native";
import Canvas from "react-native-canvas";
import styled from "styled-components/native";
import F2 from "@antv/f2/lib/index-all";
import "@antv/f2/lib/interaction/";
// const F2 = require("@antv/f2/lib/core");
// require("@antv/f2/lib/geom/");
// require("@antv/f2/lib/geom/adjust/");
// require("@antv/f2/lib/coord/polar");
// require("@antv/f2/lib/component/axis/circle");
// require("@antv/f2/lib/component/axis/line");
// require("@antv/f2/lib/scale/time-cat");
// require("@antv/f2/lib/component/guide");
// require("@antv/f2/lib/interaction/");
// const Guide = require("@antv/f2/lib/plugin/guide");
// const Legend = require("@antv/f2/lib/plugin/legend");
// const Tooltip = require("@antv/f2/lib/plugin/tooltip");
// F2.Chart.plugins.register([Legend, Guide, Tooltip]);
interface Props {
  data: Array<any>;
  onPress?: (item: any) => void;
}

/// shop bar chart
const ShopBarChart: React.FC<Props> = (props) => {
  const handleCanvas = async (canvas: any) => {
    if (canvas && props.data.length > 0) {
      const context = await canvas.getContext("2d");
      canvas.width = wp(100);
      const chart = new F2.Chart({
        context: context,
        pixelRatio: window.devicePixelRatio,
        height: props.data.length * 32,
        padding: [10, 16, 32, 48],
      });
      chart.source(props.data, {
        count: { min: 0 },
      });
      chart.animate({
        interval: {
          appear: false,
          enter: false,
          update: false,
          leave: false,
        },
      });
      chart.coord({
        transposed: true,
      });
      chart.tooltip(false);
      chart.interval().position("name*count");
      chart.render();
      // 点击
      chart.interaction("interval-select", {
        onEnd: (ev: any) => {
          const data = ev.data,
            selected = ev.selected;
          if (selected) {
            props.onPress && props.onPress(data);
          }
        },
      });
      // 绘制柱状图文本
      const offset = -8;
      const chartCanvas = chart.get("canvas");
      const group = chartCanvas.addGroup();
      const shapes: any = {};
      props.data.forEach((obj: any) => {
        const point = chart.getPosition(obj);
        const text = group.addShape("text", {
          attrs: {
            x: point.x,
            y: point.y + offset,
            text: obj.count,
            textAlign: "center",
            textBaseline: "bottom",
            fill: "#808080",
          },
        });
        shapes[obj.count] = text;
      });
    }
  };
  return (
    <ChartView>
      {Platform.OS === "web" ? (
        <canvas ref={handleCanvas} />
      ) : (
        <Canvas ref={handleCanvas} />
      )}
    </ChartView>
  );
};

ShopBarChart.defaultProps = {
  data: [],
};

export default memo(ShopBarChart);

const ChartView = styled.View``;

const styles = StyleSheet.create({});
