import React from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import { Paragraph } from "react-native-paper";

interface Props {
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  borderStyle?: any;
  data?: Array<any>;
  width?: number;
  height?: number;
  flex?: any;
}

export const Cell: React.FC<Props> = (props) => {
  const { data, width, height, flex, style, textStyle, borderStyle } = props;
  const textDom = React.isValidElement(data) ? (
    data
  ) : (
    <Paragraph style={[styles.text, textStyle]} {...props}>
      {data}
    </Paragraph>
  );
  const borderTopWidth = (borderStyle && borderStyle.borderWidth) || 0;
  const borderRightWidth = borderTopWidth;
  const borderColor = (borderStyle && borderStyle.borderColor) || "#000";
  return (
    <View
      style={[
        {
          borderTopWidth,
          borderRightWidth,
          borderColor,
        },
        styles.cell,
        width && { width },
        height && { height },
        flex && { flex },
        !width && !flex && !height && !style && { flex: 1 },
        style,
      ]}
    >
      {textDom}
    </View>
  );
};

const styles = StyleSheet.create({
  cell: { justifyContent: "center" },
  text: { backgroundColor: "transparent" },
});
