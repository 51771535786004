import { isWechat } from "@/utils/tools";
import html2canvas from "html2canvas";
// import JsPDF from "jspdf";
import ReactDOM from "react-dom";

const fileType = {
  PNG: "image/png",
  JPEG: "image/jpeg",
  PDF: "application/pdf",
};

const DEFAULT_PNG = {
  fileName: "component.png",
  type: fileType.PNG,
  html2CanvasOptions: {},
};

const DEFAULT_JPEG = {
  fileName: "component.jpg",
  type: fileType.JPEG,
  html2CanvasOptions: {},
};

const DEFAULT_PDF = {
  fileName: "component.pdf",
  type: fileType.PDF,
  html2CanvasOptions: {},
  pdfOptions: {},
};

/**
 * @param  {string} uri
 * @param  {string} filename
 */
// @ts-ignore
const saveAs = (uri, filename) => {
  // @ts-ignore
  const link = document.createElement("a");
  if (typeof link.download === "string") {
    link.href = uri;
    link.download = filename;
    // @ts-ignore
    document.body.appendChild(link);
    link.click();
    // @ts-ignore
    document.body.removeChild(link);
  } else {
    // @ts-ignore
    window.open(uri);
  }
};

/**
 * @param  {React.RefObject} node
 * @param  {string} fileName
 * @param  {string} type
 * @param  {object} html2CanvasOptions={}
 */
// @ts-ignore
const getPDF = (canvas, { w, h, orientation, unit = "mm", pdfFormat }) => {
  const width = w || canvas.width;
  const height = h || canvas.height;
  const o = orientation || width > height ? "l" : "p";
  const format = pdfFormat || "a4";

  // @ts-ignore
  return new JsPDF(o, unit, format);
};

// @ts-ignore all
const exportComponent = (
  // @ts-ignore
  node,
  // @ts-ignore
  { fileName, type, html2CanvasOptions, pdfOptions }
) => {
  if (!node.current) {
    throw new Error("'node' must be a RefObject");
  }

  const element = ReactDOM.findDOMNode(node.current);
  // @ts-ignore
  return html2canvas(element, {
    // @ts-ignore
    scrollY: -window.scrollY,
    useCORS: true,
    ...html2CanvasOptions,
  }).then((canvas: any) => {
    if (type === fileType.PDF) {
      const pdf = getPDF(canvas, pdfOptions);
      pdf.addImage(
        canvas.toDataURL(fileType.PNG, 1.0),
        "PNG",
        pdfOptions.x || 0,
        pdfOptions.y || 0,
        pdfOptions.w || canvas.width,
        pdfOptions.h || canvas.height
      );
      pdf.save(fileName);
    } else {
      if (!isWechat()) {
        saveAs(canvas.toDataURL(type, 1.0), fileName);
      }

      return canvas.toDataURL(type, 1.0);
    }
  });
};

/**
 * @param  {React.RefObject} node
 * @param  {string} fileName='component.png'
 * @param  {object} html2CanvasOptions={}
 */
// @ts-ignore
const exportComponentAsPNG = (node, fileName, parameters = {}) =>
  // @ts-ignore
  exportComponent(node, { ...DEFAULT_PNG, fileName: fileName, ...parameters });

/**
 * @param  {React.RefObject} node
 * @param  {string} fileName='component.jpeg'
 * @param  {string} type=fileType.JPEG
 * @param  {object} html2CanvasOptions={}
 */
// @ts-ignore
const exportComponentAsJPEG = (node, parameters = {}) =>
  // @ts-ignore
  exportComponent(node, { ...DEFAULT_JPEG, ...parameters });

/**
 * @param  {React.RefObject} node
 * @param  {string} fileName='component.pdf'
 * @param  {string} type=fileType.PDF
 * @param  {object} html2CanvasOptions={}
 * @p
 * aram  {string} pdfOptions={}
 */
// @ts-ignore
const exportComponentAsPDF = (node, parameters = {}) =>
  exportComponent(node, { ...DEFAULT_PDF, ...parameters });

/// view to png
const exportToPng = async (ref: any, filename: string) => {
  const result = await exportComponentAsPNG(ref, filename);
  return result;
};
export { exportToPng };
