import { wp } from "@/utils/dimension";
import { ProcessUrl } from "@/utils/image";
import { LinkTo } from "@/utils/link";
import React, { useRef } from "react";
import {
  Dimensions,
  ScrollView,
  StyleSheet,
  Animated,
  View,
} from "react-native";
import styled from "styled-components/native";
import Image from "../image";

interface Props {
  data: Array<any>;
  width?: any;
  onPress?: () => void;
  style?: any;
}
const { width } = Dimensions.get("window");

const Carousel: React.FC<Props> = (props) => {
  const carousel = useRef<any>();
  const scrollValue = useRef(new Animated.Value(0)).current;
  const translateX = scrollValue.interpolate({
    inputRange: [0, width],
    outputRange: [0, 20],
  });

  React.useEffect(() => {
    let index = 1;
    const interval = setInterval(() => {
      carousel.current.scrollTo({
        x: (width - 32) * index,
        y: 0,
        animated: true,
      });
      index++;
      if (index >= props.data.length) {
        index = 0;
      }
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <View style={[styles.container, props.style]}>
      <ScrollView
        ref={carousel}
        horizontal
        pagingEnabled
        decelerationRate="fast"
        scrollEventThrottle={16}
        showsHorizontalScrollIndicator={false}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { x: scrollValue } } }],
          { useNativeDriver: false }
        )}
      >
        {props.data.map((item, index) => (
          <ImageView
            key={index}
            onPress={() => item.imgUrl && LinkTo(item.linkTo)}
          >
            <CarouselImage source={ProcessUrl(item.imgUrl)} />
          </ImageView>
        ))}
      </ScrollView>
      <View style={styles.indicatorContainer} pointerEvents="none">
        {props.data.map((x, index) => (
          // @ts-ignore
          <Indicator x={x} key={index} />
        ))}
        <Animated.View
          style={[
            styles.activeIndicator,
            { position: "absolute", transform: [{ translateX }] },
          ]}
        />
      </View>
    </View>
  );
};

function Indicator() {
  return <View style={styles.indicator} />;
}

Carousel.defaultProps = {
  data: [],
};

export default Carousel;

const ImageView = styled.Pressable``;
const CarouselImage = styled.Image`
  width: ${wp(100) - 32}px;
  height: ${wp(30)}px;
  border-radius: 5px;
`;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    marginLeft: 16,
    marginRight: 16,
    borderRadius: 5,
  },
  indicatorContainer: {
    alignSelf: "center",
    position: "absolute",
    bottom: 10,
    flexDirection: "row",
  },
  indicator: {
    height: 3,
    width: 10,
    borderRadius: 1,
    backgroundColor: "#00000044",
    marginHorizontal: 4,
  },
  activeIndicator: {
    height: 3,
    width: 10,
    borderRadius: 1,
    backgroundColor: "#fff",
    marginHorizontal: 4,
  },
});
