import { Platform } from "react-native";
import * as RootNavigation from "../navigate";
import { isUrl } from "../tools";

// 跳转链接 包括跳转到页面、跳转到外部链接
export const LinkTo: any = (url: string, params?: any) => {
  if (isUrl(url)) {
    if (Platform.OS === "web") {
      location.href = url;
    } else {
      RootNavigation.navigate("WebView", { url });
    }
  } else {
    RootNavigation.navigate(url, params);
  }
};
