import React from "react";
import { View } from "react-native";

interface TableProps {
  style?: any;
  borderStyle?: any;
}
export const Table: React.FC<TableProps> = (props) => {
  const renderChildren = (props: any) => {
    return React.Children.map(props.children, (child) =>
      React.cloneElement(
        child,
        props.borderStyle && child.type.displayName !== "ScrollView"
          ? { borderStyle: props.borderStyle }
          : {}
      )
    );
  };

  const borderLeftWidth =
    (props.borderStyle && props.borderStyle.borderWidth) || 0;
  const borderBottomWidth = borderLeftWidth;
  const borderColor =
    (props.borderStyle && props.borderStyle.borderColor) || "#000";

  return (
    <View
      style={[
        props.style,
        {
          borderLeftWidth,
          borderBottomWidth,
          borderColor,
        },
      ]}
    >
      {renderChildren(props)}
    </View>
  );
};

interface TableWrapperProps {
  style?: any;
}
export const TableWrapper: React.FC<TableWrapperProps> = (props) => {
  const renderChildren = (props: any) => {
    return React.Children.map(props.children, (child) =>
      React.cloneElement(
        child,
        props.borderStyle ? { borderStyle: props.borderStyle } : {}
      )
    );
  };
  return <View style={props.style}>{renderChildren(props)}</View>;
};
