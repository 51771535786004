import {
  Colors,
  configureFonts,
  DarkTheme as PaperDarkTheme,
  DefaultTheme as PaperDefaultTheme,
} from "react-native-paper";
import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from "@react-navigation/native";

const fontConfig: any = {
  web: {
    regular: {
      fontFamily: "sans-serif",
      fontWeight: "normal",
    },
    medium: {
      fontFamily: "sans-serif-medium",
      fontWeight: "normal",
    },
    light: {
      fontFamily: "sans-serif-light",
      fontWeight: "normal",
    },
    thin: {
      fontFamily: "sans-serif-thin",
      fontWeight: "normal",
    },
  },
  ios: {
    regular: {
      fontFamily: "sans-serif",
      fontWeight: "normal",
    },
    medium: {
      fontFamily: "sans-serif-medium",
      fontWeight: "normal",
    },
    light: {
      fontFamily: "sans-serif-light",
      fontWeight: "normal",
    },
    thin: {
      fontFamily: "sans-serif-thin",
      fontWeight: "normal",
    },
  },
  android: {
    regular: {
      fontFamily: "sans-serif",
      fontWeight: "normal",
    },
    medium: {
      fontFamily: "sans-serif-medium",
      fontWeight: "normal",
    },
    light: {
      fontFamily: "sans-serif-light",
      fontWeight: "normal",
    },
    thin: {
      fontFamily: "sans-serif-thin",
      fontWeight: "normal",
    },
  },
};

export const DefaultTheme = {
  ...NavigationDefaultTheme,
  ...PaperDefaultTheme,
  // fonts: configureFonts(fontConfig),
  colors: {
    ...NavigationDefaultTheme.colors,
    ...PaperDefaultTheme.colors,
    ...Colors,
    primary: "#1983FB",
    subPrimary: "#F4F9FF",
    text: "#414141",
    whiteText: Colors.white,
    paragraph: "#9C9AAB",
    divider: "#9C9AAB",
    redText: Colors.red300,
    yellowText: "#f3924b",
    yellow: Colors.yellow300,
    caption: Colors.grey500,
    background: Colors.white,
    page: Colors.grey100,
    tabIconDefault: Colors.grey600,
    tabIconSelected: Colors.blue700,
    member: Colors.amber200,
    modal: "rgba(0,0,0,0.05)",
  },
};
export const DarkTheme = {
  ...PaperDarkTheme,
  ...NavigationDarkTheme,
  fonts: configureFonts(fontConfig),
  colors: {
    ...NavigationDarkTheme.colors,
    ...PaperDarkTheme.colors,
    ...Colors,
    primary: "#1983FB",
    subPrimary: "#F4F9FF",
    text: "#414141",
    whiteText: Colors.white,
    paragraph: "#9C9AAB",
    divider: "#9C9AAB",
    redText: Colors.red300,
    yellowText: "#f3924b",
    yellow: Colors.yellow300,
    caption: Colors.grey500,
    background: Colors.white,
    page: Colors.grey100,
    tabIconDefault: Colors.grey600,
    tabIconSelected: Colors.blue700,
    member: Colors.amber200,
    modal: "rgba(0,0,0,0.05)",
  },
};
