import { wp } from "@/utils/dimension";
import React from "react";
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  loadingMore?: boolean;
  hasMoreData?: boolean;
  isVip?: boolean;
  onMemberPress?: () => void;
}

const MemberLockFooter: React.FC<Props> = (props) => {
  const { loadingMore, hasMoreData } = props;
  return (
    <ItemView>
      {loadingMore && (
        <ActivityView>
          <ActivityIndicator size={28} color={Colors.grey800} />
        </ActivityView>
      )}
      {!hasMoreData && (
        <Pressable onPress={() => props.onMemberPress && props.onMemberPress()}>
          {props.isVip ? (
            <MoreText>数据持续更新中...</MoreText>
          ) : (
            <LockImage
              source={require("@/assets/images/common/common_member_lock.png")}
            />
          )}
        </Pressable>
      )}
    </ItemView>
  );
};

export default MemberLockFooter;

const ItemView = styled.View`
  margin-top: 10px;
  height: 52px;
`;
const ActivityView = styled.View`
  justify-content: "center";
  align-items: "center";
  padding-top: 8px;
`;

const MoreText = styled(Paragraph)`
  align-self: center;
  margin-top: 5px;
  margin-bottom: 10px;
`;
const LockImage = styled.Image`
  width: ${wp(100) - 24}px;
  height: ${((wp(100) - 24) * 291) / 1095}px;
  align-self: center;
  margin-bottom: 12px;
`;

const styles = StyleSheet.create({});
