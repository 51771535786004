import { Modal } from "@/react-native-papal";
import React from "react";
import { StyleSheet } from "react-native";
import { Button, Colors, Headline, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  headTitle: string;
  title: string;
  visible?: boolean;
  buttons?: React.ReactNode;
  onClose?: () => void;
}

/// share alert
const ShareAlert: React.FC<Props> = (props) => {
  return (
    <ModalView
      visible={props.visible}
      transparent
      maskClosable={true}
      onClose={props.onClose}
    >
      <ContentView>
        <TitleText>{props.headTitle}</TitleText>
        <IconImage
          source={require("@/assets/images/common/submit_success.png")}
        />
        <ParagraphText>{props.title}</ParagraphText>
        <ParagraphText>如果你方便使用电脑</ParagraphText>
        <ParagraphText>在电脑登录</ParagraphText>
        <WebsiteText>h5.smartchoose.cn</WebsiteText>
        <ParagraphText>可直接下载</ParagraphText>
        {props.buttons ? (
          props.buttons
        ) : (
          <SureButton mode="contained" onPress={props.onClose}>
            好的
          </SureButton>
        )}
      </ContentView>
    </ModalView>
  );
};

export default ShareAlert;

const ModalView = styled(Modal)`
  justify-content: center;
  align-items: center;
`;
const ContentView = styled.View`
  justify-content: center;
  align-items: center;
`;
const TitleText = styled(Headline)`
  font-size: 17px;
  font-weight: bold;
`;
const IconImage = styled.Image`
  width: 26px;
  height: 26px;
  margin-bottom: 12px;
`;

const ParagraphText = styled(Paragraph)`
  font-size: 16px;
  color: ${Colors.grey500};
`;

const WebsiteText = styled(Paragraph)`
  font-size: 16px;
  color: ${Colors.blue500};
`;

const SureButton = styled(Button)`
  background-color: ${Colors.blue500};
  margin-top: 12px;
`;

const styles = StyleSheet.create({});
