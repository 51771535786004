import { MeApi, AppApi } from "@/redux/services";
import { check, createAction } from "@/utils";
import { DvaModel, ReduxAction } from "../redux";
import { ResponseGenerator } from "../connect";
import { SAVE, TOAST_LOADING } from "@/constants/app";
import { Toast } from "@/react-native-papal";
import { useTimeout } from "@/react-native-ahook";

// actions
export const GET_MEMBER_DESC_INFO = "me/getMemberDescInfo";
export const DO_VIP_CREATE_ORDER = "me/doVipCreateOrder";
export const MP_PRESENT_VIP_CREATE_ORDER = "me/doMpPresentVipCreateOrder";
export const GET_COUPON_LIST = "me/getCouponList";
export const EXCHANGE_REDEEM = "me/exchangeRedeem";
export const GET_LIST_PRESENT_RECORD = "me/getListPresentRecord";
export const DRAW_COUPON = "me/drawCoupon";
export const GET_INVITE_REWARD = "me/getInviteRecordReward";
export const PAGE_INVITE_FRIEND_RECORD = "me/pageInviteFriendRecord";
export const PAGE_INVITE_EARN = "me/pageInviteEarn";
export const GET_WITHDRAW_ACCOUNT = "me/getWithdrawAccount";
export const WITHDRAW_ACCOUNT = "me/withdrawAccount";
export const PAGE_WITHDRAW_SETTLE = "me/pageInviteWithdrawSettle";
export const GET_PARTNER_CENTER = "me/getPartnerCenter";
export const PAGE_PARTNER_PURCHASE_RECORD = "me/pagePartnerPurchaseRecord";
export const AUTH_PARTNER_ACCOUNT = "me/authPartnerAccount";
export const PAGE_PARTNER_AUTH_RECORD = "me/pagePartnerAuthRecord";
export const ADD_SUB_ACCOUNT = "me/addSubAccount";
export const PAGE_MEMBER_ORDER_RECORD = "me/pageMemberOrderRecord";
export const GET_SUB_ACCOUNT_INFO = "me/getSubAccountManagementInfo";
export const PAGE_SUB_ACCOUNT = "me/pageSubAccount";

export interface IAppState {
  loading?: boolean;
  dictDesc?: IMemberDescInfoItem;
  wxMpPayResult: IWxMpPayResult;
  couponList?: Array<ICouponItem>;
  presentRecord: Array<IPresentRecordItem>;
  inviteReward: IInviteRewardItem;
  withdrawAccount: IWithdrawAccountItem;
  partnerCenter: IPartnerCenterItem;
  subAccountInfo: ISubAccountInfoItem;
}

export interface ISubAccountInfoItem {
  mobile: string;
  subaccountCount: number;
  usedCount: number;
  vipEndDate: string;
}

export interface IPartnerCenterItem {
  authCount: number;
  crtTime: string;
  earnFee: number;
  earnFeeCount: number;
  groupCount: number;
  vipName: string;
  inviteNum: number;
  rebateRatio: string;
  settleFee: string;
  vipLevel: number;
}

export interface IWithdrawSettleItem {
  payeeName: string;
  amount: number;
  status: 550 | 556;
  crtTime: string;
}

interface IWithdrawAccountItem {
  pk: string;
  payType: string;
  payeeName: string;
  payeeAccount: string;
}
interface IInviteRewardItem {
  inviteNum: string;
  earnVipNum: string;
  earnFee: string;
  settleFee: string;
  earnFeeCount: string;
}
interface IMemberDescInfoItem {
  personVip: Array<IMemberDescItem>;
  groupVip: Array<IMemberDescItem>;
  authVipPackage: Array<IMemberDescItem>;
}

interface IMemberDescItem {
  pk: string;
  dvalue: string;
  dkey: string;
  remark: string;
  remark2: string;
  remark3: string;
}

export interface IWxMpPayResult {
  signType: string;
  paySign: string;
  appId: string;
  packageValue: string;
  timeStamp: string;
  nonceStr: string;
}
export interface ICouponItem {
  pk: string;
  title: string;
  price: string;
  des: string;
  expired: string;
  startDate: string;
  endDate: string;
  status: string;
}

export interface IPresentRecordItem {
  crtTime: string;
  presentName: string;
  receiverMobile: string;
}

/**
 * app
 */
const NAMESPACE = "me";

const model: DvaModel<IAppState> = {
  namespace: NAMESPACE,
  state: {
    loading: false,
    // @ts-ignore
    dictDesc: {
      personVip: [],
      groupVip: [],
      authVipPackage: [],
    },
    // @ts-ignore
    wxMpPayResult: {},
    couponList: [],
    presentRecord: [],
    // @ts-ignore
    inviteReward: {},
    // @ts-ignore
    withdrawAccount: {},
    // @ts-ignore
    partnerCenter: {},
    // @ts-ignore
    subAccountInfo: {},
  },
  effects: {
    // 个人VIP信息
    *getMemberDescInfo({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        AppApi.singleDictListByKeys,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ dictDesc: response.data }));
        callback && callback();
      }
    },
    // 购买vip下订单
    *doVipCreateOrder({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        MeApi.doMpVipCreateOrder,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ wxMpPayResult: response.data }));
        callback && callback(JSON.parse(response.data));
      }
      Toast.remove(loadingKey);
    },
    // 赠送vip下订单
    *doMpPresentVipCreateOrder({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        MeApi.doMpPresentVipCreateOrder,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ wxMpPayResult: response.data }));
        callback && callback(JSON.parse(response.data));
      }
    },
    // 优惠券列表
    *getCouponList({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        MeApi.fetchCouponList,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ couponList: response.data }));
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 兑换兑换码
    *exchangeRedeem({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        MeApi.exchangeRedeem,
        payload
      );
      if (check(response)) {
        callback && callback();
        Toast.success("兑换成功");
      }
      Toast.remove(loadingKey);
    },
    // 获取赠送记录
    *getListPresentRecord({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        MeApi.fetchListPresentRecord,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ presentRecord: response.data }));
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 领取优惠券
    *drawCoupon({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(MeApi.drawCoupon, payload);
      if (check(response)) {
        yield put(createAction("app/getUserInfo")({}));
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 邀请奖励
    *getInviteRecordReward({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        MeApi.fetchInviteRecordReward,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ inviteReward: response.data }));
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 分页好友邀请记录
    *pageInviteFriendRecord({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        MeApi.pageInviteFriendRecord,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
    },
    // 分页邀请收益
    *pageInviteEarn({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        MeApi.pageInviteEarn,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
    },
    // 获取提现账号
    *getWithdrawAccount({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        MeApi.fetchWithdrawAccount,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ withdrawAccount: response.data }));
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 提现账号
    *withdrawAccount({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        MeApi.withdrawAccount,
        payload
      );
      if (check(response)) {
        yield put(createAction("getWithdrawAccount")({}));
        Toast.success("设置成功");
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 分页邀请提现结算
    *pageInviteWithdrawSettle({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        MeApi.pageInviteWithdrawSettle,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
    },
    // 合伙人中心
    *getPartnerCenter({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        MeApi.fetchPartnerCenter,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ partnerCenter: response.data }));
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 分页合伙人采购记录
    *pagePartnerPurchaseRecord({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        MeApi.pagePartnerPurchaseRecord,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
    },
    // 合伙人授权账号
    *authPartnerAccount({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        MeApi.authPartnerAccount,
        payload
      );
      if (check(response)) {
        yield put(createAction("getPartnerCenter")({}));
        callback && callback();
        Toast.success("开通成功");
      }
      Toast.remove(loadingKey);
    },
    // 分页合伙人开通VIP记录
    *pagePartnerAuthRecord({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        MeApi.pagePartnerAuthRecord,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
    },
    // 授权(添加)子账号
    *addSubAccount({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        MeApi.addSubAccount,
        payload
      );
      if (check(response)) {
        yield put(createAction("getSubAccountManagementInfo")({}));
        yield put(createAction("pageSubAccount")({ current: 1, size: 20 }));
        callback && callback();
        Toast.success("开通成功");
      }
      Toast.remove(loadingKey);
    },
    // 分页会员订单记录
    *pageMemberOrderRecord({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        MeApi.pageMemberOrderRecord,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
    },
    // 子账号管理信息查询
    *getSubAccountManagementInfo({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        MeApi.fetchSubAccountManagementInfo,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ subAccountInfo: response.data }));
        callback && callback();
      }
    },
    // 分页子账号
    *pageSubAccount({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        MeApi.pageSubAccount,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
    },
  },
  reducers: {
    save(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        ...payload,
      };
    },
    error(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        error: payload,
      };
    },
  },
  subscriptions: {
    init(dispatch: any) {},
  },
};
export default model;
