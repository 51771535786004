export const saveAs = (uri: string, filename: string) => {
  // @ts-ignore
  const link = document.createElement("a");
  if (typeof link.download === "string") {
    link.href = uri;
    link.download = filename;
    // @ts-ignore
    document.body.appendChild(link);
    link.click();
    // @ts-ignore
    document.body.removeChild(link);
  } else {
    // @ts-ignore
    window.open(uri);
  }
};
