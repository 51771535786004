import PresentSelector from "@/components/present-selector";
import { useMount } from "@/react-native-ahook";
import {
  GET_MEMBER_DESC_INFO,
  MP_PRESENT_VIP_CREATE_ORDER,
} from "@/redux/models/me";
import { createAction, createActions } from "@/utils";
import { fp } from "@/utils/dimension";
import { isWechat } from "@/utils/tools";
import WxTools from "@/utils/wechat/mp";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Platform } from "react-native";
import { Button, Colors, Paragraph } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

interface Props {}

const VipData = [
  {
    id: 1,
    title: "3年VIP",
    price: "¥1999",
    selected: true,
    badge: "超值",
  },
  {
    id: 2,
    title: "1年VIP",
    price: "¥999",
    badge: "最热",
  },
  {
    id: 3,
    title: "半年VIP",
    price: "¥699",
  },
];

/// 赠送好友VIP
const PresentScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [mobile, setMobile] = React.useState("");
  const [selectItem, setSelectItem] = React.useState<any>({});
  const { dictDesc } = useSelector(({ me, loading }: any) => ({
    dictDesc: me.dictDesc,
  }));

  useMount(() => {
    dispatch(createAction(GET_MEMBER_DESC_INFO)("personVip"));
  });
  const renderInputView = () => {
    return (
      <InputView>
        <InputTitleView>
          <InputTitle>输入好友手机号</InputTitle>
          <InputDesc onPress={() => navigation.navigate("PresentRecord")}>
            赠送纪录
          </InputDesc>
        </InputTitleView>
        <TextInput
          maxLength={11}
          keyboardType="numeric"
          placeholder="输入好友手机号"
          // @ts-ignore
          style={Platform.select({
            web: {
              outlineStyle: "none",
            },
          })}
          onChangeText={(text) => setMobile(text)}
        />
      </InputView>
    );
  };
  const renderMemberView = () => {
    const memberData = dictDesc.personVip.map((item: any, index: number) => {
      const remark3 = item.remark3.split(",");
      return {
        key: index + 1,
        title: item.dvalue,
        price: "¥" + item.remark,
        badge: remark3[2],
        pk: item.pk,
      };
    });
    return (
      <InputView>
        <InputTitleView>
          <InputTitle>VIP会员套餐</InputTitle>
          <InputDesc
            onPress={() => navigation.navigate("Member", { tabIndex: 0 })}
          >
            VIP特权
          </InputDesc>
        </InputTitleView>
        <PresentSelector
          data={memberData}
          onCallback={(item) => setSelectItem(item)}
        />
      </InputView>
    );
  };
  const renderDescView = () => {
    return (
      <InputView>
        <Paragraph>说明:</Paragraph>
        <DescText>
          *完成支付后可申请发票{"\n"}
          *请务必填准确好友的手机号，确定后无法修改{"\n"}
          *VIP会员在支付后5分钟内生效
        </DescText>
      </InputView>
    );
  };

  // 下订单
  const doPresentCreateOrder = () => {
    if (isWechat()) {
      WxTools.getConfig(window.location.href, () => {
        dispatch(
          createActions(MP_PRESENT_VIP_CREATE_ORDER)({
            vipType: 441,
            vipDictPk: selectItem.pk,
            mobile: mobile,
          })((result: any) => {
            WxTools.wxPay(result, (res) => {
              // dispatch(createAction(GET_USER_INFO)());
            });
          })
        );
      });
    }
  };

  return (
    <PageView>
      <PageScrollView>
        {renderInputView()}
        {renderMemberView()}
        {renderDescView()}
      </PageScrollView>
      <PayToolView>
        <Button
          style={styles.payButton}
          mode="contained"
          disabled={!selectItem.pk || !mobile}
          color={Colors.amber200}
          onPress={() => doPresentCreateOrder()}
        >
          立即赠送
        </Button>
      </PayToolView>
    </PageView>
  );
};

export default PresentScreen;

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;
const PageScrollView = styled.ScrollView`
  flex: 1;
`;
const InputView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  padding: 16px;
  margin-bottom: 10px;
`;
const InputTitleView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const InputTitle = styled.Text`
  flex: 1;
  font-size: ${fp(4.2)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const InputDesc = styled.Text`
  font-size: ${fp(3.4)}px;
  color: ${(props: IStyledProps) => props.theme.primary};
`;
const TextInput = styled.TextInput`
  border-width: 1px;
  border-color: ${Colors.grey200};
  height: 48px;
  border-radius: 5px;
  padding: 16px;
  margin-top: 12px;
`;

const DescText = styled.Text`
  color: ${(props: IStyledProps) => props.theme.caption};
  font-size: ${fp(3.3)}px;
  margin-top: 2px;
  line-height: 21px;
`;
const PayToolView = styled.View`
  padding: 6px 16px 16px 16px;
  justify-content: center;
  align-items: center;
`;

const styles = StyleSheet.create({
  payButton: {
    width: "100%",
    borderRadius: 32,
  },
});
