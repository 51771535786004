import { wp } from "@/utils/dimension";
import React, { memo } from "react";
import { Platform, StyleSheet } from "react-native";
import Canvas from "react-native-canvas";
import styled from "styled-components/native";

// import F2 from "@antv/f2";

const F2 = require("@antv/f2/lib/core");
require("@antv/f2/lib/geom/");
require("@antv/f2/lib/geom/adjust/");
require("@antv/f2/lib/coord/polar");
require("@antv/f2/lib/component/axis/circle");
require("@antv/f2/lib/scale/time-cat");
require("@antv/f2/lib/component/guide");
const Guide = require("@antv/f2/lib/plugin/guide");
const Legend = require("@antv/f2/lib/plugin/legend");
const Tooltip = require("@antv/f2/lib/plugin/tooltip");
const ScrollBar = require("@antv/f2/lib/plugin/scroll-bar");
const PieLabel = require("@antv/f2/lib/plugin/pie-label");
const pan = require("@antv/f2/lib/interaction/pan");
F2.Chart.plugins.register([Legend, Guide, Tooltip, ScrollBar, pan, PieLabel]);

interface Props {
  data: Array<any>;
}

/// pie chart
const PieChart: React.FC<Props> = (props) => {
  const handleCanvas = async (canvas: any) => {
    if (canvas) {
      const context = await canvas.getContext("2d");
      canvas.width = wp(100) - 24;
      canvas.height = 220;
      const chart = new F2.Chart({
        context: context,
        pixelRatio: window.devicePixelRatio,
        width: wp(100) - 24,
        height: 240,
      });
      chart.source(props.data);
      chart.coord("polar", {
        transposed: true,
        radius: 0.8,
        innerRadius: 0.4,
      });
      chart.axis(false);
      chart.legend(false);
      chart.tooltip(false);
      //   chart.guide().html({
      //     position: ["50%", "50%"],
      //     html: '<div style="text-align: center;width:150px;height: 50px;">\n      <p style="font-size: 12px;color: #999;margin: 0" id="title"></p>\n      <p style="font-size: 18px;color: #343434;margin: 0;font-weight: bold;" id="money"></p>\n      </div>',
      //   });
      chart
        .interval()
        .position("const*count")
        .adjust("stack")
        .color("type", [
          "#1890FF",
          "#13C2C2",
          "#2FC25B",
          "#FACC14",
          "#e9a423",
          "#e342e9",
          "#e41553",
        ]);
      chart.pieLabel({
        sidePadding: 30,
        activeShape: true,
        label1: function label1(data: any) {
          return {
            text: data.count + "%",
            fill: "#343434",
            fontWeight: "bold",
          };
        },
        label2: function label2(data: any) {
          return {
            text: data.type,
            fill: "#999",
          };
        },
        onClick: function onClick(ev: any) {
          const data = ev.data;
          if (data) {
            // $("#title").text(data.type);
            // $("#money").text(data.money);
          }
        },
      });
      chart.render();
    }
  };
  return (
    <ChartView>
      {Platform.OS === "web" ? (
        <canvas ref={handleCanvas} />
      ) : (
        <Canvas ref={handleCanvas} />
      )}
    </ChartView>
  );
};

PieChart.defaultProps = {
  data: [],
};

export default memo(PieChart);

const ChartView = styled.View``;

const styles = StyleSheet.create({});
