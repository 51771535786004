import { Modal } from "@/react-native-papal";
import { wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet } from "react-native";
import { Colors, Paragraph } from "react-native-paper";

import styled from "styled-components/native";

interface ShareIconProps {
  source: any;
  title: string;
  onPress?: () => void;
}

interface Props {
  visible?: boolean;
  content?: React.ReactNode;
  shareTitle?: string;
  shareDesc?: string;
  downloadTitle?: string;
  onClose?: () => void;
  onWxShareFriend?: () => void;
  onWxDownload?: () => void;
}

// 分享组
const Share: React.FC<Props> = (props) => {
  return (
    <ModalView
      popup
      visible={props.visible}
      animationType="slide-up"
      maskClosable={true}
      onClose={props.onClose}
    >
      {props.content}
      <SaveText>长按图片,可保存或分享给朋友</SaveText>
      <DescText>{props.shareDesc}</DescText>

      <CancelView onPress={props.onClose}>
        <CancelText>取消</CancelText>
      </CancelView>
    </ModalView>
  );
};

Share.defaultProps = {
  visible: false,
  content: null,
  shareTitle: "微信好友",
  downloadTitle: "下载",
};

export default Share;

const ModalView = styled(Modal)`
  background-color: transparent;
`;

const CancelView = styled.Pressable`
  background-color: ${Colors.white};
  padding: 12px;
  align-items: center;
  justify-content: center;
`;
const CancelText = styled(Paragraph)`
  font-size: 15px;
`;
const DescText = styled(Paragraph)`
  color: ${Colors.white};
  font-size: 11px;
  padding: 0px 12px 0px 12px;
  height: ${wp(20)}px;
`;

const SaveText = styled(Paragraph)`
  color: ${Colors.white};
  font-size: 18px;
  padding: 6px 12px 0px 12px;
  text-align: center;
`;

const styles = StyleSheet.create({});
