import { wp } from "@/utils/dimension";
import React from "react";

import { View, Text, Image, StyleSheet } from "react-native";
import Svg, { G, Path, Text as SvgText, Defs } from "react-native-svg";
import common from "./config/common";
import pathConfig from "./config/path";

interface Props {
  fillRule: "evenodd" | "nonzero";
  width: number;
  mapData: Array<object>;
  legend: Array<object>;
  showLegend: boolean;
  showShadow: boolean;
  backgroundColor: string;
  stroke: string;
  strokeWidth: string;
}

// the china map component
const ChinaMap = (props: Props) => {
  const {
    width,
    fillRule,
    mapData,
    legend,
    showLegend,
    showShadow,
    backgroundColor,
    stroke,
    strokeWidth,
  } = props;
  const windowWidth = wp(100);
  const baseWidth = 375;
  const baseHeight = 230;
  const legendWidth = 200;
  const legendDescViewWidth =
    legendWidth * ((legend.length - 1) / legend.length);
  const realHeight = (windowWidth * baseHeight) / baseWidth;
  const marginLeft = -(windowWidth - width) / 2;

  return (
    <View style={[styles.container, { marginLeft }]}>
      {showShadow && (
        <Image
          source={require("./assets/map_china_shadows.png")}
          style={[styles.shadowImg, { width: windowWidth, height: realHeight }]}
        />
      )}

      <Svg width={windowWidth} height={realHeight}>
        <G scale={windowWidth / baseWidth}>
          {pathConfig.map((item, index) => {
            const { id, d, dx, dy, fontSize } = item;
            const findMap: any =
              mapData.find(
                (mapItem: any) => mapItem.code === parseInt(id, 10)
              ) || {};
            const provinceGradation = findMap.provinceGradation || null;
            const name = findMap.name || "城市";
            const fillLegend: any = legend.find(
              (legendItem: any) => legendItem.gradation === provinceGradation
            );
            const fillColor = fillLegend ? fillLegend.color : backgroundColor;
            return (
              <Path
                id={id}
                fillRule={fillRule}
                fill={fillColor}
                d={d}
                key={index}
                stroke={stroke}
                strokeWidth={strokeWidth}
              />
            );
          })}
        </G>
      </Svg>
      {showLegend && (
        <View style={styles.legend}>
          <View style={[styles.legendView, { width: legendWidth }]}>
            {legend.map((item: any, index) => (
              <View
                key={index}
                style={[styles.legendBlock, { backgroundColor: item.color }]}
              />
            ))}
          </View>
          <View style={[styles.legendDescView, { width: legendDescViewWidth }]}>
            {legend.map((item: any, index) => {
              if (index !== legend.length - 1) {
                return (
                  <Text style={styles.legendDesc} key={index}>
                    {item.max}
                  </Text>
                );
              }
              return null;
            })}
          </View>
        </View>
      )}
    </View>
  );
};

ChinaMap.defaultProps = {
  fillRule: "evenodd",
  width: common.window.width,
  legend: [
    { gradation: 1, color: "#FFFFFF", min: "", max: "1万" },
    { gradation: 2, color: "rgba(0, 122, 255, 0.2)", min: "1万", max: "3万" },
    { gradation: 3, color: "rgba(0, 122, 255, 0.4)", min: "3万", max: "5万" },
    { gradation: 4, color: "rgba(0, 122, 255, 0.6)", min: "5万", max: "10万" },
    { gradation: 5, color: "rgba(0, 122, 255, 1)", min: "10万", max: "" },
  ],
  showLegend: true,
  showShadow: true,
  backgroundColor: "#ffffff",
  stroke: "#dbdbdf",
  strokeWidth: "0.5",
};

export default ChinaMap;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  shadowImg: {
    position: "absolute",
    left: 0,
    top: 0,
    resizeMode: "cover",
  },
  legend: {
    marginTop: -18,
    alignItems: "center",
    alignSelf: "center",
  },
  legendView: {
    flexDirection: "row",
    borderWidth: common.onePt,
    borderColor: "#00000019",
    borderRadius: 2,
    overflow: "hidden",
  },
  legendBlock: {
    flex: 1,
    height: 10,
  },
  lastLegendBlock: {
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
  },
  legendDescView: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 7,
    alignSelf: "center",
    backgroundColor: "transparent",
  },
  legendDesc: {
    flex: 1,
    fontFamily: "PingFangSC-Regular",
    fontSize: 11,
    color: "#909095",
    alignItems: "center",
    textAlign: "center",
  },
});
