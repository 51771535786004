import { AppApi, EnterpriseApi } from "@/redux/services";
import { check, createAction } from "@/utils";
import { DvaModel, IDictTreeItem, ReduxAction } from "../redux";
import { ResponseGenerator } from "../connect";
import { SAVE, TOAST_LOADING } from "@/constants/app";
import { Toast } from "@/react-native-papal";

// actions
export const GET_BRAND_CATEGORY = "enterprise/getBrandCategory";
export const PAGE_CATEGORY = "enterprise/pageCategory";
export const GET_CATEGORY_DETAIL = "enterprise/getCategoryDetail";
export const GET_HOT_BRAND_LIST = "enterprise/getHotBrandList";
export const GET_BRAND_SEARCH = "enterprise/getBrandSearch";
export const GET_NATION_INFO = "enterprise/getNationInfo";
export const PAGE_NATION_SHOP = "enterprise/pageNationShop";
export const PAGE_TABLE = "enterprise/pageTable";
export const GENERATE_ENTERPRISE_POSTER = "enterprise/generateEnterprisePoster";

export interface IAppState {
  loading?: boolean;
  brandCategory: IBrandCategory[];
  hotBrandData: IDictTreeItem;
  brandSearchList: IBrandItem[];
  enterpriseDetail: IEnterpriseDetailItem;
  nationInfo: INationMapsItem[];
  posterImage: string;
}

export interface IBrandCategory {
  pk: string;
  pid: string;
  title: string;
  children: IBrandCategory[];
}

export interface IEnterpriseDetailItem {
  brand: IBrandItem;
  nationalMaps: INationMapsItem[];
  sameBrandMap: ISameBrandItem[];
  cityInfoMap: IEnterpriseTableItem[];
  cityLevelMap: IEnterpriseTableItem[];
  priceCityMap: IEnterpriseTableItem[];
  priceProvinceMap: IEnterpriseTableItem[];
  rateProvinceMap: IEnterpriseTableItem[];
  rateCityMap: IEnterpriseTableItem[];
  tableData: IEnterpriseTableItem[];
}

export interface IBrandItem {
  avgPrice: string;
  avgScore: string;
  bname: string;
  logo: string;
  mcount: string;
  pk: string;
  category: string;
  updTime: string;
}

export interface INationMapsItem {
  pk: string;
  code: string;
  count: number;
  name: string;
  provinceGradation: number;
}
export interface ISameBrandItem {
  bname: string;
  logo: string;
  pk: string;
}
export interface IEnterpriseTableItem {
  count: number;
  name: string;
  rate: string;
}

/**
 * app
 */
const NAMESPACE = "enterprise";

const model: DvaModel<IAppState> = {
  namespace: NAMESPACE,
  state: {
    loading: false,
    brandCategory: [],
    // @ts-ignore
    hotBrandData: {},
    brandSearchList: [],
    // @ts-ignore
    enterpriseDetail: {
      // @ts-ignore
      brand: {},
    },
    nationInfo: [],
  },
  effects: {
    // 品牌分类
    *getBrandCategory({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        EnterpriseApi.fetchBrandCategory,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ brandCategory: response.data }));
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 品类分类
    *pageCategory({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        EnterpriseApi.fetchPageCategory,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
    },
    // 品类详情
    *getCategoryDetail({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        EnterpriseApi.fetchCategoryDetail,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ enterpriseDetail: response.data }));
        callback && callback(response.data);
      }
      Toast.remove(loadingKey);
    },
    // 热门品牌
    *getHotBrandList({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        AppApi.dictTreeByKey,
        payload
      );
      if (check(response)) {
        const hotBrandData = response.data[0];
        yield put(createAction(SAVE)({ hotBrandData }));
        callback && callback();
      }
    },
    // 品类搜索
    *getBrandSearch({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        EnterpriseApi.fetchBrandSearch,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ brandSearchList: response.data }));
        callback && callback(response.data);
      }
    },
    // 全国各地门店分布
    *getNationInfo({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        EnterpriseApi.fetchNationInfo,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ nationInfo: response.data }));
        callback && callback(response.data);
      }
      Toast.remove(loadingKey);
    },
    // 分页全国各地门店
    *pageNationShop({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        EnterpriseApi.fetchPageNationShop,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
    },
    // 企业数据分布分页
    *pageTable({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        EnterpriseApi.fetchPageTable,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
      Toast.remove(loadingKey);
    },
    // 生成企业海报
    *generateEnterprisePoster({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        EnterpriseApi.generateEnterprisePoster,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ posterImage: response.data }));
        callback && callback(response.data);
      }
      Toast.remove(loadingKey);
    },
  },
  reducers: {
    save(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        ...payload,
      };
    },
    error(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        error: payload,
      };
    },
  },
  subscriptions: {
    init(dispatch: any) {},
  },
};
export default model;
