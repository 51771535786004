import { useMount } from "@/react-native-ahook";
import { hp, wp } from "@/utils/dimension";
import { ProcessUrl } from "@/utils/image";
import React, { useCallback } from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import styled from "styled-components/native";

interface Props {
  file: string;
  pageNum: number;
}

/// pdf viewer
const PdfViewer: React.FC<Props> = (props) => {
  const [numPages, setNumPages] = React.useState(0);
  const [numOfPages, setNumOfPages] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const flatList = React.useRef<any>();

  // React.useEffect(() => {
  //   if (flatList.current) {
  //     // flatList.current.scrollToIndex({
  //     //   animated: true,
  //     //   index: props.pageNum - 1,
  //     // });
  //     flatList.current.scrollToOffset({ offset: 1000 });
  //   }
  // });

  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
    // @ts-ignore
    setNumOfPages(Array.from(Array(pdf.numPages).keys()));
  };

  const renderItem = useCallback(
    ({ item }) => <Page width={wp(100)} key={item + 1} pageNumber={item + 1} />,
    []
  );
  const keyExtractor = useCallback((item) => item, []);

  return (
    <DocumentView
      file={ProcessUrl(props.file)}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <FlatList
        ref={flatList}
        data={numOfPages}
        // onEndReachedThreshold={0.5}
        // initialScrollIndex={props.pageNum}
        //    onEndReached={onEndReached}
        // contentContainerStyle={{ flexGrow: 1 }}
        renderItem={renderItem}
        //    ListHeaderComponent={renderHeader()}
        //    ListFooterComponent={ListFooterComponent}
        keyExtractor={keyExtractor}
      />
    </DocumentView>
  );
};

export default React.memo(PdfViewer);

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;
const DocumentView = styled(Document)``;
const DoucumentPage = styled(Page)``;

const styles = StyleSheet.create({});
