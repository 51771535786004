import { AppApi, TradeApi } from "@/redux/services";
import { check, createAction } from "@/utils";
import { DvaModel, ReduxAction } from "../redux";
import { ResponseGenerator } from "../connect";
import { SAVE, TOAST_LOADING } from "@/constants/app";
import { Toast } from "@/react-native-papal";

// actions
export const GET_TRADE_DIMENSION = "trade/getTradeDimension";
export const PAGE_TRADE_CHART = "trade/pageTradeChart";
export const PAGE_TRADE_REPORT = "trade/pageTradeReport";
export const GET_TRADE_REPORT_LINK = "trade/getTradeReportLink";
export const GENERATE_TRADE_POSTER = "trade/generateTradePoster";

export interface IAppState {
  loading?: boolean;
  tradeDimension: ITradeDimension[];
  tradeReportLink: ITradeReportLink;
  posterImage: string;
}

export interface ITradeDimension {
  pk: string;
  name: string;
}

export interface ITradeReportLink {
  fileUrl: string;
}

/**
 * trade
 */
const NAMESPACE = "trade";

const model: DvaModel<IAppState> = {
  namespace: NAMESPACE,
  state: {
    loading: false,
    tradeDimension: [],
    // @ts-ignore
    tradeReportLink: {},
  },
  effects: {
    // 行业维度
    *getTradeDimension({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        TradeApi.fetchTradeDimension,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ tradeDimension: response.data }));
        callback && callback();
      }
    },
    // 行业图表分页
    *pageTradeChart({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        TradeApi.fetchPageTradeChart,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
    },
    // 行业报告分页
    *pageTradeReport({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        TradeApi.fetchPageTradeReport,
        payload
      );
      if (check(response)) {
        callback && callback(response.data);
      }
    },
    // 获取行业研报链接
    *getTradeReportLink({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        TradeApi.fetchTradeReportLink,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ tradeReportLink: response.data }));
        callback && callback(response.data);
      }
      Toast.remove(loadingKey);
    },
    // 生成行业海报
    *generateTradePoster({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        TradeApi.generateTradePoster,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ posterImage: response.data }));
        callback && callback(response.data);
      }
      Toast.remove(loadingKey);
    },
  },
  reducers: {
    save(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        ...payload,
      };
    },
    error(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        error: payload,
      };
    },
  },
  subscriptions: {
    init(dispatch: any) {},
  },
};
export default model;
