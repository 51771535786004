import request from "@/utils/request";

/**
 * @description 经济数据树形
 * @export
 * @returns
 */
export async function fetchEconomyAreaTree(params: any) {
  return request("/api/app/economy/area/tree", {});
}

/**
 * @description 公众号购买VIP
 * @export
 * @returns
 */
export async function doMpVipCreateOrder(params: any) {
  return request("/api/app/vip/create-order", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 公众号赠送VIP
 * @export
 * @returns
 */
export async function doMpPresentVipCreateOrder(params: any) {
  return request("/api/app/present/vip/create-order", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 优惠券列表
 * @export
 * @returns
 */
export async function fetchCouponList(params: any) {
  return request("/api/app/coupon/list", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 领取优惠券
 * @export
 * @returns
 */
export async function drawCoupon(params: any) {
  return request("/api/app/coupon/draw", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 兑换兑换码
 * @export
 * @returns
 */
export async function exchangeRedeem(params: any) {
  return request("/api/app/redeem/exchange", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 获取赠送记录
 * @export
 * @returns
 */
export async function fetchListPresentRecord(params: any) {
  return request("/api/app/present/record/list", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 邀请奖励
 * @export
 * @returns
 */
export async function fetchInviteRecordReward(params: any) {
  return request("/api/app/invite/reward", {
    method: "POST",
    data: params,
  });
}

/**
 * 分页好友邀请记录
 * @param params
 * @returns
 */
export async function pageInviteFriendRecord(params: any) {
  return request("/api/app/invite/friend/page", {
    method: "POST",
    data: params,
    params: params,
  });
}

/**
 * 分页邀请收益
 * @param params
 * @returns
 */
export async function pageInviteEarn(params: any) {
  return request("/api/app/invite/earns/page", {
    method: "POST",
    data: params,
    params: params,
  });
}

/**
 * @description 获取提现账号
 * @export
 * @returns
 */
export async function fetchWithdrawAccount(params: any) {
  return request("/api/app/withdraw/account/get", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 提现账号
 * @export
 * @returns
 */
export async function withdrawAccount(params: any) {
  return request("/api/app/withdraw/account", {
    method: "POST",
    data: params,
  });
}

/**
 * 分页邀请提现结算
 * @param params
 * @returns
 */
export async function pageInviteWithdrawSettle(params: any) {
  return request("/api/app/invite/withdraw/settle/page", {
    method: "POST",
    data: params,
    params: params,
  });
}

/**
 * @description 合伙人中心
 * @export
 * @returns
 */
export async function fetchPartnerCenter(params: any) {
  return request("/api/app/partner/center", {
    method: "POST",
    data: params,
  });
}

/**
 * 分页合伙人采购记录
 * @param params
 * @returns
 */
export async function pagePartnerPurchaseRecord(params: any) {
  return request("/api/app/partner/purchase/record/page", {
    method: "POST",
    data: params,
    params: params,
  });
}

/**
 * @description 合伙人授权账号
 * @export
 * @returns
 */
export async function authPartnerAccount(params: any) {
  return request("/api/app/partner/auth/account", {
    method: "POST",
    data: params,
  });
}

/**
 * 分页合伙人开通VIP记录
 * @param params
 * @returns
 */
export async function pagePartnerAuthRecord(params: any) {
  return request("/api/app/partner/auth/record/page", {
    method: "POST",
    data: params,
    params: params,
  });
}

/**
 * @description 授权(添加)子账号
 * @export
 * @returns
 */
export async function addSubAccount(params: any) {
  return request("/api/app/subaccount/add", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 分页会员订单记录
 * @export
 * @returns
 */
export async function pageMemberOrderRecord(params: any) {
  return request("/api/app/member/order/record/page", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 子账号管理信息查询
 * @export
 * @returns
 */
export async function fetchSubAccountManagementInfo(params: any) {
  return request("/api/app/subaccount/management/info", {
    method: "POST",
    data: params,
  });
}

/**
 * 分页子账号
 * @param params
 * @returns
 */
export async function pageSubAccount(params: any) {
  return request("/api/app/subaccount/page", {
    method: "POST",
    data: params,
    params: params,
  });
}
