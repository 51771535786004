import { wp, fp } from "@/utils/dimension";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Paragraph, Colors } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  item: any;
}

const PartnerRecordItem: React.FC<Props> = (props) => {
  return (
    <ItemView>
      <DateText>{dayjs(props.item.crtTime).format("YYYY-MM-DD")}</DateText>
      <DescText>{props.item.groupCount}</DescText>
      <DescText>{props.item.packageName}</DescText>
    </ItemView>
  );
};

export default React.memo(PartnerRecordItem);

const ItemView = styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${Colors.white};
  padding: 12px;
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${(props: IStyledProps) => props.theme.subPrimary};
`;

const DateText = styled(Paragraph)`
  width: ${wp(40)}px;
  font-size: ${fp(3.7)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;

const DescText = styled(Paragraph)`
  width: ${wp(30)}px;
  font-size: ${fp(3.7)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;

const styles = StyleSheet.create({});
