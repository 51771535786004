import MemberRecordItem from "@/components/member/record-item";
import Page from "@/components/page";
import { TOAST_LOADING } from "@/constants/app";
import { usePagination } from "@/react-native-ahook";
import { Toast } from "@/react-native-papal";
import { PAGE_MEMBER_ORDER_RECORD } from "@/redux/models/me";
import { createActions } from "@/utils";
import { fp, wp } from "@/utils/dimension";
import { uuid } from "@/utils/uuid";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import { useDispatch } from "react-redux";
import styled from "styled-components/native";

interface Props {}
// 会员订单记录
const MemberOrderRecordScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { data, addData, onEndReached, pageIndex, ListFooterComponent } =
    usePagination(20);

  React.useEffect(() => {
    let loadingKey: number;
    if (pageIndex == 0) {
      loadingKey = Toast.loading("...", TOAST_LOADING);
    }
    dispatch(
      createActions(PAGE_MEMBER_ORDER_RECORD)({
        current: pageIndex + 1,
        size: 20,
      })((data: any) => {
        Toast.remove(loadingKey);
        addData(data.records || []);
      })
    );
  }, [pageIndex]);

  const renderFlatListHeader = () => {
    return (
      <ListHeaderView>
        <ListSectionView>
          <ListSectionText>订单日期</ListSectionText>
          <ListSectionAmountText>订单类型</ListSectionAmountText>
          <ListSectionAmountText>套餐</ListSectionAmountText>
        </ListSectionView>
      </ListHeaderView>
    );
  };

  const renderItem = React.useCallback(
    ({ item }) => <MemberRecordItem item={item} />,
    []
  );
  const keyExtractor = React.useCallback((item) => uuid(), []);
  return (
    <Page>
      {renderFlatListHeader()}
      <ListView
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        contentContainerStyle={{ flexGrow: 1 }}
        data={data || []}
        renderItem={renderItem}
        ListFooterComponent={ListFooterComponent}
        keyExtractor={keyExtractor}
      />
    </Page>
  );
};

export default MemberOrderRecordScreen;

const ListView = styled.FlatList``;
const ListHeaderView = styled.View`
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${(props: IStyledProps) => props.theme.subPrimary};
`;
const ListTitle = styled(Paragraph)`
  padding: 6px 12px 6px 12px;
  font-size: ${fp(4.1)}px;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const ListSectionView = styled.View`
  padding: 6px 12px 6px 12px;
  background-color: ${Colors.white};
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
`;
const ListSectionText = styled(Paragraph)`
  width: ${wp(40)}px;
  font-size: ${fp(3.8)}px;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const ListSectionAmountText = styled(Paragraph)`
  width: ${wp(30)}px;
  font-size: ${fp(3.8)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  text-align: right;
`;

const styles = StyleSheet.create({});
