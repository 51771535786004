import ButtonSelector from "@/components/button-selector";
import Carousel from "@/components/carousel";
import MeGridMiddle from "@/components/grid-me/item";
import TextInput from "@/components/textinput";
import { useLockFn, useMount } from "@/react-native-ahook";
import { GET_ICON_CONFIG, GET_USER_INFO } from "@/redux/models/app";
import { createAction, createActions } from "@/utils";
import { fp, wp } from "@/utils/dimension";
import { useNavigation } from "@react-navigation/core";
import React from "react";
import { FlatList, StyleSheet } from "react-native";
import { Colors, Headline, Paragraph } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";
import CouponPopup from "@/components/coupon/popup";
import { DRAW_COUPON } from "@/redux/models/me";
import { uuid } from "@/utils/uuid";
import { LinkTo } from "@/utils/link";
import { ProcessUrl } from "@/utils/image";
import { NoticeBar } from "@/react-native-papal";
import Storage from "@/utils/storage";
import { TOKEN_SESSION } from "@/constants/app";
import { parse } from "querystring";

interface Props {}

const SELECTOR_DATA = [
  {
    id: 1,
    title: "查经济",
  },
  {
    id: 2,
    title: "查行业",
  },
  {
    id: 3,
    title: "查企业",
  },
];

// the home screen
const HomeScreen = (props: Props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [placeholder, setPlaceholder] = React.useState(
    "输入经济指标 人口、GDP、社会消费额等"
  );
  const [keyIndex, setKeyIndex] = React.useState(1);
  const [unDrawCouponWidget, setUnDrawCouponWidget] = React.useState<any>([]);
  const [widgetIndex, setWidgetIndex] = React.useState(0);
  const [couponVisible, setCouponVisible] = React.useState(false);
  const [iconConfigs, setIconConfigs] = React.useState<any>([]);
  const { userInfo } = useSelector(({ app, loading }: any) => ({
    userInfo: app.userInfo,
  }));
  const { unDrawCoupons, banners, bindMobile } = userInfo;

  useMount(() => {
    // Storage.removeItem(TOKEN_SESSION);
    dispatch(
      createActions(GET_USER_INFO)({})(() => {
        // 631首页icon配置
        dispatch(
          createActions(GET_ICON_CONFIG)({ type: 631 })((data: any) => {
            setIconConfigs(data);
          })
        );
      })
    );
  });

  // 绑定手机号
  React.useEffect(() => {
    !bindMobile && navigation.navigate("MobileBind");
  }, [bindMobile]);

  const renderHeader = () => {
    return (
      <ImageBackground
        resizeMode="cover"
        source={require("@/assets/images/home/home_banner_1.png")}
      >
        <LogoImage source={require("@/assets/images/home/logo_show.png")} />
        <HeaderView>
          <TitleText>
            商业数据查询平台{"\n"}
            {"\n"}
          </TitleText>
          <ButtonSelector
            style={styles.buttonSelector}
            data={SELECTOR_DATA}
            onChange={(key: number) => {
              setKeyIndex(key);
              if (key === 1) {
                setPlaceholder("输入经济指标 人口、GDP、社会消费额等");
              } else if (key === 2) {
                setPlaceholder("输入行业指标 互联网、电子商务等");
              } else if (key === 3) {
                setPlaceholder("输入企业名称,海底捞、蜜雪冰城等");
              }
            }}
          />
          <TextInput
            editable={false}
            selectTextOnFocus={false}
            styles={styles.textInput}
            placeholder={placeholder}
            placeholderTextColor={Colors.grey400}
            onPress={() => {
              if (keyIndex === 1) {
                navigation.navigate("EconomySearch");
              } else if (keyIndex === 2) {
                navigation.navigate("Trade");
              } else if (keyIndex === 3) {
                navigation.navigate("Enterprise");
              }
            }}
          />
        </HeaderView>
        {/* <DescView>
          <DescText>500W+{"\n"}经济数据</DescText>
          <DescText>700W+{"\n"}行业数据</DescText>
          <DescText>2000W+{"\n"}品牌数据</DescText>
        </DescView> */}
        <NoticeView>
          <NoticeText>开放测试期：2022.01.10-2022.02.09</NoticeText>
        </NoticeView>
      </ImageBackground>
    );
  };

  const renderItem = React.useCallback(
    ({ item }) => (
      <MeGridMiddle
        key={item.title}
        title={item.title}
        source={ProcessUrl(item.iconUrl)}
        onPress={() => item.linkTo && LinkTo(item.linkTo, parse(item.params))}
      />
    ),
    []
  );
  const keyExtractor = React.useCallback((item) => item.title, []);
  const renderToolView = () => {
    return (
      <ToolView>
        <FlatList
          data={iconConfigs || []}
          numColumns={4}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
      </ToolView>
    );
  };

  const renderCarousel = () => {
    return <Carousel data={banners} />;
  };
  const renderScene = () => {
    return (
      <CommonHeaderView>
        <SectionTitle>应用场景</SectionTitle>
        <SceneView>
          <ApplyImage
            resizeMode="cover"
            source={require("@/assets/images/home/home_apply.png")}
          />
        </SceneView>
      </CommonHeaderView>
    );
  };

  const onDrawCoupon = useLockFn(async (arr: Array<string>, item: any) => {
    // TODO: 发送请求
    dispatch(
      createActions(DRAW_COUPON)({ commonType: item.commonType })(() => {
        widgetIndex + 1 > arr.length && setWidgetIndex(widgetIndex + 1);
      })
    );
  });

  // 优惠券弹窗
  const renderCouponPopup = (arr: Array<string>) => {
    if (arr.length > 0) {
      if (unDrawCouponWidget.length == 0) {
        const widgetArray: Array<any> = arr.map((item: any) => (
          <CouponPopup
            key={uuid()}
            visible={true}
            item={item}
            onDrawPressed={() => onDrawCoupon(arr, item)}
          />
        ));
        setUnDrawCouponWidget(widgetArray);
      }
      return unDrawCouponWidget[widgetIndex];
    }
  };

  return (
    <PageView>
      <NoticeBar
        marqueeProps={{
          loop: true,
          leading: 5000,
          style: { fontSize: 13, color: "red" },
        }}
      >
        测试期间，数据部分完整(不用担心，系统在逐渐更新)，功能可能不稳定(程序员小哥们在不断修复bug)，欢迎提出使用反馈建议。{" "}
      </NoticeBar>
      {renderHeader()}
      {renderToolView()}
      {banners.length > 0 && renderCarousel()}
      {renderScene()}
      {renderCouponPopup(userInfo.unDrawCoupons)}
    </PageView>
  );
};

export default HomeScreen;

const PageView = styled.ScrollView`
  /* flex: 1; */
  background-color: ${(props: IStyledProps) => props.theme.page};
`;

const ImageBackground = styled.ImageBackground`
  width: 100%;
  height: 254px;
`;
const LogoImage = styled.Image`
  width: 88px;
  height: 17px;
  margin-top: 10px;
  margin-left: 10px;
`;
const HeaderView = styled.View`
  align-items: center;
  padding: 16px;
`;
const TitleText = styled.Text`
  font-size: ${fp(5)}px;
  color: ${Colors.white};
  line-height: 28px;
  text-align: center;
`;
const HeaderDescText = styled.Text`
  color: ${Colors.white};
  margin-top: 8px;
  font-size: ${fp(3)}px;
`;

const ToolView = styled.View`
  background-color: ${Colors.white};
  padding: 16px;
  border-radius: 5px;
  margin: 12px 16px 12px 16px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CarouselView = styled.View`
  margin: 0px 16px 12px 16px;
  background-color: ${Colors.white};
  padding: 16px;
  border-radius: 5px;
`;

const CommonHeaderView = styled.View`
  justify-content: center;
  align-items: center;
  padding: 12px 12px 0 12px;
`;
const SectionTitle = styled(Paragraph)`
  font-size: ${fp(4.8)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  font-weight: bold;
`;

const SceneView = styled.View`
  width: 100%;
  margin: 0px 16px 12px 16px;
  background-color: ${Colors.white};
  padding: 16px;
  border-radius: 5px;
  margin-top: 12px;
`;

const ApplyImage = styled.Image`
  width: ${wp(100) - 56}px;
  height: ${((wp(100) - 56) * 1564) / 732}px;
`;

const DescView = styled.View`
  flex-direction: row;
  justify-content: space-around;
  padding: 0px 16px 0px 16px;
`;
const DescText = styled.Text`
  text-align: center;
  color: ${Colors.white};
  line-height: 20px;
  /* margin-top: -8px; */
  font-size: ${fp(3.5)}px;
`;
const NoticeView = styled.View`
  padding: 0px 16px 0px 16px;
  margin-top: -4px;
`;
const NoticeText = styled.Text`
  text-align: center;
  color: ${Colors.white};
  line-height: 20px;
  font-size: ${fp(3)}px;
`;

const styles = StyleSheet.create({
  textInput: {},
  buttonSelector: {
    marginTop: 0,
  },
  carouselImage: {
    width: wp(100),
    height: 120,
  },
});
