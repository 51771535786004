import AvatarImage from "@/components/avatar-image";
import { fp, wp } from "@/utils/dimension";
import { ProcessUrl } from "@/utils/image";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Avatar, Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  item: any;
}

/// 邀请记录列表项
const InviteRecordItem: React.FC<Props> = (props) => {
  return (
    <ItemView>
      <AvatarImage size={wp(10)} source={ProcessUrl(props.item.avatar)} />
      <NameText>{props.item.nickName}</NameText>
      <DateText>
        {dayjs(props.item.crtTime).format("YYYY-MM-DD HH:mm")}
      </DateText>
    </ItemView>
  );
};

export default React.memo(InviteRecordItem);

const ItemView = styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${Colors.white};
  padding: 12px;
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${(props: IStyledProps) => props.theme.subPrimary};
`;

const NameText = styled(Paragraph)`
  font-size: ${fp(3.7)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
  margin-left: 8px;
`;

const DateText = styled(Paragraph)`
  font-size: ${fp(3.7)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
  flex: 1;
  text-align: right;
`;

const styles = StyleSheet.create({});
