import { Portal } from "@/react-native-papal";
import { fp } from "@/utils/dimension";
import React from "react";
import { Paragraph } from "react-native-paper";
import styled from "styled-components/native";
import AlertInfo from "../alert-info";
import MemberAlert from "./container";

const remove = (key: number) => {
  Portal.remove(key);
};

const removeAll = () => {};

/**
 * 显示
 */
const info = (
  title?: string,
  content?: React.ReactNode,
  sureText?: string,
  cancelText?: string,
  onClose?: () => void,
  onSure?: () => void
) => {
  const key = Portal.add(
    // <MemberAlert
    //   title={title}
    //   content={content}
    //   sureText={sureText}
    //   cancelText={cancelText}
    //   onSure={() => {
    //     remove(key);
    //     onSure && onSure();
    //   }}
    //   onClose={() => {
    //     remove(key);
    //     onClose && onClose();
    //   }}
    // />
    <AlertInfo
      visible={true}
      title={title}
      sureText={cancelText}
      onClose={() => {
        remove(key);
        onClose && onClose();
      }}
      content={<ContentText>{content}</ContentText>}
    />
  );
  return key;
};

const ContentText = styled(Paragraph)`
  text-align: center;
  font-size: ${fp(3.9)}px;
  white-space: pre-wrap;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;

export default {
  show: (
    title?: string,
    content?: string,
    sureText?: string,
    cancelText?: string,
    onClose?: () => void,
    onSure?: () => void
  ) => {
    info(title, content, sureText, cancelText, onClose, onSure);
  },
};
