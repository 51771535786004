import { fp, wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  style?: any;
  title?: string;
  titleExtended?: React.ReactNode;
  content?: React.ReactNode;
  moreText?: string;
  onMoreTextPressed?: () => void;
}
/// card info
const CardInfo: React.FC<Props> = (props) => {
  return (
    <ContentView style={[props.style]}>
      {props.title && (
        <TitleView>
          <Title>{props.title}</Title>
          {props.titleExtended}
        </TitleView>
      )}
      {props.content}
      {props.moreText && (
        <MoreText
          onPress={() => props.onMoreTextPressed && props.onMoreTextPressed()}
        >
          {props.moreText}
        </MoreText>
      )}
    </ContentView>
  );
};

export default CardInfo;

const ContentView = styled.View`
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${Colors.white};
`;

const TitleView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const Title = styled(Paragraph)`
  font-size: ${fp(4.4)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
  margin-right: 4px;
`;
const MoreText = styled(Paragraph)`
  text-align: center;
  color: ${Colors.blue700};
  font-size: ${fp(4)}px;
  margin-top: 12px;
`;

const styles = StyleSheet.create({});
