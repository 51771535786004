import React from "react";
import { StyleSheet, Platform } from "react-native";
import { Button, Colors, Dialog } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  title: string;
  visible?: boolean;
  placeholder?: string;
  cancelText?: string;
  sureText?: string;
  max?: number;
  onChange?: (text: string) => void;
  onCancel?: () => void;
  onSure?: (text: string) => void;
}

const AlertInput: React.FC<Props> = (props) => {
  const [text, setText] = React.useState("");
  return (
    <Dialog visible={props.visible!}>
      <Dialog.Title>{props.title}</Dialog.Title>
      <Dialog.Content>
        <Input
          placeholder={props.placeholder}
          editable
          selectTextOnFocus={false}
          autoFocus
          maxLength={props.max}
          onChange={({ nativeEvent: { text } }) =>
            props.onChange && props.onChange(text)
          }
          onChangeText={(text) => setText(text)}
          // @ts-ignore
          style={Platform.select({
            web: {
              outlineStyle: "none",
            },
          })}
        />
      </Dialog.Content>
      <Dialog.Actions>
        {props.cancelText && (
          <Button onPress={props.onCancel}>{props.cancelText}</Button>
        )}
        {props.sureText && (
          <Button onPress={() => props.onSure && props.onSure(text)}>
            {props.sureText}
          </Button>
        )}
      </Dialog.Actions>
    </Dialog>
  );
};

AlertInput.defaultProps = {
  visible: false,
  max: 10,
};

export default React.memo(AlertInput);

const Input = styled.TextInput`
  height: 40px;
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${Colors.grey200};
`;

const styles = StyleSheet.create({});
