import React from "react";
import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { Colors } from "react-native-paper";
import Image from "../image";
import { fp, wp } from "@/utils/dimension";

interface Props {
  source: any;
  title: string;
  caption: string;
  onPress?: () => void;
}

// the me grid component
const MeGridTop: React.FC<Props> = (props) => {
  return (
    <GridView onPress={() => props.onPress && props.onPress()}>
      <Image style={styles.image} source={props.source} />
      <TextView>
        <Title>{props.title}</Title>
        <Caption>{props.caption}</Caption>
      </TextView>
    </GridView>
  );
};

export default MeGridTop;

const GridView = styled.Pressable`
  background-color: ${Colors.white};
  padding: 12px;
  flex: 1;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
`;
const TextView = styled.View`
  margin-left: 4px;
`;
const Title = styled.Text`
  font-size: ${fp(4.1)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  font-weight: bold;
`;
const Caption = styled.Text`
  font-size: ${fp(3.3)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
  margin-top: 6px;
`;

const styles = StyleSheet.create({
  image: {
    width: fp(12),
    height: fp(12),
  },
});
