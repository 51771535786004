import request from "@/utils/request";

/**
 * 行业图表分页
 * @param params
 * @returns
 */
export async function fetchPageTradeChart(params: any) {
  return request("/api/app/trade/chart/page", {
    method: "POST",
    data: params,
    params: params,
  });
}

/**
 * 行业维度
 * @param params
 * @returns
 */
export async function fetchTradeDimension(params: any) {
  return request("/api/app/trade/dimension", {
    method: "POST",
    data: params,
  });
}

/**
 * 行业报告
 * @param params
 * @returns
 */
export async function fetchPageTradeReport(params: any) {
  return request("/api/app/trade/report/page", {
    method: "POST",
    data: params,
    params: params,
  });
}

/**
 * 获取行业研报链接
 * @param params
 * @returns
 */
export async function fetchTradeReportLink(params: any) {
  return request("/api/app/trade/report/link", {
    method: "POST",
    data: params,
  });
}

/**
 * 生成行业海报
 * @param params
 * @returns
 */
export async function generateTradePoster(params: any) {
  return request("/api/app/trade/share", {
    method: "POST",
    data: params,
  });
}
