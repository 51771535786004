import { fp, wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Image, View } from "react-native";
import { Chip, Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";
import { Ionicons } from "@expo/vector-icons";
import dayjs from "dayjs";
import { ProcessUrl } from "@/utils/image";

interface Props {
  item: any;
  onImagePress?: (item: any) => void;
  onViewReportPress?: (item: any) => void;
  onSharePress?: (item: any) => void;
  onTagPress?: (item: any) => void;
}

/// 图片项
const TradeChartItem: React.FC<Props> = (props) => {
  const { item } = props;
  return (
    <ContentView>
      <SectionTitle>{item.title}</SectionTitle>
      <Preview onPress={() => props.onImagePress && props.onImagePress(item)}>
        <ImageView
          resizeMode="contain"
          source={{
            uri: ProcessUrl(item.imgUrl),
          }}
        />
      </Preview>
      <FooterView>
        <LeftView>
          {item.dimension && (
            <TagView onPress={() => props.onTagPress && props.onTagPress(item)}>
              <TagText>{item.dimension}</TagText>
            </TagView>
          )}
          {item.fileDatetime && (
            <DateText>{dayjs(item.fileDatetime).format("YYYY-MM-DD")}</DateText>
          )}
        </LeftView>
        <RightView>
          <IconView
            onPress={() =>
              props.onViewReportPress && props.onViewReportPress(item)
            }
          >
            <Ionicons
              name="document-text-outline"
              size={wp(4.5)}
              color="#9C9AAB"
            />
            <IconText>查看报告</IconText>
          </IconView>
          <IconView
            onPress={() => props.onSharePress && props.onSharePress(item)}
          >
            <Ionicons name="md-share-outline" size={wp(4.5)} color="#9C9AAB" />
            <IconText>分享</IconText>
          </IconView>
        </RightView>
      </FooterView>
    </ContentView>
  );
};

export default React.memo(TradeChartItem);

const ContentView = styled.View`
  padding: 12px;
  margin: 12px 12px 0px 12px;
  background-color: ${Colors.white};
  border-radius: 5px;
`;
const SectionTitle = styled(Paragraph)`
  font-size: ${fp(4)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const Preview = styled.Pressable`
  width: 100%;
  height: ${wp(38)}px;
`;
const ImageView = styled(Image)`
  width: 100%;
  height: 100%;
  margin-top: 8px;
  /* background-color: ${(props: IStyledProps) => props.theme.subPrimary}; */
`;

const FooterView = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 18px;
`;
const TagView = styled.Pressable`
  border-width: ${StyleSheet.hairlineWidth}px;
  border-color: ${(props: IStyledProps) => props.theme.primary};
  border-radius: 3px;
`;
const LeftView = styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;
const RightView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const TagText = styled.Text`
  font-size: ${fp(3.1)}px;
  color: ${(props: IStyledProps) => props.theme.primary};
  padding: 2px 6px 2px 6px;
`;
const DateText = styled(Paragraph)`
  font-size: ${fp(3.3)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
  margin-left: 10px;
`;
const IconView = styled.Pressable`
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
`;

const IconText = styled.Text`
  font-size: ${fp(3.5)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
  /* margin-left: 6px; */
`;

const styles = StyleSheet.create({});
