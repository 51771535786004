import { Modal } from "@/react-native-papal";
import React, { useState } from "react";
import { KeyboardAvoidingView, StyleSheet, Platform } from "react-native";
import { Divider, Colors, Paragraph, Title } from "react-native-paper";
import styled from "styled-components/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { wp } from "@/utils/dimension";
import { isEmpty } from "@/utils/tools";

function parseDate(year: string, month: string, day: string) {
  let search = "";
  if (!isEmpty(year)) {
    search = year;
  }
  if (!isEmpty(month)) {
    search += "-" + month;
  }
  if (!isEmpty(day)) {
    search += "-" + day;
  }
  return search;
}

interface Props {
  data: Array<any>;
  title?: string;
  visible: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onConfirm?: (minDate: string, maxDate: string) => void;
}

interface ItemProps {
  itemData: Array<IPickerItem>;
  onSelected: (year: string, month: string, day: string) => void;
}
export interface IPickerItem {
  label: string;
  value: number;
  children?: Array<IPickerItem>;
}

interface PickerItemProps {
  label: string;
  value: number;
  selected: boolean;
  onSelected: (label: string) => void;
}

const PickerModalItem: React.FC<PickerItemProps> = (props) => {
  return (
    <PickerItemView
      selected={props.selected}
      onPress={() => props.onSelected(props.label)}
    >
      <PickerItem selected={props.selected}>{props.label}</PickerItem>
      {props.selected && (
        <MaterialCommunityIcons name="check" color={Colors.grey800} size={16} />
      )}
    </PickerItemView>
  );
};

PickerModalItem.defaultProps = {
  selected: false,
};

const PickerModalData: React.FC<ItemProps> = (props) => {
  const [selectYearKey, setSelectYearKey] = useState("");
  const [selectMonthKey, setSelectMonthKey] = useState("");
  const [selectDayKey, setSelectDayKey] = useState("");
  const [monthData, setMonthData] = useState<any>(null);
  const [dayData, setDayData] = useState<any>(null);
  const itemYearData = props.itemData
    .filter((item: any) => !isEmpty(item.value))
    .map((item: IPickerItem, key) => {
      return (
        <PickerModalItem
          key={key}
          label={item.label}
          value={item.value}
          selected={selectYearKey === item.label}
          onSelected={(label) => {
            setSelectYearKey(label);
            setSelectMonthKey("");
            setSelectDayKey("");
            setMonthData(item.children!);
            props.onSelected && props.onSelected(label, "", "");
          }}
        />
      );
    });
  const itemMonthData =
    monthData &&
    monthData.length > 0 &&
    monthData
      .filter((item: any) => !isEmpty(item.value))
      .map((item: IPickerItem, key: number) => {
        return (
          <PickerModalItem
            key={key}
            label={item.label}
            value={item.value}
            selected={selectMonthKey === item.label}
            onSelected={(label) => {
              setSelectMonthKey(label);
              setSelectDayKey("");
              setDayData(item.children!);
              props.onSelected && props.onSelected(selectYearKey, label, "");
            }}
          />
        );
      });
  const itemDayData =
    dayData &&
    dayData.length > 0 &&
    dayData
      .filter((item: any) => !isEmpty(item.value))
      .map((item: IPickerItem, key: number) => {
        return (
          <PickerModalItem
            key={key}
            label={item.label}
            value={item.value}
            selected={selectDayKey === item.label}
            onSelected={(label) => {
              setSelectDayKey(label);
              props.onSelected &&
                props.onSelected(selectYearKey, selectMonthKey, label);
            }}
          />
        );
      });
  return (
    <PickerVerView>
      <PickerDateView>
        <PickerDateText>年</PickerDateText>
        <PickerDateText>月</PickerDateText>
        {/* <PickerDateText>日</PickerDateText> */}
      </PickerDateView>
      <PickerContentView>
        <PickerView
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          {itemYearData}
        </PickerView>
        <PickerView
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          {itemMonthData}
        </PickerView>
        <PickerView
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          {itemDayData}
        </PickerView>
      </PickerContentView>
    </PickerVerView>
  );
};

const PickerModal: React.FC<Props> = (props) => {
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const pickerData = props.data.map((data, index) => {
    return (
      <PickerModalData
        key={index}
        itemData={data}
        onSelected={(year: string, month: string, day: string) => {
          index === 0
            ? setMinDate(parseDate(year, month, day))
            : setMaxDate(parseDate(year, month, day));
        }}
      />
    );
  });

  const onConfirm = () => {
    if (minDate && maxDate) {
      props.onConfirm && props.onConfirm(minDate, maxDate);
    }
  };

  return (
    <Modal
      // style={{ height: 270 }}
      popup
      visible={props.visible}
      maskClosable={true}
      animationType="slide-up"
      onClose={() => props.onClose && props.onClose()}
    >
      <ModalView>
        <ModalTitleView>
          <ModalTitleText onPress={props.onCancel}>取消</ModalTitleText>
          <ModalTitle>{props.title}</ModalTitle>
          <ModalTitleText onPress={onConfirm}>确定</ModalTitleText>
        </ModalTitleView>
        <Divider />

        <ModalContentView>{pickerData}</ModalContentView>
      </ModalView>
    </Modal>
  );
};

PickerModal.defaultProps = {
  visible: false,
  data: [[]],
  title: "",
};

export default React.memo(PickerModal);

const ModalView = styled.View`
  height: 270px;
  background-color: ${Colors.white};
`;
const ModalTitleView = styled.View`
  flex-direction: row;
  align-items: center;
  height: 44px;
  padding-left: 16px;
  padding-right: 16px;
`;
const ModalTitle = styled(Title)`
  flex: 1;
  text-align: center;
  color: ${Colors.grey800};
  font-size: 18px;
`;
const ModalTitleText = styled(Paragraph)`
  color: ${Colors.grey800};
  font-size: 15px;
`;
const ModalContentView = styled.View`
  flex-direction: row;
  align-items: center;
  /* height: 200px; */
`;
const PickerVerView = styled.View``;
const PickerContentView = styled.View`
  flex-direction: row;
  /* align-items: center; */
  width: ${wp(50)}px;
  padding-left: 10px;
  padding-right: 10px;
`;
const PickerDateView = styled.View`
  flex-direction: row;
  /* align-items: center; */
  margin-top: 10px;
`;
const PickerDateText = styled(Paragraph)`
  width: ${wp(16.5)}px;
  text-align: center;
  font-size: 18px;
`;
const PickerView = styled.ScrollView`
  height: 200px;
  margin-bottom: 8px;
  width: ${wp(15)}px;
`;
const PickerItem = styled.Text.attrs((props: any) => ({
  selected: props.selected || false,
}))`
  font-size: 17px;
  color: ${(props) => (props.selected ? Colors.grey800 : Colors.grey400)};
  margin-right: 2px;
`;
const PickerItemView = styled.Pressable.attrs((props: any) => ({
  selected: props.selected || false,
}))`
  /* background-color: ${Colors.red300}; */
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 6px;
  margin-bottom: 8px;
  margin-top: 8px;
`;

const styles = StyleSheet.create({});
