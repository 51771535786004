import coupon from "@/components/coupon";
import CouponCard from "@/components/coupon/card";
import navigation from "@/navigation";
import { useMount } from "@/react-native-ahook";
import NoRecord from "@/react-native-papal/error/no-record";
import { GET_COUPON_LIST, ICouponItem } from "@/redux/models/me";
import { createAction } from "@/utils";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import React from "react";
import { FlatList, StyleSheet } from "react-native";
import { Paragraph } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

interface Props {}

const CouponScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { couponList } = useSelector(({ app, me, loading }: any) => ({
    couponList: me.couponList,
  }));

  useMount(() => {
    dispatch(createAction(GET_COUPON_LIST)({ status: 489 }));
  });

  const renderItem = ({ item }: any) => (
    <CouponCard
      key={item.id}
      id={item.id}
      price={item.price}
      priceDesc={item.priceDesc}
      title={item.title}
      limitDate={item.limitDate}
      status={item.status}
      onUsePressed={() => navigation.navigate("Member", { tabIndex: 0 })}
      onDrawPressed={() => {}}
    />
  );
  const renderFooter = () => {
    return (
      <FooterView>
        <Paragraph>提示:</Paragraph>
        <FooterText>
          1、优惠券有效期为30天{"\n"}
          2、多张优惠券不能同时使用，一次只能使用一张优惠券
        </FooterText>
      </FooterView>
    );
  };
  const couponData = couponList.map((coupon: ICouponItem, index: number) => {
    return {
      id: index,
      price: "￥" + coupon.price,
      priceDesc: coupon.des,
      title: coupon.title,
      limitDate:
        dayjs(coupon.startDate).format("YYYY.MM.DD") +
        " - " +
        dayjs(coupon.endDate).format("YYYY.MM.DD"),
      status: coupon.status,
    };
  });
  return (
    <PageView>
      {couponList.length > 0 ? (
        <FlatList
          data={couponData}
          renderItem={renderItem}
          ListFooterComponent={renderFooter}
          keyExtractor={(item: any) => item.id}
        />
      ) : (
        <NoRecord />
      )}
    </PageView>
  );
};

export default CouponScreen;

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;
const FooterView = styled.View`
  padding: 2px 16px 12px 28px;
`;
const FooterText = styled.Text`
  font-size: 12px;
  color: ${(props: IStyledProps) => props.theme.caption};
`;

const styles = StyleSheet.create({});
