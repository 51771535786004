import createLoading from "dva-loading";
import { create } from "dva-core";

import models from "@/redux/models";

const apps = create({
  initialState: {},
  models,
  onError(e: any) {
    // console.log("onError", e);
  },
});

models.forEach((o) => {
  apps.model(o);
});

apps.use(createLoading());

apps.start();

const store = apps._store;

export default store;
