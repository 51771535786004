/**
 * 个人VIP
 */
export const PERSONAL_VIP_PURCHASE_DATA = [
  { name: "花钰珠", text: "花钰珠 20分钟前购买了1年VIP" },
  { name: "柯美001", text: "柯美001 20分钟前购买了3年VIP" },
  { name: "吴旱吴", text: "吴旱吴 20分钟前购买了1年VIP" },
  { name: "邱什音往", text: "邱什音往 20分钟前购买了1年VIP" },
  { name: "于形厘", text: "于形厘 20分钟前购买了1年VIP" },
  { name: "平禾四", text: "平禾四 20分钟前购买了3年VIP" },
  { name: "戴杞", text: "戴杞 30分钟前购买了1年VIP" },
  { name: "卢并", text: "卢并 30分钟前购买了3年VIP" },
  { name: "何老杜", text: "何老杜 30分钟前购买了1年VIP" },
  { name: "想说就说", text: "想说就说 30分钟前购买了1年VIP" },
  { name: "孙受自", text: "孙受自 30分钟前购买了1年VIP" },
  { name: "醉歌漫步", text: "醉歌漫步 40分钟前购买了3年VIP" },
  { name: "方汕宏", text: "方汕宏 40分钟前购买了1年VIP" },
  { name: "夏世", text: "夏世 40分钟前购买了3年VIP" },
  { name: "覃况", text: "覃况 40分钟前购买了1年VIP" },
  { name: "吴刑", text: "吴刑 40分钟前购买了1年VIP" },
  { name: "王家富", text: "王家富 40分钟前购买了1年VIP" },
  { name: "韦各", text: "韦各 50分钟前购买了3年VIP" },
  { name: "郑奴美", text: "郑奴美 50分钟前购买了1年VIP" },
  { name: "明天的爱", text: "明天的爱 50分钟前购买了3年VIP" },
  { name: "吴甲", text: "吴甲 50分钟前购买了1年VIP" },
  { name: "彭肖届", text: "彭肖届 50分钟前购买了1年VIP" },
  { name: "黄巳", text: "黄巳 50分钟前购买了1年VIP" },
  { name: "文亮超", text: "文亮超 1小时前购买了3年VIP" },
  { name: "钟林", text: "钟林 1小时前购买了1年VIP" },
  { name: "姜命y8655", text: "姜命y8655 1小时前购买了3年VIP" },
  { name: "周艳萍", text: "周艳萍 1小时前购买了1年VIP" },
  { name: "邹季锋", text: "邹季锋 1小时前购买了1年VIP" },
  { name: "建康好茶", text: "建康好茶 1小时前购买了1年VIP" },
  { name: "廖王", text: "廖王 1小时前购买了3年VIP" },
  { name: "秋", text: "秋 1小时前购买了1年VIP" },
  { name: "方居", text: "方居 2小时前购买了3年VIP" },
  { name: "于或", text: "于或 2小时前购买了1年VIP" },
  { name: "蔡大页", text: "蔡大页 2小时前购买了1年VIP" },
  { name: "半日闲", text: "半日闲 2小时前购买了1年VIP" },
  { name: "花好月圆", text: "花好月圆 2小时前购买了3年VIP" },
  { name: "宜雅", text: "宜雅 2小时前购买了1年VIP" },
  { name: "段兄", text: "段兄 2小时前购买了3年VIP" },
  { name: "风淡云轻", text: "风淡云轻 2小时前购买了1年VIP" },
  { name: "英英快乐", text: "英英快乐 2小时前购买了1年VIP" },
  { name: "会说话的眼睛", text: "会说话的眼睛 2小时前购买了1年VIP" },
  { name: "程必", text: "程必 2小时前购买了3年VIP" },
  { name: "無悔的青春", text: "無悔的青春 3小时前购买了1年VIP" },
  { name: "钱姗", text: "钱姗 3小时前购买了3年VIP" },
  { name: "元艾0b3", text: "元艾0b3 3小时前购买了1年VIP" },
  { name: "x83mks9", text: "x83mks9 3小时前购买了1年VIP" },
  { name: "张系", text: "张系 3小时前购买了1年VIP" },
  { name: "方佳大", text: "方佳大 3小时前购买了3年VIP" },
  { name: "建伟丰", text: "建伟丰 3小时前购买了1年VIP" },
  { name: "老爹", text: "老爹 3小时前购买了3年VIP" },
];

/**
 * 团队VIP
 */
export const GROUP_VIP_PURCHASE_DATA = [
  { name: "小桂育", text: "小桂育 20分钟前购买了1年团队VIP" },
  { name: "白汐", text: "白汐 20分钟前购买了3年团队VIP" },
  { name: "刘牛曲", text: "刘牛曲 20分钟前购买了1年团队VIP" },
  { name: "贺两受", text: "贺两受 20分钟前购买了1年团队VIP" },
  { name: "汤柁", text: "汤柁 20分钟前购买了1年团队VIP" },
  { name: "永远年轻", text: "永远年轻 20分钟前购买了3年团队VIP" },
  { name: "言飞", text: "言飞 30分钟前购买了1年团队VIP" },
  { name: "熊儿色", text: "熊儿色 30分钟前购买了3年团队VIP" },
  { name: "郝状", text: "郝状 30分钟前购买了1年团队VIP" },
  { name: "吱吱喳喳", text: "吱吱喳喳 30分钟前购买了1年团队VIP" },
  { name: "薛付b947", text: "薛付b947 30分钟前购买了1年团队VIP" },
  { name: "吕l抒", text: "吕l抒 40分钟前购买了3年团队VIP" },
  { name: "卢十沅", text: "卢十沅 40分钟前购买了1年团队VIP" },
  { name: "廖采8833", text: "廖采8833 40分钟前购买了3年团队VIP" },
  { name: "贺仔仞", text: "贺仔仞 40分钟前购买了1年团队VIP" },
  { name: "马求巡", text: "马求巡 40分钟前购买了1年团队VIP" },
  { name: "黎禹", text: "黎禹 40分钟前购买了1年团队VIP" },
  { name: "小蔡", text: "小蔡 50分钟前购买了3年团队VIP" },
  { name: "许斤上", text: "许斤上 50分钟前购买了1年团队VIP" },
  { name: "慧军阳", text: "慧军阳 50分钟前购买了3年团队VIP" },
  { name: "韦求房", text: "韦求房 50分钟前购买了1年团队VIP" },
  { name: "于至司", text: "于至司 50分钟前购买了1年团队VIP" },
  { name: "书芬娟", text: "书芬娟 50分钟前购买了1年团队VIP" },
  { name: "晓晓妍", text: "晓晓妍 1小时前购买了3年团队VIP" },
  { name: "以和为贵", text: "以和为贵 1小时前购买了1年团队VIP" },
  { name: "大队长", text: "大队长 1小时前购买了3年团队VIP" },
  { name: "dk1613", text: "dk1613 31小时前购买了1年团队VIP" },
  { name: "郭先生", text: "郭先生 1小时前购买了1年团队VIP" },
  { name: "梁居泡", text: "梁居泡 1小时前购买了1年团队VIP" },
  { name: "徐宏保", text: "徐宏保 1小时前购买了3年团队VIP" },
  { name: "momo", text: "momo 1小时前购买了1年团队VIP" },
  { name: "wob6901339", text: "wob6901339 2小时前购买了3年团队VIP" },
  { name: "南瓜", text: "南瓜 2小时前购买了1年团队VIP" },
  { name: "夏川", text: "夏川 2小时前购买了1年团队VIP" },
  { name: "孙局甘0906", text: "孙局甘0906 2小时前购买了1年团队VIP" },
  { name: "高酉占", text: "高酉占 2小时前购买了3年团队VIP" },
  { name: "文知同生", text: "文知同生 2小时前购买了1年团队VIP" },
  { name: "雅然中国", text: "雅然中国 2小时前购买了3年团队VIP" },
  { name: "高姐", text: "高姐 2小时前购买了1年团队VIP" },
  { name: "姚吟", text: "姚吟 2小时前购买了1年团队VIP" },
  { name: "郝双娃", text: "郝双娃 2小时前购买了1年团队VIP" },
  { name: "轻舞飞扬121", text: "轻舞飞扬121 2小时前购买了3年团队VIP" },
  { name: "秋天的梦想", text: "秋天的梦想 3小时前购买了1年团队VIP" },
  { name: "史受纪", text: "史受纪 3小时前购买了3年团队VIP" },
  { name: "蔡明", text: "蔡明 3小时前购买了1年团队VIP" },
  { name: "应应亿事", text: "应应亿事 3小时前购买了1年团队VIP" },
  { name: "紫兰色的心", text: "紫兰色的心 3小时前购买了1年团队VIP" },
  { name: "淡竹无声", text: "淡竹无声 3小时前购买了3年团队VIP" },
  { name: "一枝独秀cd", text: "一枝独秀cd 3小时前购买了1年团队VIP" },
  { name: "薛佳", text: "薛佳 3小时前购买了3年团队VIP" },
];

/**
 * 邀请
 */
export const INVITE_PURCHASE_DATA = [
  { name: "谢宋在", text: "谢宋在邀请了3人,获得3天VIP" },
  { name: "沈佐卒", text: "沈佐卒邀请了6人,获得6天VIP" },
  { name: "崔岐厚", text: "崔岐厚邀请了3人,获得3天VIP" },
  { name: "石瓜", text: "石瓜邀请了6人,获得6天VIP" },
  { name: "淡定", text: "淡定邀请了9人,获得9天VIP" },
  { name: "蒋乞昔", text: "蒋乞昔邀请了3人,获得3天VIP" },
  { name: "曾儿侈", text: "曾儿侈邀请了6人,获得6天VIP" },
  { name: "唐长句3", text: "唐长句3邀请了3人,获得3天VIP" },
  { name: "胡例彦", text: "胡例彦邀请了6人,获得6天VIP" },
  { name: "毛完佐", text: "毛完佐邀请了9人,获得9天VIP" },
  { name: "郝丰", text: "郝丰邀请了3人,获得3天VIP" },
  { name: "袁汛百", text: "袁汛百邀请了6人,获得6天VIP" },
  { name: "geili2shou", text: "geili2shou邀请了3人,获得3天VIP" },
  { name: "丁我兵", text: "丁我兵邀请了6人,获得6天VIP" },
  { name: "周巧", text: "周巧邀请了9人,获得9天VIP" },
  { name: "贾肝", text: "贾肝邀请了3人,获得3天VIP" },
  { name: "李清", text: "李清邀请了6人,获得6天VIP" },
  { name: "胡儿或", text: "胡儿或邀请了3人,获得3天VIP" },
  { name: "黄政", text: "黄政邀请了6人,获得6天VIP" },
  { name: "y3flhz8", text: "y3flhz8邀请了9人,获得9天VIP" },
  { name: "bj1636", text: "bj1636邀请了3人,获得3天VIP" },
  { name: "正义之举2", text: "正义之举2邀请了6人,获得6天VIP" },
  { name: "程癸", text: "程癸邀请了3人,获得3天VIP" },
  { name: "eu6888", text: "eu6888邀请了6人,获得6天VIP" },
  { name: "ltnnzll", text: "ltnnzll邀请了9人,获得9天VIP" },
  { name: "段杭", text: "段杭邀请了3人,获得3天VIP" },
  { name: "wt8341", text: "wt8341邀请了6人,获得6天VIP" },
  { name: "a4iejj", text: "a4iejj邀请了3人,获得3天VIP" },
  { name: "白纸一张", text: "白纸一张邀请了6人,获得6天VIP" },
  { name: "士吕", text: "士吕邀请了9人,获得9天VIP" },
  { name: "道", text: "道邀请了3人,获得3天VIP" },
  { name: "王丽明", text: "王丽明邀请了6人,获得6天VIP" },
  { name: "徐帅", text: "徐帅邀请了3人,获得3天VIP" },
  { name: "戴舟", text: "戴舟邀请了6人,获得6天VIP" },
  { name: "廖姑", text: "廖姑邀请了9人,获得9天VIP" },
  { name: "孔扣别", text: "孔扣别邀请了3人,获得3天VIP" },
  { name: "杨召判81", text: "杨召判81邀请了6人,获得6天VIP" },
  { name: "n5eis3", text: "n5eis3邀请了3人,获得3天VIP" },
  { name: "vl4343", text: "vl4343邀请了6人,获得6天VIP" },
  { name: "宋含", text: "宋含邀请了9人,获得9天VIP" },
  { name: "牧童牧歌", text: "牧童牧歌邀请了3人,获得3天VIP" },
  { name: "小巨蛋", text: "小巨蛋邀请了6人,获得6天VIP" },
  { name: "尹约致", text: "尹约致邀请了3人,获得3天VIP" },
  { name: "jd7537", text: "jd7537邀请了6人,获得6天VIP" },
  { name: "余眄侠", text: "余眄侠邀请了9人,获得9天VIP" },
  { name: "黎此勺", text: "黎此勺邀请了3人,获得3天VIP" },
  { name: "qi7612", text: "qi7612邀请了6人,获得6天VIP" },
  { name: "余另昱", text: "余另昱邀请了3人,获得3天VIP" },
  { name: "亮苏", text: "亮苏邀请了6人,获得6天VIP" },
  { name: "mi0036", text: "mi0036邀请了9人,获得9天VIP" },
];
