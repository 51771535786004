import AvatarImage from "@/components/avatar-image";
import Page from "@/components/page";
import React from "react";
import { Platform, StyleSheet } from "react-native";
import {
  Button,
  Caption,
  Dialog,
  Divider,
  List,
  Paragraph,
} from "react-native-paper";
import styled from "styled-components/native";
import * as ImagePicker from "expo-image-picker";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "@/react-native-ahook";
import { createAction, createActions } from "@/utils";
import { DO_EDIT_USER, GET_USER_INFO } from "@/redux/models/app";
import ListItem from "@/components/list/item";
import AlertInput from "@/components/alert/input";
import { useNavigation } from "@react-navigation/native";
import { ProcessUrl } from "@/utils/image";

interface Props {}

// 个人资料
const PersonalInfoScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [alertVisible, setAlertVisible] = React.useState(false);
  const [companyVisible, setCompanyVisible] = React.useState(false);
  const { userInfo } = useSelector(({ app, loading }: any) => ({
    userInfo: app.userInfo,
  }));
  useMount(() => {
    dispatch(createAction(GET_USER_INFO)({}));
  });
  // effect
  React.useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);

  const doEditUser = (params: any) => {
    dispatch(
      createActions(DO_EDIT_USER)(params)(() => {
        setAlertVisible(false);
        setCompanyVisible(false);
      })
    );
  };

  // pick image
  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 0.5,
    });

    if (!result.cancelled) {
      // setImage(result.uri);
      doEditUser({ avatar: result.uri });
    }
  };

  // the render header
  const renderHeader = () => {
    return (
      <HeaderView>
        <AvatarImage
          size={88}
          source={ProcessUrl(userInfo?.avatar)}
          onPress={pickImage}
        />
      </HeaderView>
    );
  };

  // the render list
  const renderList = () => {
    return (
      <ListView>
        <ListItem
          title="昵称"
          rightText={userInfo?.nickName}
          rightArrow
          onPress={() => setAlertVisible(true)}
        />
        <Divider />
        <ListItem
          title="我的企业"
          rightText={userInfo?.company}
          rightArrow
          onPress={() => setCompanyVisible(true)}
        />
        <Divider />
        <ListItem
          title="绑定手机"
          rightText={userInfo?.mobile}
          rightArrow
          onPress={() => navigation.navigate("MobileBind")}
        />
      </ListView>
    );
  };

  return (
    <Page>
      {renderHeader()}
      {renderList()}
      <AlertInput
        visible={alertVisible}
        max={8}
        title="修改昵称"
        placeholder={userInfo?.nickName}
        cancelText="取消"
        sureText="确定"
        onCancel={() => setAlertVisible(false)}
        onSure={(text) => doEditUser({ nickName: text })}
      />
      <AlertInput
        max={16}
        visible={companyVisible}
        title="修改企业"
        placeholder={userInfo?.company}
        cancelText="取消"
        sureText="确定"
        onCancel={() => setCompanyVisible(false)}
        onSure={(text) => doEditUser({ company: text })}
      />
    </Page>
  );
};

export default PersonalInfoScreen;

const HeaderView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  padding: 16px;
  justify-content: center;
  align-items: center;
`;
const ListView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  margin-top: 12px;
`;
const RightView = styled.View`
  flex-direction: row;
  align-items: center;
`;

const styles = StyleSheet.create({
  caption: {
    // fontSize: 14,
  },
});
