import CryptoJS from "crypto-js";
import _ from "lodash";
import { Toast } from "@/react-native-papal";

import { REG_MOBILE } from "./reg";

/**
 *  密码密钥
 */
export const AES_PASSWORD_KEY = "openiloveatum028";

/**
 * 判断对象是否为空
 * null或{} 返回false
 * @param obj 对象
 */
export const isEmptyObject = (obj: any) => {
  for (var key in obj) {
    return false;
  }
  return true;
};

/**
 * 判断是否为空
 * @param value
 */
export const isEmpty = (value: any) => {
  if (
    value == null ||
    value == "" ||
    value == "undefined" ||
    value == undefined ||
    value == "null"
  ) {
    return true;
  } else {
    value = value.replace(/\s/g, "");
    if (value == "") {
      return true;
    }
    return false;
  }
};

/**
 * AES 加密内容 和 服务端加密结果相同
 * @param message 明文
 */
export const aesEncode = (message: any) => {
  const msg = CryptoJS.enc.Utf8.parse(message);
  const key = CryptoJS.enc.Utf8.parse(AES_PASSWORD_KEY);
  const encode = CryptoJS.AES.encrypt(msg, key, {
    iv: key,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encode.ciphertext.toString();
};

/**
 * Aes解密
 * @param cipherMessage 密文
 */
export const aesDecode = (cipherMessage?: any) => {
  let msg: any = CryptoJS.enc.Hex.parse(cipherMessage);
  const key = CryptoJS.enc.Utf8.parse(AES_PASSWORD_KEY);
  msg = CryptoJS.enc.Base64.stringify(msg);
  const decode = CryptoJS.AES.decrypt(msg, key, {
    iv: key,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decode.toString(CryptoJS.enc.Utf8);
};

/**
 * sha256加密
 * @param message 明文
 */
export const SHA1 = (message: string) => {
  return CryptoJS.SHA1(message).toString();
};

export const aesEncodeNew = (content: string, privateKey: string) => {
  const key = CryptoJS.enc.Utf8.parse(privateKey);
  const message = CryptoJS.enc.Utf8.parse(content);
  const encrypted = CryptoJS.AES.encrypt(message, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

/**
 * base64 加密
 * @param message  明文
 */
export const Base64Encode = (message: string) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(message));
};

/**
 * base64解密
 * @param encodedMessage 密文
 */
export const Base64Decode = (encodedMessage: string) => {
  return CryptoJS.enc.Base64.parse(encodedMessage).toString(CryptoJS.enc.Utf8);
};

/**
 * 密码加密
 * @param content 明文
 */
export const passwordAesEncode = (content: string) => {
  return aesEncodeNew(content, AES_PASSWORD_KEY);
};

/**
 * 网站表达式
 */
// const reg =
//   /(((^http(s)?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
const reg =
  /((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?)/;

/**
 * 是否为网址
 * @param path 路径
 */
export const isUrl = (path: string): boolean => reg.test(path);

/**
 * 转换大小
 * @param limit
 */
export const convertSize = (limit: number) => {
  let size = "";
  if (limit < 0.1 * 1024) {
    //如果小于0.1KB转化成B
    size = limit.toFixed(2) + "B";
  } else if (limit < 0.1 * 1024 * 1024) {
    //如果小于0.1MB转化成KB
    size = (limit / 1024).toFixed(2) + "KB";
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {
    //如果小于0.1GB转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + "MB";
  } else {
    //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
  }

  var sizestr = size + "";
  var len = sizestr.indexOf(".");
  var dec = sizestr.substr(len + 1, 2);
  if (dec == "00") {
    //当小数点后为00时 去掉小数部分
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
  }
  return sizestr;
};

/**
 * ellipsis
 * @param text
 * @param limit
 */
export const ellipsisString = (text: string, limit = 8) => {
  if (text.length > limit) {
    return text.substring(0, limit) + "....";
  }
  return text;
};

/**
 * 校验表单吐司
 * @param {*} err
 */
export const errorToast = (err: any) => {
  const errors: any = [];
  _.each(err, (item) => errors.push(...item.errors));
  const [firstError] = errors;
  const { message } = firstError;
  Toast.info(message);
};

/**
 * 手机号脱敏
 * @param phone
 */
export const getShieldPhone = (phone: string) =>
  phone ? `${phone.substring(0, 3)}****${phone.substring(7, 11)}` : "";

/**
 * 判断是否为手机号的正则表达式
 * @param phone
 */
export const isMobile = (phone: string) => {
  if (!REG_MOBILE.test(phone)) {
    return false;
  } else {
    return true;
  }
};

/**
 * 判断是否为电话号
 * @param phone
 */
export const isPhone = (phone: string) => {
  var reg = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
  if (!reg.test(phone)) {
    return false;
  } else {
    return true;
  }
};

/**
 * 数字获取汉字
 * @param num
 */
export const changeToCN = (num: number): string => {
  let words = [
    "零",
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九",
    "十",
  ];
  let adds = ["", "十", "百", "千", "万", "亿", "十", "百", "千"];
  if (words[num]) {
    return words[num];
  } else if (num > 10 && num < 20) {
    let numStr = num.toString();
    let n: any = numStr.substring(1, 2);
    let result = adds[1] + words[n];
    return result;
  } else if (num > 10) {
    let result = "";
    let numStr = num.toString();
    for (var i = 0; i < numStr.length; ++i) {
      let n: any = numStr.substring(i, i + 1);
      let m = numStr.length - i - 1;
      result += words[n] + adds[m];
    }
    return result;
  } else return "零";
};

export const dateCount = (endTime: string) => {
  // 现在时间
  const now: any = new Date();
  //截止时间
  const until: any = new Date(endTime);
  // 计算时会发生隐式转换，调用valueOf()方法，转化成时间戳的形式
  const days = (until - now) / 1000 / 3600 / 24;
  // 下面都是简单的数学计算
  const day = Math.floor(days);
  const hours = (days - day) * 24;
  const hour = Math.floor(hours);
  const minutes = (hours - hour) * 60;
  const minute = Math.floor(minutes);
  const seconds = (minutes - minute) * 60;
  const second = Math.floor(seconds);
  const back =
    "剩余时间：" + day + "天" + hour + "小时" + minute + "分钟" + second + "秒";
  return back;
};

export const convertSecondsToTime = (value: number) => {
  var min = Math.floor(value % 3600);
  let seconds = value % 60;
  let minute = Math.floor(min / 60);
  let hour = Math.floor(value / 3600);
  let sStr;
  let mStr;
  let hStr;
  seconds < 10 ? (sStr = "0" + seconds) : (sStr = seconds);
  minute < 10 ? (mStr = "0" + minute) : (mStr = minute);
  hour < 10 ? (hStr = "0" + hour) : (hStr = hour);
  return hStr + ":" + mStr + ":" + sStr;
};

/**
 * 随机生成字符串
 * @param len  长度
 */
export const randomString = (len: number) => {
  len = len || 32;
  /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  const $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const maxPos = $chars.length;
  let pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};

/**
 * judge whether the wechat browser
 * mp
 * @returns
 */
export const isWechat = () => {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("micromessenger") > -1) {
    return true;
  } else {
    return false;
  }
};

/**
 * 带...的字符串数组变成树形
 *  [
 *         "catalog.product.name",
 *         "catalog.product.description",
 *         "catalog.product.status",
 *         "catalog.product_attribute_set.name",
 *         "catalog.product_attribute_set.type",
 *       ];
 * @param arr
 */
export const arrayDotToTree = (data: Array<any>) => {
  const result = Array<any>();
  data.forEach((s) =>
    s.split(".").reduce(
      (object: any, value: any) => {
        var item = (object.children = object.children || []).find(
          (q: any) => q.value === value
        );
        if (!item) object.children.push((item = { value, label: value }));
        return item;
      },
      { children: result }
    )
  );

  return result;
};

/**
 * 把null和0变为-
 * @param name
 */
export const parseNullOrZero = (name: any, unit: string) => {
  if (!name || name === 0) {
    return "——";
  } else {
    return name + unit;
  }
};

export const formatFloat = (f: any, digit: any) => {
  const m = Math.pow(10, digit);
  // @ts-ignore
  return parseInt(f * m, 10) / m;
};

/**
 * 分转元
 * @param fen
 * @returns
 */
export const fenToYuan = (fen: string) => {
  return (parseFloat(fen) * 0.01).toFixed(2).toString();
};

export const isFloat = (f: string) => {
  const count = f.indexOf(".") + 1;
  return count > 1 ? true : false;
};

export const ClipboardSetString = (text: string): boolean => {
  if (isWechat()) {
    let success = false;
    const textField = document.createElement("textarea");
    textField.textContent = text;
    document.body.appendChild(textField);
    if (window.navigator.platform === "iPhone") {
      textField.setSelectionRange(0, 99999);
    } else {
      textField.select();
    }
    try {
      document.execCommand("copy");
      success = true;
    } catch (e) {}
    document.body.removeChild(textField);
    return success;
  }
  return false;
};
