import { BASIC_TYPE } from "@/constants/app";
import request from "@/utils/request";

/**
 * 获取微信配置
 * @param params
 */
export async function getWxConfig(params: any) {
  return request(`/api/wx/mp/config`, {
    method: "POST",
    data: params,
  });
}

/**
 * @description 微信授权
 * @export
 * @returns
 */
export async function wpAuth(params: any) {
  return request("/api/wx/mp/auth", {
    data: params,
    params: params,
  });
}

/**
 * @description 微信自动登录注册
 * @export
 * @returns
 */
export async function wpSignIn(params: any) {
  return request("/api/wx/mp/signin", {
    method: "POST",
    data: params,
    params: params,
  });
}

/**
 * 微信登录
 * @param params
 */
export async function wechatLogin(params: any) {
  const grant_type = "open";
  const scope = "server";
  // 账号类型 1账号 2手机验证码 3微信登录 4微信公众号 5微信web
  const signin_type = 3;
  // 登录方式 30代表WEB 31 APP
  const type = 31;
  return request(`/api/oauth/token`, {
    method: "POST",
    params: { ...params, grant_type, scope, signin_type, type },
    headers: {
      Authorization: BASIC_TYPE,
    },
  });
}

/**
 * 微信公众号登录
 * @param params
 */
export async function wechatMpLogin(params: any) {
  const grant_type = "mp";
  const scope = "server";
  // 账号类型 1账号 2手机验证码 3微信登录 4微信公众号 5微信web
  const signin_type = 4;
  // 登录方式 30代表WEB 31 APP
  const type = 31;
  return request(`/api/oauth/token`, {
    method: "POST",
    params: { ...params, grant_type, scope, signin_type, type },
    headers: {
      Authorization: BASIC_TYPE,
    },
  });
}

/**
 * 绑定手机
 * @param params
 */
export async function bindMobile(params: any) {
  params.grant_type = "captcha";
  // 登录方式 30代表WEB 31 APP
  params.type = 31;
  return request(`/api/account/bind/mobile`, {
    data: params,
    params: params,
    method: "POST",
  });
}

/**
 * 解绑手机
 * @param params
 */
export async function unBindMobile(params: any) {
  params.grant_type = "captcha";
  // 登录方式 30代表WEB 31 APP
  params.type = 31;
  return request(`/api/account/unbind/mobile`, {
    data: params,
    params: params,
    method: "POST",
  });
}

/**
 * 获取图形验证码
 * @param params
 */
export async function getImageCaptcha(params: any) {
  return request(`/api/captcha/image`, {
    params: { ...params },
    headers: {
      Authorization: BASIC_TYPE,
    },
  });
}

/**
 * 获取验证码
 * @param params
 */
export async function getSmsCaptcha(params: any) {
  return request(`/api/captcha/code`, {
    params: { ...params },
    headers: {
      Authorization: BASIC_TYPE,
    },
  });
}
