import CouponSelector from "@/components/coupon";
import MemberSelector from "@/components/member-selector";
import {
  Modal,
  NoticeBar,
  NoticeItem,
  Stepper,
  Toast,
} from "@/react-native-papal";
import React, { useReducer } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, Caption, Colors, List, Paragraph } from "react-native-paper";
import styled from "styled-components/native";
import { fp, hp, wp } from "@/utils/dimension";
import { useDispatch, useSelector } from "react-redux";
import ListItem from "@/components/list/item";
import { GROUP_VIP_PURCHASE_DATA } from "@/constants/purchase-data";
import { isWechat } from "@/utils/tools";
import WxTools from "@/utils/wechat/mp";
import { createAction, createActions } from "@/utils";
import { DO_VIP_CREATE_ORDER, GET_COUPON_LIST } from "@/redux/models/me";
import { GET_USER_INFO } from "@/redux/models/app";
import CouponModal from "@/components/coupon/modal";
import { useLockFn } from "@/react-native-ahook";
import ColorTable from "@/components/table";
import { HEADERS, TABLE_DATA } from "@/constants/app";
import { LinkTo } from "@/utils/link";
import { ProcessUrl } from "@/utils/image";

interface Props {}

function reducer(state: any, action: any) {
  switch (action.type) {
    case "ModalVisible":
      return { ...state, modalVisible: action.value };
    case "VipSelectedItem":
      return { ...state, vipSelectedItem: action.value };
  }
}

/// the member group page
const TabGroup: React.FC<Props> = (props) => {
  const reduxDispatch = useDispatch();
  const [couponVisible, setCouponVisible] = React.useState(false);
  const [couponItem, setCouponItem] = React.useState<any>(null);
  const [personVipItem, setPersonVipItem] = React.useState<any>(null);
  const [packageNum, setPackageNum] = React.useState<number>(3);
  const { dictDesc, couponList } = useSelector(({ me, loading }: any) => ({
    dictDesc: me.dictDesc,
    couponList: me.couponList,
  }));
  const [state, dispatch] = useReducer(reducer, {
    modalVisible: false,
    vipSelectedItem: {
      price: 0,
    },
  });
  // the PaymentView
  const renderPayment = () => {
    const memberData = dictDesc.groupVip
      ? dictDesc.groupVip.map((item: any, index: number) => {
          const remark3 = item.remark3.split(",");
          return {
            key: index + 1,
            title: item.dvalue,
            price: item.remark,
            realPrice: "¥" + remark3[0],
            desc: remark3[1] + "元/人/天",
            badge: remark3[2],
            pk: item.pk,
          };
        })
      : [];
    return (
      <PaymentView>
        <SectionTitle>开通会员VIP</SectionTitle>
        <MemberSelector
          style={styles.memberSelector}
          data={memberData}
          onCallback={(item) => {
            const personVip = dictDesc.personVip[item.key - 1];
            setPersonVipItem(personVip);
            dispatch({ type: "VipSelectedItem", value: item });
          }}
        />
        <NoticeItem data={GROUP_VIP_PURCHASE_DATA} />
        <MemberDescImage
          resizeMode="contain"
          source={{
            uri: ProcessUrl("/resource/ma/member_team_right1.png"),
          }}
        />
        <MemberDesc2Image
          resizeMode="contain"
          source={{
            uri: ProcessUrl("/resource/ma/member_team_right2.png"),
          }}
        />
      </PaymentView>
    );
  };

  // member privileges
  const renderPrivileges = () => {
    return (
      <PrivilegeView>
        <SectionTitle>会员特权</SectionTitle>
        <ColorTable headers={HEADERS} data={TABLE_DATA} />
      </PrivilegeView>
    );
  };

  // coupon
  const renderCoupon = () => {
    return (
      <ListItem
        title="优惠券"
        rightText={couponItem ? "已抵扣优惠券 " : "优惠券"}
        rightRedText={couponItem ? "-" + couponItem.priceNum : ""}
        rightArrow
        onPress={() => {
          reduxDispatch(
            createActions(GET_COUPON_LIST)({ status: 481, type: "partner" })(
              () => {}
            )
          );
          setCouponVisible(true);
        }}
      />
    );
  };

  // 下订单
  const doVipCreateOrder = useLockFn(async () => {
    if (isWechat()) {
      WxTools.getConfig(window.location.href, () => {
        reduxDispatch(
          createActions(DO_VIP_CREATE_ORDER)({
            vipType: 442,
            vipDictPk: state.vipSelectedItem && state.vipSelectedItem.pk,
            couponPk: couponItem && couponItem.pk,
            personVipDictPk: personVipItem && personVipItem.pk,
            groupNum: packageNum,
          })((result: any) => {
            WxTools.wxPay(result, (res) => {
              reduxDispatch(createAction(GET_USER_INFO)());
              Toast.success("支付成功");
            });
            // 设置优惠券
            setCouponItem(null);
          })
        );
      });
    }
  });

  // render pay tool
  const renderPayTool = () => {
    let price = state.vipSelectedItem.price * packageNum;
    // if (personVipItem) {
    //   price =
    //     state.vipSelectedItem.price * packageNum -
    //     (personVipItem.remark - state.vipSelectedItem.price) * packageNum;
    // }
    if (couponItem) {
      price = price - couponItem.priceNum;
    }
    return (
      <PayToolView>
        <Button
          style={styles.payButton}
          mode="contained"
          disabled={!state.vipSelectedItem || !state.vipSelectedItem.pk}
          color={Colors.amber200}
          onPress={() => doVipCreateOrder()}
        >
          支付{price} ,购买VIP,效率提升1整年
        </Button>
        {/* <BookText>
          购买成功后,立即享有VIP权益,会员时长从2月10日开始计算
        </BookText> */}
        <PayToolDesc>
          开通会员后表示您已经阅读并接受
          <PayToolRuleDesc
            onPress={() => LinkTo("https://smartchoose.cn/vipserviceagreement")}
          >
            《VIP会员服务协议》
          </PayToolRuleDesc>
        </PayToolDesc>
      </PayToolView>
    );
  };

  // render settlement
  const renderSettlement = () => {
    return (
      <SettlementView>
        <SectionTitle>套餐选择</SectionTitle>
        <SettlementInnerView>
          {/* <SettlementText>
            开通账号(主账号)：<SettlementSubText>1519000999</SettlementSubText>
          </SettlementText>
          <SettlementText>
            到期时间：<SettlementSubText>2022-09-09</SettlementSubText>
          </SettlementText> */}
          <SettlementTitleView>
            <SettlementTitle>套餐人数：</SettlementTitle>
            <Stepper
              defaultValue={packageNum}
              onChange={(value) => setPackageNum(value)}
            />
          </SettlementTitleView>
          <SettlementDescView>
            <SettlementDescText>
              {state.vipSelectedItem.price}*{packageNum}=
            </SettlementDescText>
            <SettlementDescRedText>
              {state.vipSelectedItem.price * packageNum}
            </SettlementDescRedText>
            <SettlementDescText>元，比个人VIP节省</SettlementDescText>
            <SettlementDescRedText>
              {personVipItem
                ? (personVipItem.remark - state.vipSelectedItem.price) *
                  packageNum
                : state.vipSelectedItem.price * packageNum}
            </SettlementDescRedText>
            <SettlementDescText>元</SettlementDescText>
          </SettlementDescView>
        </SettlementInnerView>
        <SettlementCaption>
          *团队VIP套餐人数最少3人起,其中包含支付的主账号
        </SettlementCaption>
      </SettlementView>
    );
  };

  const renderDesc = () => {
    return (
      <DescView>
        <DescText>
          温馨提示：{"\n"}
          平台设置防盗刷系统，请合理使用账号，如果用户短时间内高频或大量查询下载可能触发该系统，将暂停账号下载功能。如有疑问请联系客服
        </DescText>
      </DescView>
    );
  };

  return (
    <PageView>
      <PageScrollView>
        {renderPayment()}
        {/* {renderPrivileges()} */}
        {renderCoupon()}
        {/* {renderModal()} */}
        {renderSettlement()}
        {renderDesc()}
      </PageScrollView>
      {renderPayTool()}
      <CouponModal
        title="优惠券"
        visible={couponVisible}
        data={couponList}
        onClose={() => setCouponVisible(false)}
        onSurePress={(item) => {
          setCouponItem(item);
          setCouponVisible(false);
        }}
      />
    </PageView>
  );
};

export default React.memo(TabGroup);
const PageView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.page};
  flex: 1;
`;

const PageScrollView = styled.ScrollView`
  padding-bottom: 4px;
`;

const PaymentView = styled.View`
  background-color: ${Colors.white};
  padding: 16px;
`;

const SectionTitle = styled.Text`
  font-size: ${fp(4)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;

const PrivilegeView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const ListView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  margin-top: 12px;
`;

const RightView = styled.View`
  flex-direction: row;
  align-items: center;
`;

const PayToolView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  padding: 6px 16px 8px 16px;
  justify-content: center;
  align-items: center;
`;
const PayToolDesc = styled.Text`
  font-size: ${fp(3.3)}px;
  margin: 4px 0 4px 0;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;
const PayToolRuleDesc = styled.Text`
  font-size: ${fp(3.3)}px;
  color: ${(props: IStyledProps) => props.theme.primary};
`;

const ModalView = styled.View``;

const SettlementView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  padding: 16px;
  margin-top: 12px;
`;
const SettlementInnerView = styled.View`
  /* background-color: ${Colors.orange50}; */
  /* padding: 16px; */
  margin-top: 12px;
  border-radius: 5px;
`;
const SettlementText = styled(Paragraph)`
  font-size: ${fp(3.4)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  /* margin-bottom: 8px; */
`;
const SettlementSubText = styled(Paragraph)`
  font-size: ${fp(3.4)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
  /* margin-bottom: 8px; */
`;
const SettlementTitleView = styled.View`
  flex-direction: row;
  align-items: center;
  /* margin-bottom: 12px; */
  /* margin-top: 2px; */
`;
const SettlementTitle = styled(Paragraph)`
  font-size: ${fp(3.8)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
  margin-right: 8px;
`;
const SettlementDescView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const SettlementDescText = styled(Paragraph)`
  font-size: ${fp(3.2)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph}; ;
`;
const SettlementDescRedText = styled(Paragraph)`
  font-size: ${fp(3.8)}px;
  color: ${(props: IStyledProps) => props.theme.redText}; ;
`;
const SettlementCaption = styled(Paragraph)`
  font-size: ${fp(3)}px;
  color: ${(props: IStyledProps) => props.theme.redText};
`;

const MemberDescImage = styled.Image`
  width: 100%;
  /* height: 100%; */
  height: ${((wp(100) - 32) * 1264) / 720}px;
  margin-top: 12px;
`;
const MemberDesc2Image = styled.Image`
  width: 100%;
  /* height: 100%; */
  height: ${((wp(100) - 32) * 1318) / 720}px;
  margin-top: 12px;
`;

const BookText = styled.Text`
  margin-top: 6px;
  font-size: ${fp(3.3)}px;
  color: ${(props: IStyledProps) => props.theme.yellowText};
`;

const DescView = styled.View`
  background: ${Colors.white};
  flex-direction: row;
  padding: 12px;
  margin-top: 12px;
`;

const DescText = styled.Text`
  font-size: ${fp(3.5)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;

const styles = StyleSheet.create({
  memberSelector: {
    marginTop: 16,
    marginBottom: 16,
  },
  noticeBar: {
    borderRadius: 5,
  },
  caption: {
    fontSize: 14,
  },
  payButton: {
    width: "100%",
    borderRadius: 32,
  },
  modalView: {
    height: hp(65),
    paddingBottom: 4,
  },
  modalTitleView: {
    flexDirection: "row",
    padding: 16,
  },
  modalTitle: {
    fontSize: fp(3.5),
    fontWeight: "bold",
  },
});
