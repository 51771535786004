import InviteDetailItem from "@/components/invite/detail-item";
import Page from "@/components/page";
import { useLockFn, useMount, usePagination } from "@/react-native-ahook";
import { fp, wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";
import InviteDetailListItem from "@/components/invite/detail-list-item";
import { useDispatch, useSelector } from "react-redux";
import { createAction, createActions } from "@/utils";
import {
  GET_WITHDRAW_ACCOUNT,
  PAGE_WITHDRAW_SETTLE,
  WITHDRAW_ACCOUNT,
} from "@/redux/models/me";
import AlertInput from "@/components/alert/input";
import { withdrawAccount } from "@/redux/services/me";
import { Toast } from "@/react-native-papal";
import { TOAST_LOADING } from "@/constants/app";
import { uuid } from "@/utils/uuid";

interface Props {}

/// 结算明细
const SettleDetailScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [nameVisible, setNameVisible] = React.useState(false);
  const [accountVisible, setAccountVisible] = React.useState(false);
  const { data, addData, onEndReached, pageIndex, ListFooterComponent } =
    usePagination(20);
  const { withdrawAccount } = useSelector(({ me, loading }: any) => ({
    withdrawAccount: me.withdrawAccount,
  }));

  useMount(() => {
    dispatch(createAction(GET_WITHDRAW_ACCOUNT)({}));
  });

  React.useEffect(() => {
    let loadingKey: number;
    if (pageIndex == 0) {
      loadingKey = Toast.loading("...", TOAST_LOADING);
    }
    dispatch(
      createActions(PAGE_WITHDRAW_SETTLE)({
        current: pageIndex + 1,
        size: 20,
      })((data: any) => {
        Toast.remove(loadingKey);
        addData(data.records || []);
      })
    );
  }, [pageIndex]);

  const renderAccount = () => {
    return (
      <AccountView>
        <InviteDetailItem
          title="结算时间"
          text="每月15号、30号自动结算，节假日顺延"
        />
        <InviteDetailItem title="收款方式" text="支付宝" />
        <InviteDetailItem
          title="收款姓名"
          text={withdrawAccount?.payeeName}
          arrow
          onPress={() => setNameVisible(true)}
        />
        <InviteDetailItem
          title="收款账号"
          text={withdrawAccount?.payeeAccount}
          arrow
          onPress={() => setAccountVisible(true)}
        />
      </AccountView>
    );
  };

  const renderFlatListHeader = () => {
    return (
      <ListHeaderView>
        <ListSectionView>
          <ListSectionFirstText>结算时间</ListSectionFirstText>
          <ListSectionText>收款人</ListSectionText>
          <ListSectionText>金额(元)</ListSectionText>
          <ListSectionText>状态</ListSectionText>
        </ListSectionView>
      </ListHeaderView>
    );
  };
  const renderItem = React.useCallback(
    ({ item }) => <InviteDetailListItem item={item} />,
    []
  );
  const keyExtractor = React.useCallback((item) => uuid(), []);

  const nameSubmit = useLockFn(async (value: string) => {
    if (value) {
      const params: any = {};
      params.payeeName = value;
      withdrawAccount && (params.pk = withdrawAccount.pk);
      dispatch(
        createActions(WITHDRAW_ACCOUNT)(params)(() => {
          setNameVisible(false);
        })
      );
    }
  });
  const accountSubmit = useLockFn(async (value: string) => {
    if (value) {
      const params: any = {};
      params.payeeAccount = value;
      withdrawAccount && (params.pk = withdrawAccount.pk);
      dispatch(
        createActions(WITHDRAW_ACCOUNT)(params)(() => {
          setAccountVisible(false);
        })
      );
    }
  });

  return (
    <Page>
      {renderAccount()}
      <ListView
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        contentContainerStyle={{ flexGrow: 1 }}
        data={data || []}
        renderItem={renderItem}
        ListHeaderComponent={renderFlatListHeader()}
        ListFooterComponent={ListFooterComponent}
        keyExtractor={keyExtractor}
      />
      <AlertInput
        visible={nameVisible}
        max={12}
        title={"收款人姓名"}
        placeholder={withdrawAccount?.payeeName}
        cancelText="取消"
        sureText="确定"
        onCancel={() => setNameVisible(false)}
        onSure={(text) => nameSubmit(text)}
      />
      <AlertInput
        visible={accountVisible}
        max={32}
        title={"收款人账号"}
        placeholder={withdrawAccount?.payeeAccount}
        cancelText="取消"
        sureText="确定"
        onCancel={() => setAccountVisible(false)}
        onSure={(text) => accountSubmit(text)}
      />
    </Page>
  );
};

export default SettleDetailScreen;

const AccountView = styled.View``;

const ListView = styled.FlatList`
  margin-top: 12px;
`;
const ListHeaderView = styled.View``;
const ListSectionView = styled.View`
  padding: 6px 12px 6px 12px;
  background-color: ${Colors.white};
  flex-direction: row;
  align-items: center;
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${(props: IStyledProps) => props.theme.subPrimary};
`;

const ListSectionFirstText = styled(Paragraph)`
  width: ${wp(40)}px;
  font-size: ${fp(3.9)}px;
  color: ${(props: IStyledProps) => props.theme.text};
`;

const ListSectionText = styled(Paragraph)`
  width: ${wp(20)}px;
  font-size: ${fp(3.9)}px;
  color: ${(props: IStyledProps) => props.theme.text};
`;

const styles = StyleSheet.create({});
