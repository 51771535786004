import React from "react";
import { StyleSheet, Text, View } from "react-native";
import styled from "styled-components/native";
import { Colors } from "react-native-paper";
import Image from "../../image";
import { fp, hp, wp } from "@/utils/dimension";

interface Props {
  source: any;
  title: string;
  onPress?: () => void;
}

// the me grid middle component
const MeGridMiddle: React.FC<Props> = (props) => {
  return (
    <ItemView onPress={() => props.onPress && props.onPress()}>
      <ItemImage source={props.source} />
      <Title>{props.title}</Title>
    </ItemView>
  );
};

export default MeGridMiddle;

const ItemView = styled.Pressable`
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  width: ${(wp(100) - 64) / 4}px;
`;
const ItemImage = styled(Image)`
  width: ${fp(10)}px;
  height: ${fp(10)}px;
`;
const Title = styled.Text`
  font-size: ${fp(3.4)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
  margin-top: 4px;
`;

const styles = StyleSheet.create({});
