import { fp } from "@/utils/dimension";
import React from "react";
import { StyleSheet } from "react-native";
import { Paragraph, useTheme } from "react-native-paper";
import styled from "styled-components/native";
import { Ionicons } from "@expo/vector-icons";

interface Props {
  title: string;
  text?: string;
  arrow?: boolean;
  onPress?: () => void;
}

/// 结算明细项
const InviteDetailItem: React.FC<Props> = (props) => {
  const theme = useTheme();
  return (
    <ListView onPress={() => props.onPress && props.onPress()}>
      <ListTitle>{props.title}</ListTitle>
      <ListText>{props.text}</ListText>
      {props.arrow && (
        <Ionicons
          name="md-chevron-forward"
          size={18}
          color={theme.colors.paragraph}
        />
      )}
    </ListView>
  );
};

InviteDetailItem.defaultProps = {
  arrow: false,
};

export default InviteDetailItem;
const ListView = styled.Pressable`
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 12px;
  background-color: ${(props: IStyledProps) => props.theme.background};
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${(props: IStyledProps) => props.theme.subPrimary};
`;

const ListTitle = styled(Paragraph)`
  font-size: ${fp(4.3)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  font-weight: bold;
  text-align: right;
`;

const ListText = styled(Paragraph)`
  font-size: ${fp(3.8)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
  text-align: left;
  margin-left: 12px;
  flex: 1;
`;

const styles = StyleSheet.create({});
