import AvatarImage from "@/components/avatar-image";
import Carousel from "@/components/carousel";
import MeGridTop from "@/components/grid-me";
import MeGridMiddle from "@/components/grid-me/item";
import { useMount } from "@/react-native-ahook";
import { NoticeBar, Space } from "@/react-native-papal";
import {
  GET_BANNER_INFO,
  GET_ICON_CONFIG,
  GET_USER_INFO,
  IAppState,
} from "@/redux/models/app";
import { createAction, createActions } from "@/utils";
import { fp, wp } from "@/utils/dimension";
import { ProcessUrl } from "@/utils/image";
import { LinkTo } from "@/utils/link";
import { uuid } from "@/utils/uuid";
import { ParseVipCode } from "@/utils/vip";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import React from "react";
import { FlatList, Platform, StyleSheet } from "react-native";
import { Chip, Colors, Paragraph, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";
import { parse } from "querystring";

interface Props {}

// the me screen
const MeScreen = (props: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigation = useNavigation();
  const [iconFunction, setIconFunction] = React.useState<any>([]);
  const [iconLinkus, setIconLinkus] = React.useState<any>([]);
  const { userInfo, banners } = useSelector(({ app, loading }: any) => ({
    userInfo: app.userInfo,
    banners: app.banners,
  }));
  useMount(() => {
    dispatch(createAction(GET_USER_INFO)({}));
    // type 590首页 591我的
    dispatch(createAction(GET_BANNER_INFO)({ type: 591 }));
  });

  useMount(() => {
    // 632我的常用功能
    dispatch(
      createActions(GET_ICON_CONFIG)({ type: 632 })((data: any) => {
        setIconFunction(data);
      })
    );
  });

  useMount(() => {
    // 633我的联系我们
    dispatch(
      createActions(GET_ICON_CONFIG)({ type: 633 })((data: any) => {
        setIconLinkus(data);
      })
    );
  });
  // the render header
  const renderHeader = () => {
    return (
      <Header>
        <HeaderAvatarView>
          <AvatarImage
            size={64}
            source={ProcessUrl(ProcessUrl(userInfo?.avatar))}
            onPress={() => navigation.navigate("PersonalInfo")}
          />
          <HeaderNameView>
            <HeaderNameText onPress={() => navigation.navigate("PersonalInfo")}>
              {userInfo?.nickName}
            </HeaderNameText>
            <Chip
              style={styles.chip}
              textStyle={styles.chipText}
              icon="information"
            >
              {ParseVipCode(userInfo)}
            </Chip>
          </HeaderNameView>
          <Ionicons
            name="md-chevron-forward"
            size={18}
            color={Colors.white}
            onPress={() => navigation.navigate("PersonalInfo")}
          />
        </HeaderAvatarView>
        <HeaderMemberView
          onPress={() => navigation.navigate("Member", { tabIndex: 0 })}
        >
          <HeaderMemberText>会员VIP服务</HeaderMemberText>
          <HeaderMemberSubView>
            {userInfo.vipLevel > 440 ? (
              userInfo?.vipEndDate && (
                <HeaderMemberSubText>
                  到期时间：{dayjs(userInfo?.vipEndDate).format("YYYY-MM-DD")}
                </HeaderMemberSubText>
              )
            ) : (
              <HeaderMemberSubText>比更多人洞见商业机会</HeaderMemberSubText>
            )}

            <Ionicons
              name="md-chevron-forward"
              size={18}
              color={theme.colors.text}
            />
          </HeaderMemberSubView>
        </HeaderMemberView>
      </Header>
    );
  };

  // the render function
  const renderFunction = () => {
    return (
      <FunctionView>
        <MeGridTop
          title="邀请得VIP"
          caption="查看邀请进度"
          source={require("@/assets/images/me/me_invite.png")}
          onPress={() => navigation.navigate("Invite")}
        />
        <Space />
        <MeGridTop
          title="合伙人"
          caption="资源合作变现"
          source={require("@/assets/images/me/me_partner.png")}
          onPress={() =>
            userInfo?.vipLevel > 442
              ? navigation.navigate("PartnerCenter")
              : navigation.navigate("Partner")
          }
          // onPress={() => navigation.navigate("Partner")}
          // onPress={() => navigation.navigate("PartnerCenter")}
        />
      </FunctionView>
    );
  };

  const renderItem = React.useCallback(
    ({ item }) => (
      <MeGridMiddle
        key={uuid()}
        title={item.title}
        source={ProcessUrl(item.iconUrl)}
        onPress={() => item.linkTo && LinkTo(item.linkTo, parse(item.params))}
      />
    ),
    []
  );
  const keyExtractor = React.useCallback((item) => item.title, []);
  const renderToolView = () => {
    return (
      <ToolsView>
        <ToolsTitle>常用功能</ToolsTitle>
        <FlatList
          data={iconFunction || []}
          numColumns={4}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
      </ToolsView>
    );
  };

  const renderCarousel = () => {
    return <CarouselView data={banners} />;
  };

  // the render contact us
  const renderContactUs = () => {
    return (
      <ToolsView>
        <ToolsTitle>联系我们</ToolsTitle>
        <FlatList
          data={iconLinkus || []}
          numColumns={4}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
      </ToolsView>
    );
  };

  // the return
  return (
    <PageScrollView>
      <NoticeBar
        marqueeProps={{
          loop: true,
          leading: 5000,
          style: { fontSize: 13, color: "red" },
        }}
      >
        测试期间，数据部分完整(不用担心，系统在逐渐更新)，功能可能不稳定(程序员小哥们在不断修复bug)，欢迎提出使用反馈建议。{" "}
      </NoticeBar>
      {renderHeader()}
      {renderFunction()}
      {/* {renderTools()} */}
      {renderToolView()}
      {banners.length > 0 && renderCarousel()}
      {renderContactUs()}
    </PageScrollView>
  );
};

export default MeScreen;

const PageScrollView = styled.ScrollView`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;
const Header = styled.View`
  height: 180px;
  background-color: ${Colors.blue500};
  padding: 16px 16px 0 16px;
  justify-content: flex-end;
`;
const HeaderAvatarView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const HeaderNameView = styled.View`
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
`;
const HeaderNameText = styled.Text`
  font-size: ${fp(4.7)}px;
  font-weight: bold;
  color: ${Colors.white};
  margin-bottom: 6px;
`;

const HeaderMemberView = styled.Pressable`
  background-color: ${(props: IStyledProps) => props.theme.member};
  padding: 12px;
  flex-direction: row;
  margin-top: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  align-items: center;
`;

const HeaderMemberText = styled.Text`
  font-size: ${fp(4.0)}px;
  font-weight: bold;
  flex: 1;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const HeaderMemberSubView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const HeaderMemberSubText = styled.Text`
  font-size: ${fp(3.3)}px;
  color: ${(props: IStyledProps) => props.theme.text};
`;

const FunctionView = styled.View`
  padding: 16px;
  flex-direction: row;
`;

const ToolsView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  margin: 0px 16px 0 16px;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 6px;
`;
const ToolsTitle = styled.Text`
  font-size: ${fp(4.3)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const ToolsGridView = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 8px;
`;

const CarouselView = styled(Carousel)`
  margin-bottom: 12px;
`;

const styles = StyleSheet.create({
  chip: {
    alignItems: "center",
    justifyContent: "center",
    width: wp(23),
    height: 22,
    backgroundColor: Colors.amber400,
  },
  chipText: {
    fontSize: wp(3.2),
    marginTop: Platform.OS === "android" ? 1 : 5,
  },
});
