import { ProcessUrl } from "@/utils/image";
import React from "react";
import {
  StyleSheet,
  Image as RNImage,
  View,
  ImageStyle,
  ImageSourcePropType,
  ImageProps,
} from "react-native";

interface Props extends ImageProps {
  source: ImageSourcePropType;
  style?: ImageStyle;
}

// the image component
const Image: React.FC<Props> = (props) => {
  return (
    <View>
      <RNImage style={props.style} source={props.source} />
    </View>
  );
};

export default Image;

const styles = StyleSheet.create({});
