import Page from "@/components/page";
import React, { useCallback, useReducer } from "react";
import {
  StyleSheet,
  FlatList,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import styled from "styled-components/native";
import { Button, Colors, List, Paragraph } from "react-native-paper";
import TextInput from "@/components/textinput";
import { Modal, Toast } from "@/react-native-papal";
import { hp } from "@/utils/dimension";
import { useNavigation } from "@react-navigation/core";
import { useDebounceFn, useMount } from "@/react-native-ahook";
import { useDispatch, useSelector } from "react-redux";
import { createAction } from "@/utils";
import {
  GET_ECONOMY_AREA_SEARCH,
  GET_ECONOMY_SEARCH,
  GET_HOT_AREA_LIST,
  IEconomyAreaSearch,
} from "@/redux/models/economy";
import { isEmpty, isWechat } from "@/utils/tools";
import { TOAST_SHORT } from "@/constants/app";
import SearchGrid from "@/components/search";
import { IDictItem, IDictTreeItem } from "@/redux/models/redux";
import { LinkTo } from "@/utils/link";
import { debounce } from "lodash";

interface Props {}

function parseArea(item: IEconomyAreaSearch) {
  let search = "";
  if (!isEmpty(item.nation)) {
    search = item.nation;
  }
  if (!isEmpty(item.province)) {
    search += " " + item.province;
  }
  if (!isEmpty(item.city)) {
    search += " " + item.city;
  }
  if (!isEmpty(item.district)) {
    search += " " + item.district;
  }
  return search;
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case "AreaType":
      return { ...state, areaType: action.value };
    case "AreaResult":
      return { ...state, areaResult: action.value };
    case "AreaIndex":
      return { ...state, areaIndex: action.value };
    case "SearchText":
      return { ...state, searchText: action.value };
    case "AreaText":
      return { ...state, AreaText: action.value };
    case "ModalVisible":
      return { ...state, modalVisible: action.value };
  }
}

const AREA_TYPE = ["nation", "province", "city", "district"];

/// 经济 搜索指标
const EconomySearchScreen: React.FC<Props> = (props) => {
  const reduxDispatch = useDispatch();
  const navigation = useNavigation();
  const [state, dispatch] = useReducer(reducer, {
    areaType: "",
    areaIndex: "",
    searchText: "",
    areaResult: "",
    AreaText: "",
    modalVisible: false,
  });

  const { areaSearch, economySearch, hotAreaData } = useSelector(
    ({ economy, loading }: any) => ({
      areaSearch: economy.areaSearch,
      economySearch: economy.economySearch,
      hotAreaData: economy.hotAreaData,
    })
  );

  useMount(() => {
    // 热门数据
    reduxDispatch(createAction(GET_HOT_AREA_LIST)({ key: "economyHotArea" }));
  });

  const onSearchResult = (text: string) => {
    text &&
      reduxDispatch(
        createAction(GET_ECONOMY_SEARCH)({
          keyword: text,
          // areaType: state.areaType,
          areaName: state.areaResult,
        })
      );
  };

  const renderSearch = () => {
    return (
      <SearchView>
        <TextInput
          styles={styles.input}
          leftType="menu"
          returnKeyType="search"
          menuText={state.areaIndex || "指标范围"}
          placeholder="请输入经济指标，如人口、GDP"
          editable={state.areaIndex ? true : false}
          selectTextOnFocus={state.areaIndex ? true : false}
          onLeftPress={() => dispatch({ type: "ModalVisible", value: true })}
          onChangeText={(text) => dispatch({ type: "SearchText", value: text })}
          onPress={() =>
            state.areaIndex || Toast.info("请先选择指标范围", TOAST_SHORT)
          }
          onSubmitEditing={({ nativeEvent: { text } }) => onSearchResult(text)}
          onChange={({ nativeEvent: { text } }) => {
            isWechat() && debounce.run(text);
          }}
        />
        <Button style={styles.button} onPress={() => navigation.goBack()}>
          取消
        </Button>
      </SearchView>
    );
  };

  const debounce = useDebounceFn(
    (text) => {
      onSearchResult(text);
    },
    {
      wait: 600,
    }
  );

  // the render search result
  const renderSearchResult = () => {
    return (
      <FlatList
        data={economySearch}
        contentContainerStyle={styles.list}
        keyExtractor={(item, index) => item + index}
        renderItem={({ item, index }) => {
          return (
            <ListItem
              key={index}
              title={item.name + "(" + item.unit + ")"}
              onPress={() =>
                navigation.navigate("EconomyDetail", {
                  areaType: AREA_TYPE[state.areaType],
                  areaName: state.areaResult,
                  indexName: item.name,
                  unit: "(" + item.unit + ")",
                })
              }
            />
          );
        }}
        ListHeaderComponent={() => {
          return (
            <ListHeader>
              <ListHeaderText>
                找到<ListFooterText>{economySearch.length}</ListFooterText>
                个相关指标
              </ListHeaderText>
            </ListHeader>
          );
        }}
        ListFooterComponent={() => {
          return (
            <ListFooter>
              <ListFooterText>没找到想查询的指标？</ListFooterText>
              <ListFooterDescText>1.换一个指标关键词搜索</ListFooterDescText>
              <ListFooterDescText>
                2.点击提交你的
                <ListFooterContactText
                  onPress={() => LinkTo("https://jinshuju.net/f/Xo6UT2")}
                >
                  数据需求
                </ListFooterContactText>
              </ListFooterDescText>
            </ListFooter>
          );
        }}
      />
    );
  };

  const onSearchAreaIndex = (text: string) => {
    text &&
      reduxDispatch(createAction(GET_ECONOMY_AREA_SEARCH)({ keyword: text }));
  };

  const renderAreaResult = () => {
    return (
      <FlatList
        data={areaSearch}
        keyExtractor={(item, index) => item + index}
        renderItem={({ item, index }) => {
          const search = parseArea(item);
          const lastSearchArray = search.split(" ");
          const lastSearch = lastSearchArray[lastSearchArray.length - 1];
          return (
            <ListItem
              key={index}
              title={search}
              onPress={() => {
                const atype = lastSearchArray.indexOf(lastSearch!);
                dispatch({ type: "AreaType", value: atype });
                dispatch({ type: "AreaResult", value: search });
                dispatch({ type: "AreaIndex", value: lastSearch });
                dispatch({ type: "ModalVisible", value: false });
              }}
            />
          );
        }}
      />
    );
  };
  const renderAreaDefault = () => {
    const hotData = hotAreaData.map((item: IDictTreeItem) => {
      return (
        <SearchGrid
          key={item.pk}
          title={item.title}
          data={item.children}
          onPress={(treeItem: IDictTreeItem) => {
            const search = treeItem.remark;
            const lastSearchArray = search.split(" ");
            const lastSearch = lastSearchArray[lastSearchArray.length - 1];
            const atype = lastSearchArray.indexOf(lastSearch!);
            dispatch({ type: "AreaType", value: atype });
            dispatch({ type: "AreaResult", value: search });
            dispatch({ type: "AreaIndex", value: lastSearch });
            dispatch({ type: "ModalVisible", value: false });
          }}
        />
      );
    });
    return <AreaDefaultView>{hotData}</AreaDefaultView>;
  };

  const areaDebounce = useDebounceFn(
    (text) => {
      onSearchAreaIndex(text);
    },
    {
      wait: 600,
    }
  );

  // the modal
  const renderModal = () => {
    return (
      <Modal
        popup
        visible={state.modalVisible}
        maskClosable={true}
        animationType="slide-up"
        onClose={() => dispatch({ type: "ModalVisible", value: false })}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "position"}
        >
          <ModalView>
            <ModalSearchView>
              <TextInput
                styles={styles.modalInput}
                autoFocus
                returnKeyType="search"
                placeholder="输入查询指标范围 如:中国/北京/四川/成都等"
                clearButtonMode="while-editing"
                onLeftPress={() =>
                  dispatch({ type: "ModalVisible", value: true })
                }
                onChangeText={(text) =>
                  dispatch({ type: "AreaText", value: text })
                }
                onSubmitEditing={({ nativeEvent: { text } }) =>
                  onSearchAreaIndex(text)
                }
                onChange={({ nativeEvent: { text } }) =>
                  isWechat() && areaDebounce.run(text)
                }
              />
            </ModalSearchView>
            {!state.AreaText && renderAreaDefault()}
            {state.AreaText
              ? areaSearch.length > 0 && renderAreaResult()
              : null}
          </ModalView>
        </KeyboardAvoidingView>
      </Modal>
    );
  };
  return (
    <Page>
      {renderSearch()}
      {state.searchText ? renderSearchResult() : null}
      {renderModal()}
    </Page>
  );
};

export default EconomySearchScreen;

const SearchView = styled.Pressable`
  background-color: ${Colors.white};
  padding: 12px;
  flex-direction: row;
`;
const ModalSearchView = styled.View`
  /* background-color: ${Colors.white}; */
  padding: 12px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${(props: IStyledProps) => props.theme.subPrimary};
`;
const ModalView = styled.View`
  height: ${hp(50)}px;
  background-color: ${Colors.white};
`;
const ListItem = styled(List.Item)`
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${(props: IStyledProps) => props.theme.subPrimary};
`;
const ListHeader = styled.View`
  background-color: ${Colors.grey100};
  height: 36px;
`;
const ListFooter = styled.View`
  background-color: ${Colors.grey100};
  justify-content: center;
  align-items: center;
`;
const ListHeaderText = styled(Paragraph)``;
const ListFooterText = styled(Paragraph)`
  color: ${Colors.red300};
  font-size: 15px;
  margin-top: 10px;
`;
const ListFooterDescText = styled(Paragraph)``;
const ListFooterContactText = styled(Paragraph)`
  color: ${Colors.blue400};
`;
const AreaDefaultView = styled.ScrollView``;

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginRight: 12,
    backgroundColor: "#F4F9FF",
  },
  button: {
    alignSelf: "center",
  },
  modalInput: {
    flex: 1,
    backgroundColor: "#F4F9FF",
  },
  list: {
    backgroundColor: Colors.white,
    margin: 16,
    borderRadius: 5,
  },
  container: {
    // flex: 1,
    // marginHorizontal: 16,
    // height: hp(46),
  },
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8,
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
  },
  title: {
    fontSize: 24,
  },
});
