import { EXCHANGE_REDEEM } from "@/redux/models/me";
import { createAction } from "@/utils";
import React from "react";
import { StyleSheet, Platform } from "react-native";
import { Button, Colors } from "react-native-paper";
import { useDispatch } from "react-redux";

import styled from "styled-components/native";

interface Props {}

const RedeemScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [redeem, setRedeem] = React.useState("");

  const onExchangePressed = () => {
    dispatch(createAction(EXCHANGE_REDEEM)({ redeem }));
  };

  return (
    <PageView>
      <HeaderView>
        <TextInput
          placeholder="请输入或粘贴兑换码"
          maxLength={24}
          // @ts-ignore
          style={Platform.select({
            web: {
              outlineStyle: "none",
            },
          })}
          onChangeText={(text) => setRedeem(text)}
        />
        <Button
          mode="contained"
          disabled={!redeem}
          onPress={() => onExchangePressed()}
        >
          兑换VIP会员
        </Button>
      </HeaderView>
      <DescView>
        <Title>关于兑换码</Title>
        <Desc>
          1、兑换码是司马诸葛针对用户设计的一种特殊赠送、奖励的方式，支持兑换司马诸葛会员等权益
          {"\n"}
          2、每个兑换码，仅可兑换一次，重复使用无效{"\n"}
          3、兑换码有效期1年，过期后不可兑换{"\n"}
          4、兑换成功后5分钟内VIP生效
          {"\n"}
        </Desc>
      </DescView>
    </PageView>
  );
};

export default RedeemScreen;

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;
const TextInput = styled.TextInput`
  border-width: 1px;
  border-color: ${Colors.grey200};
  height: 48px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 16px;
`;
const HeaderView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  padding: 16px;
  /* margin-top: 6px; */
`;
const DescView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  padding: 16px;
  margin-top: 6px;
`;
const Title = styled.Text`
  font-size: 15px;
  font-weight: bold;
  color: ${Colors.grey700};
`;
const Desc = styled.Text`
  font-size: 13px;
  color: ${(props: IStyledProps) => props.theme.caption};
  margin-top: 10px;
  line-height: 21px;
`;
const styles = StyleSheet.create({});
