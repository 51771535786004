import BrandCategory from "@/components/brand";
import CardInfo from "@/components/card/card-info";
import Page from "@/components/page";
import TextInput from "@/components/textinput";
import { useDebounceFn, useMount, usePagination } from "@/react-native-ahook";
import {
  GET_BRAND_SEARCH,
  GET_HOT_BRAND_LIST,
} from "@/redux/models/enterprise";
import { createAction } from "@/utils";
import { aesEncode, isWechat } from "@/utils/tools";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Title } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";
import CategoryItem from "@/components/category-item";
import { fp, wp } from "@/utils/dimension";

interface Props {}

/// 企业搜索
const EnterpriseSearchScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [searchText, setSearchText] = React.useState("");
  const { hotBrandData, brandSearchList } = useSelector(
    ({ enterprise, loading }: any) => ({
      hotBrandData: enterprise.hotBrandData,
      brandSearchList: enterprise.brandSearchList,
    })
  );

  useMount(() => {
    // 获取热门品牌
    dispatch(createAction(GET_HOT_BRAND_LIST)({ key: "hotBrand" }));
  });

  // 搜索
  const renderSearch = () => {
    return (
      <SearchView>
        <TextInput
          styles={styles.input}
          returnKeyType="search"
          placeholder="请输入品牌名"
          onSubmitEditing={({ nativeEvent: { text } }) => onSearchResult(text)}
          onChange={({ nativeEvent: { text } }) =>
            isWechat() && debounce.run(text)
          }
        />
      </SearchView>
    );
  };

  const debounce = useDebounceFn(
    (text) => {
      onSearchResult(text);
    },
    {
      wait: 600,
    }
  );

  // 搜索
  const onSearchResult = (text: string) => {
    setSearchText(text);
    text && dispatch(createAction(GET_BRAND_SEARCH)({ keyword: text }));
  };

  // 搜索结果
  const renderSearchResult = () => {
    return (
      <ListView
        contentContainerStyle={{ flexGrow: 1 }}
        data={brandSearchList || []}
        keyExtractor={(item: any) => item.pk}
        ListHeaderComponent={
          <ListHeaderView>
            <HeaderText>
              找到
              <HeaderRedText>
                {brandSearchList ? brandSearchList.length : 0}个
              </HeaderRedText>
              相关品牌
            </HeaderText>
          </ListHeaderView>
        }
        renderItem={({ item }: any) => (
          <CategoryItem
            key={item.pk}
            item={item}
            onPress={() =>
              navigation.navigate("EnterpriseDetail", {
                items: aesEncode(JSON.stringify(item)),
              })
            }
          />
        )}
      />
    );
  };

  // 热门品牌
  const renderHotBrand = () => {
    return (
      <BrandCategory
        item={hotBrandData}
        onPress={(item: any) => {
          navigation.navigate("EnterpriseDetail", {
            items: aesEncode(JSON.stringify({ pk: item.key })),
          });
        }}
      />
    );
  };

  return (
    <Page>
      {renderSearch()}
      {searchText ? renderSearchResult() : renderHotBrand()}
    </Page>
  );
};

export default EnterpriseSearchScreen;

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;

const SearchView = styled.Pressable`
  background-color: ${Colors.white};
  padding: 8px 12px 8px 12px;
  flex-direction: row;
`;

const ListView = styled.FlatList`
  padding-top: 12px;
`;
const ListHeaderView = styled.View`
  padding: 8px;
  justify-content: center;
  padding-left: 16px;
`;
const HeaderText = styled(Title)`
  font-size: ${fp(3.7)}px;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const HeaderRedText = styled(Title)`
  font-size: ${fp(3.7)}px;
  color: ${(props: IStyledProps) => props.theme.redText};
`;

const styles = StyleSheet.create({
  input: {
    backgroundColor: "#F4F9FF",
  },
});
