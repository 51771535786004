import React from "react";
import { StyleSheet, Text, View } from "react-native";

interface Props {}
/// 提现 结算明细
const WithdrawScreen: React.FC<Props> = (props) => {
  return (
    <View>
      <Text></Text>
    </View>
  );
};

export default WithdrawScreen;

const styles = StyleSheet.create({});
