import request from "@/utils/request";

/**
 * @description 经济搜索
 * @export
 * @returns
 */
export async function fetchBrandCategory(params: any) {
  return request("/api/app/brand/category/list", {
    method: "POST",
    data: params,
  });
}

/**
 * 品类分页
 * @param params
 * @returns
 */
export async function fetchPageCategory(params: any) {
  return request("/api/app/category/page", {
    method: "POST",
    data: params,
    params: params,
  });
}

/**
 * 品类详情
 * @param params
 * @returns
 */
export async function fetchCategoryDetail(params: any) {
  return request("/api/app/category/detail", {
    method: "POST",
    data: params,
  });
}

/**
 * 品类搜索
 * @param params
 * @returns
 */
export async function fetchBrandSearch(params: any) {
  return request("/api/app/brand/search", {
    method: "POST",
    data: params,
  });
}

/**
 * 全国各地门店分布
 * @param params
 * @returns
 */
export async function fetchNationInfo(params: any) {
  return request("/api/app/enterprise/nation/info", {
    method: "POST",
    data: params,
  });
}

/**
 * 分页全国各地门店
 * @param params
 * @returns
 */
export async function fetchPageNationShop(params: any) {
  return request("/api/app/enterprise/nation/info/page", {
    method: "POST",
    params: params,
  });
}

/**
 * 企业数据分布分页
 * @param params
 * @returns
 */
export async function fetchPageTable(params: any) {
  return request("/api/app/enterprise/table/page", {
    method: "POST",
    params: params,
  });
}

/**
 * 生成企业海报
 * @param params
 * @returns
 */
export async function generateEnterprisePoster(params: any) {
  return request("/api/app/enterprise/share", {
    method: "POST",
    data: params,
  });
}
