import { hp, wp } from "@/utils/dimension";
import { ProcessUrl } from "@/utils/image";
import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { Portal, Modal } from "react-native-paper";
import styled from "styled-components/native";
import * as Animatable from "react-native-animatable";

interface Props {
  item: any;
  visible: boolean;
  onDrawPressed?: () => void;
  onClose?: () => void;
}

const COUPON_DATA = [
  {
    type: "inviteNew",
    source: require("@/assets/images/common/common_coupon_personal.png"),
  },
  {
    type: "invitePartnerNew",
    source: require("@/assets/images/common/common_coupon_partner.png"),
  },
];

/// 优惠券弹窗
const CouponPopup: React.FC<Props> = (props) => {
  const couponData = COUPON_DATA.map((coupon, index) => {
    return props.item.commonType === coupon.type ? (
      <CouponView key={index + 2}>
        <CouponImage key={index} resizeMode="contain" source={coupon.source} />
        <Animatable.View
          animation="pulse"
          easing="ease-in-sine"
          iterationCount="infinite"
        >
          <Pressable onPress={props.onDrawPressed}>
            <DrawImage
              key={index + 1}
              resizeMode="contain"
              source={require("@/assets/images/common/common_coupon_draw.png")}
            />
          </Pressable>
        </Animatable.View>
      </CouponView>
    ) : null;
  });
  return (
    <Portal>
      <Modal
        visible={props.visible}
        dismissable={false}
        onDismiss={props.onClose}
      >
        {couponData}
      </Modal>
    </Portal>
  );
};

CouponPopup.defaultProps = {
  visible: false,
};

export default React.memo(CouponPopup);
const CouponView = styled.View``;
const CouponImage = styled.Image`
  width: ${wp(80)}px;
  height: ${(wp(80) * 1065) / 921}px;
  align-self: center;
`;
const DrawImage = styled.Image`
  width: ${wp(34)}px;
  height: ${(wp(34) * 87) / 405}px;
  align-self: center;
  margin-top: ${wp(-14)}px;
`;

const styles = StyleSheet.create({});
