import Page from "@/components/page";
import React, { useCallback } from "react";
import { FlatList, RefreshControl, StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph, Title } from "react-native-paper";
import styled from "styled-components/native";
import TextInput from "@/components/textinput";
import { fp, wp } from "@/utils/dimension";
import { useDebounceFn, useMount, usePagination } from "@/react-native-ahook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation, useRoute } from "@react-navigation/native";
import TradeChartItem from "@/components/trade/chart-item";
import { createAction, createActions } from "@/utils";
import { Toast } from "@/react-native-papal";
import { TOAST_LOADING } from "@/constants/app";
import {
  GENERATE_TRADE_POSTER,
  GET_TRADE_DIMENSION,
  GET_TRADE_REPORT_LINK,
  PAGE_TRADE_CHART,
} from "@/redux/models/trade";
import { aesDecode, aesEncode, isWechat } from "@/utils/tools";
import ImagePreview from "@/components/image-preview";
import TradeTagSelector from "@/components/trade/tag";
import { uuid } from "@/utils/uuid";
import MemberLockFooter from "@/components/pagination/member-lock";
import { IsVip } from "@/utils/vip";
import { GET_USER_INFO } from "@/redux/models/app";
import { ProcessUrl } from "@/utils/image";
import Share from "@/components/share";

interface Props {}

/// 图表
const TradeChartScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route: any = useRoute();
  const { items } = route.params;
  const [searchText, setSearchText] = React.useState("");
  const [dimensionPk, setDimensionPk] = React.useState("");
  const [imageViewVisible, setImageViewVisible] = React.useState(false);
  const [imageView, setImageView] = React.useState("");
  const [shareVisible, setShareVisible] = React.useState(false);

  const { pk } = JSON.parse(aesDecode(items));
  const {
    data,
    addData,
    onEndReached,
    resetData,
    pageIndex,
    loadingMore,
    hasMoreData,
  } = usePagination(20);
  const { tradeDimension, userInfo, posterImage } = useSelector(
    ({ trade, app, loading }: any) => ({
      tradeDimension: trade.tradeDimension,
      userInfo: app.userInfo,
      posterImage: trade.posterImage,
    })
  );

  useMount(() => {
    dispatch(createAction(GET_USER_INFO)({}));
    dispatch(createAction(GET_TRADE_DIMENSION)({}));
  });

  React.useEffect(() => {
    setDimensionPk(pk);
  }, [pk]);

  React.useEffect(() => {
    let loadingKey: number;
    if (pageIndex == 0) {
      loadingKey = Toast.loading("...", TOAST_LOADING);
    }
    dispatch(
      createActions(PAGE_TRADE_CHART)({
        keyword: searchText,
        dimensionPk: dimensionPk,
        current: pageIndex + 1,
        size: 20,
      })((data: any) => {
        Toast.remove(loadingKey);
        addData(data.records || []);
      })
    );
  }, [pageIndex, searchText, dimensionPk]);

  const onSearchResult = (text: string) => {
    setSearchText(text);
    resetData([]);
  };

  const renderSearch = () => {
    return (
      <SearchView>
        <TextInput
          styles={styles.input}
          returnKeyType="search"
          placeholder="请输入行业关键字"
          // onChangeText={(text) => dispatch({ type: "SearchText", value: text })}
          onSubmitEditing={({ nativeEvent: { text } }) => onSearchResult(text)}
          // onChange={({ nativeEvent: { text } }) => isWechat() && run(text)}
        />
      </SearchView>
    );
  };

  const { run } = useDebounceFn(
    (text) => {
      onSearchResult(text);
    },
    {
      wait: 600,
    }
  );

  const renderHeader = () => {
    return (
      <HeaderView>
        <DimensionView>
          <DimensionTitle>行业维度</DimensionTitle>
          <TradeTagSelector
            data={tradeDimension}
            defaultValue={dimensionPk}
            onPress={(item) => {
              setDimensionPk(dimensionPk === item.pk ? "" : item.pk);
              resetData([]);
            }}
          />
        </DimensionView>
        {searchText ? (
          <HeaderText>
            找到<HeaderRedText>"{searchText}"</HeaderRedText>相关图表数据
          </HeaderText>
        ) : null}
      </HeaderView>
    );
  };

  const onSharePosterPressed = useCallback((item) => {
    dispatch(
      createActions(GENERATE_TRADE_POSTER)({
        title: item.title,
        posterType: "tradeChart",
        poster: ProcessUrl(item.imgUrl),
      })(() => {
        setShareVisible(true);
      })
    );
  }, []);

  const renderItem = useCallback(
    ({ item }) => (
      <TradeChartItem
        item={item}
        onImagePress={() => {
          setImageView(item.imgUrl);
          setImageViewVisible(true);
        }}
        onTagPress={() => {
          setDimensionPk(item.dimensionPk);
          resetData([]);
        }}
        onSharePress={(item) => onSharePosterPressed(item)}
        onViewReportPress={() => {
          navigation.navigate("TradePdf", {
            item: aesEncode(
              JSON.stringify({
                tradePk: item.tradePk,
                title: item.title,
                pageNum: item.pageNum,
                type: "chart",
              })
            ),
          });
        }}
      />
    ),
    []
  );
  const keyExtractor = useCallback((item) => item.pk, []);

  // render the share image
  const renderShareImage = () => {
    return (
      <ShareImage source={{ uri: "data:image/png;base64," + posterImage }} />
    );
  };

  return (
    <Page>
      {renderSearch()}
      <ListView
        data={data || []}
        // refreshControl={<RefreshControl refreshing={true} />}
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        contentContainerStyle={{ flexGrow: 1 }}
        renderItem={renderItem}
        ListHeaderComponent={renderHeader()}
        ListFooterComponent={
          <MemberLockFooter
            loadingMore={loadingMore}
            hasMoreData={hasMoreData}
            isVip={IsVip(userInfo)}
            onMemberPress={() => navigation.navigate("Member", { tabIndex: 0 })}
          />
        }
        keyExtractor={keyExtractor}
      />
      {imageViewVisible && (
        <ImagePreview
          image={imageView}
          visible={imageViewVisible}
          onClose={() => setImageViewVisible(false)}
        />
      )}
      <Share
        visible={shareVisible}
        content={renderShareImage()}
        onClose={() => setShareVisible(false)}
      />
    </Page>
  );
};

export default TradeChartScreen;

const SearchView = styled.Pressable`
  background-color: ${Colors.white};
  padding: 12px;
  flex-direction: row;
`;

const DimensionView = styled.View`
  padding: 8px 12px 8px 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: ${Colors.white};
`;
const DimensionTitle = styled(Title)`
  font-size: ${fp(4.3)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const HeaderGrid = styled(FlatList)``;

const ListView = styled(FlatList)``;
const HeaderView = styled.View`
  /* margin: 0 12px 0 12px; */
`;
const HeaderText = styled(Paragraph)`
  font-size: ${fp(3.5)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  margin: 0 12px 0 12px;
`;
const HeaderRedText = styled(Paragraph)`
  font-size: ${fp(3.5)}px;
  color: ${(props: IStyledProps) => props.theme.redText};
`;

const ShareImage = styled.Image`
  width: ${wp(70)}px;
  height: ${(wp(70) * 1640) / 1280}px;
  margin-left: ${wp(15)}px;
  margin-right: ${wp(15)}px;
`;

const styles = StyleSheet.create({
  input: {
    flex: 1,
    backgroundColor: "#F4F9FF",
  },
});
