import { check, createAction } from "@/utils";
import { DvaModel, ReduxAction } from "../redux";
import { SAVE, TOAST_LOADING, TOAST_SHORT } from "@/constants/app";
import { ResponseGenerator } from "../connect";
import { AccountApi } from "@/redux/services";
import { isWechat } from "@/utils/tools";
import { parse } from "querystring";
import { Toast } from "@/react-native-papal";
import { GET_USER_INFO } from "../app";

// actions
export const WP_AUTH = "account/wpAuth";
export const GET_WX_CONFIG = "account/getWxConfig";
export const GET_SMS_CAPTCHA = "account/smsCaptcha";
export const BIND_MOBILE = "account/bindMobile";

// interface
export interface IAccountState {
  loading?: boolean;
}

/**
 * account
 */
const NAMESPACE = "account";

const model: DvaModel<IAccountState> = {
  namespace: NAMESPACE,
  state: {},
  effects: {
    // 获取微信配置
    *getWxConfig({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        AccountApi.getWxConfig,
        payload
      );
      if (check(response)) {
        yield put(createAction(SAVE)({ wxConfig: response.data }));
        // 返回
        callback && callback(response.data);
      }
      Toast.remove(loadingKey);
    },
    // 微信授权
    *wpAuth({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        AccountApi.wpAuth,
        payload
      );
      if (check(response)) {
        // 跳转获取code Linking.openURL(response.data);
        if (isWechat()) {
          location.href = response.data;
          let param = window.location.search;
          param = param.replace("?", "");
          const params = parse(param);
          const code = params.code;
          // 登录
          yield put(createAction("wpSignIn")({ code }));
          // 返回
          callback && callback(response.data);
        }
      }
    },
    // 微信登录注册
    *wpSignIn({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        AccountApi.wpSignIn,
        payload
      );

      if (check(response)) {
        // save token
        // Storage.setItem(TOKEN_SESSION, response.data, response.expiresIn);
        // 返回
        callback && callback(response.data);
      }
    },
    // 获取短信验证码
    *smsCaptcha({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        AccountApi.getSmsCaptcha,
        payload
      );
      if (check(response)) {
        Toast.info(response.msg!, TOAST_SHORT);
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 绑定手机
    *bindMobile({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        AccountApi.bindMobile,
        payload
      );
      if (check(response)) {
        yield put(createAction(GET_USER_INFO)());
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 解绑手机
    *unBindMobile({ payload, callback }, { call, put }) {
      const loadingKey = Toast.loading("...", TOAST_LOADING);
      const response: ResponseGenerator = yield call(
        AccountApi.unBindMobile,
        payload
      );
      if (check(response)) {
        yield put(createAction(GET_USER_INFO)());
        callback && callback();
      }
      Toast.remove(loadingKey);
    },
    // 获取图形验证码
    *imageCaptcha({ payload, callback }, { call, put }) {
      const response: ResponseGenerator = yield call(
        AccountApi.getImageCaptcha,
        payload
      );
      if (check(response)) {
        // 获取图形验证码
        yield put(createAction(SAVE)({ imageCaptcha: response.data }));
        callback && callback();
      }
    },
  },
  reducers: {
    save(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        ...payload,
      };
    },
    error(state: object, { payload }: ReduxAction) {
      return {
        ...state,
        error: payload,
      };
    },
  },
  subscriptions: {
    init(dispatch: any) {},
  },
};
export default model;
