import { fp, wp } from "@/utils/dimension";
import { ProcessUrl } from "@/utils/image";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Paragraph, Colors } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  title: string;
  desc: string;
  subDesc: string;
  source: any;
  onPress?: () => void;
}

/// 行业项
const TradeItem: React.FC<Props> = (props) => {
  return (
    <PageView onPress={props.onPress}>
      <ContentView>
        <IconImage source={ProcessUrl(props.source)} />
        <TextView>
          <MainText>{props.title}</MainText>
          <SubText>{props.desc}</SubText>
          <SubText>{props.subDesc}</SubText>
        </TextView>
      </ContentView>
    </PageView>
  );
};

export default React.memo(TradeItem);
const PageView = styled.Pressable`
  width: ${wp(100) / 2 - 12}px;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;
const ContentView = styled.View`
  flex-direction: row;
  border-radius: 5px;
  background-color: ${Colors.white};
  padding: 12px;
  width: ${wp(100) / 2 - 22}px;
`;
const IconImage = styled.Image`
  width: ${fp(9)}px;
  height: ${fp(9)}px;
`;
const TextView = styled.View`
  margin-left: 10px;
`;

const MainText = styled(Paragraph)`
  font-size: ${fp(3.9)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  margin-bottom: 2px;
  font-weight: bold;
`;
const SubText = styled(Paragraph)`
  font-size: ${fp(3.1)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;

const styles = StyleSheet.create({});
