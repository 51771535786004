import { wp } from "@/utils/dimension";
import React, { memo } from "react";
import { Title, Colors } from "react-native-paper";
import styled from "styled-components/native";
import Image from "../image";
interface SharePosterProps {
  data: Array<string>;
}
const SharePoster = React.forwardRef((props: SharePosterProps, ref) => {
  return (
    <ShareScrollView
      horizontal
      pagingEnabled
      decelerationRate="fast"
      showsHorizontalScrollIndicator={false}
    >
      {props.data.map((item, index) => (
        <ShareImage
          key={index}
          source={{ uri: "data:image/png;base64," + item }}
        />
      ))}
    </ShareScrollView>
  );
});
SharePoster.defaultProps = {
  data: [],
};
export default memo(SharePoster);

const ShareContentView = styled.View``;
const ShareScrollView = styled.ScrollView`
  margin-bottom: 12px;
`;
const ShareImage = styled(Image)`
  width: ${wp(70)}px;
  height: ${(wp(70) * 1008) / 640}px;
  margin-left: ${wp(5)}px;
  margin-right: ${wp(5)}px;
`;
