import { fp, wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  data: IShopItem[];
}

export interface IShopItem {
  title: string;
  value: string;
}

/// shop item
const ShopItem: React.FC<Props> = (props) => {
  const dataItem = props.data.map((item, index) => {
    return (
      <ItemView key={index}>
        <ItemTitle>{item.title}</ItemTitle>
        <ItemText>{item.value}</ItemText>
      </ItemView>
    );
  });
  return <ShopView>{dataItem}</ShopView>;
};
ShopItem.defaultProps = {
  data: [],
};
export default React.memo(ShopItem);

const ShopView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 12px;
`;
const ItemView = styled.View`
  justify-content: center;
  align-items: center;
`;
const ItemTitle = styled(Paragraph)`
  font-size: ${fp(3.8)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  font-weight: bold;
`;
const ItemText = styled(Paragraph)`
  font-size: ${fp(3.5)}px;
  color: ${(props: IStyledProps) => props.theme.redText};
`;

const styles = StyleSheet.create({});
