import AvatarImage from "@/components/avatar-image";
import { fp, wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Avatar, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  item: any;
}

const SubAccountItem: React.FC<Props> = (props) => {
  return (
    <ItemView>
      <AvatarImage size={wp(10)} source={props.item.avatar} />
      <MobileText>{props.item.mobile}</MobileText>
    </ItemView>
  );
};

export default SubAccountItem;

const ItemView = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 8px 0px 8px 0px;
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${(props: IStyledProps) => props.theme.subPrimary};
`;
const MobileText = styled(Paragraph)`
  font-size: ${fp(4)}px;
  margin-left: 12px;
`;

const styles = StyleSheet.create({});
