import request from "@/utils/request";

/**
 * @description 经济数据树形
 * @export
 * @returns
 */
export async function fetchEconomyAreaTree(params: any) {
  return request("/api/app/economy/area/tree", {});
}

/**
 * @description 经济数据搜索
 * @export
 * @returns
 */
export async function fetchEconomyAreaSearch(params: any) {
  return request("/api/app/economy/area/search", {
    params: params,
    data: params,
  });
}

/**
 * @description 经济搜索
 * @export
 * @returns
 */
export async function fetchEconomySearch(params: any) {
  return request("/api/app/economy/search", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 经济详情
 * @export
 * @returns
 */
export async function fetchEconomyDetails(params: any) {
  return request("/api/app/economy/detail", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 获取经济数据EXCEL
 * @export
 * @returns
 */
export async function fetchEconomyExcel(params: any) {
  return request("/api/app/economy/generate/excel", {
    method: "POST",
    data: params,
  });
}
