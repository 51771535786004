import { Toast } from "@/react-native-papal";
import { GET_WX_CONFIG } from "@/redux/models/account";
import store from "@/redux/store";
import { createActions } from "@/utils";
import wx from "@tybys/jweixin";

const IS_DEV = process.env.NODE_ENV === "development";

const WX_MP_APPID = IS_DEV ? "wxc3a0346f1dd5f815" : "wxc3a0346f1dd5f815";

declare global {
  interface Window {
    wx: any;
    g_app: any;
  }
}

export interface WxAppShareConfig {
  title?: string;
  desc?: string;
  link?: string;
  imgUrl?: string;
  // type?: "music" | "video" | "link";
  // dataUrl?: string;
}

export interface WxPayConfig {
  signType: string;
  paySign: string;
  appId: string;
  packageValue: string;
  timeStamp: string;
  nonceStr: string;
}

/**
 * @description 微信工具
 * @author 驷爺.JC
 * @date 2019-11-12
 * @export
 * @class WxTools
 */
export default class WxTools {
  /**
   * @description 微信配置
   * @static
   * @memberof WxTools
   */
  public static getConfig = (url: string, callback?: () => void) => {
    store.dispatch(
      createActions(GET_WX_CONFIG)({ url })((config: any) => {
        wx.config({
          // debug: IS_DEV ? true : false,
          appId: WX_MP_APPID, // 必填，公众号的唯一标识
          timestamp: config.timestamp, // 必填，生成签名的时间戳
          nonceStr: config.nonceStr, // 必填，生成签名的随机串
          signature: config.signature, // 必填，签名
          jsApiList: [
            "updateAppMessageShareData",
            "onMenuShareAppMessage",
            "chooseWXPay",
          ], // 必填，需要使用的JS接口列表
        });
        wx.ready(() => {
          // 回调
          callback && callback();
        });
      })
    );
  };

  /**
   * 微信支付
   * @param param
   * @param callback
   */
  public static wxPay = (param: any, callback?: (res: any) => void) => {
    wx.chooseWXPay({
      timestamp: param.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      nonceStr: param.nonceStr, // 支付签名随机串，不长于 32 位
      package: param.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
      signType: param.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
      paySign: param.paySign, // 支付签名
      success: (res: any) => {
        // 支付成功后的回调函数
        callback && callback(res);
      },
    });
  };

  /**
   * 风险给朋友
   * @param param
   * @param callback
   */
  public static shareAppMessage = (
    param: WxAppShareConfig,
    callback?: () => void
  ) => {
    wx.onMenuShareAppMessage({
      title: param.title, // 分享标题
      desc: param.desc, // 分享描述
      link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: param.imgUrl, // 分享图标
      success: () => {
        // 用户点击了分享后执行的回调函数
        callback && callback();
      },
    });
  };

  /**
   * 分享朋友圈
   * @param param
   * @param callback
   */
  public static shareTimeline = (
    param: WxAppShareConfig,
    callback?: () => void
  ) => {
    wx.onMenuShareTimeline({
      title: param.title, // 分享标题
      link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: param.imgUrl, // 分享图标
      success: function () {
        // 用户点击了分享后执行的回调函数
        callback && callback();
      },
    });
  };

  /**
   * 下载图片到本地
   * @param url
   */
  public static downloadImageToLocal = (url: string) => {};

  /**
   * @description 使用微信内置地图查看位置接口
   * @static
   * @memberof WxTools
   */
  public static openLocation = (param: any) => {
    wx.openLocation({
      latitude: param.latitude || 0, // 纬度，浮点数，范围为90 ~ -90
      longitude: param.longitude || 0, // 经度，浮点数，范围为180 ~ -180。
      name: param.name || "", // 位置名
      address: param.address || "", // 地址详情说明
      scale: param.scale || 18, // 地图缩放级别,整形值,范围从1~28。默认为最大
      infoUrl: param.scale || "", // 在查看位置界面底部显示的超链接,可点击跳转
    });
  };

  /**
   * @description 获取地理位置接口
   * @static
   * @memberof WxTools
   */
  public static getLocation = (callback?: (res: any) => void) => {
    wx.getLocation({
      type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: (res: any) => {
        callback && callback(res);
      },
    });
  };

  /**
   * @description 选择相册
   * @static
   * @memberof WxTools
   */
  public static chooseImage = (callback?: (res: any) => void) => {
    wx.chooseImage({
      count: 1, // 默认9
      sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
      sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
      success: (res: any) => {
        var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
        localIds &&
          localIds.map((localId: any) => {
            wx.getLocalImgData({
              localId: localId, // 图片的localID
              success: function (image: any) {
                var localData = image.localData; // localData是图片的base64数据，可以用img标签显示
                let imageBase64 = "";
                if (localData.indexOf("data:image") === 0) {
                  //苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
                  imageBase64 = localData;
                } else {
                  //此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
                  //此时一个正常的base64图片路径就完美生成赋值到img的src中了
                  imageBase64 = "data:image/jpeg;base64," + localData;
                }
                callback && callback(imageBase64);
              },
            });
          });
      },
    });
  };
}
