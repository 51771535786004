import { useTheme } from "react-native-paper";
import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

interface Props extends ViewStyle {
  style?: React.CSSProperties;
}
// the page component
const Page: React.FC<Props> = (props) => {
  const theme = useTheme();
  return (
    <View style={[styles.container, { backgroundColor: theme.colors.page }]}>
      {props.children}
    </View>
  );
};

export default Page;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
