import { fp, wp } from "@/utils/dimension";
import { Ionicons } from "@expo/vector-icons";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  item: any;
  onPress?: () => void;
  onDownloadPress?: () => void;
  onSharePress?: (item: any) => void;
}

/// 报告
const TradeReportItem: React.FC<Props> = (props) => {
  const { item, onPress, onDownloadPress, onSharePress } = props;
  return (
    <ContentView onPress={() => onPress && onPress()}>
      <Image source={require("@/assets/images/common/common_pdf.png")} />
      <RightView>
        <Title>{item.name}</Title>
        <FooterView>
          <FooterLeftView>
            {item.fileDatetime && (
              <DateText>
                {dayjs(item.fileDatetime).format("YYYY-MM-DD")}
              </DateText>
            )}
            <DateText>{item.source}</DateText>
          </FooterLeftView>

          <FooterRightView>
            <IconView onPress={() => onDownloadPress && onDownloadPress()}>
              <Ionicons
                name="md-download-outline"
                size={wp(4.5)}
                color="#9C9AAB"
              />
              <IconText>下载报告</IconText>
            </IconView>
            <IconView onPress={() => onSharePress && onSharePress(item)}>
              <Ionicons
                name="md-share-outline"
                size={wp(4.5)}
                color="#9C9AAB"
              />
              <IconText>分享</IconText>
            </IconView>
          </FooterRightView>
        </FooterView>
      </RightView>
    </ContentView>
  );
};

export default TradeReportItem;

const ContentView = styled.Pressable`
  padding: 12px;
  margin: 12px 12px 0px 12px;
  background-color: ${Colors.white};
  border-radius: 5px;
  flex-direction: row;
`;
const LeftView = styled.View``;
const Image = styled.Image`
  width: ${wp(10)}px;
  height: ${wp(10)}px;
`;
const RightView = styled.View`
  flex: 1;
  margin-left: 12px;
`;

const Title = styled(Paragraph)`
  font-size: ${fp(3.8)}px;
  font-weight: bold;
`;
const DateText = styled(Paragraph)`
  font-size: ${fp(3.2)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
  margin-right: 6px;
`;
const FooterView = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

const FooterLeftView = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const FooterRightView = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const IconView = styled.Pressable`
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
`;

const IconText = styled.Text`
  font-size: ${fp(3.5)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;

const styles = StyleSheet.create({});
