const pathConfig = [
  {
    id: "71",
    fill: "rgb(0, 122, 255)",
    dx: "200",
    dy: "100",
    title: "台湾",
    d: "M 261.5,175.17 L 264.17,167.7 264.95,164.88 262.92,163.49 259.38,167.02 256.77,171.62 257.28,175.06 259.62,178.98 261.5,175.17 Z M 261.5,175.17",
  },
  {
    id: "65",
    fill: "rgb(0, 122, 255)",
    dx: "110",
    dy: "80",
    title: "新疆",
    d: "M 78.58,117.19 L 81.16,114.13 87.36,115.13 91.5,112.99 94.81,114.72 103.63,113.14 106.62,110.77 116.84,109.17 122.31,110.29 127.26,111.31 128.4,110.94 126.84,107.74 129.33,106.21 125.4,102.38 124.19,99.27 136.63,95.22 135.8,91.55 136.46,88.32 138.42,88.45 143.04,83.59 149.55,81.25 150.12,77.93 151.24,76.27 149.05,73.6 147.02,68.63 144.29,68.03 138.93,64.65 132.95,63.88 127.69,62.95 126.09,60.56 127.8,54.1 124.75,49.6 118.42,47.49 114.69,44.49 113.52,40.51 111.78,40.98 108.42,44.77 104.73,45.29 104.53,50.94 102.06,53.47 93.27,51.64 90.08,61.54 87.8,62.77 79.03,64.95 83.01,74.33 79.98,75.72 80.32,78.77 79.71,79.97 72.72,82.83 71.14,84.93 65.45,85.54 63.77,88.89 59.07,88.19 56.01,89.21 51.77,91.68 52.39,92.88 51.12,94.09 52.25,98.84 53.7,98.33 56.39,99.49 56.23,101.48 56.89,104.38 57.68,105.85 60.96,108.22 62.27,112.09 69.56,114.12 74.15,119.76 78.2,119.04 78.58,117.19 Z M 78.58,117.19",
  },
  {
    id: "64",
    title: "宁夏",
    fill: "rgb(0, 122, 255)",
    d: "M 200.16,104.27 L 200.64,103.53 200.71,102.84 201.74,102.28 201.93,102.17 196.78,99.62 198.84,95.8 198.08,94.22 195.23,95.32 193.62,100.22 193.51,102.28 188,103.85 188.86,105.35 191.62,107.41 191.24,108.68 192.32,110.9 191.59,111.34 192.66,112.99 195.08,114.64 195.68,114.6 196.2,115.26 196.81,114.64 196.55,112.96 197,112.71 198.21,112.86 198.7,112.39 198.78,110.75 197.02,110.12 196.35,108.56 197.17,107.63 197.45,107.77 197.28,105.68 199.75,105.6 200.24,105.94 200.5,105.5 200.16,104.27 Z M 200.16,104.27",
  },
  {
    id: "63",
    title: "青海",
    fill: "rgb(0, 122, 255)",
    d: "M 156.2,126.81 L 157.39,124.68 156.52,122.97 157.57,121.35 161.13,122.17 162.45,124.97 166.79,127.76 172.08,128.32 173.08,125.62 175.26,125.81 175.82,123.67 175,123.05 173.47,123.14 171.56,121.04 171.5,120.07 172.44,119.66 175.66,121.04 177.53,119.92 177.63,119.11 176.05,118.31 176.28,117.65 176.46,117.04 178.32,115.63 178.37,115.03 178.97,113.94 179.79,113.94 180.19,113.33 179.88,112.42 181.1,112.56 181.31,111.59 180.91,111.09 181.54,110.36 180.76,109.84 180.02,108.31 179.47,107.85 180.04,107.54 178.85,106.49 179.45,105.51 177.28,104.06 177.34,103.46 176.72,102.44 176.23,102.93 175.9,103.71 170.74,100.73 170.58,99.99 168.91,98.92 168.71,100.14 166.51,99.02 162.69,95.68 161.64,96.46 160.76,95.96 159.45,97.17 156.15,95.25 154.73,95.06 154.37,95.57 154.44,99.52 152.59,98.96 148.26,96.04 143.15,94.35 136.63,95.22 124.19,99.27 125.4,102.38 129.33,106.21 126.84,107.74 128.4,110.94 127.26,111.32 122.31,110.29 122.59,122.07 127.24,125.82 129.61,125.13 135.27,127.74 142.19,128.75 144.03,127.98 147.74,130.94 148.04,132.51 149.59,132.65 151.05,133.53 151.57,133.27 151.01,131.8 151.59,131.57 153.9,132.63 156.25,128.7 156.91,128.69 156.72,128.04 156.2,126.81 Z M 156.2,126.81",
  },
  {
    id: "62",
    title: "甘肃",
    fill: "rgb(0, 122, 255)",
    d: "M 149.55,81.25 L 143.04,83.59 138.42,88.45 136.46,88.32 135.8,91.55 136.63,95.22 143.15,94.35 148.26,96.04 152.59,98.96 154.44,99.52 154.37,95.57 154.73,95.06 156.15,95.25 159.45,97.17 160.76,95.96 161.64,96.46 162.69,95.68 166.51,99.02 168.71,100.14 168.91,98.92 170.58,99.99 170.74,100.73 175.9,103.7 176.23,102.93 176.72,102.44 177.34,103.46 177.28,104.06 179.45,105.51 178.85,106.49 180.04,107.54 179.47,107.85 180.02,108.31 180.76,109.84 181.54,110.36 180.91,111.09 181.31,111.59 181.1,112.56 179.88,112.42 180.19,113.33 179.79,113.94 178.97,113.94 178.37,115.03 178.32,115.63 176.46,117.04 176.28,117.65 176.06,118.31 177.63,119.11 177.53,119.92 175.66,121.04 172.44,119.66 171.5,120.07 171.56,121.04 173.47,123.14 175,123.05 175.82,123.67 176.55,124.17 176.74,125.39 178.43,125.01 178.94,124.08 178.02,121.47 181.04,119.97 182.01,120.96 181.94,122.36 185.91,122.98 187.6,126.29 187.35,127.33 189.28,128.37 191.76,127.85 192.09,126.73 192.97,126.96 193.97,126.67 193.67,125.08 194.4,123.36 197.19,123.49 196.44,121.94 197.77,119.56 196.1,118.83 196.76,116.42 198.67,115.97 199.91,117.03 202.83,116.45 201.92,115.22 206.17,114.72 205.8,113.2 205.69,112 206.36,111.68 206.63,109.55 205.75,109.02 201.42,107.23 200.24,105.94 199.75,105.6 197.28,105.68 197.45,107.77 197.17,107.63 196.35,108.56 197.02,110.12 198.78,110.75 198.7,112.39 198.21,112.86 197,112.71 196.55,112.96 196.81,114.64 196.2,115.26 195.68,114.6 195.08,114.64 192.66,112.99 191.59,111.34 192.32,110.9 191.24,108.68 191.62,107.41 188.86,105.35 188,103.85 186.49,104.27 183.08,102.03 184.49,98.33 186.62,95.63 185.44,93.7 176.77,95.34 177.18,96.64 175.83,97.84 171.72,96.05 165.5,91.57 169,87.78 166.5,86.04 161.92,88.09 160.18,88.18 157.32,83.27 158.33,81.61 155.75,76.67 151.66,76.78 151.24,76.27 150.12,77.93 149.55,81.25 Z M 149.55,81.25",
  },
  {
    id: "61",
    title: "陕西",
    fill: "rgb(0, 122, 255)",
    d: "M 214.06,118.42 L 213.57,116.6 215.07,113.28 214.26,106.54 215.86,103.55 214.65,101.45 217.34,93.54 212.16,95.14 207.22,101.17 206.94,102.87 203.54,102.96 201.93,102.17 201.74,102.28 200.71,102.84 200.64,103.53 200.16,104.27 200.5,105.5 200.24,105.94 201.42,107.23 205.75,109.02 206.63,109.56 206.36,111.68 205.69,112 205.8,113.2 206.17,114.72 201.92,115.22 202.83,116.45 199.91,117.03 198.67,115.97 196.76,116.42 196.1,118.83 197.77,119.56 196.44,121.94 197.19,123.49 194.4,123.36 193.67,125.08 193.97,126.67 192.97,126.96 193.48,127.51 196.43,128.27 199.1,127.73 203.36,130.56 204.52,129.92 205.77,130.25 208.78,132.14 210.48,132.59 210.1,129.81 211.95,128.37 213.03,127.89 211.3,126.82 210.15,125.23 213.08,125.33 216.71,125.09 215.8,122.41 214.06,118.42 Z M 214.06,118.42",
  },
  {
    id: "54",
    title: "西藏",
    fill: "rgb(0, 122, 255)",
    d: "M 160.85,150.12 L 162.2,149.52 161.82,148.21 161.93,146.7 163.08,146.7 163.34,144.94 164,144.63 163.64,142.01 162.61,136.12 162.98,134.32 159.92,129.51 157.86,128.69 156.25,128.7 153.9,132.63 151.59,131.57 151.01,131.8 151.57,133.27 151.05,133.53 149.59,132.65 148.05,132.51 147.74,130.94 144.03,127.98 142.19,128.75 135.27,127.74 129.61,125.13 127.24,125.82 122.59,122.07 122.31,110.29 116.84,109.17 106.62,110.77 103.64,113.14 94.81,114.72 91.5,112.99 87.36,115.13 81.16,114.13 78.58,117.19 78.2,119.04 74.15,119.76 74.32,119.97 73.87,123.99 75.64,126.52 75.5,129.03 72.32,128.38 73.57,133.77 77.92,136.85 84.09,140.23 85.92,139.07 89.48,140.56 93.95,143.68 96.45,144.38 97.94,146.67 101.38,147.62 104.99,149.72 110,150.82 115.17,151.28 117.87,150.28 118.25,154.03 121.18,150.49 123.56,149.29 126.72,150.4 129.06,150.51 131.01,151.8 134.58,151.2 138.61,147.65 143.72,144.6 147.45,145.77 150.61,143.75 152.68,146.73 151.18,148.74 155.96,149.45 158.55,149.08 159.05,150.03 159.8,149.63 160.85,150.12 Z M 160.85,150.12",
  },
  {
    id: "53",
    title: "云南",
    fill: "rgb(0, 122, 255)",
    d: "M 195.21,171.51 L 194.59,168.9 190.95,168.84 187.95,166.81 188.6,163.42 187.13,161.28 188.72,158.34 187.51,156.74 184.87,157.57 184.06,154.82 185.41,153.27 187.98,153.46 191,153.6 191.49,152.01 191.53,150.3 190.35,150.15 189.6,151.04 188.28,151.36 187.06,150.39 187.57,147.6 185.01,147.42 184.41,149.64 183.26,150.01 183.45,151.5 181.01,153.39 181.52,157.58 178.87,159.05 176.1,159.9 170.78,151.02 169.39,151.95 168.05,149.9 168.71,149.56 166.69,146.58 165.56,148.37 165.26,149.86 164.27,148.64 164,144.63 163.34,144.94 163.08,146.7 161.93,146.7 161.82,148.21 162.2,149.52 160.85,150.12 159.8,149.63 159.05,150.03 160.04,151.88 161.96,153.02 162.09,156.65 161.92,160.55 157.71,164.46 157.17,170.01 161.85,169.23 162.91,173.51 165.73,174.41 164.43,178.27 167.72,180 169.63,180.84 172.89,179.51 173.01,181.41 173.41,182.48 175.78,182.61 175.12,177.33 177.41,176.67 179.78,175.53 183.32,175.57 187.64,175.03 191.42,172.55 192.57,173.49 193.03,172.31 195.21,171.51 Z M 195.21,171.51",
  },
  {
    id: "52",
    title: "贵州",
    fill: "rgb(0, 122, 255)",
    d: "M 208.4,155.02 L 208.2,153.99 209.46,151.41 209.15,148.22 208.31,149.7 206.84,149.67 206.01,148.06 204.82,145.43 201.53,144.78 200.97,146.09 198.24,147.78 196.26,148.09 194.09,147.47 192.9,148.29 192.98,149.11 195.24,149.82 196.07,151.46 192.96,152.24 191.49,152.01 191,153.6 187.98,153.46 185.41,153.27 184.06,154.82 184.87,157.57 187.51,156.74 188.72,158.34 187.13,161.28 188.6,163.42 187.95,166.81 191.08,164.84 194.69,166.51 195.25,165 199.3,162.19 201.13,163.81 207.63,162.2 210.28,160.12 209.75,157.56 208.4,155.02 Z M 208.4,155.02",
  },
  {
    id: "51",
    title: "四川",
    fill: "rgb(0, 122, 255)",
    d: "M 205.03,131.95 L 205.77,130.25 204.52,129.92 203.36,130.56 199.1,127.73 196.43,128.26 193.48,127.51 192.97,126.96 192.09,126.73 191.76,127.85 189.28,128.37 187.35,127.33 187.6,126.29 185.91,122.98 181.94,122.36 182.01,120.96 181.04,119.97 178.02,121.47 178.94,124.08 178.43,125.01 176.74,125.39 176.55,124.17 175.82,123.67 175.26,125.81 173.08,125.62 172.08,128.32 166.79,127.76 162.45,124.97 161.13,122.17 157.57,121.35 156.52,122.97 157.39,124.68 156.2,126.81 156.72,128.04 156.91,128.69 157.86,128.69 159.92,129.51 162.98,134.32 162.61,136.12 163.64,142.01 164,144.63 164.27,148.64 165.26,149.86 165.56,148.37 166.69,146.58 168.71,149.56 168.05,149.9 169.39,151.95 170.78,151.02 176.1,159.9 178.87,159.05 181.52,157.58 181.01,153.39 183.45,151.5 183.26,150.01 184.41,149.64 185.01,147.42 187.57,147.6 187.06,150.39 188.28,151.36 189.6,151.04 190.35,150.15 191.53,150.3 191.49,152.01 192.96,152.24 196.07,151.46 195.24,149.82 192.98,149.11 192.9,148.29 194.09,147.47 196.26,148.09 196.37,147.87 195.55,146.33 194.15,146.3 192.17,144.44 191.65,142.99 193.37,141.71 193.44,140.88 192.53,140.18 193.82,138.92 195.64,140.11 197.09,139.53 197.91,140.87 198.69,140.85 201.29,137.92 200.9,137.41 201.29,136.94 202.42,137.06 205.6,133.12 205.82,132.45 205.03,131.95 Z M 205.03,131.95",
  },
  {
    id: "50",
    title: "重庆",
    fill: "rgb(0, 122, 255)",
    d: "M 205.77,130.25 L 205.03,131.95 205.82,132.45 205.6,133.12 202.42,137.06 201.29,136.94 200.9,137.41 201.29,137.92 198.69,140.85 197.91,140.87 197.09,139.53 195.64,140.11 193.82,138.92 192.53,140.18 193.44,140.88 193.37,141.71 191.65,142.99 192.17,144.44 194.15,146.3 195.55,146.33 196.37,147.87 196.26,148.09 198.24,147.78 200.97,146.09 201.53,144.78 204.82,145.43 206.01,148.06 206.84,149.67 208.32,149.7 209.15,148.22 208.94,145.25 205.4,141.57 206.72,138.59 212.7,137.12 213.21,135.44 210.48,132.59 208.78,132.14 205.77,130.25 Z M 205.77,130.25",
  },
  {
    id: "46",
    title: "海南",
    fill: "rgb(0, 122, 255)",
    d: "M 213.08,187.58 L 208.24,188.86 206.06,190.93 206.18,194.85 209.82,196.25 213.65,194.07 214.67,191.44 216.62,189.43 215.62,187.68 213.08,187.58 Z M 213.08,187.58",
  },
  {
    id: "45",
    title: "广西",
    fill: "rgb(0, 122, 255)",
    d: "M 218.36,174.67 L 219.51,171.17 220.89,169.66 221.33,166.05 218.74,166.37 218.71,164.94 216.78,165.19 216.79,164.08 218.12,162.74 218.76,161.2 216.68,158.29 213.82,160.22 211.43,160.73 210.28,160.12 207.63,162.2 201.13,163.81 199.3,162.19 195.25,165 194.69,166.51 191.08,164.84 187.95,166.81 190.95,168.84 194.59,168.9 195.21,171.51 193.03,172.31 192.57,173.49 193.55,174.29 197.6,175.14 196.9,177.81 199.01,179.67 203.46,180.87 205.57,180.13 210.24,181.28 211.9,180.4 218.36,174.67 Z M 218.36,174.67",
  },
  {
    id: "44",
    title: "广东",
    fill: "rgb(0, 122, 255)",
    d: "M 238.48,165.13 L 237.75,166.99 236.41,165.86 232.03,167.24 230.89,166.36 233.14,164.02 232.49,162.86 230.26,163.63 229.95,162.68 229.09,163.29 226.25,162.89 225.71,165.15 224.7,165.31 222.16,163.87 221.33,166.05 220.89,169.66 219.51,171.17 218.36,174.67 211.9,180.4 210.24,181.28 211.51,181.6 210.47,183.38 211.64,186.71 214.09,186.45 215.61,181.6 220.3,180.89 226.5,178.58 229.01,176.28 230.55,177.79 233.25,175.72 238.25,175.19 244.12,171.47 242.54,166.34 238.48,165.13 Z M 238.48,165.13",
  },
  {
    id: "43",
    title: "湖南",
    fill: "rgb(0, 122, 255)",
    d: "M 209.46,151.41 L 208.2,153.99 208.4,155.02 209.75,157.56 210.28,160.12 211.43,160.73 213.82,160.22 216.68,158.29 218.76,161.2 218.12,162.74 216.79,164.08 216.78,165.19 218.71,164.94 218.74,166.37 221.33,166.05 222.16,163.88 224.7,165.31 225.71,165.15 226.25,162.89 229.09,163.29 229.95,162.68 231.15,159.39 229.52,157.01 228.39,152.36 230.52,149.74 230.41,146.57 228.96,145.53 228.95,143.05 226.26,143.7 225.79,142.11 222.31,142.64 215.24,140.63 215.2,142.09 212.85,142.02 208.94,145.25 209.15,148.22 209.46,151.41 Z M 209.46,151.41",
  },
  {
    id: "42",
    title: "湖北",
    fill: "rgb(0, 122, 255)",
    d: "M 233.1,143.92 L 235.74,142.65 239.6,141.86 238.92,139.58 238.02,137.35 239.33,136.33 236.2,134.2 232.59,133.44 228.63,129.22 221.89,129.39 216.71,125.09 213.08,125.33 210.15,125.23 211.3,126.82 213.03,127.89 211.95,128.37 210.1,129.81 210.48,132.59 213.21,135.44 212.7,137.12 206.72,138.59 205.4,141.57 208.94,145.25 212.85,142.02 215.2,142.09 215.24,140.63 222.31,142.64 225.79,142.11 226.26,143.7 228.95,143.05 228.96,145.53 229.76,146.11 230.04,145.87 233.1,143.92 Z M 233.1,143.92",
  },
  {
    id: "41",
    title: "河南",
    fill: "rgb(0, 122, 255)",
    d: "M 238.6,132.28 L 238.66,128.49 237,129.3 234.03,126.45 234.27,125.59 236.17,124.75 236.39,123.61 237.39,123.47 236.99,122.01 237.44,121.09 238.85,121.41 239.71,122.87 241.84,121.97 241.57,120.04 239.85,118.54 239.73,118.45 238.9,118.48 236.96,118.54 235.08,116.39 233.8,115.94 233.89,114.87 239.24,112.06 239.28,110.89 236.16,112.35 236.14,111.59 236.69,110.56 234.22,111.19 228.91,109.6 228.44,113.25 223,115.2 221.37,116.28 214.06,118.42 215.8,122.41 216.71,125.09 221.89,129.39 228.63,129.22 232.59,133.44 236.2,134.2 237.56,132.36 238.6,132.28 Z M 238.6,132.28",
  },
  {
    id: "37",
    title: "山东",
    fill: "rgb(0, 122, 255)",
    d: "M 242.56,102.11 L 238.03,106.39 235.83,108.92 236.69,110.56 236.13,111.59 236.16,112.35 239.28,110.89 239.24,112.06 233.89,114.87 233.8,115.94 235.08,116.39 236.96,118.54 238.89,118.48 240.62,118.42 241,116.93 242.43,116.85 244.38,119.14 248.69,119.18 251.71,116.28 253.34,116.04 254.99,113.51 259.3,110.98 261.38,108.27 267.65,106.84 266.92,104.18 264.06,104.05 260.13,102.08 255.16,105.71 251.65,104.22 251.51,101.95 247.89,101.11 247.68,100.81 245.39,102.02 242.56,102.11 Z M 242.56,102.11",
  },
  {
    id: "36",
    title: "江西",
    fill: "rgb(0, 122, 255)",
    d: "M 241.83,157.89 L 241.44,156.13 244.19,154.07 244.96,151.29 247.25,150.87 249.52,149.12 249.69,148.98 248.34,145.86 248.76,143.92 244.38,141.36 243.94,142.4 241.9,142.76 243,141.25 241.62,140.75 241.09,141.5 239.6,141.87 235.74,142.65 233.1,143.92 230.04,145.87 229.76,146.11 230.41,146.57 230.52,149.74 228.39,152.36 229.52,157.01 231.15,159.39 229.95,162.68 230.26,163.63 232.49,162.86 233.14,164.02 230.89,166.36 232.03,167.24 236.41,165.86 237.75,166.99 238.48,165.13 239.58,160.77 241.83,157.89 Z M 241.83,157.89",
  },
  {
    id: "35",
    title: "福建",
    fill: "rgb(0, 122, 255)",
    d: "M 252.15,153.18 L 249.8,149.23 249.69,148.98 249.52,149.12 247.25,150.87 244.96,151.29 244.19,154.07 241.44,156.13 241.83,157.89 239.58,160.77 238.48,165.13 242.54,166.34 244.12,171.47 244.41,171.29 250.51,166.98 254.62,161.39 258.21,155.18 258.51,154.71 252.15,153.18 Z M 252.15,153.18",
  },
  {
    id: "34",
    fill: "rgb(0, 122, 255)",
    dx: "240",
    dy: "130",
    fontSize: 8,
    name: "安徽",
    title: "安徽",
    d: "M 255.17,135.48 L 251,134.7 251.72,133.14 249.52,131.61 250.82,130.22 250.41,128.41 251.89,128.4 252.69,129.08 253.34,128.31 252.4,126.58 251.27,126.59 251.04,127.76 249.5,127.5 248.64,125.32 247.6,125.28 248.69,122.72 246.68,122.79 245.69,121.13 243.54,120.56 243.26,119.49 240.62,118.42 239.73,118.45 239.85,118.54 241.57,120.04 241.84,121.97 239.71,122.87 238.85,121.41 237.44,121.09 236.99,122.01 237.39,123.47 236.39,123.61 236.17,124.75 234.27,125.59 234.03,126.45 237,129.3 238.66,128.49 238.6,132.28 237.56,132.36 236.2,134.2 239.33,136.33 238.02,137.35 238.92,139.58 239.6,141.86 241.09,141.5 241.62,140.74 243,141.25 241.9,142.76 243.94,142.4 244.38,141.36 248.76,143.92 251.26,141.75 251.83,139.46 254.12,139.21 253.42,138.4 255.17,135.48 Z M 255.17,135.48",
  },
  {
    id: "33",
    title: "浙江",
    fill: "rgb(0, 122, 255)",
    d: "M 259.05,137.3 L 256.4,135.31 255.17,135.48 253.42,138.4 254.12,139.21 251.83,139.46 251.26,141.75 248.76,143.92 248.34,145.86 249.8,149.23 252.15,153.18 258.51,154.71 261.45,150.03 263.94,149.61 265.06,145.82 265.74,141.9 263.13,140.4 262.07,137.83 262.67,137.54 260.78,136.11 259.05,137.3 Z M 259.05,137.3",
  },
  {
    id: "32",
    title: "江苏",
    fill: "rgb(0, 122, 255)",
    d: "M 248.69,119.18 L 244.38,119.14 242.43,116.85 241,116.93 240.62,118.42 243.26,119.49 243.54,120.56 245.69,121.13 246.68,122.79 248.69,122.72 247.6,125.28 248.64,125.31 249.5,127.5 251.04,127.76 251.26,126.59 252.4,126.58 253.34,128.31 252.69,129.08 251.89,128.4 250.41,128.41 250.82,130.22 249.52,131.61 251.72,133.14 251,134.7 255.17,135.48 256.4,135.31 259.05,137.3 260.78,136.11 262.68,133.71 261.6,132.66 262.55,131.93 264.4,132.2 261.93,129.11 259.23,124.61 257.49,119.73 252.71,117 253.34,116.04 251.71,116.28 248.69,119.18 Z M 248.69,119.18",
  },
  {
    id: "31",
    title: "上海",
    fill: "rgb(0, 122, 255)",
    d: "M 261.6,132.66 L 262.68,133.71 260.78,136.11 262.67,137.54 264.85,136.48 264.93,132.87 264.4,132.2 262.55,131.93 261.6,132.66 Z M 261.6,132.66",
  },
  {
    id: "23",
    title: "黑龙江",
    fill: "rgb(0, 122, 255)",
    d: "M 315.74,46.85 L 311.9,49.08 305.17,49.08 303.38,43.76 298.13,39.72 290.42,37.87 288.76,32.18 287.21,28.6 285.55,26.06 282.81,20.09 278.92,17.89 272.28,16.11 266.4,16.27 263.74,16.79 264.83,18.13 262.64,21.09 265.41,22.71 266.57,21.69 271.38,28.9 278.72,27.74 280.53,26.71 283.78,30.55 279.97,37.33 279.95,40.84 276.37,46.51 274.82,46.85 274.53,45.46 270.12,49.14 267.39,51.27 269.62,54.23 272.44,53.46 272.91,54.27 270.1,55.6 270.87,57.39 274.35,57.17 276.29,61.85 282.06,61.46 287.72,63.85 288.08,66.61 290.26,66.86 291.89,69.33 293.23,67.38 294.4,67.66 296.79,72.28 298.34,70.78 302.74,69.43 304.83,72.36 306.17,72.57 306.5,69.34 305.33,64.68 309.14,62.76 314.51,63.72 317.5,58.38 319.02,52.3 320.74,50.25 323.06,45.18 315.74,46.85 Z M 315.74,46.85",
  },
  {
    id: "22",
    title: "吉林",
    fill: "rgb(0, 122, 255)",
    d: "M 302.74,69.43 L 298.34,70.78 296.79,72.28 294.4,67.66 293.23,67.38 291.89,69.33 290.26,66.86 288.08,66.61 287.72,63.85 282.06,61.46 276.29,61.85 274.35,57.17 270.87,57.39 268.82,60.47 264.86,59.16 264.43,60.49 266.02,64.73 267.79,68.43 270.71,66.89 272.23,72.56 273.21,73.59 274.27,72.88 276.4,74.66 276.16,75.65 276.59,76.03 277.43,75.25 278,74.46 279.02,74.16 279.32,77.72 281.15,79.67 280.28,81.25 281,82.15 282.52,84.79 281.93,86.14 282.09,86.36 283.89,85.32 286.94,81.59 289.04,83.24 292.87,83.44 292.17,80.66 299.02,78.37 300.77,75.39 303.63,78.53 303.6,75.83 305.86,75.69 306.17,72.57 304.83,72.36 302.74,69.43 Z M 302.74,69.43",
  },
  {
    id: "21",
    title: "辽宁",
    fill: "rgb(0, 122, 255)",
    d: "M 282.52,84.78 L 281,82.15 280.28,81.25 281.15,79.67 279.32,77.72 279.02,74.16 278,74.46 277.43,75.25 276.59,76.03 276.16,75.65 276.4,74.66 274.27,72.88 273.21,73.59 272.67,75.09 257.17,82.03 254.53,78.35 253.37,79.27 253.37,84.05 251.66,86.76 254.88,88.12 256.06,90.79 259.9,88.02 263.76,86.17 266.1,88.92 262.59,92.41 263.53,94.42 261.17,96.82 265.94,95.41 269.2,93 275.4,91.48 279,88.13 282.09,86.35 281.93,86.14 282.52,84.78 Z M 282.52,84.78",
  },
  {
    id: "15",
    title: "内蒙古",
    fill: "rgb(0, 122, 255)",
    d: "M 157.32,83.27 L 160.18,88.18 161.92,88.09 166.5,86.04 169,87.78 165.5,91.57 171.72,96.05 175.83,97.84 177.18,96.64 176.77,95.34 185.44,93.7 186.62,95.63 184.49,98.33 183.08,102.03 186.49,104.27 188,103.85 193.51,102.28 193.62,100.22 195.23,95.32 198.08,94.22 198.84,95.8 196.78,99.62 203.54,102.96 206.94,102.87 207.22,101.17 212.16,95.14 217.34,93.54 217.43,93.27 217.34,93.54 219.7,92.82 230.38,88.17 229.29,85.18 233.47,79.86 237.42,81.34 243.08,78.25 246.98,77.15 249.49,84.01 253.37,84.05 253.37,79.28 254.53,78.35 257.17,82.02 272.67,75.09 273.21,73.59 272.23,72.56 270.71,66.89 267.79,68.43 266.02,64.73 264.43,60.49 264.86,59.16 268.82,60.47 270.87,57.39 270.1,55.6 272.91,54.27 272.44,53.46 269.62,54.23 267.39,51.27 270.12,49.14 274.53,45.46 274.82,46.85 276.37,46.51 279.95,40.84 279.97,37.33 283.78,30.55 280.53,26.71 278.72,27.74 271.38,28.89 266.57,21.69 265.41,22.71 262.64,21.09 264.83,18.13 263.74,16.79 260.9,17.34 257.23,20.33 259.67,21.75 259.73,25.02 257.26,26.89 253.26,33.1 253.31,35.64 247.07,39.29 241.74,37.13 239.57,41.44 236.44,47.12 237.58,49.43 240.1,48.7 244.47,49.59 247.89,47.5 251.45,49.3 255.46,53.22 254.97,55.19 251.48,54.56 245.04,55.3 241.93,56.88 238.69,60.53 231.92,62.66 227.51,65.55 222.96,64.45 220.46,63.96 218.14,67.46 219.56,69.55 220.27,71.33 217.17,73.15 213.99,76 208.81,77.88 202.17,78.09 195.01,79.92 189.85,82.76 187.89,81.12 182.53,81.12 175.97,77.9 171.59,77.09 165.7,77.85 156.54,76.65 155.75,76.67 158.33,81.61 157.32,83.27 Z M 157.32,83.27",
  },
  {
    id: "14",
    title: "山西",
    fill: "rgb(0, 122, 255)",
    d: "M 219.7,92.82 L 217.34,93.54 214.65,101.45 215.86,103.55 214.26,106.54 215.07,113.28 213.57,116.6 214.06,118.42 221.37,116.28 223,115.2 228.44,113.25 228.91,109.6 227.79,108.18 230.73,102.92 228,99.29 230.3,95.41 232.15,95.02 232.56,93.28 229.71,90.86 231.59,89.26 230.94,86.51 229.29,85.18 230.38,88.17 219.7,92.82 Z M 219.7,92.82",
  },
  {
    id: "13",
    title: "河北",
    fill: "rgb(0, 122, 255)",
    d: "M 251.66,86.76 L 254.88,88.12 256.06,90.79 254.89,91.64 252.16,94.98 248,95.21 247.61,93.13 246.22,93.03 245.88,91.01 246.99,90.87 245.87,89.73 245.15,89.74 244.32,88.32 245.4,87.9 245.66,87.44 243.43,87.3 241.84,85.38 239.93,85.85 240.04,86.8 238.92,87.89 237.91,88.09 238.28,90.19 236.53,90.78 236.69,92.72 238.15,93.46 239.83,93.11 240.52,93.77 242.12,92.92 242.54,92.72 243.16,95.4 242.16,96.46 242.36,97.45 243.92,98.24 245.8,98.01 247.68,100.81 245.39,102.02 242.56,102.11 238.03,106.39 235.83,108.92 236.69,110.56 234.22,111.19 228.91,109.6 227.79,108.18 230.73,102.92 228,99.29 230.3,95.41 232.15,95.02 232.56,93.28 229.71,90.86 231.59,89.26 230.94,86.51 229.29,85.18 233.47,79.86 237.42,81.34 243.08,78.25 246.98,77.15 249.49,84.01 253.37,84.05 251.66,86.76 Z M 242.48,91.43 L 242.48,90.77 244.33,90.49 244.15,91.56 244.32,92.11 244.14,92.86 242.54,92.72 242.99,92.5 242.48,91.43 Z M 242.48,91.43",
  },
  {
    id: "12",
    title: "天津",
    fill: "rgb(0, 122, 255)",
    d: "M 246.22,93.03 L 245.88,91.01 246.99,90.87 245.87,89.73 245.15,89.74 244.33,90.49 244.15,91.56 244.32,92.11 244.14,92.86 242.54,92.72 243.16,95.4 242.16,96.46 242.36,97.45 243.92,98.24 245.8,98.01 245.55,97.64 247.81,95.22 248,95.21 247.61,93.13 246.22,93.03 Z M 246.22,93.03",
  },
  {
    id: "11",
    title: "北京",
    fill: "rgb(0, 122, 255)",
    d: "M 242.54,92.72 L 242.99,92.5 242.48,91.43 242.48,90.77 244.33,90.49 245.15,89.74 244.32,88.32 245.4,87.9 245.66,87.44 243.43,87.3 241.84,85.37 239.93,85.85 240.04,86.8 238.92,87.89 237.91,88.09 238.28,90.19 236.53,90.78 236.69,92.72 238.15,93.46 239.83,93.11 240.52,93.77 242.12,92.92 242.54,92.72 Z M 242.54,92.72",
  },
];

export default pathConfig;
