import React from "react";
import { StyleSheet, Text, View } from "react-native";

interface Props {
  direction?: "row" | "column";
  size?: number;
}

// the space component
const Space: React.FC<Props> = (props) => {
  const spaceStyle =
    props.direction === "row"
      ? { marginLeft: props.size, marginRight: props.size }
      : { marginTop: props.size, marginBottom: props.size };
  return <View style={[styles.space, spaceStyle]}>{props.children}</View>;
};

Space.defaultProps = {
  direction: "row",
  size: 5,
};

export default Space;

const styles = StyleSheet.create({
  space: {},
});
