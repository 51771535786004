import { IEnterpriseTableItem, PAGE_TABLE } from "@/redux/models/enterprise";
import { createActions } from "@/utils";
import { fp, wp } from "@/utils/dimension";
import { aesDecode } from "@/utils/tools";
import { useRoute } from "@react-navigation/native";
import React from "react";
import { StyleSheet } from "react-native";
import { Colors, DataTable, Paragraph } from "react-native-paper";
import { useDispatch } from "react-redux";
import styled from "styled-components/native";

interface Props {}

/// 好评率分布
const FeedbackRateScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const route: any = useRoute();
  const { item } = route.params;
  const { brandPk, type } = JSON.parse(aesDecode(item));
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pages, setPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    dispatch(
      createActions(PAGE_TABLE)({
        brandPk,
        type,
        current: pageIndex + 1,
        size: 10,
      })((data: any) => {
        setData(data.records || []);
        setPages(data.pages);
        setCurrentPage(data.current);
        setTotal(data.total);
      })
    );
  }, [pageIndex]);

  const handleData = (data: Array<IEnterpriseTableItem>) => {
    return data.map((item: IEnterpriseTableItem, index: number) => {
      return (
        <Row key={index}>
          <Cell>
            <CellText>{index + 1}</CellText>
          </Cell>
          <Cell numeric>
            <CellText>{item.name}</CellText>
          </Cell>
          <Cell numeric>
            <CellText>{item.rate}%</CellText>
          </Cell>
        </Row>
      );
    });
  };

  const renderTableRow = React.useMemo(() => handleData(data), [data]);

  return (
    <PageView>
      <Table>
        <Header>
          <Title>
            <HeaderText>排名</HeaderText>
          </Title>
          <Title numeric>
            <HeaderText>{type == "score1" ? "省" : "城市"}</HeaderText>
          </Title>
          <Title numeric>
            <HeaderText>好评率</HeaderText>
          </Title>
        </Header>
        {renderTableRow}

        <Pagination
          page={pageIndex}
          numberOfPages={pages}
          onPageChange={(page) => setPageIndex(page)}
          label={
            <PaginationText>
              {"共" + total + "条数据" + "  " + currentPage + "/" + pages}
            </PaginationText>
          }
        />
      </Table>
    </PageView>
  );
};

export default FeedbackRateScreen;

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
  padding: 10px;
`;
const Table = styled(DataTable)`
  background-color: ${Colors.white};
  border-radius: 5px;
`;
const Header = styled(DataTable.Header)`
  background-color: ${(props: IStyledProps) => props.theme.subPrimary};
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
const Title = styled(DataTable.Title)``;
const Row = styled(DataTable.Row)``;
const Cell = styled(DataTable.Cell)``;
const Pagination = styled(DataTable.Pagination)`
  /* background-color: ${(props: IStyledProps) => props.theme.subPrimary}; */
`;

const HeaderText = styled(Paragraph)`
  font-size: ${fp(3.8)}px;
  font-weight: bold;
`;

const CellText = styled(Paragraph)`
  color: ${(props: IStyledProps) => props.theme.paragraph};
  font-size: ${fp(3.6)}px;
`;
const PaginationText = styled(Paragraph)`
  color: ${(props: IStyledProps) => props.theme.paragraph};
  font-size: ${fp(3.3)}px;
  font-weight: bold;
`;

const styles = StyleSheet.create({});
