import Page from "@/components/page";
import React from "react";
import { Pressable, StyleSheet } from "react-native";
import {
  Colors,
  Paragraph,
  ToggleButton,
  Title,
  Caption,
  DataTable,
  Button,
} from "react-native-paper";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import styled from "styled-components/native";
import {
  AntBarChart,
  AntLineChart,
  Space,
  Toast,
  WaterMark,
} from "@/react-native-papal";
import { wp } from "@/utils/dimension";
import PickerModal from "@/components/picker-modal";
import { exportToPng } from "@/components/export-image";
import { useMount, useSetState } from "@/react-native-ahook";
import {
  GET_ECONOMY_DETAIL,
  GET_ECONOMY_EXCEL,
  IEconomyDetail,
  SWITCH_RANGE_DATE,
} from "@/redux/models/economy";
import { useDispatch, useSelector } from "react-redux";
import { createAction, createActions } from "@/utils";
import { useRoute } from "@react-navigation/core";
import _ from "lodash";
import { TOAST_SHORT } from "@/constants/app";
import { ClipboardSetString, isWechat } from "@/utils/tools";
import Share from "@/components/share";
import { DO_GENERATE_POSTER } from "@/redux/models/app";
import ShareAlert from "@/components/share/share-alert";
import { exportExcel } from "@/utils/excel";
import ColorTable from "@/components/table";
import { ProcessUrl } from "@/utils/image";
import * as Clipboard from "expo-clipboard";

interface Props {}
export const HEADERS = ["时间", "数据"];

/// 经济数据
const EconomyDetailScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const route: any = useRoute();
  const { areaType, areaName, indexName, unit } = route.params;
  const [chartValue, setChartValue] = React.useState("bar");
  const [pickerVisible, setPickerVisible] = React.useState(false);
  const [shareVisible, setShareVisible] = React.useState(false);
  const [downloadShareVisible, setDownloadShareVisible] = React.useState(false);
  const [excelVisible, setExcelVisible] = React.useState(false);
  const waterMarkRef = React.useRef<any>();
  const [downloadImage, setDownloadImage] = React.useState("");

  const {
    economyDetail,
    chartData,
    tableData,
    searchStartDate,
    searchEndDate,
    startDateData,
    endDateData,
    posterImage,
    excelUrl,
  } = useSelector(({ economy, app, loading }: any) => ({
    economyDetail: economy.economyDetail,
    detailLoading: loading.effects[GET_ECONOMY_DETAIL],
    chartData: economy.chartData,
    tableData: economy.tableData,
    searchStartDate: economy.searchStartDate,
    searchEndDate: economy.searchEndDate,
    startDateData: economy.startDateData,
    endDateData: economy.endDateData,
    userInfo: app.userInfo,
    posterImage: app.posterImage,
    excelUrl: economy.excelUrl,
  }));
  useMount(() => {
    dispatch(
      createAction(GET_ECONOMY_DETAIL)({
        areaType: areaType,
        areaName: areaName,
        indexName: indexName,
      })
    );
  });

  const renderTools = () => {
    return (
      <ContentView>
        <SectionTitle>数据呈现</SectionTitle>
        <ToolView>
          <ToolLeftView>
            <ToggleButton.Row
              style={{ marginRight: 10 }}
              onValueChange={(value) => setChartValue(value)}
              value={chartValue}
            >
              <ToggleButton
                icon="chart-bar"
                value="bar"
                color={Colors.grey700}
              />
              <ToggleButton
                icon="chart-line"
                value="line"
                color={Colors.grey700}
              />
              <ToggleButton icon="table" value="table" color={Colors.grey700} />
            </ToggleButton.Row>
            {/* <ToggleButton
              style={{
                borderWidth: StyleSheet.hairlineWidth,
                borderColor: Colors.grey400,
              }}
              icon="numeric"
              value="numeric"
              color={Colors.grey700}
              status={showNum ? "checked" : "unchecked"}
              onPress={() => setShowNum(!showNum)}
            /> */}
          </ToolLeftView>

          <DateSelector onPress={() => setPickerVisible(true)}>
            <MaterialCommunityIcons
              name="calendar-month"
              size={20}
              color={Colors.grey700}
            />
            <ToolText>
              {searchStartDate} - {searchEndDate}
            </ToolText>
            <MaterialCommunityIcons
              name="menu-down"
              size={20}
              color={Colors.grey700}
            />
          </DateSelector>
        </ToolView>
      </ContentView>
    );
  };
  const renderChart = () => {
    let chart;
    switch (chartValue) {
      case "bar":
        chart = renderBarChart();
        break;
      case "line":
        chart = renderLineChart();
        break;
      case "table":
        chart = renderTableChart();
        break;
      default:
        chart = renderBarChart();
        break;
    }
    const firstDetail: any = _.first(economyDetail);
    return (
      <ContentView center ref={waterMarkRef}>
        <ChartTitle>
          {areaName}
          {indexName}
          {unit}
        </ChartTitle>
        <WaterMark watermark="司马诸葛">{chart}</WaterMark>
        <ChartDescView>
          <ChartDesc>数据来源:{firstDetail && firstDetail.source}</ChartDesc>
          <ChartLogoCaption>©司马诸葛</ChartLogoCaption>
        </ChartDescView>
      </ContentView>
    );
  };

  const onDownloadImage = async () => {
    const result: any = await exportToPng(
      waterMarkRef,
      areaName + indexName + ".png"
    );
    if (isWechat()) {
      if (chartValue == "table") {
        Toast.info("图片过长,请到电脑端下载", TOAST_SHORT);
        return;
      }
      setDownloadImage(result);
      setDownloadShareVisible(true);

      // dispatch(
      //   createActions(DO_GENERATE_POSTER)({
      //     poster: result,
      //     posterType: "economy",
      //   })(() => {
      //     setShareVisible(true);
      //   })
      // );
    }
  };

  const onSharePressed = async () => {
    const result = await exportToPng(
      waterMarkRef,
      areaName + indexName + ".png"
    );
    if (isWechat()) {
      dispatch(
        createActions(DO_GENERATE_POSTER)({
          poster: result,
          posterType: "economy",
        })(() => {
          setShareVisible(true);
        })
      );
    }
  };

  const renderDownload = () => {
    const headers = [
      {
        title: "年",
        dataIndex: "year",
        key: "year",
      },
      {
        title: "月",
        dataIndex: "month",
        key: "month",
      },
      {
        title: "日",
        dataIndex: "day",
        key: "day",
      },
      {
        title: "数据",
        dataIndex: "value",
        key: "value",
      },
    ];
    const title = areaName + indexName;
    const tableExcel = tableData.map((item: IEconomyDetail, index: number) => {
      return {
        year: item.year,
        month: item.month,
        day: item.day,
        value: item.value,
      };
    });
    return (
      <ContentView>
        <SectionTitle>下载</SectionTitle>
        <DownloadView>
          <Button
            icon="image"
            mode="outlined"
            onPress={() => onDownloadImage()}
          >
            下载图片
          </Button>
          <Space />
          <Button
            icon="microsoft-excel"
            mode="outlined"
            onPress={() => {
              if (isWechat()) {
                dispatch(
                  createActions(GET_ECONOMY_EXCEL)({
                    title: title,
                    excelData: tableExcel,
                  })(() => {
                    setExcelVisible(true);
                  })
                );
              } else {
                exportExcel(headers, tableExcel, title, title + ".xlsx");
              }
            }}
          >
            下载Excel
          </Button>
        </DownloadView>
      </ContentView>
    );
  };

  const renderFooter = () => {
    return (
      <FooterView>
        <FooterButton onPress={() => Toast.info("即将上线", TOAST_SHORT)}>
          <FooterText>对比</FooterText>
        </FooterButton>
        <FooterButton color="#0b202f86" onPress={() => onSharePressed()}>
          <FooterText>分享</FooterText>
        </FooterButton>
      </FooterView>
    );
  };

  const renderBarChart = () => {
    return <AntBarChart data={chartData} />;
  };
  const renderLineChart = () => {
    return <AntLineChart data={chartData} />;
  };

  const renderTableChart = () => {
    const TABLE_DATA = tableData.map((item: IEconomyDetail, index: number) => {
      return [item.year, item.value];
    });
    return (
      <ColorTable
        tableStyle={{ width: wp(100) - 56, marginTop: 0, marginBottom: 6 }}
        headers={HEADERS}
        data={TABLE_DATA}
      />
    );
  };
  // the modal
  const renderModal = () => {
    return (
      <PickerModal
        data={[startDateData, endDateData]}
        visible={pickerVisible}
        onClose={() => setPickerVisible(false)}
        onCancel={() => setPickerVisible(false)}
        onConfirm={(minDate, maxDate) => {
          if (minDate.split("-").length != maxDate.split("-").length) {
            Toast.info("日期格式不正确", TOAST_SHORT);
            return;
          }
          if (new Date(minDate).getTime() > new Date(maxDate).getTime()) {
            Toast.info("开始日期必须小于结束日期", TOAST_SHORT);
            return;
          }
          dispatch(
            createAction(SWITCH_RANGE_DATE)({
              minDate: minDate,
              maxDate: maxDate,
            })
          );
          setPickerVisible(false);
        }}
      />
    );
  };

  // render the share image
  const renderShareImage = () => {
    return (
      <ShareImage source={{ uri: "data:image/png;base64," + posterImage }} />
    );
  };

  const renderDownloadShareImage = () => {
    return (
      <DownloadImage source={{ uri: downloadImage }} resizeMode="contain" />
    );
  };

  return (
    <Page>
      <PageView>
        {renderTools()}
        {renderChart()}
        {renderDownload()}
      </PageView>
      {renderFooter()}
      <Share
        visible={shareVisible}
        content={renderShareImage()}
        onClose={() => setShareVisible(false)}
      />
      <Share
        visible={downloadShareVisible}
        content={renderDownloadShareImage()}
        onClose={() => setDownloadShareVisible(false)}
      />
      <ShareAlert
        headTitle="Excel表下载链接复制成功"
        title="你可打开手机浏览器，粘贴链接下载"
        visible={excelVisible}
        onClose={() => {
          Clipboard.setString(ProcessUrl(excelUrl));
          setExcelVisible(false);
        }}
      />
      {renderModal()}
    </Page>
  );
};

export default EconomyDetailScreen;

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
  padding-bottom: 12px;
`;
const ContentView = styled.View.attrs((props: any) => ({
  center: props.center,
}))`
  background-color: ${(props: IStyledProps) => props.theme.background};
  padding: 16px;
  margin: 12px 12px 0 12px;
  border-radius: 5px;
  align-items: ${(props) => (props.center ? "center" : "null")};
`;

const SectionTitle = styled(Title)`
  font-size: 16px;
`;
const ToolView = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;
const ToolLeftView = styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;
const ToolText = styled(Paragraph)`
  font-size: 14px;
  padding-left: 4px;
  padding-right: 4px;
  color: ${Colors.grey700};
`;
const DateSelector = styled.Pressable`
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px;
  border-width: ${StyleSheet.hairlineWidth}px;
  border-color: ${Colors.grey400};
  height: 42px;
`;

const ChartTitle = styled(Title)`
  font-size: 18px;
`;
const ChartDescView = styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const ChartDesc = styled(Caption)`
  font-size: 13px;
  flex: 1;
`;
const ChartLogoCaption = styled(Caption)`
  font-size: 13px;
`;

const DownloadView = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

const FooterView = styled.View`
  flex-direction: row;
  height: 48px;
  align-items: center;
`;
const FooterButton = styled(Pressable).attrs((props: any) => ({
  color: props.color || "#60c184",
}))`
  width: 50%;
  background-color: ${(props) => props.color};
  height: 48px;
  justify-content: center;
  align-items: center;
`;
const FooterText = styled(Paragraph)`
  font-size: 16px;
  color: ${Colors.white};
`;
const Table = styled(DataTable)`
  width: ${wp(100) - 56};
`;
const TableTitle = styled(DataTable.Title)`
  justify-content: center;
`;
const TableCell = styled(DataTable.Cell)`
  justify-content: center;
`;
const ShareImage = styled.Image`
  width: ${wp(70)}px;
  height: ${(wp(70) * 2016) / 1280}px;
  margin-left: ${wp(15)}px;
  margin-right: ${wp(15)}px;
`;

const SureButton = styled(Button)`
  background-color: ${Colors.blue500};
  margin-top: 12px;
`;

const DownloadImage = styled.Image`
  /* width: ${wp(70)}px; */
  height: ${wp(70)}px;
  margin-left: ${wp(15)}px;
  margin-right: ${wp(15)}px;
`;

const styles = StyleSheet.create({});
