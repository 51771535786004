import { fp, wp } from "@/utils/dimension";
import { ProcessUrl } from "@/utils/image";
import { parseNullOrZero } from "@/utils/tools";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  item: ICategoryItem;
  onPress: () => void;
}

export interface ICategoryItem {
  pk: string;
  mcount: string;
  logo: string;
  bname: string;
  avgScore: string;
  avgPrice: string;
}

/// 品类列表项
const CategoryItem: React.FC<Props> = (props) => {
  return (
    <ItemView style={styles.item} onPress={props.onPress}>
      <ItemImage source={{ uri: ProcessUrl(props.item.logo) }} />
      <ContentView>
        <TitleText numberOfLines={1} ellipsizeMode="tail">
          {props.item.bname}
        </TitleText>
        <TextView>
          <CaptionText>品牌门店:</CaptionText>
          <RedText>
            {props.item.mcount}
            <CaptionText>家</CaptionText>
          </RedText>
        </TextView>
        <CaptionView>
          <CaptionText>客单价:</CaptionText>
          <RedText>{parseNullOrZero(props.item.avgPrice, "元")}</RedText>
          <CaptionText>好评率:</CaptionText>
          <RedText>{parseNullOrZero(props.item.avgScore, "%")}</RedText>
        </CaptionView>
      </ContentView>
    </ItemView>
  );
};

export default React.memo(CategoryItem);

const ItemView = styled.Pressable`
  margin: 0px 12px 12px 12px;
  background-color: ${Colors.white};
  padding: 20px 12px 20px 12px;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
`;
const ItemImage = styled.Image`
  width: ${wp(26)}px;
  height: ${wp(26)}px;
  background-color: ${Colors.grey300};
`;
const ContentView = styled.View`
  margin-left: 12px;
  justify-content: center;
`;
const TitleText = styled(Paragraph)`
  width: ${wp(59)}px;
  font-size: ${fp(4.3)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const TextView = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: ${wp(0.5)}px;
`;
const CaptionView = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: ${wp(3)}px;
`;
const CaptionText = styled(Paragraph)`
  font-size: ${fp(3.7)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;
const RedText = styled(Paragraph)`
  font-size: ${fp(3.7)}px;
  color: ${(props: IStyledProps) => props.theme.redText};
  margin-right: ${fp(3)}px;
`;

const styles = StyleSheet.create({
  item: {
    marginVertical: 12,
  },
});
