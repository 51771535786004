import Page from "@/components/page";
import { TOAST_SHORT } from "@/constants/app";
import { useCountDown } from "@/react-native-ahook";
import { Toast } from "@/react-native-papal";
import { BIND_MOBILE, GET_SMS_CAPTCHA } from "@/redux/models/account";
import { createAction, createActions } from "@/utils";
import { fp, wp } from "@/utils/dimension";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Platform, View } from "react-native";
import { Button, Colors, Paragraph } from "react-native-paper";
import { useDispatch } from "react-redux";
import styled from "styled-components/native";

interface Props {}

/// bind mobile
const MobileBindScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [phoneNum, setPhoneNum] = React.useState("");
  const [captcha, setCaptcha] = React.useState("");
  const onEnd = () => {
    // console.log("onEnd of the time");
  };
  const [countdown, setTargetDate] = useCountDown({ onEnd: onEnd });

  const onGetCaptcha = () => {
    if (!phoneNum || (phoneNum && phoneNum.length < 11)) {
      Toast.info("手机号为11位", TOAST_SHORT);
      return;
    }
    dispatch(
      createActions(GET_SMS_CAPTCHA)({ mobile: phoneNum })(() => {
        setTargetDate(Date.now() + 60000);
      })
    );
  };

  const onBindAction = () => {
    if (!phoneNum || (phoneNum && phoneNum.length < 11)) {
      Toast.info("手机号为11位", TOAST_SHORT);
      return;
    }
    if (!captcha || (captcha && captcha.length < 6)) {
      Toast.info("验证码为6位", TOAST_SHORT);
      return;
    }
    // 提交
    dispatch(
      createActions(BIND_MOBILE)({ account: phoneNum, captcha: captcha })(
        () => {
          Toast.info("绑定成功", TOAST_SHORT);
          navigation.goBack();
        }
      )
    );
  };

  return (
    <PageView>
      <HeaderImage
        source={require("@/assets/images/common/me_bind_mobile_header.png")}
      />
      {/* <DescText>为保障您的账号安全，请绑定验证手机号</DescText> */}
      <InputView>
        <LeftView>
          <MenuText>+86</MenuText>
          <Ionicons name="caret-down" size={14} color={Colors.grey700} />
        </LeftView>
        <MobileInput
          placeholder="输入手机号"
          maxLength={11}
          keyboardType="numeric"
          // @ts-ignore
          style={Platform.select({
            web: {
              outlineStyle: "none",
            },
          })}
          onChange={({ nativeEvent: { text } }) => setPhoneNum(text)}
        />
      </InputView>
      <InputView>
        <LeftView>
          <MenuText>验证码</MenuText>
        </LeftView>
        <CaptchaInput
          placeholder="6位验证码"
          maxLength={6}
          keyboardType="numeric"
          // @ts-ignore
          style={Platform.select({
            web: {
              outlineStyle: "none",
            },
          })}
          onChange={({ nativeEvent: { text } }) => setCaptcha(text)}
        />
        <CaptchaButton
          mode="contained"
          disabled={countdown != 0}
          onPress={() => onGetCaptcha()}
        >
          {countdown == 0 ? "获取验证码" : Math.floor(countdown / 1000) + "s"}
        </CaptchaButton>
      </InputView>

      <SureButton mode="contained" onPress={() => onBindAction()}>
        完成绑定
      </SureButton>
      <FooterImage
        source={require("@/assets/images/common/me_bind_mobile_footer.png")}
      />
    </PageView>
  );
};

export default MobileBindScreen;

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
  align-items: center;
`;
const DescText = styled(Paragraph)`
  font-size: ${fp(4.2)}px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const InputView = styled.View`
  width: ${wp(93)}px;

  flex-direction: row;
  /* align-items: center; */
  /* justify-content: center; */
  margin-top: 16px;
`;
const MobileInput = styled.TextInput`
  height: 40px;
  flex: 1;
  border-radius: 0px;
  background-color: ${Colors.grey200};
`;
const CaptchaInput = styled.TextInput`
  width: ${wp(36)}px;
  height: 40px;
  border-radius: 0px;
  background-color: ${Colors.grey200};
`;
const CaptchaButton = styled(Button)`
  border-radius: 0px;
  margin-left: 8px;
`;
const LeftView = styled.Pressable`
  flex-direction: row;
  align-items: center;
  background-color: ${Colors.grey200};
  height: 40px;
  padding-right: 12px;
  padding-left: 12px;
`;
const MenuText = styled.Text`
  font-size: 14px;
  color: ${Colors.grey700};
`;
const SureButton = styled(Button)`
  margin-top: 34px;
  border-radius: 0px;
  width: ${wp(93)}px;
`;
const HeaderImage = styled.Image`
  width: ${wp(56)}px;
  height: ${(wp(56) * 720) / 723}px;
  margin-top: ${wp(14)}px;
  margin-bottom: ${wp(4)}px;
`;
const FooterImage = styled.Image`
  width: ${wp(54)}px;
  height: ${(wp(54) * 309) / 819}px;
  /* position: sticky; */
  /* bottom: ${wp(6)}px; */
  margin-top: ${wp(20)}px;
`;

const styles = StyleSheet.create({});
