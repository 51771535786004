import { OSS_BASE_URLS } from "@/constants/app";
import { isUrl } from "./tools";

/**
 * 处理url自动加上http或https
 * @param url
 * @returns
 */
export const ProcessUrl: any = (url?: string) => {
  if (url && url.startsWith("data:image")) {
    return url;
  }
  if (url && !isUrl(url)) {
    const num = Math.floor(Math.random() * OSS_BASE_URLS.length);
    return OSS_BASE_URLS[num] + url;
  }

  return url;
};
