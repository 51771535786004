import { useMount } from "@/react-native-ahook";
import { fp, wp } from "@/utils/dimension";
import { uuid } from "@/utils/uuid";
import React from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  title?: string;
  selected?: boolean;
  onPress?: () => void;
}

///标签
const TradeTag: React.FC<Props> = React.memo((props) => {
  return (
    <TagView onPress={props.onPress}>
      <TagText selected={props.selected!}>{props.title}</TagText>
    </TagView>
  );
});
TradeTag.defaultProps = {
  selected: false,
};

interface TagProps {
  data: Array<any>;
  defaultValue?: string;
  onPress?: (item: any) => void;
}

const TradeTagSelector: React.FC<TagProps> = (props) => {
  const [selectKey, setSelectKey] = React.useState(props.defaultValue);

  React.useEffect(() => {
    setSelectKey(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <HeaderGrid
      data={props.data}
      numColumns={4}
      renderItem={({ item }: any) => (
        <TradeTag
          key={uuid()}
          title={item.name}
          selected={selectKey == item.pk}
          onPress={() => {
            setSelectKey(selectKey == item.pk ? "" : item.pk);
            props.onPress && props.onPress(item);
          }}
        />
      )}
    />
  );
};
TradeTagSelector.defaultProps = {
  data: [],
};
export default React.memo(TradeTagSelector);
const HeaderGrid = styled(FlatList)``;
const TagView = styled.Pressable`
  width: ${(wp(100) - 24) / 4}px;
  justify-content: center;
  align-items: center;
  margin: 1px 0 6px 0;
`;
const TagText = styled(Paragraph).attrs((props: any) => ({
  selected: props.selected || false,
}))`
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${fp(3.3)}px;
  padding: 2px 1px 2px 1px;
  width: ${wp(20)}px;
  color: ${(props) =>
    props.selected ? props.theme.primary : props.theme.paragraph};
  border-width: ${StyleSheet.hairlineWidth};
  border-color: ${(props) =>
    props.selected ? props.theme.primary : "#d4dbe9"};
  border-radius: 3px;
`;

const styles = StyleSheet.create({});
