import { StatusBar } from "expo-status-bar";
import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider as PaperProvider } from "react-native-paper";
import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";
import { DefaultTheme } from "./constants/theme";
import { ThemeProvider } from "styled-components/native";
import { Provider as PapalProvider } from "@/react-native-papal";
import { Provider } from "react-redux";
import store from "./redux/store";

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <ThemeProvider
        // theme={colorScheme === "dark" ? DarkTheme.colors : DefaultTheme.colors}
        theme={DefaultTheme.colors}
      >
        <PaperProvider theme={DefaultTheme}>
          <PapalProvider>
            <Provider store={store}>
              <SafeAreaProvider>
                {/* <SafeAreaProvider style={{ maxWidth: 800 }}> */}

                <Navigation theme={DefaultTheme} />
                <StatusBar style="dark" />
              </SafeAreaProvider>
            </Provider>
          </PapalProvider>
        </PaperProvider>
      </ThemeProvider>
    );
  }
}
