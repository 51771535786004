import React, { useImperativeHandle } from "react";
import {
  StyleSheet,
  TextStyle,
  useWindowDimensions,
  ViewStyle,
} from "react-native";
import styled from "styled-components/native";

interface Props {
  foreground?: boolean;
  style?: ViewStyle;
  watermark?: string;
  itemWidth?: number;
  itemHeight?: number;
  watermarkTextStyle?: TextStyle;
  rotateZ?: number;
}

const WaterMark: React.FC<Props> = (props) => {
  const { width, height } = useWindowDimensions();

  const renderWatermark = () => {
    const { watermark, itemWidth, itemHeight, watermarkTextStyle, rotateZ } =
      props;
    if (
      typeof watermark != "string" ||
      (typeof watermark == "string" && watermark.length < 1)
    ) {
      return null;
    }
    if (
      typeof watermark != "string" ||
      (typeof watermark == "string" && watermark.length < 1)
    ) {
      return null;
    }
    const items = new Array();
    for (
      let index = 0;
      index < (width / itemWidth!) * (height / itemHeight!);
      index++
    ) {
      const item = (
        <MarkItemView
          key={"watermark_item" + index}
          style={[
            {
              width: itemWidth,
              height: itemHeight,
              transform: [{ rotateZ: rotateZ + "deg" }],
            },
          ]}
        >
          <MarkText style={[watermarkTextStyle]}>{watermark}</MarkText>
        </MarkItemView>
      );
      items.push(item);
    }

    return <MarkView pointerEvents={"none"}>{items}</MarkView>;
  };
  return (
    <WaterMarkView>
      {props.foreground ? renderWatermark() : null}
      {props.children}
      {!props.foreground ? renderWatermark() : null}
    </WaterMarkView>
  );
};

WaterMark.defaultProps = {
  watermark: "",
  itemWidth: 160,
  itemHeight: 100,
  rotateZ: -45,
  foreground: false,
};

export default WaterMark;

const WaterMarkView = styled.View`
  /* flex: 1; */
  overflow: hidden;
`;
const MarkView = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
`;
const MarkItemView = styled.View`
  justify-content: center;
  align-items: center;
`;
const MarkText = styled.Text`
  font-size: 20px;
  color: rgba(0, 0, 0, 0.1);
`;
const styles = StyleSheet.create({});
