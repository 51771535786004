import {
  GENERATE_ENTERPRISE_POSTER,
  GET_CATEGORY_DETAIL,
  IEnterpriseTableItem,
} from "@/redux/models/enterprise";
import { createAction, createActions } from "@/utils";
import { fp, wp } from "@/utils/dimension";
import { aesDecode, aesEncode, parseNullOrZero } from "@/utils/tools";
import {
  useNavigation,
  useRoute,
  StackActions,
} from "@react-navigation/native";
import React, { useCallback } from "react";
import { FlatList, Pressable, StyleSheet, View } from "react-native";
import { Caption, Colors, Paragraph, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";
import { Ionicons } from "@expo/vector-icons";
import AlertInfo from "@/components/alert/alert-info";
import CardInfo from "@/components/card/card-info";
import ShopItem from "./widgets/shop-item";
import ChinaMap from "@/react-native-chinamap";
import {
  PieChart,
  Row,
  Rows,
  Table,
  Toast,
  WaterMark,
} from "@/react-native-papal";
import dayjs from "dayjs";
import { ProcessUrl } from "@/utils/image";
import RuleModal from "@/components/rule-modal";
import Share from "@/components/share";
import { exportToPng } from "@/components/export-image";

const MapData = {
  legend: [
    { gradation: 1, color: "rgba(0, 122, 255, 0.1)", min: "0", max: "50" },
    { gradation: 2, color: "rgba(0, 122, 255, 0.2)", min: "51", max: "100" },
    { gradation: 3, color: "rgba(0, 122, 255, 0.3)", min: "101", max: "2百" },
    { gradation: 4, color: "rgba(0, 122, 255, 0.4)", min: "201", max: "5百" },
    { gradation: 5, color: "rgba(0, 122, 255, 0.5)", min: "501", max: "1千" },
    { gradation: 6, color: "rgba(0, 122, 255, 0.6)", min: "1千", max: "2千" },
    { gradation: 7, color: "rgba(0, 122, 255,0.7)", min: "2千", max: "5千" },
    { gradation: 8, color: "rgba(0, 122, 255,0.8)", min: "5千", max: "7千" },
    { gradation: 9, color: "rgba(0, 122, 255,0.9)", min: "7千", max: "9千" },
    { gradation: 10, color: "rgba(0, 122, 255,1)", min: "1万", max: "1万+" },
  ],
};

interface Props {}
/// 详细页
const EnterpriseDetailScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const route: any = useRoute();
  const navigation = useNavigation();
  const [ruleVisible, setRuleVisible] = React.useState(false);
  const [headerAlertVisible, setHeaderAlertVisible] = React.useState(false);
  const [shareVisible, setShareVisible] = React.useState(false);
  const nationMapRef = React.useRef<any>();

  const { items } = route.params;
  const { enterpriseDetail, posterImage } = useSelector(
    ({ enterprise, loading }: any) => ({
      enterpriseDetail: enterprise.enterpriseDetail,
      posterImage: enterprise.posterImage,
    })
  );
  const { pk } = JSON.parse(aesDecode(items));

  const {
    brand,
    nationalMaps,
    sameBrandMap,
    cityInfoMap,
    cityLevelMap,
    priceCityMap,
    priceProvinceMap,
    rateCityMap,
    rateProvinceMap,
  } = enterpriseDetail;

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: brand.bname,
    });
  }, [navigation, brand.bname]);

  React.useEffect(() => {
    // 获取详情
    pk && dispatch(createAction(GET_CATEGORY_DETAIL)({ brandPk: pk }));
  }, [pk]);

  /// 头部
  const renderHeader = () => {
    return (
      <HeaderView>
        <HeaderLogo source={{ uri: brand ? ProcessUrl(brand.logo) : "" }} />
        <HeaderTitleView>
          <HeaderTitle numberOfLines={1} ellipsizeMode="tail">
            {brand.bname}
          </HeaderTitle>
          {brand && (
            <CategoryChip>
              <CategoryText>{brand.category}</CategoryText>
            </CategoryChip>
          )}
          <DateView>
            {brand && brand.updTime && (
              <DateChip>
                <Ionicons name="sync" color="#f8b080" size={wp(3.2)} />
                <DateText>
                  {dayjs(brand.updTime).format("YYYY月MM年DD日")} 更新
                </DateText>
              </DateChip>
            )}
            <Ion
              name="help-circle-outline"
              color="#929ba1"
              size={wp(5)}
              onPress={() => setHeaderAlertVisible(true)}
            />
          </DateView>
        </HeaderTitleView>
      </HeaderView>
    );
  };

  const renderShopInfo = () => {
    const shopData = [
      {
        title: "现有门店",
        value: parseNullOrZero(brand.mcount, "家"),
      },
      {
        title: "客单价",
        value: parseNullOrZero(parseFloat(brand.avgPrice), "元"),
      },
      {
        title: "好评率",
        value: parseNullOrZero(parseFloat(brand.avgScore), "%"),
      },
    ];
    return <InfoCard title="门店概况" content={<ShopItem data={shopData} />} />;
  };

  // 全国门店分布
  const renderNationInfo = () => {
    return (
      <InfoCard
        title="全国门店分布"
        moreText="查看全部数据"
        content={
          <View ref={nationMapRef}>
            <ChinaMap
              fillRule="evenodd"
              width={wp(90)}
              mapData={nationalMaps || []}
              legend={MapData.legend}
              showShadow={false}
              showLegend={true}
              backgroundColor={"#fff"}
              stroke="#dbdbdf"
            />
          </View>
        }
        onMoreTextPressed={() =>
          navigation.navigate("NationInfo", {
            item: aesEncode(
              JSON.stringify({ brandPk: pk, areaPk: 1, type: 0 })
            ),
          })
        }
      />
    );
  };

  // 城市门店分布
  const renderCityInfo = () => {
    const cityData = cityInfoMap
      ? cityInfoMap.map((city: IEnterpriseTableItem, index: number) => {
          return [
            index + 1,
            city.name,
            city.count,
            parseFloat(city.rate) + "%",
          ];
        })
      : [];
    return (
      <InfoCard
        title="城市门店分布"
        moreText="查看全部数据"
        content={
          <Table
            style={styles.table}
            borderStyle={{
              borderWidth: StyleSheet.hairlineWidth,
              borderColor: Colors.grey100,
            }}
          >
            <Row
              data={["排名", "城市", "门店数", "占比"]}
              style={styles.head}
              textStyle={styles.headText}
            />
            <Rows data={cityData} textStyle={styles.text} />
          </Table>
        }
        onMoreTextPressed={() =>
          navigation.navigate("CityInfo", {
            item: aesEncode(JSON.stringify({ brandPk: pk })),
          })
        }
      />
    );
  };

  // 城市类型分布
  const renderCityType = () => {
    const pieData = cityLevelMap
      ? cityLevelMap.map((city: IEnterpriseTableItem, index: number) => {
          return {
            const: "const",
            type: city.name,
            count: parseFloat(city.rate),
          };
        })
      : [];
    const cityData = cityLevelMap
      ? cityLevelMap.map((city: IEnterpriseTableItem, index: number) => {
          return [city.name, city.count, parseFloat(city.rate) + "%"];
        })
      : [];
    return (
      <InfoCard
        title="城市类型分布"
        titleExtended={
          <Ionicons
            name="md-information-circle-outline"
            size={fp(5.5)}
            color={theme.colors.caption}
            onPress={() => setRuleVisible(true)}
          />
        }
        content={
          <ContentView>
            <PieChart data={pieData || []} />
            <Table
              style={styles.table}
              borderStyle={{
                borderWidth: StyleSheet.hairlineWidth,
                borderColor: Colors.grey100,
              }}
            >
              <Row
                data={["城市", "门店数", "覆盖度"]}
                style={styles.head}
                textStyle={styles.headText}
              />
              <Rows data={cityData} textStyle={styles.text} />
            </Table>
          </ContentView>
        }
      />
    );
  };

  // 客单价分布(省)
  const renderPerCustomerTransaction = () => {
    const cityData = priceProvinceMap
      ? priceProvinceMap.map((city: IEnterpriseTableItem, index: number) => {
          return [index + 1, city.name, parseFloat(city.rate)];
        })
      : [];
    return (
      <InfoCard
        title="客单价分布(省)"
        moreText="查看全部数据"
        content={
          <ContentView>
            <Table
              style={styles.table}
              borderStyle={{
                borderWidth: StyleSheet.hairlineWidth,
                borderColor: Colors.grey100,
              }}
            >
              <Row
                data={["排名", "省", "客单价(元)"]}
                style={styles.head}
                textStyle={styles.headText}
              />
              <Rows data={cityData || []} textStyle={styles.text} />
            </Table>
          </ContentView>
        }
        onMoreTextPressed={() =>
          navigation.navigate("PerCustomer", {
            item: aesEncode(JSON.stringify({ brandPk: pk, type: "price1" })),
          })
        }
      />
    );
  };

  // 客单价分布(市)
  const renderCityPerCustomerTransaction = () => {
    const cityData = priceCityMap
      ? priceCityMap.map((city: IEnterpriseTableItem, index: number) => {
          return [index + 1, city.name, parseFloat(city.rate)];
        })
      : [];
    return (
      <InfoCard
        title="客单价分布(市)"
        moreText="查看全部数据"
        content={
          <ContentView>
            <Table
              style={styles.table}
              borderStyle={{
                borderWidth: StyleSheet.hairlineWidth,
                borderColor: Colors.grey100,
              }}
            >
              <Row
                data={["排名", "市", "客单价(元)"]}
                style={styles.head}
                textStyle={styles.headText}
              />
              <Rows data={cityData || []} textStyle={styles.text} />
            </Table>
          </ContentView>
        }
        onMoreTextPressed={() =>
          navigation.navigate("PerCustomer", {
            item: aesEncode(JSON.stringify({ brandPk: pk, type: "price2" })),
          })
        }
      />
    );
  };

  // 好评率分布(省)
  const renderProvinceFeedbackRate = () => {
    const cityData = rateProvinceMap
      ? rateProvinceMap.map((city: IEnterpriseTableItem, index: number) => {
          return [index + 1, city.name, parseFloat(city.rate) + "%"];
        })
      : [];
    return (
      <InfoCard
        title="好评率分布(省)"
        moreText="查看全部数据"
        content={
          <ContentView>
            <Table
              style={styles.table}
              borderStyle={{
                borderWidth: StyleSheet.hairlineWidth,
                borderColor: Colors.grey100,
              }}
            >
              <Row
                data={["排名", "省", "好评率"]}
                style={styles.head}
                textStyle={styles.headText}
              />
              <Rows data={cityData || []} textStyle={styles.text} />
            </Table>
          </ContentView>
        }
        onMoreTextPressed={() =>
          navigation.navigate("FeedbackRate", {
            item: aesEncode(JSON.stringify({ brandPk: pk, type: "score1" })),
          })
        }
      />
    );
  };

  // 好评率分布(市)
  const renderCityFeedbackRate = () => {
    const cityData = rateCityMap
      ? rateCityMap.map((city: IEnterpriseTableItem, index: number) => {
          return [index + 1, city.name, parseFloat(city.rate) + "%"];
        })
      : [];
    return (
      <InfoCard
        title="好评率分布(市)"
        moreText="查看全部数据"
        content={
          <ContentView>
            <Table
              style={styles.table}
              borderStyle={{
                borderWidth: StyleSheet.hairlineWidth,
                borderColor: Colors.grey100,
              }}
            >
              <Row
                data={["排名", "市", "好评率"]}
                style={styles.head}
                textStyle={styles.headText}
              />
              <Rows data={cityData || []} textStyle={styles.text} />
            </Table>
          </ContentView>
        }
        onMoreTextPressed={() =>
          navigation.navigate("FeedbackRate", {
            item: aesEncode(JSON.stringify({ brandPk: pk, type: "score2" })),
          })
        }
      />
    );
  };

  const renderItem = React.useCallback(({ item }) => {
    return (
      <GridView
        onPress={() =>
          navigation.dispatch(
            StackActions.push("EnterpriseDetail", {
              items: aesEncode(JSON.stringify(item)),
            })
          )
        }
      >
        <BrandImage source={{ uri: ProcessUrl(item.logo) }} />
        <BrandText numberOfLines={1}>{item.bname}</BrandText>
      </GridView>
    );
  }, []);
  const keyExtractor = React.useCallback((item) => item.pk, []);

  // 类似品牌
  const renderSameBrand = () => {
    return (
      <InfoCard
        title="类似品牌"
        content={
          <FlatList
            numColumns={3}
            data={sameBrandMap || []}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
          />
        }
      />
    );
  };

  const renderFooter = () => {
    return (
      <FooterView>
        <FooterButton>
          <FooterText onPress={() => Toast.info("即将上线...")}>
            对比
          </FooterText>
        </FooterButton>
        <FooterButton
          color="#0b202f86"
          onPress={() => onSharePosterPressed(brand!.bname, brand!.mcount)}
        >
          <FooterText>分享</FooterText>
        </FooterButton>
      </FooterView>
    );
  };

  // render the share image
  const renderShareImage = () => {
    return (
      <ShareImage source={{ uri: "data:image/png;base64," + posterImage }} />
    );
  };

  const onSharePosterPressed = useCallback(
    async (bname = "--", mcount = "--") => {
      const image = await exportToPng(nationMapRef, "enterpriseMap.png");

      dispatch(
        createActions(GENERATE_ENTERPRISE_POSTER)({
          title: bname + "," + mcount,
          posterType: "enterprise",
          poster: image,
        })(() => {
          setShareVisible(true);
        })
      );
    },
    []
  );

  return (
    <PageView>
      <ScView>
        {renderHeader()}
        {renderShopInfo()}
        {renderNationInfo()}
        {renderCityInfo()}
        {renderCityType()}
        {renderPerCustomerTransaction()}
        {renderCityPerCustomerTransaction()}
        {renderProvinceFeedbackRate()}
        {renderCityFeedbackRate()}
        <RemindView>
          <RemindText>此品牌更多数据，逐渐更新中...</RemindText>
        </RemindView>
        {renderSameBrand()}
      </ScView>
      {renderFooter()}
      <AlertInfo
        visible={headerAlertVisible}
        title="数据说明"
        sureText="我知道了"
        onClose={() => setHeaderAlertVisible(false)}
        content={
          <ParagraphText>
            {/* {"\n"} */}
            品牌数据均来自于网络公开信息整理而成，包括但不限于国家工商局、商标局、统计局、品牌官网、招商信息平台、及本地生活服务平台等。通过大数据建模测试匹配，仅供用户作为宏观了解参考，绝对精确数据以官方实际为准
          </ParagraphText>
        }
      />
      <RuleModal
        visible={ruleVisible}
        title="城市等级说明"
        content={
          <ContentText>
            城市类别参考第一财经·新一线城市研究所发布的《2021城市商业魅力排行榜》
          </ContentText>
        }
        onClose={() => setRuleVisible(false)}
      />
      <Share
        visible={shareVisible}
        content={renderShareImage()}
        onClose={() => setShareVisible(false)}
      />
    </PageView>
  );
};

export default EnterpriseDetailScreen;

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;
const ScView = styled.ScrollView`
  /* flex: 1; */
  background-color: ${(props: IStyledProps) => props.theme.page};
`;

const HeaderView = styled.View`
  background-color: ${Colors.white};
  padding: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  flex-direction: row;
`;

const HeaderLogo = styled.Image`
  width: ${wp(24)}px;
  height: ${wp(24)}px;
  background-color: ${Colors.grey300};
`;
const HeaderTitleView = styled.View`
  margin-left: 12px;
  justify-content: center;
`;
const HeaderTitle = styled(Caption)`
  width: ${wp(65)}px;
  font-size: ${fp(4.6)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const CategoryChip = styled.View`
  width: ${wp(20)}px;
  height: ${wp(6)}px;
  justify-content: center;
  align-items: center;
  border-width: ${StyleSheet.hairlineWidth}px;
  border-color: ${(props: IStyledProps) => props.theme.redText};
  border-radius: 3px;
  margin-top: 7px;
  margin-right: 10px;
`;
const Ion = styled(Ionicons)`
  /* margin-top: ${wp(2.2)}px; */
`;
const CategoryText = styled(Caption)`
  color: ${(props: IStyledProps) => props.theme.redText};
  font-size: ${fp(3.2)}px;
  margin-top: ${wp(1.1)}px;
`;
const DateView = styled.View`
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  margin-top: 7px;
  /* height: ${wp(2)}px; */
`;
const DateChip = styled.View`
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
  align-items: center;
  border-width: ${StyleSheet.hairlineWidth}px;
  border-color: #f8b080;
  border-radius: 32px;
  padding: 0px 10px 0px 10px;
  /* margin-top: 7px; */
  margin-right: 6px;
`;
const DateText = styled(Caption)`
  color: #f8b080;
  font-size: ${fp(2.8)}px;
  margin-left: 4px;
`;
const ParagraphText = styled(Paragraph)`
  font-size: 15px;
  color: ${Colors.grey500};
`;

const InfoCard = styled(CardInfo)`
  margin-top: 12px;
`;
const ContentView = styled.View``;

const RemindView = styled.View`
  padding: 10px;
  align-items: center;
  justify-content: center;
`;
const RemindText = styled(Paragraph)`
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;

const FooterView = styled.View`
  flex-direction: row;
  height: 48px;
  align-items: center;
`;
const FooterButton = styled(Pressable).attrs((props: any) => ({
  color: props.color || "#60c184",
}))`
  width: 50%;
  background-color: ${(props) => props.color};
  height: 48px;
  justify-content: center;
  align-items: center;
`;
const FooterText = styled(Paragraph)`
  font-size: 16px;
  color: ${Colors.white};
`;

const GridView = styled.Pressable`
  width: ${(wp(100) - 32) / 3}px;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`;
const BrandImage = styled.Image`
  width: ${wp(19)}px;
  height: ${wp(19)}px;
  background-color: ${Colors.grey200};
`;
const BrandText = styled(Paragraph)`
  font-size: ${fp(3.3)}px;
  margin-top: 7px;
  font-weight: bold;
`;

const ContentText = styled(Paragraph)`
  font-size: ${fp(3.7)}px;
  color: ${Colors.grey500};
`;

const ShareImage = styled.Image`
  width: ${wp(70)}px;
  height: ${(wp(70) * 1640) / 1280}px;
  margin-left: ${wp(15)}px;
  margin-right: ${wp(15)}px;
`;

const styles = StyleSheet.create({
  table: {
    marginTop: 16,
  },
  head: {
    height: 40,
    backgroundColor: "#F4F9FF",
  },
  headText: {
    margin: wp(2.3),
    fontSize: wp(3.5),
    fontWeight: "bold",
    color: "#414141",
  },
  text: {
    margin: wp(2.3),
    color: "#9C9AAB",
    paddingTop: wp(2),
    paddingBottom: wp(2),
    fontSize: wp(3.3),
  },
});
