// import * as Device from "expo-device";
import CryptoJS from "crypto-js";
import hash from "hash.js";
import _ from "lodash";
import axios, { AxiosRequestConfig } from "axios";
import { Platform } from "react-native";
import { Toast } from "@/react-native-papal";
import { BASIC_TYPE, TOAST_SHORT, TOKEN_SESSION } from "@/constants/app";
import * as RootNavigation from "../navigate";
import Storage from "../storage";
import { isEmpty, isEmptyObject, isWechat } from "../tools";
import { parse } from "querystring";
import MemberToast from "@/components/alert/member";

export const IS_DEV = process.env.NODE_ENV === "development";

const MP_APPID = IS_DEV ? "wx078467628cf431d4" : "wx50e1d79bcb5ac937";

const instance = axios.create({
  // baseURL: BASE_URL,
  // 超时时间
  timeout: 1000 * 5 * 60,
  // 跨域请求，允许保存cookie
  withCredentials: true,
  headers: {
    platform: Platform.OS,
    // version: Device.osVersion,
  },
});

instance.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";

// 是否正在刷新的标记
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
let retryRequests: Array<any> = [];
// 是否重新登录
let isReLogin = false;

/**
 * 数字签名
 * 添加 nonce来保证一次有效 https://www.cnblogs.com/mymelody/p/7325325.html
 * @param url 请求url
 * @param config 参数
 */
const fingerSign = (url: string, config: AxiosRequestConfig) => {
  // 获取强求路径
  let uri = _.replace(url, "/api", "");
  uri = _.first(uri.split("?")) || "";
  // 参数
  let paramStr;
  const body = config.data;
  if (!isEmptyObject(body)) {
    paramStr = body;
  }
  const params = config.params;
  if (!isEmptyObject(params)) {
    paramStr = params;
  }

  // 时间戳
  const timestamp = Math.floor(new Date().getTime() / 1000).toString();
  // 获取请求参数
  const sortedArray = _.map(paramStr, (value, key) => {
    return key + "=" + value;
  });
  const sortedParams = sortedArray.sort().join("&");
  // (Number used once）来保证一次有效
  const nonce = CryptoJS.MD5(uri + sortedParams).toString();
  // 封装签名参数
  const signParam = [uri, nonce, sortedParams, timestamp].sort().join("");
  // 获得签名
  const signature = hash.sha1().update(signParam).digest("hex");
  // console.log("====================================");
  // console.log(paramStr);
  // console.log(timestamp);
  // console.log(sortedArray);
  // console.log(nonce);
  // console.log(signParam);
  // console.log(signature);
  // console.log("====================================");
  config.headers = {
    ...config.headers,
    "X-SIGN": signature,
    "X-TIMESTAMP": timestamp,
    "X-NONCE": nonce,
  };
};

// http request 拦截器 Request
instance.interceptors.request.use(
  async (config) => {
    // header是否有Authorization
    const hasAuthorization = config.headers && config.headers.Authorization;
    // store token
    const storeToken = await Storage.getItem(TOKEN_SESSION);
    if (isEmpty(hasAuthorization) && !isEmptyObject(storeToken)) {
      config.headers!["Authorization"] = `Bearer ${storeToken.accessToken}`;
    }
    // 签名
    config.url && fingerSign(config.url, config);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// http response 拦截器 Response
instance.interceptors.response.use(
  async (response) => {
    // toast 移除
    Toast.removeAll();
    // 返回码
    const status =
      response.data.status || response.data.code || response.status;
    const msg = response.data.msg || response.data.message;
    // 417
    if (status === 417) {
      msg && Toast.info(msg, TOAST_SHORT);
      return response.data;
    }
    if (status !== 200) {
      // Toast
      msg && Toast.info(msg, TOAST_SHORT);
      return Promise.reject(response.data);
    } else {
      return response.data;
    }
  },
  (error) => {
    // toast 移除
    Toast.removeAll();
    if (!error.response) return Promise.reject(error);
    // code
    const code =
      error.response.data.status ||
      error.response.data.code ||
      error.response.status;
    const msg = error.response.data.msg || error.response.data.message;
    // 417
    if (code === 417) {
      msg && Toast.info(msg, TOAST_SHORT);
      return error.response.data;
    }
    // 根据refreshToken重新获取token
    if (code === 401) {
      const config = error.config;
      if (!isRefreshing) {
        isRefreshing = true;
        return getRefreshToken()
          .then((res) => {
            // 重新设置token
            Storage.setItem(TOKEN_SESSION, res.data.data, res.data.expiresIn);
            config.headers[
              "Authorization"
            ] = `Bearer ${res.data.data.accessToken}`;
            // 已经刷新了token，将所有队列中的请求进行重试
            retryRequests.forEach((cb) =>
              cb(`Bearer ${res.data.data.accessToken}`)
            );
            // 重试完清空这个队列
            retryRequests = [];
            return instance(config);
          })
          .catch(() => {
            // 重新登录
            resetLogin();
          })
          .finally(() => {
            isRefreshing = false;
          });
      } else {
        // 正在刷新token，返回一个未执行resolve的promise
        return new Promise((resolve) => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          // @ts-ignore
          retryRequests.push((token: any) => {
            config.headers["Authorization"] = token;
            resolve(instance(config));
          });
        });
      }
    } else if (code === 4001) {
      // 重新登录
      resetLogin();
    } else {
      // 503
      if (code === 503) {
        Toast.info("服务器繁忙！请稍后...");
        return;
      }
      // 481会员限制
      if (code === 481) {
        MemberToast.show(
          "温馨提示",
          msg,
          "再考虑下",
          "了解会员",
          () => RootNavigation.navigate("Member", { tabIndex: 0 }),
          () => RootNavigation.goBack()
        );
        return;
      }

      // Toast
      msg && Toast.info(msg, TOAST_SHORT);
      return Promise.reject(error);
    }
  }
);

/**
 * 刷新token
 */
const getRefreshToken = async () => {
  const storeToken = await Storage.getItem(TOKEN_SESSION);
  let params = {
    grant_type: "refresh_token",
    refresh_token: storeToken.refreshToken || "",
  };
  return axios.post("/api/oauth/token", params, {
    headers: {
      Authorization: BASIC_TYPE,
    },
    params,
  });
};

/**
 * 重新登录
 */
const resetLogin = async () => {
  if (!isReLogin) {
    isReLogin = true;
    // web端
    if (Platform.OS === "web") {
      if (isWechat()) {
        wechatMpAuth(window.location.pathname);
      } else {
        // web
        wechatQrcodeAuth();
      }
    } else {
      // 移动端
      // RootNavigation.replace("Login");
    }
  }
};

/**
 * 公众号授权
 * FIXME: 存在多次登录现象
 */
const wechatMpAuth = (pathname: string) => {
  let search = window.location.search;
  search = search.replace("?", "");
  const searchName = parse(search);
  const shareCode = searchName.shareCode;
  const params = {
    search: pathname,
    shareCode,
  };
  axios
    .get("/api/wx/mp/auth", {
      headers: {
        Authorization: BASIC_TYPE,
      },
      params,
    })
    .then((response) => {
      location.href = response.data.data;
      let param = window.location.search;
      param = param.replace("?", "");
      const paramName = parse(param);
      const code = paramName.code;
      // 公众号登录
      code && wechatMpLogin(code, shareCode);
    });
};

/**
 * 公众号登录
 */
const wechatMpLogin = (code: any, shareCode?: any) => {
  const params = {
    grant_type: "mp",
    scope: "server",
    signin_type: 4,
    type: 31,
    code,
    shareCode,
  };
  axios
    .post("/api/oauth/token", params, {
      headers: {
        Authorization: BASIC_TYPE,
      },
      params,
    })
    .then((res) => {
      Storage.setItem(TOKEN_SESSION, res.data.data, res.data.expiresIn);
    });
};

/**
 * 微信扫码登录
 */
const wechatQrcodeAuth = () => {
  if (window.location.href.indexOf("code=") > 0) {
    let param = window.location.search;
    param = param.replace("?", "");
    const paramName = parse(param);
    const code = paramName.code;
    const shareCode = paramName.shareCode;
    // 登录
    wechatWebLogin(code, shareCode);
  } else {
    const appid = MP_APPID;
    const redirect_uri = encodeURIComponent(window.location.href);
    const state = "" + new Date().getTime();
    const scope = "snsapi_login";
    // 跳转
    location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
  }
};

/**
 * 微信网页登录
 */
const wechatWebLogin = (code: any, shareCode?: any) => {
  const params = {
    grant_type: "wxWeb",
    scope: "server",
    signin_type: 5,
    type: 31,
    code,
    shareCode,
  };
  axios
    .post("/api/oauth/token", params, {
      headers: {
        Authorization: BASIC_TYPE,
      },
      params,
    })
    .then((res) => {
      Storage.setItem(TOKEN_SESSION, res.data.data, res.data.expiresIn);
      // 跳转首页
      location.href = window.location.origin + window.location.pathname;
    });
};

export default instance;
