import Page from "@/components/page";
import ShareAlert from "@/components/share/share-alert";
import TextInput from "@/components/textinput";
import TradeReportItem from "@/components/trade/report-item";
import { TOAST_LOADING } from "@/constants/app";
import { useDebounceFn, useMount, usePagination } from "@/react-native-ahook";
import { Toast } from "@/react-native-papal";
import {
  GENERATE_TRADE_POSTER,
  GET_TRADE_REPORT_LINK,
  PAGE_TRADE_REPORT,
} from "@/redux/models/trade";
import { createAction, createActions } from "@/utils";
import { fp, wp } from "@/utils/dimension";
import { aesEncode, isWechat } from "@/utils/tools";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { FlatList, StyleSheet } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";
import * as Clipboard from "expo-clipboard";
import { ProcessUrl } from "@/utils/image";
import MemberLockFooter from "@/components/pagination/member-lock";
import { IsVip } from "@/utils/vip";
import { GET_USER_INFO } from "@/redux/models/app";
import Share from "@/components/share";

interface Props {}

/// 报告
const TradeReportScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [searchText, setSearchText] = React.useState("");
  const [downloadVisible, setDownloadVisible] = React.useState(false);
  const [shareVisible, setShareVisible] = React.useState(false);
  const {
    data,
    addData,
    onEndReached,
    resetData,
    pageIndex,
    loadingMore,
    hasMoreData,
  } = usePagination(20);
  const { userInfo, posterImage } = useSelector(
    ({ app, loading, trade }: any) => ({
      userInfo: app.userInfo,
      posterImage: trade.posterImage,
    })
  );

  useMount(() => {
    dispatch(createAction(GET_USER_INFO)({}));
  });

  React.useEffect(() => {
    let loadingKey: number;
    if (pageIndex == 0) {
      loadingKey = Toast.loading("...", TOAST_LOADING);
    }
    dispatch(
      createActions(PAGE_TRADE_REPORT)({
        keyword: searchText,
        current: pageIndex + 1,
        size: 20,
      })((data: any) => {
        Toast.remove(loadingKey);
        addData(data.records || []);
      })
    );
  }, [pageIndex, searchText]);

  const onSearchResult = (text: string) => {
    setSearchText(text);
    resetData([]);
  };

  const renderSearch = () => {
    return (
      <SearchView>
        <TextInput
          styles={styles.input}
          returnKeyType="search"
          placeholder="请输入行业关键字"
          onSubmitEditing={({ nativeEvent: { text } }) => onSearchResult(text)}
          // onChange={({ nativeEvent: { text } }) => isWechat() && run(text)}
        />
      </SearchView>
    );
  };

  const { run } = useDebounceFn(
    (text) => {
      onSearchResult(text);
    },
    {
      wait: 600,
    }
  );

  const renderHeader = () => {
    return (
      <HeaderView>
        {searchText ? (
          <HeaderText>
            找到<HeaderRedText>"{searchText}"</HeaderRedText>相关报告
          </HeaderText>
        ) : null}
      </HeaderView>
    );
  };

  const onSharePosterPressed = useCallback((item) => {
    dispatch(
      createActions(GENERATE_TRADE_POSTER)({
        title: item.name,
        posterType: "tradeReport",
        poster: "sdfgdsgfdsgdf",
      })(() => {
        setShareVisible(true);
      })
    );
  }, []);

  const renderItem = useCallback(
    ({ item }) => (
      <TradeReportItem
        key={item.pk}
        item={item}
        onDownloadPress={() => {
          dispatch(
            createActions(GET_TRADE_REPORT_LINK)({
              type: "report",
              pk: item.pk,
            })((data: any) => {
              if (data && data.fileUrl) {
                Clipboard.setString(ProcessUrl(data.fileUrl));
                setDownloadVisible(true);
              }
            })
          );
        }}
        onSharePress={(item) => onSharePosterPressed(item)}
        onPress={() =>
          navigation.navigate("TradePdf", {
            item: aesEncode(
              JSON.stringify({
                tradePk: item.pk,
                title: item.name,
                type: "report",
              })
            ),
          })
        }
      />
    ),
    []
  );
  const keyExtractor = useCallback((item) => item.pk, []);

  // render the share image
  const renderShareImage = () => {
    return (
      <ShareImage source={{ uri: "data:image/png;base64," + posterImage }} />
    );
  };

  return (
    <Page>
      {renderSearch()}
      <ListView
        data={data || []}
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        contentContainerStyle={{ flexGrow: 1 }}
        renderItem={renderItem}
        ListHeaderComponent={renderHeader()}
        ListFooterComponent={
          <MemberLockFooter
            loadingMore={loadingMore}
            hasMoreData={hasMoreData}
            isVip={IsVip(userInfo)}
            onMemberPress={() => navigation.navigate("Member", { tabIndex: 0 })}
          />
        }
        keyExtractor={keyExtractor}
      />
      {downloadVisible && (
        <ShareAlert
          headTitle="报告下载链接复制成功"
          title="你可打开手机浏览器，粘贴链接下载"
          visible={downloadVisible}
          onClose={() => setDownloadVisible(false)}
        />
      )}
      <Share
        visible={shareVisible}
        content={renderShareImage()}
        onClose={() => setShareVisible(false)}
      />
    </Page>
  );
};

export default TradeReportScreen;

const SearchView = styled.Pressable`
  background-color: ${Colors.white};
  padding: 12px;
  flex-direction: row;
`;
const ListView = styled(FlatList)``;
const HeaderView = styled.View`
  margin-top: 10px;
`;
const HeaderText = styled(Paragraph)`
  font-size: ${fp(3.5)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  margin: 0 12px 0 12px;
`;
const HeaderRedText = styled(Paragraph)`
  font-size: ${fp(3.5)}px;
  color: ${(props: IStyledProps) => props.theme.redText};
`;

const ShareImage = styled.Image`
  width: ${wp(70)}px;
  height: ${(wp(70) * 1640) / 1280}px;
  margin-left: ${wp(15)}px;
  margin-right: ${wp(15)}px;
`;

const styles = StyleSheet.create({
  input: {
    flex: 1,
    backgroundColor: "#F4F9FF",
  },
});
