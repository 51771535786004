import { Modal } from "@/react-native-papal";
import { ICouponItem } from "@/redux/models/me";
import { hp } from "@/utils/dimension";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";
import CouponSelector from "..";

interface Props {
  data: Array<ICouponItem>;
  title?: string;
  visible: boolean;
  onClose?: () => void;
  onSurePress?: (item: any) => void;
}

/// 优惠券弹窗
const CouponModal: React.FC<Props> = (props) => {
  const [couponItem, setCouponItem] = React.useState<any>(null);
  const couponData = props.data.map((coupon: ICouponItem, index: number) => {
    return {
      pk: coupon.pk,
      id: index,
      priceNum: coupon.price,
      price: "￥" + coupon.price,
      priceDesc: coupon.des,
      title: coupon.title,
      limitDate:
        dayjs(coupon.startDate).format("YYYY.MM.DD") +
        " - " +
        dayjs(coupon.endDate).format("YYYY.MM.DD"),
      status: coupon.status,
    };
  });
  return (
    <Modal
      popup
      visible={props.visible}
      maskClosable={true}
      animationType="slide-up"
      onClose={props.onClose}
    >
      <ContentView>
        <TitleView>
          <TitleText>{props.title}</TitleText>
        </TitleView>
        <CouponSelector
          data={couponData}
          onPress={(item) => setCouponItem(item)}
        />
      </ContentView>
      <SureButton
        mode="contained"
        onPress={() => props.onSurePress!(couponItem)}
      >
        确定
      </SureButton>
    </Modal>
  );
};

CouponModal.defaultProps = {
  title: "优惠券",
  data: [],
  visible: false,
};

export default CouponModal;

const ModalView = styled(Modal)``;
const ContentView = styled.View`
  height: ${hp(60)}px;
  padding-bottom: 4px;
`;
const TitleView = styled.View`
  flex-direction: row;
  padding: 16px;
`;
const TitleText = styled(Paragraph)`
  font-size: 16px;
  font-weight: bold;
`;
const SureButton = styled(Button)`
  background-color: ${Colors.blue500};
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 10px;
  margin-top: 4px;
  border-radius: 32px;
`;

const styles = StyleSheet.create({});
