import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, Dialog, Paragraph, Portal } from "react-native-paper";

interface Props {
  visible?: boolean;
  title?: string;
  content?: React.ReactNode;
  sureText?: string;
  cancelText?: string;
  onClose?: () => void;
  onSure?: () => void;
}

const Alert: React.FC<Props> = (props) => {
  return (
    <Portal>
      <Dialog visible={props.visible!} onDismiss={props.onClose}>
        {props.title && <Dialog.Title>{props.title}</Dialog.Title>}
        {props.content && <Dialog.Content>{props.content}</Dialog.Content>}

        <Dialog.Actions>
          {props.cancelText && (
            <Button onPress={props.onClose}>{props.cancelText}</Button>
          )}
          {props.sureText && (
            <Button onPress={props.onSure}>{props.sureText}</Button>
          )}
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

Alert.defaultProps = {
  visible: false,
};

export default Alert;

const styles = StyleSheet.create({});
