import Page from "@/components/page";
import React from "react";
import {
  Platform,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import styled from "styled-components/native";
import { Chip, Colors, Paragraph, useTheme } from "react-native-paper";
import AvatarImage from "@/components/avatar-image";
import { fp, wp } from "@/utils/dimension";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import TabPersonal from "./personal";
import TabGroup from "./group";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useMount } from "@/react-native-ahook";
import { createAction } from "@/utils";
import { GET_MEMBER_DESC_INFO } from "@/redux/models/me";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER_INFO } from "@/redux/models/app";
import dayjs from "dayjs";
import { ProcessUrl } from "@/utils/image";
import { ParseVipCode } from "@/utils/vip";

interface Props {}

// 会员购买
const MemberScreen: React.FC<Props> = (props) => {
  const navigation = useNavigation();
  const route: any = useRoute();
  const dispatch = useDispatch();
  const { tabIndex } = route.params;
  const theme = useTheme();
  const layout = useWindowDimensions();
  const [index, setIndex] = React.useState(tabIndex);
  const [routes] = React.useState([
    { key: "personal", title: "个人版" },
    { key: "group", title: "团队版" },
  ]);

  const { userInfo } = useSelector(({ app, loading }: any) => ({
    userInfo: app.userInfo,
  }));

  useMount(() => {
    dispatch(createAction(GET_USER_INFO)({}));
    dispatch(createAction(GET_MEMBER_DESC_INFO)("personVip,groupVip"));
  });

  // the header view
  const renderHeader = () => {
    return (
      <HeaderColView>
        <OrderText onPress={() => navigation.navigate("MemberOrderRecord")}>
          订单记录
        </OrderText>
        <HeaderView>
          <AvatarImage size={64} source={ProcessUrl(userInfo?.avatar)} />
          <HeaderNameView>
            <HeaderNameTitle>{userInfo?.nickName}</HeaderNameTitle>
            <HeaderNameDescView>
              <Chip
                style={styles.chip}
                textStyle={styles.chipText}
                icon="information"
              >
                {ParseVipCode(userInfo)}
              </Chip>
              {userInfo?.vipEndDate && (
                <HeaderNameDesc>
                  到期：{dayjs(userInfo?.vipEndDate).format("YYYY-MM-DD")}
                </HeaderNameDesc>
              )}
            </HeaderNameDescView>
          </HeaderNameView>
        </HeaderView>
      </HeaderColView>
    );
  };

  // the tab bar view
  const renderTabBar = (prop: any) => (
    <TabBar
      {...prop}
      indicatorStyle={{ backgroundColor: theme.colors.text }}
      style={{ backgroundColor: Colors.white }}
      labelStyle={{
        color: theme.colors.text,
        fontWeight: "bold",
        fontSize: 16,
      }}
    />
  );

  const renderScene = SceneMap({
    personal: TabPersonal,
    group: TabGroup,
  });

  return (
    <Page>
      {renderHeader()}
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width, height: 0 }}
      />
    </Page>
  );
};

export default MemberScreen;
const HeaderColView = styled.View`
  background-color: ${Colors.blue500};
  padding: 16px;
  /* height: 88px; */
`;
const HeaderView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const HeaderNameView = styled.View`
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
`;
const HeaderNameTitle = styled.Text`
  font-size: ${fp(4.5)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.whiteText};
  margin-bottom: 6px;
`;
const HeaderNameDescView = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
`;
const HeaderNameDesc = styled.Text`
  font-size: ${fp(3.7)}px;
  margin-left: 12px;
  color: ${Colors.grey200};
`;
const OrderText = styled.Text`
  font-size: ${fp(3.7)}px;
  margin-left: 12px;
  margin-bottom: 12px;
  color: ${Colors.grey200};
  text-align: right;
  /* position: absolute;
  right: 16px; */
`;

const styles = StyleSheet.create({
  chip: {
    alignItems: "center",
    justifyContent: "center",
    width: wp(23),
    height: 22,
    backgroundColor: Colors.amber400,
  },
  chipText: {
    fontSize: wp(3.2),
    marginTop: Platform.OS === "android" ? 1 : 5,
  },
});
