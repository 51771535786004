import { wp } from "@/utils/dimension";
import React, { memo } from "react";
import { Platform, StyleSheet } from "react-native";
import Canvas from "react-native-canvas";
import styled from "styled-components/native";
import "@antv/data-set";
import GeoJSON from "./data.json";

// import F2 from "@antv/f2";

const F2 = require("@antv/f2/lib/core");
require("@antv/f2/lib/geom/");
require("@antv/f2/lib/geom/adjust/");
require("@antv/f2/lib/coord/polar");
require("@antv/f2/lib/component/axis/circle");
require("@antv/f2/lib/scale/time-cat");
require("@antv/f2/lib/component/guide");
const Guide = require("@antv/f2/lib/plugin/guide");
const Legend = require("@antv/f2/lib/plugin/legend");
const Tooltip = require("@antv/f2/lib/plugin/tooltip");
const ScrollBar = require("@antv/f2/lib/plugin/scroll-bar");
const pan = require("@antv/f2/lib/interaction/pan");
F2.Chart.plugins.register([Legend, Guide, Tooltip, ScrollBar, pan]);
// @ts-ignore
const DataSet = window.DataSet;

interface Props {
  data: Array<any>;
}

/// china map chart
const ChinaMapChart: React.FC<Props> = (props) => {
  const handleCanvas = async (canvas: any) => {
    if (canvas) {
      const context = await canvas.getContext("2d");
      canvas.width = wp(100) - 36;
      canvas.height = 220;
      // chart
      const geoDv = new DataSet.View().source(GeoJSON, {
        type: "GeoJSON",
      });

      const chart = new F2.Chart({
        context: context,
        padding: 0,
        pixelRatio: window.devicePixelRatio,
        width: wp(100) - 36,
        height: 240,
      });
      chart.legend(false);
      chart.axis(false);
      chart.tooltip(false);

      chart.source(geoDv.rows);
      chart.polygon().position("longitude*latitude").color("grey").style({
        // opacity: 0.3
      });

      chart.render();
    }
  };
  return (
    <ChartView>
      {Platform.OS === "web" ? (
        <canvas ref={handleCanvas} />
      ) : (
        <Canvas ref={handleCanvas} />
      )}
    </ChartView>
  );
};

ChinaMapChart.defaultProps = {
  data: [],
};

export default memo(ChinaMapChart);

const ChartView = styled.View``;

const styles = StyleSheet.create({});
