import { fp, wp } from "@/utils/dimension";
import { ProcessUrl } from "@/utils/image";
import { parseNullOrZero } from "@/utils/tools";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph, Surface } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  item: IShopItem;
}
export interface IShopItem {
  pk: string;
  mname: string;
  logo: string;
  address: string;
  price: string;
  score: string;
}

/// 门店
const ShopItem: React.FC<Props> = (props) => {
  return (
    <ItemView>
      <ItemImage source={{ uri: ProcessUrl(props.item.logo) }} />
      <ContentView>
        <TitleText numberOfLines={1} ellipsizeMode="tail">
          {props.item.mname}
        </TitleText>
        <CaptionText>{props.item.address}</CaptionText>
        <CaptionView>
          <CaptionText>客单价:</CaptionText>
          <RedText>
            {parseNullOrZero(parseFloat(props.item.price) / 100, "元")}
          </RedText>
          <CaptionText>好评率:</CaptionText>
          <RedText>
            {parseNullOrZero(parseFloat(props.item.score) * 2, "%")}
          </RedText>
        </CaptionView>
      </ContentView>
    </ItemView>
  );
};

export default React.memo(ShopItem);

const ItemView = styled.View`
  margin-left: 16px;
  margin-right: 16px;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 5px;
  background-color: ${Colors.white};
  flex-direction: row;
  align-items: center;
`;

const ItemImage = styled.Image`
  width: ${wp(20)}px;
  height: ${wp(20)}px;
  background-color: ${Colors.grey300};
`;
const ContentView = styled.View`
  margin-left: 12px;
  justify-content: center;
`;
const TitleText = styled(Paragraph)`
  width: ${wp(62)}px;
  font-size: ${fp(4)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const CaptionView = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: ${wp(1.3)}px;
`;
const CaptionText = styled(Paragraph)`
  font-size: ${fp(3.5)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;
const RedText = styled(Paragraph)`
  font-size: ${fp(3.5)}px;
  color: ${(props: IStyledProps) => props.theme.redText};
  margin-right: ${wp(3)}px;
`;

const styles = StyleSheet.create({});
