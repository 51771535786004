import SubAccountItem from "@/components/account/subaccount-item";
import Alert from "@/components/alert";
import { TOAST_LOADING } from "@/constants/app";
import { useLockFn, useMount, usePagination } from "@/react-native-ahook";
import { Space, Toast } from "@/react-native-papal";
import {
  ADD_SUB_ACCOUNT,
  GET_SUB_ACCOUNT_INFO,
  PAGE_SUB_ACCOUNT,
} from "@/redux/models/me";
import { createAction, createActions } from "@/utils";
import { uuid } from "@/utils/uuid";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, Platform, View } from "react-native";
import { Button, Colors, Divider, List, Paragraph } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

interface Props {}

/// 子账号管理 TODO: 子账号管理
const AccountScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [alertVisible, setAlertVisible] = React.useState(false);
  const [mobile, setMobile] = React.useState("");
  const { subAccountInfo } = useSelector(({ me, loading }: any) => ({
    subAccountInfo: me.subAccountInfo,
  }));
  const { data, addData, onEndReached, pageIndex, ListFooterComponent } =
    usePagination(20);

  React.useEffect(() => {
    let loadingKey: number;
    if (pageIndex == 0) {
      loadingKey = Toast.loading("...", TOAST_LOADING);
    }
    dispatch(
      createActions(PAGE_SUB_ACCOUNT)({
        current: pageIndex + 1,
        size: 20,
      })((data: any) => {
        Toast.remove(loadingKey);
        addData(data.records || []);
      })
    );
  }, [pageIndex]);

  useMount(() => {
    dispatch(createAction(GET_SUB_ACCOUNT_INFO)({}));
  });

  const renderAccount = () => {
    const remainCount =
      subAccountInfo.subaccountCount - subAccountInfo.usedCount;
    return (
      <ContentView>
        <ContentText>
          我的账号(主账号)：
          <ContentDescText>{subAccountInfo.mobile}</ContentDescText>
        </ContentText>
        <ContentText>
          到期时间：
          <ContentDescText>
            {subAccountInfo.vipEndDate &&
              dayjs(subAccountInfo.vipEndDate).format("YYYY-MM-DD")}
          </ContentDescText>
        </ContentText>
        <ContentText>
          套餐人数：
          <ContentDescText>
            {subAccountInfo.subaccountCount || 0}人(可添加子账号
            {remainCount || 0}人)
          </ContentDescText>
        </ContentText>
      </ContentView>
    );
  };

  // add sub account
  const addSubAccount = useLockFn(async () => {
    setAlertVisible(false);
    dispatch(
      createAction(ADD_SUB_ACCOUNT)({
        mobile: mobile,
      })
    );
  });

  const renderSearchAccount = () => {
    const remainCount =
      subAccountInfo.subaccountCount - subAccountInfo.usedCount;
    return (
      <ContentView>
        <Title>添加团队成员</Title>
        <InputView>
          <TextInput
            placeholder="输入团队成员手机号"
            maxLength={11}
            keyboardType="numeric"
            // @ts-ignore
            style={Platform.select({
              web: {
                outlineStyle: "none",
              },
            })}
            onChangeText={(text) => setMobile(text)}
          />
          <Button
            mode="contained"
            disabled={remainCount == 0}
            onPress={() => setAlertVisible(true)}
          >
            添加
          </Button>
        </InputView>
        <InputDesc>*请仔细核对添加成员的手机号</InputDesc>
      </ContentView>
    );
  };

  const renderItem = React.useCallback(
    ({ item }) => <SubAccountItem key={uuid()} item={item} />,
    []
  );
  const keyExtractor = React.useCallback((item) => uuid(), []);

  const renderAddAccount = () => {
    return (
      <ContentView>
        <Title>已添加成员{subAccountInfo.usedCount}人</Title>
        <Space direction="column" />
        <ListView
          onEndReachedThreshold={0.5}
          onEndReached={onEndReached}
          contentContainerStyle={{ flexGrow: 1 }}
          data={data || []}
          renderItem={renderItem}
          // ListHeaderComponent={renderFlatListHeader()}
          ListFooterComponent={ListFooterComponent}
          keyExtractor={keyExtractor}
        />
      </ContentView>
    );
  };
  return (
    <PageScrollView>
      {renderAccount()}
      {renderSearchAccount()}
      {data.length > 0 && renderAddAccount()}
      <Alert
        visible={alertVisible}
        title="温馨提示"
        content={
          <AlertContentText>
            为用户开通VIP将会扣除账号次数,一旦确定不可返回
          </AlertContentText>
        }
        cancelText="取消"
        sureText="确定"
        onClose={() => setAlertVisible(false)}
        onSure={() => addSubAccount()}
      />
    </PageScrollView>
  );
};

export default AccountScreen;

const PageScrollView = styled.ScrollView`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;
const ContentView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  margin: 8px 12px 8px 12px;
  padding: 16px;
  border-radius: 5px;
`;
const ContentText = styled.Text`
  font-size: 15px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
  margin-top: 4px;
  margin-bottom: 4px;
`;
const ContentDescText = styled.Text`
  font-size: 14px;
  font-weight: normal;
  color: ${(props: IStyledProps) => props.theme.text};
`;

const Title = styled.Text`
  font-size: 15px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const InputView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`;
const TextInput = styled.TextInput`
  border-width: 1px;
  border-color: ${Colors.grey200};
  height: 44px;
  border-radius: 5px;
  padding: 16px;
  flex: 1;
  margin-right: 12px;
`;
const InputDesc = styled.Text`
  font-size: 13px;
  color: ${Colors.red500};
  margin-top: 12px;
`;

const AlertContentText = styled(Paragraph)``;

const ListView = styled.FlatList``;

const styles = StyleSheet.create({
  listItem: {
    // borderWidth: 1,
    backgroundColor: Colors.grey50,
  },
});
