import AvatarImage from "@/components/avatar-image";
import { fp, wp } from "@/utils/dimension";
import { ProcessUrl } from "@/utils/image";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  item: any;
}

/// 累计收益
const InviteSettleItem: React.FC<Props> = (props) => {
  return (
    <ItemView>
      <FriendView>
        <AvatarImage size={wp(10)} source={ProcessUrl(props.item.avatar)} />
        <NameText>{props.item.nickName}</NameText>
      </FriendView>
      <AmountText>{props.item.payPrice * 0.01}</AmountText>
      <AmountText>{props.item.expenses * 0.01}</AmountText>
      <DateText>
        {dayjs(props.item.payDate).format("YYYY-MM-DD HH:mm")}
      </DateText>
    </ItemView>
  );
};

export default React.memo(InviteSettleItem);

const ItemView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.white};
  padding: 12px;
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${(props: IStyledProps) => props.theme.subPrimary};
`;

const FriendView = styled.View`
  flex-direction: row;
  align-items: center;
  width: ${wp(34)}px;
`;

const NameText = styled(Paragraph)`
  font-size: ${fp(3.7)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
  margin-left: 8px;
`;
const AmountText = styled(Paragraph)`
  font-size: ${fp(3.7)}px;
  width: ${wp(12)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  /* margin-left: 12px; */
`;

const DateText = styled(Paragraph)`
  font-size: ${fp(3.7)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
  text-align: right;
  /* flex: 1;
  text-align: right; */
`;

const styles = StyleSheet.create({});
