import ShopBarChart from "@/components/shop-bar-chart";
import ShopItem from "@/components/shop-item";
import { useDebounceFn, useMount, usePagination } from "@/react-native-ahook";
import {
  GET_NATION_INFO,
  INationMapsItem,
  PAGE_NATION_SHOP,
} from "@/redux/models/enterprise";
import { createAction, createActions } from "@/utils";
import { fp, wp } from "@/utils/dimension";
import { aesDecode, aesEncode } from "@/utils/tools";
import {
  StackActions,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import React, { useCallback } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Title } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

interface Props {}

const NATIONS = ["nation", "province", "city"];

/// 全国城市数据
const NationInfoScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const route: any = useRoute();
  const navigation = useNavigation();
  const { item } = route.params;
  const { brandPk, areaPk, type, areaName } = JSON.parse(aesDecode(item));
  // const { nationInfo } = useSelector(({ enterprise, loading }: any) => ({
  //   nationInfo: enterprise.nationInfo,
  // }));
  const [nationInfo, setNationInfo] = React.useState([]);
  const { data, addData, onEndReached, pageIndex, ListFooterComponent } =
    usePagination(20);

  React.useEffect(() => {
    dispatch(
      createActions(GET_NATION_INFO)({ brandPk, areaPk, type: NATIONS[type] })(
        (data: any) => {
          setNationInfo(data || []);
        }
      )
    );
  }, [areaPk]);

  React.useEffect(() => {
    dispatch(
      createActions(PAGE_NATION_SHOP)({
        brandPk,
        areaPk,
        type: NATIONS[type],
        current: pageIndex + 1,
        size: 20,
      })((data: any) => {
        addData(data.records || []);
      })
    );
  }, [pageIndex]);

  const { run } = useDebounceFn(
    (areaItem) => {
      type < 2 &&
        navigation.dispatch(
          StackActions.push("NationInfo", {
            item: aesEncode(
              JSON.stringify({
                brandPk: brandPk,
                areaPk: areaItem.pk,
                type: type + 1,
                areaName: areaItem.name,
              })
            ),
          })
        );
    },
    {
      wait: 500,
    }
  );

  /// header
  const renderHeaderView = () => {
    const totalCount =
      nationInfo &&
      nationInfo.reduce(
        (totalCount: number, item: INationMapsItem) => totalCount + item.count,
        0
      );
    return (
      <HeaderView>
        <HeaderTitle>
          {areaName ? areaName : "全国"}门店
          <HeaderRedTitle>{totalCount}</HeaderRedTitle>家
        </HeaderTitle>
        <ShopBarChart data={nationInfo || []} onPress={(item) => run(item)} />
        <HeaderTitleView>
          <HeaderTitle>城市门店详情</HeaderTitle>
        </HeaderTitleView>
      </HeaderView>
    );
  };

  const renderItem = useCallback(({ item }) => <ShopItem item={item} />, []);
  const keyExtractor = useCallback((item) => item.pk, []);
  const getItemLayout = useCallback(
    (data, index) => ({
      length: wp(30),
      offset: wp(30) * index,
      index,
    }),
    []
  );

  return (
    <PageView>
      <ListView
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        contentContainerStyle={{ flexGrow: 1 }}
        data={data}
        ListHeaderComponent={renderHeaderView}
        renderItem={renderItem}
        ListFooterComponent={ListFooterComponent}
        keyExtractor={keyExtractor}
        // getItemLayout={getItemLayout}
      />
    </PageView>
  );
};

export default NationInfoScreen;

const PageView = styled.View`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;

const HeaderView = styled.View`
  background-color: ${Colors.white};
  /* padding: 16px; */
`;
const HeaderTitleView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.page};
`;
const HeaderTitle = styled(Title)`
  color: ${(props: IStyledProps) => props.theme.text};
  font-size: ${fp(4.5)}px;
  font-weight: bold;
  padding: 8px 16px 0 16px;
`;
const HeaderRedTitle = styled(Title)`
  color: ${(props: IStyledProps) => props.theme.redText};
  font-size: ${fp(4.5)}px;
  font-weight: bold;
`;

const ListView = styled.FlatList``;

const styles = StyleSheet.create({});
