import { wp } from "@/utils/dimension";
import Color from "color";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  title: string;
  onPress?: () => void;
}

const SearchItem: React.FC<Props> = (props) => {
  return (
    <ItemView onPress={props.onPress}>
      <CaptionText>{props.title}</CaptionText>
    </ItemView>
  );
};

export default SearchItem;

const ItemView = styled.Pressable`
  justify-content: center;
  align-items: center;
  /* background-color: ${Colors.grey200}; */
  background-color: #f4f9ff;
  padding: 7px;
  width: ${wp(100) / 3 - 16}px;
  margin-left: 12px;
  margin-top: 8px;
  border-radius: 3px;
`;
const CaptionText = styled(Paragraph)`
  color: ${Colors.grey700};
`;

const styles = StyleSheet.create({});
