import { Space } from "@/react-native-papal";
import { IDictItem, IDictTreeItem } from "@/redux/models/redux";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Paragraph } from "react-native-paper";
import styled from "styled-components/native";
import SearchItem from "./search-item";

interface Props {
  title: string;
  data: Array<IDictTreeItem>;
  onPress?: (item: IDictTreeItem) => void;
}

const SearchGrid: React.FC<Props> = (props) => {
  const itemData = props.data.map((item: IDictTreeItem) => (
    <SearchItem
      key={item.pk}
      title={item.title}
      onPress={() => props.onPress && props.onPress(item)}
    />
  ));
  return (
    <ItemView>
      <TitleText>{props.title}</TitleText>
      <ContentView>{itemData}</ContentView>
    </ItemView>
  );
};

SearchGrid.defaultProps = {};

export default SearchGrid;

const ItemView = styled.View``;
const TitleText = styled(Paragraph)`
  /* padding: 12px; */
  font-size: 16px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 12px;
`;
const ContentView = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const styles = StyleSheet.create({});
