import Alert from "@/components/alert";
import Page from "@/components/page";
import PresentSelector from "@/components/present-selector";
import { useLockFn, useMount } from "@/react-native-ahook";
import { AUTH_PARTNER_ACCOUNT, GET_MEMBER_DESC_INFO } from "@/redux/models/me";
import { createAction } from "@/utils";
import { fp } from "@/utils/dimension";
import { useNavigation, useRoute } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Platform, View } from "react-native";
import { Button, Colors, Paragraph } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

interface Props {}

/// 开通VIP

const AuthVipScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route: any = useRoute();
  const { item } = route.params;
  const [mobile, setMobile] = React.useState("");
  const [selectItem, setSelectItem] = React.useState<any>({});
  const [alertVisible, setAlertVisible] = React.useState(false);
  const { dictDesc } = useSelector(({ me, loading }: any) => ({
    dictDesc: me.dictDesc,
  }));

  useMount(() => {
    dispatch(createAction(GET_MEMBER_DESC_INFO)("authVipPackage"));
  });

  const renderInputView = () => {
    return (
      <InputView>
        <InputTitleView>
          <InputTitle>输入好友手机号</InputTitle>
          <InputDesc
            onPress={() => navigation.navigate("AuthRecord", { item: item })}
          >
            开通纪录
          </InputDesc>
        </InputTitleView>
        <TextInput
          maxLength={11}
          keyboardType="numeric"
          placeholder="输入好友手机号"
          // @ts-ignore
          style={Platform.select({
            web: {
              outlineStyle: "none",
            },
          })}
          onChangeText={(text) => setMobile(text)}
        />
      </InputView>
    );
  };

  const renderMemberView = () => {
    const memberData = dictDesc.authVipPackage.map(
      (item: any, index: number) => {
        return {
          key: index + 1,
          title: item.dvalue,
          price: item.remark,
          pk: item.pk,
        };
      }
    );

    return (
      <InputView>
        <InputTitleView>
          <InputTitle>开通VIP会员套餐</InputTitle>
        </InputTitleView>
        <PresentSelector
          data={memberData}
          onCallback={(item) => setSelectItem(item)}
        />
      </InputView>
    );
  };

  const renderDescView = () => {
    return (
      <InputView>
        <Paragraph>说明:</Paragraph>
        <DescText>
          *请务必填准确开通手机号，确定后无法修改{"\n"}
          *提醒用户登录绑定手机，绑定手机后可享VIP权益{"\n"}
          *VIP会员在开通后5分钟内生效
        </DescText>
      </InputView>
    );
  };

  const authAccountPress = useLockFn(async () => {
    setAlertVisible(false);
    dispatch(
      createAction(AUTH_PARTNER_ACCOUNT)({
        mobile: mobile,
      })
    );
  });

  return (
    <Page>
      <PageScrollView>
        {renderInputView()}
        {renderMemberView()}
        {renderDescView()}
      </PageScrollView>
      <PayToolView>
        <AuthButton
          mode="contained"
          disabled={!selectItem.pk || !mobile}
          color={Colors.amber200}
          onPress={() => setAlertVisible(true)}
        >
          立即开通
        </AuthButton>
      </PayToolView>
      <Alert
        visible={alertVisible}
        title="温馨提示"
        content={
          <AlertContentText>
            为用户开通VIP将会扣除账号次数,一旦确定不可返回
          </AlertContentText>
        }
        cancelText="取消"
        sureText="确定"
        onClose={() => setAlertVisible(false)}
        onSure={() => authAccountPress()}
      />
    </Page>
  );
};

export default AuthVipScreen;

const PageScrollView = styled.ScrollView`
  flex: 1;
`;
const InputView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  padding: 16px;
  margin-bottom: 10px;
`;
const InputTitleView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const InputTitle = styled.Text`
  flex: 1;
  font-size: ${fp(4.2)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;
const InputDesc = styled.Text`
  font-size: ${fp(3.4)}px;
  color: ${(props: IStyledProps) => props.theme.primary};
`;
const TextInput = styled.TextInput`
  border-width: 1px;
  border-color: ${Colors.grey200};
  height: 48px;
  border-radius: 5px;
  padding: 16px;
  margin-top: 12px;
`;

const DescText = styled.Text`
  color: ${(props: IStyledProps) => props.theme.caption};
  font-size: ${fp(3.2)}px;
  margin-top: 2px;
  line-height: 21px;
`;
const PayToolView = styled.View`
  padding: 6px 16px 16px 16px;
  justify-content: center;
  align-items: center;
`;
const AuthButton = styled(Button)`
  width: 100%;
  border-radius: 32px;
`;
const AlertContentText = styled(Paragraph)``;

const styles = StyleSheet.create({});
