import { wp } from "@/utils/dimension";
import React, { memo } from "react";
import { Platform, StyleSheet } from "react-native";
import Canvas from "react-native-canvas";
import styled from "styled-components/native";

// import F2 from "@antv/f2";

const F2 = require("@antv/f2/lib/core");
require("@antv/f2/lib/geom/");
require("@antv/f2/lib/geom/adjust/");
require("@antv/f2/lib/coord/polar");
require("@antv/f2/lib/component/axis/circle");
require("@antv/f2/lib/scale/time-cat");
require("@antv/f2/lib/component/guide");
const Guide = require("@antv/f2/lib/plugin/guide");
const Legend = require("@antv/f2/lib/plugin/legend");
const Tooltip = require("@antv/f2/lib/plugin/tooltip");
const ScrollBar = require("@antv/f2/lib/plugin/scroll-bar");
const pan = require("@antv/f2/lib/interaction/pan");
F2.Chart.plugins.register([Legend, Guide, Tooltip, ScrollBar, pan]);

interface Props {
  data: Array<any>;
}

/// bar chart
const AntLineChart: React.FC<Props> = (props) => {
  const handleCanvas = async (canvas: any) => {
    if (canvas) {
      const context = await canvas.getContext("2d");
      canvas.width = wp(100) - 36;
      canvas.height = 220;
      const chart = new F2.Chart({
        context: context,
        pixelRatio: window.devicePixelRatio,
        width: wp(100) - 36,
        height: 240,
      });
      chart.source(props.data, {
        value: {
          min: 0,
        },
        label: {
          tickCount: 6,
        },
      });
      chart.tooltip({
        showCrosshairs: true,
        showItemMarker: false,
        background: {
          radius: 2,
          fill: "#1890FF",
          padding: [3, 5],
        },
        nameStyle: {
          fill: "#fff",
        },
        onShow: (ev: any) => {
          const items = ev.items;
          items[0].name = items[0].title;
        },
      });
      chart.line().position("label*value");
      chart.point().position("label*value").style({
        lineWidth: 1,
        stroke: "#fff",
      });

      // chart.interaction("pan");
      // // 定义进度条
      // chart.scrollBar({
      //   mode: "x",
      //   xStyle: {
      //     offsetY: -5,
      //   },
      // });

      // 绘制 tag
      //   chart.guide().tag({
      //     position: [1969, 1344],
      //     withPoint: false,
      //     content: "1,344",
      //     limitInPlot: true,
      //     offsetX: 5,
      //     direct: "cr",
      //   });
      chart.render();
    }
  };
  return (
    <ChartView>
      {Platform.OS === "web" ? (
        <canvas ref={handleCanvas} />
      ) : (
        <Canvas ref={handleCanvas} />
      )}
    </ChartView>
  );
};

AntLineChart.defaultProps = {
  data: [],
};

export default memo(AntLineChart);

const ChartView = styled.View``;

const styles = StyleSheet.create({});
