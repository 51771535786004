import { useInterval } from "@/react-native-ahook";
import React, { memo, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import styled from "styled-components/native";
import NoticeBar from "../notice-bar";

interface Props {
  data: Array<NoticeBarItem>;
  style?: any;
}

interface NoticeBarItem {
  name: string;
  text: string;
}

/// 通知栏
const NoticeItem: React.FC<Props> = (props) => {
  const [showText, setShowText] = useState("");
  useInterval(
    () => {
      const randomIndex = Math.floor(Math.random() * (props.data.length - 1));
      setShowText(props.data[randomIndex].text);
    },
    5000,
    { immediate: true }
  );

  return (
    <NoticeView style={[props.style]} marqueeProps={{ loop: false }}>
      {showText}
    </NoticeView>
  );
};

NoticeItem.defaultProps = {
  data: [],
};

export default memo(NoticeItem);

const ItemView = styled.View``;
const NoticeView = styled(NoticeBar)`
  /* flex: 1; */
  border-radius: 5px;
`;

const styles = StyleSheet.create({});
