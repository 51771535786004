import { Row, Rows, Table } from "@/react-native-papal";
import { fp, wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "react-native-paper";

interface Props {
  data: any[];
  headers: string[];
  tableStyle?: any;
}

const ColorTable: React.FC<Props> = (props) => {
  return (
    <Table
      style={[styles.table, props.tableStyle]}
      borderStyle={{
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: Colors.grey100,
      }}
    >
      <Row
        data={props.headers}
        style={styles.head}
        textStyle={styles.headText}
      />
      <Rows data={props.data} textStyle={styles.text} />
    </Table>
  );
};
ColorTable.defaultProps = {
  data: [],
  headers: [],
};
export default ColorTable;

const styles = StyleSheet.create({
  table: {
    marginTop: 16,
  },
  head: {
    height: 40,
    backgroundColor: "#F4F9FF",
  },
  headText: {
    margin: wp(2.3),
    fontSize: fp(3.5),
    fontWeight: "bold",
    color: "#414141",
    textAlign: "center",
  },
  text: {
    margin: wp(2.3),
    color: "#9C9AAB",
    paddingTop: wp(2),
    paddingBottom: wp(2),
    fontSize: fp(3.3),
    textAlign: "center",
  },
});
