import { Modal, Space } from "@/react-native-papal";
import { fp, wp } from "@/utils/dimension";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, Colors, Headline, Paragraph } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  visible?: boolean;
  title?: string;
  content?: any;
  onClose?: () => void;
}

// 活动规则
const RuleModal: React.FC<Props> = (props) => {
  return (
    <ModalView
      visible={props.visible}
      transparent
      maskClosable={true}
      onClose={props.onClose}
    >
      <ContentView>
        <TitleText>{props.title}</TitleText>
        {props.content}
        <SureButton mode="contained" onPress={props.onClose}>
          我知道了
        </SureButton>
      </ContentView>
    </ModalView>
  );
};

RuleModal.defaultProps = {
  visible: false,
  title: "活动规则",
};

export default React.memo(RuleModal);

const ModalView = styled(Modal)`
  justify-content: center;
  align-items: center;
  width: ${wp(80)}px;
`;
const ContentView = styled.View`
  justify-content: center;
  align-items: center;
`;
const TitleText = styled(Headline)`
  font-size: ${fp(4.2)}px;
  font-weight: bold;
`;
const SureButton = styled(Button)`
  margin-top: 10px;
  background-color: ${Colors.blue500};
  font-size: ${fp(3.2)}px;
`;

const styles = StyleSheet.create({});
