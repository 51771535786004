import { isFloat } from "../tools";

/**
 * VIP CODE转换为VIP等级
 * @param userInfo
 * @returns
 */
export const ParseVipCode: any = (userInfo: any) => {
  let vipName;
  if (userInfo?.vipLevel === 440) {
    vipName = "普通用户";
  } else if (userInfo?.vipLevel === 441) {
    vipName = "个人会员";
  } else if (userInfo?.vipLevel === 442) {
    vipName = "团队会员";
  } else if (userInfo?.vipLevel === 445) {
    vipName = "黄金合伙人";
  } else if (userInfo?.vipLevel === 446) {
    vipName = "铂金合伙人";
  } else if (userInfo?.vipLevel === 447) {
    vipName = "钻石合伙人";
  } else {
    vipName = "普通用户";
  }
  return vipName;
};

export const parseVipCodeToName: any = (vipLevel: number) => {
  let vipName;
  if (vipLevel === 440) {
    vipName = "普通用户";
  } else if (vipLevel === 441) {
    vipName = "个人会员";
  } else if (vipLevel === 442) {
    vipName = "团队会员";
  } else if (vipLevel === 445) {
    vipName = "黄金合伙人";
  } else if (vipLevel === 446) {
    vipName = "铂金合伙人";
  } else if (vipLevel === 447) {
    vipName = "钻石合伙人";
  } else {
    vipName = "普通用户";
  }
  return vipName;
};

/**
 * 是否是VIP
 * @param userInfo
 * @returns
 */
export const IsVip: any = (userInfo: any) => {
  return userInfo?.vipLevel > 440;
};

export const HandleVipTerm: any = (vipTerm: string) => {
  if (isFloat(vipTerm)) {
    if (vipTerm === "0.6") {
      return "半年VIP";
    } else {
      return parseFloat(vipTerm) * 0.1 + "月VIP";
    }
  } else {
    return vipTerm + "年VIP";
  }
};
