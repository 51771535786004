import { BASIC_TYPE } from "@/constants/app";
import request from "@/utils/request";

/**
 * @description 用户信息
 * @export
 * @returns
 */
export async function fetchUserInfo(params: any) {
  return request("/api/app/user/info", {
    headers: {
      Authorization: BASIC_TYPE,
    },
  });
}

/**
 * 查询字典
 * @param keys
 * @returns
 */
export async function singleDictListByKeys(keys: string) {
  return request(`/api/dict/single-map/${keys}`);
}

/**
 * 查询字典树形
 * @param keys
 * @returns
 */
export async function dictTreeByKey(params: any) {
  return request(`/api/dict/tree/map`, {
    method: "POST",
    data: params,
  });
}

/**
 * @description 经济数据搜索
 * @export
 * @returns
 */
export async function doGeneratePoster(params: any) {
  return request("/api/app/generate/poster", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 生成多张海报
 * @export
 * @returns
 */
export async function doGeneratePosters(params: any) {
  return request("/api/app/generate/posters", {
    method: "POST",
    data: params,
  });
}

/**
 * @description 修改用户
 * @export
 * @returns
 */
export async function doEditUser(params: any) {
  return request("/api/app/user/edit", {
    method: "POST",
    data: params,
  });
}

/**
 * @description Banner信息
 * @export
 * @returns
 */
export async function fetchBannerInfo(params: any) {
  return request("/api/app/banner/info", {
    method: "POST",
    data: params,
  });
}

/**
 * @description icon配置
 * @export
 * @returns
 */
export async function fetchIconConfig(params: any) {
  return request("/api/app/icon/config", {
    method: "POST",
    data: params,
  });
}
