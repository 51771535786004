import React from "react";
import { StyleSheet } from "react-native";
import { Checkbox, Colors } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  data: Array<ItemProps>;
  onPress?: (item: ItemProps) => void;
}

interface ItemProps {
  id: number;
  price: string;
  priceDesc: string;
  title: string;
  limitDate: string;
  selected?: boolean;
  onPress?: (key: number) => void;
}

const CouponSelectorItem: React.FC<ItemProps> = (props) => {
  const checked = props.selected ? "checked" : "unchecked";
  return (
    <CouponItemView onPress={() => props.onPress!(props.id)}>
      <ItemLeadingView>
        <PriceText>{props.price}</PriceText>
        <DescText>{props.priceDesc}</DescText>
      </ItemLeadingView>
      <ItemMiddleView>
        <TitleText>{props.title}</TitleText>
        <LimitText>有效期：{props.limitDate}</LimitText>
      </ItemMiddleView>
      <ItemEndView>
        <Checkbox
          color={Colors.blue500}
          uncheckedColor={Colors.grey400}
          status={checked}
          onPress={() => props.onPress!(props.id)}
        />
      </ItemEndView>
    </CouponItemView>
  );
};

CouponSelectorItem.defaultProps = {
  selected: false,
};

/// the coupon selector component
const CouponSelector: React.FC<Props> = (props) => {
  const [selectKey, setSelectKey] = React.useState<number>();
  const cardData = props.data.map((item: ItemProps) => {
    return (
      <CouponSelectorItem
        key={item.id}
        id={item.id}
        price={item.price}
        priceDesc={item.priceDesc}
        title={item.title}
        limitDate={item.limitDate}
        selected={item.id == selectKey}
        onPress={(key) => {
          setSelectKey(key);
          props.onPress && props.onPress(item);
        }}
      />
    );
  });
  return <CouponView>{cardData}</CouponView>;
};

CouponSelector.defaultProps = {
  data: [],
};

export default CouponSelector;

const CouponView = styled.ScrollView``;
const CouponItemView = styled.Pressable`
  flex-direction: row;
  align-items: center;
  margin: 0 16px 0 16px;
  padding: 20px 16px 20px 16px;
  border-width: 1px;
  border-color: ${Colors.grey200};
  border-radius: 8px;
  margin-bottom: 12px;
`;
const ItemLeadingView = styled.View`
  justify-content: center;
  align-items: center;
`;
const PriceText = styled.Text`
  font-size: 21px;
  font-weight: bold;
  color: ${Colors.red400};
`;
const DescText = styled.Text`
  font-size: 12px;
  color: ${Colors.grey400};
  margin-top: 7px;
`;
const ItemMiddleView = styled.View`
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
`;
const TitleText = styled.Text`
  font-size: 17px;
  font-weight: bold;
  color: ${Colors.grey900};
`;
const LimitText = styled.Text`
  font-size: 12px;
  color: ${Colors.grey400};
  margin-top: 12px;
`;
const ItemEndView = styled.View``;

const styles = StyleSheet.create({});
