import { useMount } from "@/react-native-ahook";
import { GET_PARTNER_CENTER } from "@/redux/models/me";
import { createAction, createActions } from "@/utils";
import { fp, hp, wp } from "@/utils/dimension";
import { aesEncode, fenToYuan } from "@/utils/tools";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { Colors, Paragraph, Title } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";
import RuleModal from "@/components/rule-modal";
import Share from "@/components/share";
import SharePoster from "@/components/share-poster";
import { DO_GENERATE_POSTERS } from "@/redux/models/app";

interface RewardItemProps {
  title: string;
  desc: string;
  onPress?: () => void;
}
const RewardItem: React.FC<RewardItemProps> = (props) => {
  return (
    <RewardItemView onPress={props.onPress}>
      <RewardItemText>{props.title}</RewardItemText>
      <RewardItemDescText>{props.desc}</RewardItemDescText>
    </RewardItemView>
  );
};

interface Props {}

// 合伙人中心
const PartnerCenterScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [shareVisible, setShareVisible] = React.useState(false);
  const [ruleVisible, setRuleVisible] = React.useState(false);
  const { partnerCenter, posters } = useSelector(
    ({ app, me, loading }: any) => ({
      partnerCenter: me.partnerCenter,
      posters: app.posters,
    })
  );

  useMount(() => {
    dispatch(createAction(GET_PARTNER_CENTER)({}));
  });

  // header
  const renderHeader = () => {
    return (
      <HeaderImage
        resizeMode="cover"
        source={require("@/assets/images/me/me_partner_bg.png")}
      >
        <HeaderRowView>
          <HeaderMaskView>
            <HeaderMaskText>
              加入合伙人日期：
              {partnerCenter.crtTime &&
                dayjs(partnerCenter.crtTime).format("YYYY.MM.DD")}
            </HeaderMaskText>
            <HeaderMaskText>合伙人等级：{partnerCenter.vipName}</HeaderMaskText>
            <HeaderMaskText>
              分销佣金比：{parseFloat(partnerCenter.rebateRatio) * 100}%
            </HeaderMaskText>
          </HeaderMaskView>
          <HeaderRuleText onPress={() => setRuleVisible(true)}>
            权益规则
          </HeaderRuleText>
        </HeaderRowView>
      </HeaderImage>
    );
  };

  // earning
  const renderEarning = () => {
    return (
      <EarningImage
        resizeMode="cover"
        source={require("@/assets/images/me/me_partner_earning.png")}
      >
        <RewardView>
          <TotalSettleView>
            <RewardItem
              title={fenToYuan(partnerCenter.earnFee)}
              desc="累计收入(元)"
              onPress={() =>
                navigation.navigate("InviteRecord", {
                  item: aesEncode(JSON.stringify(partnerCenter)),
                  type: "partner",
                })
              }
            />
          </TotalSettleView>
          <RewardHView>
            <RewardItem
              title={partnerCenter.inviteNum}
              desc="已邀请好友(人)"
              onPress={() =>
                navigation.navigate("InviteRecord", {
                  item: aesEncode(JSON.stringify(partnerCenter)),
                  type: "partner",
                })
              }
            />
            <RewardItem
              title={partnerCenter.earnFeeCount}
              desc="佣金收益(笔)"
              onPress={() =>
                navigation.navigate("Earnings", {
                  item: aesEncode(JSON.stringify(partnerCenter)),
                  type: "partner",
                })
              }
            />
          </RewardHView>
          <Divider />
          <RewardHWView>
            <RewardHLeftView>
              <RewardTextW>待结算(元)：</RewardTextW>
              <RewardMoneyW>{fenToYuan(partnerCenter.settleFee)}</RewardMoneyW>
            </RewardHLeftView>
            <RewardButton onPress={() => navigation.navigate("SettleDetail")}>
              <RewardButtonText>结算明细</RewardButtonText>
            </RewardButton>
          </RewardHWView>
        </RewardView>
      </EarningImage>
    );
  };

  // auth
  const renderAuth = () => {
    return (
      <AuthView>
        <AuthDesc>
          上述收益详情是通过你的推广，线上获得的收益，平台定期与你进行结算。
          如下是钻石合伙人的批量采购记录以及单独为用户开通VIP的管理记录。
        </AuthDesc>
        <AuthImage
          resizeMode="cover"
          source={require("@/assets/images/me/me_partner_auth.png")}
        >
          <EarningRewardView>
            <RewardHView>
              <RewardItem
                title={partnerCenter.groupCount}
                desc="采购账号(个)"
                onPress={() =>
                  navigation.navigate("AccountRecord", {
                    item: aesEncode(JSON.stringify(partnerCenter)),
                  })
                }
              />
              <RewardItem
                title={partnerCenter.authCount}
                desc="已授权(个)"
                onPress={() =>
                  navigation.navigate("AuthRecord", {
                    item: aesEncode(JSON.stringify(partnerCenter)),
                  })
                }
              />
            </RewardHView>
            <Divider />
            <RewardHWView style={{ justifyContent: "center" }}>
              <RewardButton
                onPress={() =>
                  navigation.navigate("AuthVip", {
                    item: aesEncode(JSON.stringify(partnerCenter)),
                  })
                }
              >
                <RewardButtonText>为用户开通VIP</RewardButtonText>
              </RewardButton>
            </RewardHWView>
          </EarningRewardView>
        </AuthImage>
      </AuthView>
    );
  };

  // share
  const renderShare = () => {
    return (
      <Pressable
        onPress={() => {
          dispatch(
            createActions(DO_GENERATE_POSTERS)({})(() => {
              setShareVisible(true);
            })
          );
        }}
      >
        <ShareImage
          resizeMode="cover"
          source={require("@/assets/images/me/me_partner_share.png")}
        />
      </Pressable>
    );
  };

  return (
    <Page>
      <PageView>
        {renderHeader()}
        {renderEarning()}
        {partnerCenter.groupCount > 0 && renderAuth()}
      </PageView>
      <RuleModal
        visible={ruleVisible}
        title="权益规则"
        content={
          <ContentText>
            1、你的好友通过你的邀请登录平台后，将与你绑定好友关系(绑定关系具备唯一性，若被你绑定，其他人则无法绑定)，绑定期限1年。
            {"\n"}
            2、成为合伙人，权益发生变化：{"\n"}
            （1）佣金提升，非合伙人15%，黄金合伙人35%，铂金合伙人50%，钻石合伙人65%。
            {"\n"}
            （2）合伙人不再有邀请好友赚VIP时长的权益。{"\n"}
            （3）成为合伙人之前，邀请的好友购买VIP的佣金，按照之前规则计算，如：用户A在你成为合伙人之前邀请的，在你成为合伙人后购买VIP，则你获得15%佣金奖励。
            {"\n"}
            （4）作为合伙人邀请的好友，可获得非合伙人邀请好友的2倍金额的优惠券。
            {"\n"}
            （5）成为合伙人之前的权益、收益，在邀请页面查看，成为合伙人后，权益、收益在合伙人中心查看。
            {"\n"}
            （6）铂金合伙人、钻石合伙人权益、收益更高，更详细内容，可与官方商务人员咨询了解。
            {"\n"}
            {"\n"}
            其他说明：{"\n"}
            1、只有你所邀请好友通过扫码你的专属海报登录，才计入奖励统计。{"\n"}
            2、新用户指的是之前未登陆过本平台的用户。{"\n"}
            3、若遇问题，请联系官方服务人员。{"\n"}
            4、活动解释权归司马诸葛所有。{"\n"}
          </ContentText>
        }
        onClose={() => setRuleVisible(false)}
      />
      {renderShare()}
      <Share
        visible={shareVisible}
        shareDesc={
          "温馨提示：\n你分享的所有图片/海报，都带有你的专属码。若用户通过你的海报扫码登录平台，就与你绑定了好友关系，你的好友在1年内任何时候购买会员等服务，你将获得佣金。"
        }
        content={<SharePoster data={posters || []} />}
        onClose={() => setShareVisible(false)}
      />
    </Page>
  );
};

export default PartnerCenterScreen;

const Page = styled.View`
  flex: 1;
  background-color: #f3924b;
`;

const PageView = styled.ScrollView``;
const HeaderImage = styled.ImageBackground`
  height: ${(wp(100) * 1248) / 1170}px;
`;
const HeaderRowView = styled.View`
  flex-direction: row;
  padding: 16px 0 16px 26px;
`;
const HeaderMaskView = styled.View`
  flex: 1;
`;
const HeaderMaskText = styled(Paragraph)`
  font-size: ${fp(3.9)}px;
  color: ${(props: IStyledProps) => props.theme.subPrimary};
`;
const HeaderRuleText = styled(Paragraph)`
  font-size: ${fp(3.2)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  background-color: #fee3cb;
  height: ${wp(6)}px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2px 2px 2px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;

const EarningImage = styled.ImageBackground`
  width: ${wp(96)}px;
  height: ${(wp(96) * 1023) / 1131}px;
  align-self: center;
  margin-top: ${wp(-78)}px;
`;

const AuthView = styled.View``;
const AuthDesc = styled(Paragraph)`
  padding: 8px 0 18px 0;
  width: ${wp(86)}px;
  font-size: ${fp(3.2)}px;
  color: ${(props: IStyledProps) => props.theme.subPrimary};
  margin-left: ${wp(7)}px;
`;
const AuthImage = styled.ImageBackground`
  width: ${wp(86)}px;
  height: ${(wp(86) * 777) / 1023}px;
  align-self: center;
`;

const RewardView = styled.View`
  background-color: #fdeeee;
  padding: ${wp(3)}px 12px ${wp(3)}px 12px;
  margin-left: ${wp(9.4)}px;
  margin-right: ${wp(9.4)}px;
  margin-top: ${wp(20)}px;
  border-radius: 10px;
`;
const EarningRewardView = styled.View`
  background-color: #fdeeee;
  padding: ${wp(3)}px 12px ${wp(3)}px 12px;
  margin-left: ${wp(4.4)}px;
  margin-right: ${wp(4.4)}px;
  margin-top: ${wp(16)}px;
  border-radius: 10px;
`;

const TotalSettleView = styled.View`
  align-self: center;
  margin-bottom: ${fp(-5)}px;
`;
const RewardHView = styled.View`
  flex-direction: row;
  justify-content: space-around;
`;
const RewardItemView = styled.Pressable`
  justify-content: center;
  align-items: center;
  width: 90px;
  margin-bottom: ${fp(5)}px;
`;
const RewardItemText = styled(Text)`
  font-size: ${fp(5.5)}px;
  color: #e02020;
  font-weight: bold;
  margin-top: ${wp(5)}px;
`;
const RewardItemDescText = styled(Text)`
  font-size: ${fp(3.2)}px;
  color: ${(props: IStyledProps) => props.theme.text};
  margin-top: ${wp(2)}px;
`;
const RewardButton = styled.Pressable`
  background-color: #eb7070;
  padding: 5px 10px 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;
const RewardButtonText = styled.Text`
  font-size: ${fp(3.3)}px;
  color: ${Colors.white};
`;

const RewardHWView = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: ${fp(5)}px;
`;
const RewardHLeftView = styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;
const RewardTextW = styled(Paragraph)`
  font-size: ${fp(3.2)}px;
  color: #030303;
`;
const RewardMoneyW = styled(Title)`
  font-size: ${fp(5.2)}px;
  color: #e02020;
`;
const Divider = styled.View`
  width: ${wp(70)}px;
  height: 0.5px;
  background-color: ${(props: IStyledProps) => props.theme.subPrimary};
`;

const ShareImage = styled.Image`
  width: ${wp(86)}px;
  height: ${(wp(86) * 117) / 1023}px;
  align-self: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const ContentText = styled(Paragraph)`
  font-size: ${fp(3.5)}px;
  color: ${Colors.grey600};
`;
const styles = StyleSheet.create({});
