import { fp } from "@/utils/dimension";
import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { StyleSheet } from "react-native";
import { Caption, Colors, Title } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  title: string;
  rightText?: string;
  rightRedText?: string;
  rightArrow?: boolean;
  onPress?: () => void;
}

/// the list item
const ListItem: React.FC<Props> = (props) => {
  return (
    <ItemView onPress={props.onPress}>
      <LeftView>
        <ItemTitle>{props.title}</ItemTitle>
      </LeftView>
      <RightView>
        <RightText>
          {props.rightText}
          <RightRedText>{props.rightRedText}</RightRedText>
        </RightText>
        {props.rightArrow && (
          <Ionicons
            name="md-chevron-forward"
            size={18}
            color={Colors.grey500}
          />
        )}
      </RightView>
    </ItemView>
  );
};

export default ListItem;

const ItemView = styled.Pressable`
  background: ${Colors.white};
  flex-direction: row;
  padding: 12px;
  align-items: center;
`;
const LeftView = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;
const ImageView = styled.View``;
const ItemTitle = styled(Title)`
  font-size: ${fp(4)}px;
`;

const RightView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const RightText = styled(Caption)`
  font-size: ${fp(3.4)}px;
`;
const RightRedText = styled(Caption)`
  font-size: ${fp(3.4)}px;
  color: ${(props: IStyledProps) => props.theme.redText};
`;

const styles = StyleSheet.create({});
