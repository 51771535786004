import React, { useReducer } from "react";
import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { Button, Colors } from "react-native-paper";
import MemberSelector from "@/components/member-selector";
import { NoticeItem, Row, Rows, Table, Toast } from "@/react-native-papal";
import { useDispatch, useSelector } from "react-redux";
import ListItem from "@/components/list/item";
import { PERSONAL_VIP_PURCHASE_DATA } from "@/constants/purchase-data";
import { DO_VIP_CREATE_ORDER, GET_COUPON_LIST } from "@/redux/models/me";
import { isWechat } from "@/utils/tools";
import WxTools from "@/utils/wechat/mp";
import { createAction, createActions } from "@/utils";
import { GET_USER_INFO } from "@/redux/models/app";
import CouponModal from "@/components/coupon/modal";
import ColorTable from "@/components/table";
import _ from "lodash";
import { useLockFn } from "@/react-native-ahook";
import { HEADERS, TABLE_DATA } from "@/constants/app";
import { fp, wp } from "@/utils/dimension";
import { LinkTo } from "@/utils/link";
import { ProcessUrl } from "@/utils/image";

interface Props {}

function reducer(state: any, action: any) {
  switch (action.type) {
    case "ModalVisible":
      return { ...state, modalVisible: action.value };
    case "VipSelectedItem":
      return { ...state, vipSelectedItem: action.value };
  }
}

/// the tab personal
const TabPersonal: React.FC<Props> = (props) => {
  const reduxDispatch = useDispatch();
  const [couponVisible, setCouponVisible] = React.useState(false);
  const [couponItem, setCouponItem] = React.useState<any>(null);
  const { dictDesc, couponList } = useSelector(({ me, loading }: any) => ({
    dictDesc: me.dictDesc,
    couponList: me.couponList,
  }));
  const [state, dispatch] = useReducer(reducer, {
    modalVisible: false,
    vipSelectedItem: {
      price: 0,
    },
  });

  // the PaymentView
  const renderPayment = () => {
    const memberData = dictDesc.personVip
      ? dictDesc.personVip.map((item: any, index: number) => {
          const remark3 = item.remark3.split(",");
          return {
            key: index + 1,
            title: item.dvalue,
            price: item.remark,
            realPrice: "¥" + remark3[0],
            desc: remark3[1] + "元/天",
            badge: remark3[2],
            pk: item.pk,
          };
        })
      : [];
    return (
      <PaymentView>
        <SectionTitle>开通会员VIP</SectionTitle>
        <MemberSelector
          style={styles.memberSelector}
          data={memberData}
          onCallback={(item) =>
            dispatch({ type: "VipSelectedItem", value: item || { price: 0 } })
          }
        />
        <NoticeItem data={PERSONAL_VIP_PURCHASE_DATA} />
        <MemberDescImage
          resizeMode="contain"
          source={{
            uri: ProcessUrl("/resource/ma/member_personal_right1.png"),
          }}
        />
        <MemberDesc2Image
          resizeMode="contain"
          source={{
            uri: ProcessUrl("/resource/ma/member_personal_right2.png"),
          }}
        />
      </PaymentView>
    );
  };

  // member privileges
  const renderPrivileges = () => {
    return (
      <PrivilegeView>
        <SectionTitle>会员特权</SectionTitle>
        <ColorTable headers={HEADERS} data={TABLE_DATA} />
      </PrivilegeView>
    );
  };

  // coupon
  const renderCoupon = () => {
    return (
      <ListItem
        title="优惠券"
        rightText={couponItem ? "已抵扣优惠券 " : "优惠券"}
        rightRedText={couponItem ? "-" + couponItem.priceNum : ""}
        rightArrow
        onPress={() => {
          reduxDispatch(
            createActions(GET_COUPON_LIST)({ status: 481, type: "personal" })(
              () => {}
            )
          );
          setCouponVisible(true);
        }}
      />
    );
  };

  // 下订单
  const doVipCreateOrder = useLockFn(async () => {
    if (isWechat()) {
      WxTools.getConfig(window.location.href, () => {
        reduxDispatch(
          createActions(DO_VIP_CREATE_ORDER)({
            vipType: 441,
            vipDictPk: state.vipSelectedItem && state.vipSelectedItem.pk,
            couponPk: couponItem && couponItem.pk,
          })((result: any) => {
            WxTools.wxPay(result, (res) => {
              reduxDispatch(createAction(GET_USER_INFO)());
              Toast.success("支付成功");
            });
            // 设置优惠券
            setCouponItem(null);
          })
        );
      });
    }
  });

  const renderDesc = () => {
    return (
      <DescView>
        <DescText>
          温馨提示：{"\n"}
          平台设置防盗刷系统，请合理使用账号，如果用户短时间内高频或大量查询下载可能触发该系统，将暂停账号下载功能。如有疑问请联系客服
        </DescText>
      </DescView>
    );
  };

  // render pay tool
  const renderPayTool = () => {
    let price;
    if (state.vipSelectedItem) {
      price = state.vipSelectedItem.price;
    }
    if (couponItem) {
      price = (price * 10 - couponItem.priceNum * 10) / 10;
    }

    return (
      <PayToolView>
        <Button
          style={styles.payButton}
          mode="contained"
          disabled={!state.vipSelectedItem || !state.vipSelectedItem.pk}
          color={Colors.amber200}
          onPress={() => doVipCreateOrder()}
        >
          支付¥{price}
          ,购买VIP,效率提升1整年
        </Button>
        {/* <BookText>
          购买成功后,立即享有VIP权益,会员时长从2月10日开始计算
        </BookText> */}
        <PayToolDesc>
          开通会员后表示您已经阅读并接受
          <PayToolRuleDesc
            onPress={() => LinkTo("https://smartchoose.cn/vipserviceagreement")}
          >
            《VIP会员服务协议》
          </PayToolRuleDesc>
        </PayToolDesc>
      </PayToolView>
    );
  };

  return (
    <PageView>
      <PageScrollView>
        {renderPayment()}
        {/* {renderPrivileges()} */}
        {renderCoupon()}
        {renderDesc()}
      </PageScrollView>
      {renderPayTool()}
      <CouponModal
        title="优惠券"
        visible={couponVisible}
        data={couponList}
        onClose={() => setCouponVisible(false)}
        onSurePress={(item) => {
          setCouponItem(item);
          setCouponVisible(false);
        }}
      />
    </PageView>
  );
};

export default React.memo(TabPersonal);

const PageView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.page};
  flex: 1;
`;

const PageScrollView = styled.ScrollView`
  padding-bottom: 4px;
`;

const PaymentView = styled.View`
  background-color: ${Colors.white};
  padding: 16px;
`;

const SectionTitle = styled.Text`
  font-size: ${fp(4)}px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.text};
`;

const PrivilegeView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const ListView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  margin-top: 12px;
`;

const RightView = styled.View`
  flex-direction: row;
  align-items: center;
`;

const PayToolView = styled.View`
  background-color: ${(props: IStyledProps) => props.theme.background};
  padding: 6px 16px 8px 16px;
  justify-content: center;
  align-items: center;
`;
const PayToolDesc = styled.Text`
  font-size: ${fp(3.3)}px;
  margin: 4px 0 4px 0;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;
const PayToolRuleDesc = styled.Text`
  font-size: ${fp(3.3)}px;
  color: ${(props: IStyledProps) => props.theme.primary};
`;

const BookText = styled.Text`
  margin-top: 6px;
  font-size: ${fp(3.3)}px;
  color: ${(props: IStyledProps) => props.theme.yellowText};
`;

const MemberDescImage = styled.Image`
  width: 100%;
  /* height: 100%; */
  height: ${((wp(100) - 32) * 1264) / 720}px;
  margin-top: 12px;
`;
const MemberDesc2Image = styled.Image`
  width: 100%;
  /* height: 100%; */
  height: ${((wp(100) - 32) * 1318) / 720}px;
  margin-top: 12px;
`;
const DescView = styled.View`
  background: ${Colors.white};
  flex-direction: row;
  padding: 12px;
  margin-top: 12px;
`;

const DescText = styled.Text`
  font-size: ${fp(3.5)}px;
  color: ${(props: IStyledProps) => props.theme.paragraph};
`;

const styles = StyleSheet.create({
  memberSelector: {
    marginTop: 16,
    marginBottom: 16,
  },
  caption: {
    fontSize: 14,
  },
  payButton: {
    width: "100%",
    borderRadius: 32,
  },
});
