export const SAVE = "save";

export const OSS_BASE_URLS = [
  "https://oss.supperdata.cn",
  "https://oss.smartchoose.cn",
];

/**
 * 土司时间
 */
export const TOAST_SHORT = 1;

export const TOAST_LOADING = 65;

/**
 * 刷新token
 */
export const REFRESH_TOKEN_URL = "/oauth/token";

/**
 * token
 */
export const TOKEN_SESSION = "smart:session:token";

/**
 * x-auth-token
 */
export const AUTH_TOKEN_FLAG = "x-auth-token";

/**
 * x-crypt
 */
export const CRYPT = "x-crypt";

/**
 * 权限
 */
export const PERMISSIONS = "permission";

/**
 *  基础认证
 */
export const BASIC_TYPE = "Basic Y3JheW9uOmNyYXlvbg==";

/**
 *  密码密钥
 */
export const AES_PASSWORD_KEY = "openiloveatum028";

/**
 * token密钥
 */
export const AES_TOKEN_KEY = "open,love,870605";

/**
 * 请求加密密钥
 */
export const AES_REQUEST_KEY = "forever,request,";

/**
 * 返回数据加密密钥
 */
export const AES_RESPONSE_KEY = "forever,response";

export const SETTLE_STATUS = { 556: "已结算", 550: "申请中" };

export const HEADERS = ["权益内容", "普通用户", "个人VIP", "团队VIP"];
export const TABLE_DATA = [
  ["查经济数据", "每日限5个", "√", "√"],
  ["经济数据对比", "×", "√", "√"],
  ["下载经济数据", "×", "√", "√"],
  ["行业图表数据", "前10个", "√", "√"],
  ["行业研报", "前10个", "√", "√"],
  ["搜索品牌", "前10个", "√", "√"],
  ["品牌列表", "前10个", "√", "√"],
  ["品牌数据", "每日限5个", "√", "√"],
  ["品牌详情", "部分", "√", "√"],
  ["品牌对比", "×", "√", "√"],
  ["申请合伙人资格", "×", "√", "√"],
  ["子账号管理", "×", "×", "√"],
];
