import { useMount } from "@/react-native-ahook";
import { GET_LIST_PRESENT_RECORD } from "@/redux/models/me";
import { createAction } from "@/utils";
import { fp, wp } from "@/utils/dimension";
import Color from "color";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, DataTable } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

interface Props {}

/// 赠送记录
const PresentRecordScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { presentRecord } = useSelector(({ me, loading }: any) => ({
    presentRecord: me.presentRecord,
  }));

  useMount(() => {
    dispatch(createAction(GET_LIST_PRESENT_RECORD)({}));
  });
  const presentData = presentRecord.map((item: any, index: number) => {
    return (
      <DataTable.Row key={index}>
        <DataTable.Cell>
          {dayjs(item.crtTime).format("YYYY-MM-DD HH:mm")}
        </DataTable.Cell>
        <DataTable.Cell numeric>{item.receiverMobile}</DataTable.Cell>
        <DataTable.Cell numeric>{item.presentName}</DataTable.Cell>
      </DataTable.Row>
    );
  });

  return (
    <PageView>
      <Table>
        <DataTable.Header>
          <DataTable.Cell>赠送时间</DataTable.Cell>
          <DataTable.Cell numeric>好友手机</DataTable.Cell>
          <DataTable.Cell numeric>赠送套餐</DataTable.Cell>
        </DataTable.Header>
        {presentData}
      </Table>
    </PageView>
  );
};

export default PresentRecordScreen;
const PageView = styled.ScrollView`
  flex: 1;
  background-color: ${(props: IStyledProps) => props.theme.page};
`;
const Table = styled(DataTable)`
  background-color: ${Colors.white};
`;

const TableTitle = styled(DataTable.Title)`
  font-size: ${fp(8)}px;
`;

const styles = StyleSheet.create({});
