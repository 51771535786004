import { Modal } from "@/react-native-papal";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  Button,
  Colors,
  DefaultTheme,
  Headline,
  Paragraph,
  Title,
} from "react-native-paper";
import styled from "styled-components/native";

interface Props {
  title?: string;
  visible?: boolean;
  content: React.ReactNode;
  sureText?: string;
  cancelText?: string;
  onClose?: () => void;
}

/// alert info
const AlertInfo: React.FC<Props> = (props) => {
  return (
    <ModalView
      visible={props.visible}
      transparent
      maskClosable={true}
      onClose={props.onClose}
    >
      <ContentView>
        <TitleText>{props.title}</TitleText>
        {props.content}
        <SureButton mode="contained" onPress={props.onClose}>
          {props.sureText}
        </SureButton>
      </ContentView>
    </ModalView>
  );
};

AlertInfo.defaultProps = {
  visible: false,
  sureText: "确定",
};

export default React.memo(AlertInfo);

const ModalView = styled(Modal)`
  justify-content: center;
  align-items: center;
`;
const ContentView = styled.View`
  justify-content: center;
  align-items: center;
`;
const TitleText = styled(Headline)`
  font-size: 17px;
  font-weight: bold;
`;

const ParagraphText = styled(Paragraph)`
  font-size: 16px;
  color: ${Colors.grey500};
`;

const ButtonView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`;

const SureButton = styled(Button)`
  background-color: ${Colors.blue500};
  margin-top: 12px;
`;

const styles = StyleSheet.create({});
