/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

import { RootStackParamList } from "../types";

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            path: "home",
            screens: {
              HomeScreen: "home",
            },
          },
          Economy: {
            path: "economy",
            screens: {
              EconomyScreen: "economy",
            },
          },
          Trade: {
            path: "trade",
            screens: {
              TradeScreen: "trade",
            },
          },
          Enterprise: {
            path: "enterprise",
            screens: {
              EnterpriseScreen: "enterprise",
            },
          },
          Me: {
            path: "me",
            screens: {
              MeScreen: "me",
            },
          },
        },
      },
      NotFound: "*",
      PersonalInfo: {
        path: "me/info",
        screens: {
          HomeScreen: "info",
        },
      },
      Member: "me/member",
      Coupon: "me/coupon",
      Redeem: "me/redeem",
      Present: "me/present",
      Account: "me/account",
      Invite: "me/invite",
      Withdraw: "me/withdraw",
      InviteRecord: "me/invite-record",
      Earnings: "me/earnings",
      Partner: "me/partner",
      PartnerCenter: "me/partner/center",
      EconomySearch: "economy/search",
      EconomyDetail: "economy/detail",
      Login: "login",
      EnterpriseList: "enterprise/list",
      EnterpriseDetail: "enterprise/detail",
      MobileBind: "me/info/mobile-bind",
      PresentRecord: "me/present/record",
      EnterpriseSearch: "enterprise/search",
      NationInfo: "enterprise/nation-info",
      CityInfo: "enterprise/city-info",
      PerCustomer: "enterprise/per-customer",
      FeedbackRate: "enterprise/feedback-rate",
      TradeChart: "trade/chart",
      TradeReport: "trade/report",
      TradePdf: "trade/pdf-view",
      SettleDetail: "trade/settle-detail",
      AccountRecord: "me/partner/account-record",
      AuthRecord: "me/partner/auth-record",
      AuthVip: "me/partner/auth-vip",
      MemberOrderRecord: "me/member/order-record",
    },
  },
};

export default linking;
